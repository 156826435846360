import React, { useState } from 'react';
import BillingApprovals from './BillingApprovals';
import AdminCampaigns from './AdminCampaigns';
import UserManagement from './UserManagement';
import CreateUnclaimedProfiles from './CreateUnclaimedProfiles';
import './AdminHub.css';

const AdminHub = () => {
    const [activeSection, setActiveSection] = useState('billing');

    const renderContent = () => {
        switch (activeSection) {
            case 'billing':
                return <BillingApprovals />;
            case 'campaigns':
                return <AdminCampaigns />;
            case 'dashboard':
                return <div>Dashboard Content Coming Soon</div>;
            case 'users':
                return <UserManagement />;
            case 'creators':
                return <CreateUnclaimedProfiles />;
            default:
                return <BillingApprovals />;
        }
    };

    return (
        <div className="admin-hub">
            <div className="admin-sidebar">
                <h2>Admin Panel</h2>
                <nav>
                    <button 
                        className={`admin-nav-button ${activeSection === 'dashboard' ? 'active' : ''}`}
                        onClick={() => setActiveSection('dashboard')}
                    >
                        Dashboard
                    </button>
                    <button 
                        className={`admin-nav-button ${activeSection === 'billing' ? 'active' : ''}`}
                        onClick={() => setActiveSection('billing')}
                    >
                        Affiliate Payout Approvals
                    </button>
                    <button 
                        className={`admin-nav-button ${activeSection === 'campaigns' ? 'active' : ''}`}
                        onClick={() => setActiveSection('campaigns')}
                    >
                        Campaigns
                    </button>
                    <button 
                        className={`admin-nav-button ${activeSection === 'users' ? 'active' : ''}`}
                        onClick={() => setActiveSection('users')}
                    >
                        Users
                    </button>
                    <button 
                        className={`admin-nav-button ${activeSection === 'creators' ? 'active' : ''}`}
                        onClick={() => setActiveSection('creators')}
                    >
                        Creator Profiles
                    </button>
                </nav>
            </div>
            <div className="admin-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default AdminHub;