import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../../firebase';
import config from '../../config/config';
import { Box, Button, Input, Text, VStack, Heading, Image, Code, Alert, AlertIcon, HStack, Divider } from '@chakra-ui/react';

const TestUpload = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [testResult, setTestResult] = useState(null);
    const [cloudinaryConfig, setCloudinaryConfig] = useState(null);
    const [configLoading, setConfigLoading] = useState(false);

    // Check Cloudinary config on component mount
    useEffect(() => {
        const checkCloudinaryConfig = async () => {
            setConfigLoading(true);
            try {
                const response = await axios.get(`${config.api.baseURL}/upload/check-cloudinary`);
                setCloudinaryConfig(response.data);
            } catch (error) {
                console.error('Error checking Cloudinary config:', error);
            } finally {
                setConfigLoading(false);
            }
        };
        
        checkCloudinaryConfig();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Test minimal endpoint
    const testMinimalEndpoint = async () => {
        setLoading(true);
        setError(null);
        setTestResult(null);
        
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.post(
                `${config.api.baseURL}/upload/minimal-test`,
                { test: 'data' },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            console.log('Minimal test response:', response.data);
            setTestResult(response.data);
        } catch (error) {
            console.error('Minimal test error:', error);
            setError(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };
    
    // Test echo file endpoint
    const testEchoFile = async () => {
        if (!file) {
            setError('Please select a file');
            return;
        }
        
        setLoading(true);
        setError(null);
        setTestResult(null);
        
        try {
            const formData = new FormData();
            formData.append('image', file);
            
            const response = await axios.post(
                `${config.api.baseURL}/upload/echo-file`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            
            console.log('Echo file response:', response.data);
            setTestResult(response.data);
        } catch (error) {
            console.error('Echo file error:', error);
            setError(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };
    
    // Test Cloudinary directly
    const testCloudinaryDirectly = async () => {
        setLoading(true);
        setError(null);
        setTestResult(null);
        
        try {
            const response = await axios.get(`${config.api.baseURL}/upload/direct-cloudinary-test`);
            console.log('Direct Cloudinary test response:', response.data);
            setTestResult(response.data);
        } catch (error) {
            console.error('Direct Cloudinary test error:', error);
            setError(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };

    // Add this function to your TestUpload component
    const testSimpleUpload = async () => {
        if (!file) {
            setError('Please select a file');
            return;
        }
        
        setLoading(true);
        setError(null);
        setTestResult(null);
        
        try {
            const token = await auth.currentUser.getIdToken();
            const formData = new FormData();
            formData.append('image', file);
            
            console.log('Testing simple upload with file:', file.name);
            
            const response = await axios.post(
                `${config.api.baseURL}/upload/simple-upload`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            
            console.log('Simple upload response:', response.data);
            setTestResult(response.data);
        } catch (error) {
            console.error('Simple upload error:', error);
            setError(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };

    // Add this function to your TestUpload component
    const testBase64Upload = async () => {
        if (!file) {
            setError('Please select a file');
            return;
        }
        
        setLoading(true);
        setError(null);
        setTestResult(null);
        
        try {
            const token = await auth.currentUser.getIdToken();
            
            // Convert file to base64
            const reader = new FileReader();
            
            reader.onload = async () => {
                try {
                    const base64data = reader.result;
                    console.log('File converted to base64, length:', base64data.length);
                    
                    const response = await axios.post(
                        `${config.api.baseURL}/upload/base64-upload`,
                        {
                            image: base64data,
                            fileName: file.name
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    
                    console.log('Base64 upload response:', response.data);
                    setTestResult(response.data);
                    setLoading(false);
                } catch (error) {
                    console.error('Base64 upload error:', error);
                    setError(error.response?.data?.message || error.message);
                    setLoading(false);
                }
            };
            
            reader.onerror = () => {
                setError('Error reading file');
                setLoading(false);
            };
            
            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Base64 conversion error:', error);
            setError(error.message);
            setLoading(false);
        }
    };

    return (
        <Box p={6} borderWidth="1px" borderRadius="lg" boxShadow="md">
            <VStack spacing={6} align="stretch">
                <Heading size="md">API Testing Tools</Heading>
                
                <Box>
                    <Heading size="sm" mb={2}>Test Minimal Endpoint</Heading>
                    <Text fontSize="sm" color="gray.600" mb={2}>
                        This tests a minimal endpoint that doesn't involve file uploads
                    </Text>
                    <Button 
                        onClick={testMinimalEndpoint} 
                        isLoading={loading} 
                        colorScheme="blue"
                        size="sm"
                    >
                        Test Minimal Endpoint
                    </Button>
                </Box>
                
                <Divider />
                
                <Box>
                    <Heading size="sm" mb={2}>Test Cloudinary Directly</Heading>
                    <Text fontSize="sm" color="gray.600" mb={2}>
                        This tests the Cloudinary configuration directly
                    </Text>
                    <Button 
                        onClick={testCloudinaryDirectly} 
                        isLoading={loading} 
                        colorScheme="green"
                        size="sm"
                    >
                        Test Cloudinary Config
                    </Button>
                </Box>
                
                <Divider />
                
                <Box>
                    <Heading size="sm" mb={2}>Test File Echo</Heading>
                    <Text fontSize="sm" color="gray.600" mb={2}>
                        This tests if the server can receive files without processing them
                    </Text>
                    <Input 
                        type="file" 
                        onChange={handleFileChange} 
                        accept="image/*"
                        p={1}
                        mb={2}
                    />
                    <Button 
                        onClick={testEchoFile} 
                        isLoading={loading} 
                        isDisabled={!file}
                        colorScheme="purple"
                        size="sm"
                    >
                        Echo File
                    </Button>
                </Box>
                
                <Box>
                    <Heading size="sm" mb={2}>Test Simple Upload</Heading>
                    <Text fontSize="sm" color="gray.600" mb={2}>
                        This tests a simplified file upload endpoint
                    </Text>
                    <Button 
                        onClick={testSimpleUpload} 
                        isLoading={loading} 
                        isDisabled={!file}
                        colorScheme="orange"
                        size="sm"
                    >
                        Simple Upload
                    </Button>
                </Box>
                
                <Box>
                    <Heading size="sm" mb={2}>Test Base64 Upload</Heading>
                    <Text fontSize="sm" color="gray.600" mb={2}>
                        This tests uploading a file as base64 data
                    </Text>
                    <Button 
                        onClick={testBase64Upload} 
                        isLoading={loading} 
                        isDisabled={!file}
                        colorScheme="teal"
                        size="sm"
                    >
                        Base64 Upload
                    </Button>
                </Box>
                
                {error && (
                    <Alert status="error" mt={4}>
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                
                {testResult && (
                    <Box mt={4}>
                        <Heading size="sm" mb={2}>Test Result:</Heading>
                        <Code p={3} borderRadius="md" overflowX="auto" fontSize="sm">
                            {JSON.stringify(testResult, null, 2)}
                        </Code>
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

export default TestUpload; 