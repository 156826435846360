import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCampaigns } from '../../api/api';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './CampaignList.css';
import { useAuth } from '../../contexts/AuthContext';

const truncateText = (text, maxLength = 60) => {
  if (!text) return 'Untitled Offer';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const getCategoryClass = (category) => {
  switch (category?.toLowerCase()) {
    case 'crypto':
      return 'category-crypto';
    case 'investing':
      return 'category-investing';
    case 'budgeting':
      return 'category-budgeting';
    case 'banking':
      return 'category-banking';
    case 'retirement':
      return 'category-retirement';
    case 'fintech':
      return 'category-fintech';
    case 'realestate':
      return 'category-realestate';
    case 'credit':
      return 'category-credit';
    case 'education':
      return 'category-education';
    case 'student':
      return 'category-student';
    case 'business':
      return 'category-business';
    case 'sustainable':
      return 'category-sustainable';
    case 'innovation':
      return 'category-innovation';
    case 'security':
      return 'category-security';
    default:
      return 'category-default';
  }
};

const CampaignList = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState(null);
  const [filters, setFilters] = useState({
    niche: 'all',
    minPayout: '',
    maxPayout: '',
    datePosted: 'all',
    status: 'all'
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const loadCampaigns = async () => {
      try {
        setLoading(true);
        setError(null);
        
        let campaignsData;
        if (user) {
          const token = await user.getIdToken();
          campaignsData = await fetchCampaigns(token);
        } else {
          campaignsData = await fetchCampaigns();
        }
        
        if (Array.isArray(campaignsData)) {
          console.log(`Loaded ${campaignsData.length} campaigns`);
          setCampaigns(campaignsData);
        } else {
          console.warn('Received invalid campaigns data:', campaignsData);
          setCampaigns([]);
        }
      } catch (error) {
        console.error('Error loading campaigns:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadCampaigns();
  }, [user]);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const role = userDoc.exists() ? userDoc.data().role : null;
          setUserType(role);
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      } else {
        setUserType(null);
      }
    };

    fetchUserRole();
  }, [user]);

  const calculateAveragePayout = (campaigns) => {
    // Check if campaigns is an array and not empty
    if (!Array.isArray(campaigns) || campaigns.length === 0) {
      return 0;
    }
    
    const totalPayout = campaigns.reduce((sum, campaign) => {
      // Convert payout to number, default to 0 if invalid
      const payoutValue = Number(campaign?.payout) || 0;
      return sum + payoutValue;
    }, 0);
    
    return Math.round(totalPayout / campaigns.length);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getFilteredCampaigns = () => {
    return campaigns.filter(campaign => {
      // Niche filter
      if (filters.niche !== 'all' && campaign.productCategory?.toLowerCase() !== filters.niche) {
        return false;
      }

      // Payout filter
      const payout = Number(campaign.payout) || 0;
      if (filters.minPayout && payout < Number(filters.minPayout)) {
        return false;
      }
      if (filters.maxPayout && payout > Number(filters.maxPayout)) {
        return false;
      }

      // Date Posted filter
      if (filters.datePosted !== 'all') {
        const campaignDate = new Date(campaign.createdAt);
        const now = new Date();
        
        switch (filters.datePosted) {
          case 'today':
            if (campaignDate.toDateString() !== now.toDateString()) return false;
            break;
          case 'week':
            const weekAgo = new Date(now.setDate(now.getDate() - 7));
            if (campaignDate < weekAgo) return false;
            break;
          case 'month':
            const monthAgo = new Date(now.setMonth(now.getMonth() - 1));
            if (campaignDate < monthAgo) return false;
            break;
          default:
            break;
        }
      }

      // Status filter
      if (filters.status !== 'all' && campaign.status?.toLowerCase() !== filters.status) {
        return false;
      }

      return true;
    });
  };

  if (loading) {
    return (
      <div className="campaigns-section">
        <div className="campaigns-container">
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="campaigns-section">
        <div className="campaigns-container">
          <div className="error-container">
            <p>Error loading campaigns: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="campaigns-section">
      <div className="campaigns-container">
        <div className="campaigns-header">
          <div className="header-content">
            <div className="header-text">
              <h1 className="campaigns-title">Turn Your Influence Into Income</h1>
              <p className="campaigns-subtitle">Partner with leading fintech brands and create value for your audience</p>
            </div>

            <div className="campaigns-stats">
              {userType === 'brand' && (
                <button 
                  className="create-campaign-btn"
                  onClick={() => window.location.href = `${window.location.origin}/create-campaign`}
                >
                  Create Campaign
                </button>
              )}
              <div className="stat-item">
                <div className="stat-number">{getFilteredCampaigns().length}</div>
                <div className="stat-label">Active Campaigns</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">${calculateAveragePayout(getFilteredCampaigns())}</div>
                <div className="stat-label">Per Sale</div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="campaign-promo-banner">
          <div className="promo-content">
            <div className="promo-icon">🎯</div>
            <div className="promo-text">
              <h3>Apply To Promote FinTech Brands</h3>
              <p>Browse fintech brands and earn commissions by promoting them to your audience. Click "Learn More" to apply for campaigns.</p>
            </div>
          </div>
        </div>
        
        <div className="campaigns-layout">
          <button 
            className={`filter-toggle-btn ${isFilterVisible ? 'active' : ''}`}
            onClick={() => setIsFilterVisible(!isFilterVisible)}
          >
            <span>Filters</span>
            <svg 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>

          <div className={`campaigns-filters ${isFilterVisible ? 'show' : ''}`}>
            <div className="filters-header">
              <h3>Filters</h3>
              <button 
                onClick={() => {
                  setFilters({
                    niche: 'all',
                    minPayout: '',
                    maxPayout: '',
                    datePosted: 'all',
                    status: 'all'
                  });
                }}
              >
                Reset
              </button>
            </div>

            <div className="filter-group">
              <label>Niche</label>
              <select name="niche" value={filters.niche} onChange={handleFilterChange}>
                <option value="all">All Niches</option>
                <option value="crypto">Crypto</option>
                <option value="investing">Investing</option>
                <option value="budgeting">Budgeting</option>
                <option value="banking">Banking</option>
                <option value="retirement">Retirement</option>
                <option value="fintech">Fintech</option>
                <option value="realestate">Real Estate</option>
                <option value="credit">Credit</option>
                <option value="education">Financial Education</option>
                <option value="student">Student Finance</option>
                <option value="business">Business Finance</option>
                <option value="sustainable">Sustainable Finance</option>
                <option value="innovation">Innovation</option>
                <option value="security">Security</option>
              </select>
            </div>

            <div className="filter-group">
              <label>Pay Per Sale</label>
              <div className="range-inputs">
                <input
                  type="number"
                  name="minPayout"
                  placeholder="Min $"
                  value={filters.minPayout}
                  onChange={handleFilterChange}
                />
                <input
                  type="number"
                  name="maxPayout"
                  placeholder="Max $"
                  value={filters.maxPayout}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Date Posted</label>
              <select name="datePosted" value={filters.datePosted} onChange={handleFilterChange}>
                <option value="all">All Time</option>
                <option value="today">Today</option>
                <option value="week">This Week</option>
                <option value="month">This Month</option>
              </select>
            </div>

            <div className="filter-group">
              <label>Status</label>
              <select name="status" value={filters.status} onChange={handleFilterChange}>
                <option value="all">All Status</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>

          <div className="campaigns-grid">
            {Array.isArray(campaigns) && getFilteredCampaigns().map((campaign) => (
              <div 
                key={campaign.id} 
                className={`campaign-card ${getCategoryClass(campaign.productCategory)}`}
              >
                <div className="campaign-list-header">
                  <div className="campaign-list-image">
                    {campaign.imageUrl ? (
                      <img src={campaign.imageUrl} alt={campaign.offerName || 'Campaign'} />
                    ) : (
                      <div className="placeholder-image">
                        {(campaign.campaignHeadline || 'C')[0]}
                      </div>
                    )}
                  </div>
                  <div className="campaign-list-info">
                    <h2 className="campaign-title">
                      {campaign.campaignHeadline || 'Untitled Campaign'}
                    </h2>
                    <p className="campaign-brand">
                      {campaign.brand || 'Brand Name'} - {campaign.productDescription}
                    </p>
                  </div>
                </div>
                
                <div className="campaign-content">
                  <div className="campaign-inner-content">
                    <div className="campaign-details">
                      <div className="payout-info">
                        <span className="payout-amount">${campaign.payout || '0'}</span>
                        <span className="payout-label">pay per sale</span>
                      </div>
                      <div className={`campaign-category ${getCategoryClass(campaign.productCategory)}`}>
                        {campaign.productCategory || 'Uncategorized'}
                      </div>
                    </div>
                    <div className="campaign-actions">
                      <button 
                        className="learn-more-btn"
                        onClick={() => navigate(`/campaigns/${campaign.id}`)}
                      >
                        Learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
            {(!Array.isArray(campaigns) || getFilteredCampaigns().length === 0) && (
              <div className="no-campaigns">
                <p>No campaigns match your filters.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignList;
