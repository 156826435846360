import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './AdminCampaigns.css';

const AdminCampaigns = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCampaigns();
    }, []);

    const fetchCampaigns = async () => {
        try {
            setLoading(true);
            const campaignsRef = collection(db, 'campaigns');
            const snapshot = await getDocs(campaignsRef);
            const campaignsList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            console.log('Fetched campaigns:', campaignsList);
            setCampaigns(campaignsList);
        } catch (error) {
            console.error('Error fetching campaigns:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const formatTimestamp = (timestamp) => {
        try {
            if (!timestamp) return 'N/A';
            if (typeof timestamp === 'string') return timestamp;
            if (timestamp.toDate && typeof timestamp.toDate === 'function') {
                const date = timestamp.toDate();
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
            }
            return 'Invalid date';
        } catch (error) {
            console.error('Error formatting timestamp:', error);
            return 'Invalid date';
        }
    };

    const formatDate = (date) => {
        if (!date) return 'Not set';
        try {
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        } catch (error) {
            return date.toString();
        }
    };

    if (loading) return <div className="admin-loading-state">Loading campaigns...</div>;
    if (error) return <div className="admin-error-message">{error}</div>;

    return (
        <div className="admin-campaigns-container">
            <div className="admin-campaigns-header">
                <h2>Campaign Management</h2>
                <div className="admin-campaign-actions">
                    <button 
                        className="admin-create-campaign-btn"
                        onClick={() => navigate('/create-campaign')}
                    >
                        Create New Campaign
                    </button>
                </div>
            </div>

            <div className="admin-campaigns-grid">
                {campaigns.map(campaign => (
                    <div key={campaign.id} className="admin-campaign-card">
                        <div className="admin-campaign-info">
                            <div className="admin-campaign-header">
                                <h3>{campaign.offerName || campaign.programName || 'Untitled Campaign'}</h3>
                                <span className={`admin-status-badge admin-status-${campaign.status?.toLowerCase()}`}>
                                    {campaign.status || 'Active'}
                                </span>
                            </div>
                            
                            <div className="admin-campaign-details">
                                <div className="admin-detail-row">
                                    <strong>Brand:</strong>
                                    <span>{campaign.brandName || campaign.brand || 'Unknown Brand'}</span>
                                </div>
                                
                                <div className="admin-detail-row">
                                    <strong>Category:</strong>
                                    <span>{campaign.productCategory || 'N/A'}</span>
                                </div>

                                <div className="admin-detail-row">
                                    <strong>Type:</strong>
                                    <span>{campaign.campaignType || 'N/A'}</span>
                                </div>

                                <div className="admin-detail-row">
                                    <strong>Payout:</strong>
                                    <span>${campaign.payout || '0'}</span>
                                </div>

                                <div className="admin-detail-row">
                                    <strong>Product:</strong>
                                    <span>{campaign.productName || 'N/A'}</span>
                                </div>

                                <div className="admin-detail-row">
                                    <strong>Platforms:</strong>
                                    <span>{campaign.platformRequirements?.allowedPlatforms || 'Any'}</span>
                                </div>

                                <div className="admin-detail-row">
                                    <strong>Start Date:</strong>
                                    <span>{formatDate(campaign.startDate)}</span>
                                </div>

                                <div className="admin-detail-row">
                                    <strong>End Date:</strong>
                                    <span>{formatDate(campaign.endDate)}</span>
                                </div>
                            </div>

                            {campaign.description && (
                                <div className="admin-campaign-description">
                                    <p>{campaign.description}</p>
                                </div>
                            )}
                        </div>
                        
                        <div className="admin-campaign-dates">
                            <p>Created: {formatTimestamp(campaign.createdAt)}</p>
                            <p>Updated: {formatTimestamp(campaign.updatedAt)}</p>
                        </div>

                        <div className="admin-campaign-actions">
                            <button 
                                className="admin-edit-button"
                                onClick={() => navigate(`/campaigns/${campaign.id}/edit`)}
                            >
                                Edit Campaign
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {campaigns.length === 0 && (
                <div className="admin-no-campaigns">
                    <p>No campaigns found</p>
                </div>
            )}
        </div>
    );
};

export default AdminCampaigns; 