import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        return auth.onIdTokenChanged(async (user) => {
            if (user) {
                const token = await user.getIdTokenResult();
                setIsAdmin(!!token.claims.admin);
            } else {
                setIsAdmin(false);
            }
            setLoading(false);
        });
    }, []);

    return (
        <AdminContext.Provider value={{ isAdmin, loading }}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdmin = () => useContext(AdminContext); 