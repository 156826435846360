import { getAuth } from 'firebase/auth';
import config from '../config/config';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

// Debug the current environment and API URL
console.log('Current environment:', process.env.NODE_ENV);
console.log('Raw API URL from env:', process.env.REACT_APP_API_URL);

// FIXED - Export the API base URL without any hardcoded /api
export const API_BASE_URL = process.env.REACT_APP_API_URL || 
  (process.env.NODE_ENV === 'development' 
    ? 'http://localhost:5001/api' 
    : 'https://us-central1-hype-9b87b.cloudfunctions.net/api');

// Debug log the final URL
console.log('Using API base URL:', API_BASE_URL);

// Test function to detect double /api
(function checkApiUrl() {
  if (API_BASE_URL.includes('/api/api')) {
    console.error('CRITICAL ERROR: API URL contains double /api path:', API_BASE_URL);
  }
  
  const testUrls = {
    creators: `${API_BASE_URL}/creators`,
    campaigns: `${API_BASE_URL}/campaigns`,
    checkout: `${API_BASE_URL}/checkout/test`
  };
  
  console.log('Test URLs being used:', testUrls);
})();

// Helper function to get auth token
async function getToken() {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
        throw new Error('No authenticated user found');
    }
    
    return await user.getIdToken();
}

// Common fetch configuration for public routes
const fetchPublic = async (url, options = {}) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Origin': window.location.origin
        };

        const response = await fetch(url, {
            ...options,
            headers: {
                ...headers,
                ...options.headers
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

// Public routes
export const getCreators = async () => {
    try {
        const url = `${API_BASE_URL}/creators`;
        console.log('Fetching creators from:', url);
        const data = await fetchPublic(url);
        return data.creators || [];
    } catch (error) {
        console.error('Error in getCreators:', error);
        return [];
    }
};

export const fetchCampaigns = async () => {
    try {
        const url = `${API_BASE_URL}/campaigns`;
        console.log('Fetching campaigns from:', url);
        const data = await fetchPublic(url);
        return data.campaigns || [];
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        return [];
    }
};

// Keep all your existing functions
export const registerCreator = async (userData) => {
    const response = await fetch(`${API_BASE_URL}/register/creator`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });

    if (!response.ok) {
        throw new Error('Registration failed');
    }

    return response.json();
};

export const registerBrand = async (userData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/auth/register/brand`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
            credentials: 'include'
        });

        let data;
        try {
            data = await response.json();
        } catch (e) {
            console.error('Error parsing JSON response:', e);
            throw new Error('Invalid server response');
        }

        if (!response.ok) {
            throw new Error(data.message || data.error || 'Registration failed');
        }

        return data;
    } catch (error) {
        console.error('Error during registration:', error);
        throw error.message ? error : new Error('Network error during registration');
    }
};

export const login = async (email, password) => {
    const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
        throw new Error('Login failed');
    }

    return response.json();
};

export const getCampaignById = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/campaigns/${id}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch campaign: ${response.status}`);
        }
        return response.json();
    } catch (error) {
        console.error('Error fetching campaign:', error);
        throw error;
    }
};

// Protected endpoints - require authentication
export const getBrandProfile = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/brand/profile`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            }
        });

        if (response.status === 431) {
            return { role: 'brand' };
        }

        if (!response.ok) {
            return null;
        }

        const data = await response.json();
        return data;
    } catch {
        return { role: 'brand' };
    }
};

export const fetchSelectedCampaigns = async () => {
    try {
        const token = await getAuth().currentUser?.getIdToken();

        const response = await fetch(`${API_BASE_URL}/campaigns/selected`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            throw new Error(`Expected JSON response but got ${contentType}`);
        }

        const data = await response.json();

        if (!response.ok) {
            throw new Error(`Failed to fetch selected campaigns: ${JSON.stringify(data)}`);
        }

        return data;
    } catch (error) {
        throw error;
    }
};

