import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Text, 
    Badge, 
    Grid,
    GridItem,
    Avatar,
    Button,
    Spinner,
    useColorModeValue,
    HStack,
    Flex,
    Stack
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { db, auth } from '../../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

const OrderCard = ({ order, userRole }) => {
    const [offerData, setOfferData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [brandData, setBrandData] = useState({
        name: 'Loading...',
        image: ''
    });
    const navigate = useNavigate();
    
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const bgColor = useColorModeValue('white', 'gray.800');
    const badgeBg = useColorModeValue('blue.100', 'blue.900');
    const badgeColor = useColorModeValue('blue.800', 'blue.200');
    
    // Fetch offer data when component mounts
    useEffect(() => {
        const fetchOfferData = async () => {
            try {
                setLoading(true);
                
                // Check for creatorOffer field first
                let offerId = order.creatorOffer;
                
                // If no creatorOffer, check if there's an item with offerId in the items array
                if (!offerId && Array.isArray(order.items) && order.items.length > 0) {
                    const item = order.items.find(item => item.offerId);
                    if (item?.offerId) {
                        offerId = item.offerId;
                    }
                }
                
                // If still no offerId, check order directly
                if (!offerId) {
                    offerId = order.offerId || order.id;
                }
                
                if (offerId) {
                    // First try direct document lookup
                    const offerDocRef = doc(db, 'creatorOffers', offerId);
                    const offerDoc = await getDoc(offerDocRef);
                    
                    if (offerDoc.exists()) {
                        setOfferData({ id: offerDoc.id, ...offerDoc.data() });
                        setLoading(false);
                        return;
                    }
                    
                    // If not found, try offers collection
                    const regularOfferDocRef = doc(db, 'offers', offerId);
                    const regularOfferDoc = await getDoc(regularOfferDocRef);
                    
                    if (regularOfferDoc.exists()) {
                        setOfferData({ id: regularOfferDoc.id, ...regularOfferDoc.data() });
                        setLoading(false);
                        return;
                    }
                }
                
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        
        fetchOfferData();
    }, [order]);
    
    useEffect(() => {
        const fetchBrandData = async () => {
            if (order.userId) {
                try {
                    const userRef = doc(db, 'users', order.userId);
                    const userDoc = await getDoc(userRef);
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setBrandData({
                            name: userData.brandName || 'Unknown Brand',
                            image: userData.brandImage || userData.photoURL || ''
                        });
                    }
                } catch (err) {
                    console.error('Error fetching brand data:', err);
                    setBrandData({
                        name: 'Unknown Brand',
                        image: ''
                    });
                }
            }
        };

        fetchBrandData();
    }, [order.userId]);
    
    // Helper function to get initials from name
    const getInitials = (name) => {
        if (!name) return '?';
        return name
            .split(' ')
            .map(part => part[0])
            .join('')
            .toUpperCase();
    };
    
    // Get creator info from order or offer
    const getCreatorInfo = () => {
        // First try to get from order.items
        if (Array.isArray(order.items) && order.items.length > 0) {
            const item = order.items[0];
            if (item.creatorName || item.creatorImage) {
                return {
                    name: item.creatorName || 'Creator',
                    avatar: item.creatorImage || '',
                    id: item.creatorId || ''
                };
            }
        }
        
        // Then try to get from offerData
        if (offerData && offerData.creatorName) {
            return {
                name: offerData.creatorName || 'Creator',
                avatar: offerData.creatorImage || '',
                id: offerData.creatorId || ''
            };
        }
        
        // Fallback to order
        return {
            name: order.creatorName || 'Creator',
            avatar: order.creatorImage || '',
            id: order.creatorId || ''
        };
    };
    
    // Get brand info from order or offer
    const getBrandInfo = () => {
        // First try to get from order
        if (order.brandName || order.brandImage) {
            return {
                name: order.brandName || 'Brand',
                avatar: order.brandImage || '',
                id: order.brandId || order.userId || ''
            };
        }
        
        // Then try to get from order.items
        if (Array.isArray(order.items) && order.items.length > 0) {
            const item = order.items[0];
            if (item.brandName || item.brandImage) {
                return {
                    name: item.brandName || 'Brand',
                    avatar: item.brandImage || '',
                    id: item.brandId || item.userId || ''
                };
            }
        }
        
        // Fallback to user info
        return {
            name: order.userName || 'Brand',
            avatar: order.userImage || '',
            id: order.userId || ''
        };
    };
    
    // Get formatted date
    const getFormattedDate = () => {
        let date;
        
        if (order.createdAt?.toDate) {
            // Firestore timestamp
            date = order.createdAt.toDate();
        } else if (order.createdAt?.seconds) {
            // Firestore timestamp in seconds
            date = new Date(order.createdAt.seconds * 1000);
        } else if (order.createdAt) {
            // ISO string or timestamp
            date = new Date(order.createdAt);
        } else if (order.timestamp?.toDate) {
            // Fallback to timestamp
            date = order.timestamp.toDate();
        } else if (order.timestamp?.seconds) {
            // Fallback to timestamp in seconds
            date = new Date(order.timestamp.seconds * 1000);
        } else if (order.timestamp) {
            // ISO string or timestamp
            date = new Date(order.timestamp);
        } else {
            // Default to now
            date = new Date();
        }
        
        try {
            return formatDistanceToNow(date, { addSuffix: true });
        } catch (error) {
            console.error("Error formatting date:", error, date);
            return "Unknown date";
        }
    };
    
    // Get order title
    const getOrderTitle = () => {
        // First try to get from offerData
        if (offerData) {
            if (offerData.type) {
                return offerData.type;
            }
            
            if (offerData.title) {
                return offerData.title;
            }
        }
        
        // Then try to get from order.items
        if (Array.isArray(order.items) && order.items.length > 0) {
            const item = order.items[0];
            if (item.type) {
                return item.type;
            }
            
            if (item.title) {
                return item.title;
            }
        }
        
        // Fallback to order
        return order.type || order.title || 'Order';
    };
    
    // Get price
    const getPrice = () => {
        // First try to get from order
        if (order.price) {
            return order.price;
        }
        
        // Then try to get from order.items
        if (Array.isArray(order.items) && order.items.length > 0) {
            const item = order.items[0];
            if (item.price) {
                return item.price;
            }
        }
        
        // Then try to get from offerData
        if (offerData && offerData.price) {
            return offerData.price;
        }
        
        return order.price || 0;
    };
    
    // Handle view click
    const handleViewProject = () => {
        // Use the document ID from the order object for navigation
        navigate(`/project-status/${order.id}`);
    };
    
    const creatorInfo = getCreatorInfo();
    const brandInfo = getBrandInfo();
    
    // Determine which user info to display based on userRole
    const displayInfo = userRole === 'brand' ? creatorInfo : {
        name: brandData.name,
        avatar: brandData.image
    };
    
    if (loading) {
        return (
            <Box 
                p={4} 
                borderWidth="1px" 
                borderRadius="lg" 
                borderColor={borderColor}
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="120px"
                bg={bgColor}
                mb={4}
            >
                <Spinner />
            </Box>
        );
    }
    
    return (
        <Box 
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
            borderWidth="1px" 
            borderRadius="lg" 
            borderColor={borderColor}
            position="relative"
            transition="all 0.2s"
            _hover={{ borderColor: 'gray.300' }}
            bg={bgColor}
            mb={4}
        >
            <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                <Box flex="1">
                    <Text 
                        fontSize={{ base: "lg", md: "xl" }} 
                        fontWeight="bold" 
                        mb={3}
                        noOfLines={2}
                    >
                        {getOrderTitle()}
                    </Text>
                    
                    <HStack spacing={3} align="center" mb={3}>
                        <Avatar 
                            size={{ base: "sm", md: "md" }}
                            name={displayInfo.name}
                            src={displayInfo.avatar}
                        />
                        <Text 
                            fontSize={{ base: "sm", md: "md" }}
                            fontWeight="medium"
                            noOfLines={1}
                        >
                            {displayInfo.name}
                        </Text>
                    </HStack>
                    
                    <Flex 
                        justify={{ base: "space-between", md: "flex-start" }}
                        align="center"
                        gap={4}
                    >
                        <Badge 
                            bg={badgeBg}
                            color={badgeColor}
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontWeight="medium"
                            fontSize="sm"
                        >
                            {order.status?.toUpperCase() || 'PENDING'}
                        </Badge>
                        
                        <Text 
                            fontSize="sm" 
                            color="gray.500"
                            display={{ base: 'block', md: 'none' }}
                        >
                            {getFormattedDate()}
                        </Text>
                    </Flex>
                </Box>
                
                <Flex 
                    direction="column" 
                    align={{ base: "stretch", md: "flex-end" }}
                    justify="space-between"
                    gap={3}
                >
                    <Text 
                        fontSize="sm" 
                        color="gray.500" 
                        display={{ base: 'none', md: 'block' }}
                    >
                        {getFormattedDate()}
                    </Text>
                    
                    <Stack 
                        direction={{ base: "column", md: "row" }}
                        align={{ base: "stretch", md: "center" }}
                        spacing={3}
                    >
                        <Button 
                            colorScheme="blue" 
                            size="sm"
                            borderRadius="md"
                            onClick={handleViewProject}
                            width={{ base: "full", md: "auto" }}
                            order={{ base: 2, md: 1 }}
                        >
                            View Project Status
                        </Button>
                        <Text 
                            fontSize={{ base: "xl", md: "2xl" }}
                            fontWeight="bold"
                            textAlign={{ base: "center", md: "right" }}
                            order={{ base: 1, md: 2 }}
                        >
                            ${getPrice()}
                        </Text>
                    </Stack>
                </Flex>
            </Stack>
        </Box>
    );
};

export default OrderCard; 