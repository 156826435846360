import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext';

const CartIcon = () => {
  const { cartItems } = useCart();
  
  // Calculate total quantity of items in cart
  const cartItemCount = cartItems?.reduce((total, item) => total + (item.quantity || 1), 0) || 0;
  
  return (
    <Box position="relative" display="inline-block">
      <Icon as={FaShoppingCart} boxSize={5} />
      {cartItemCount > 0 && (
        <Box
          position="absolute"
          top="-8px"
          right="-8px"
          bg="red.500"
          color="white"
          borderRadius="full"
          width="18px"
          height="18px"
          fontSize="xs"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cartItemCount}
        </Box>
      )}
    </Box>
  );
};

export default CartIcon; 