import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';

const PasswordResetComplete = () => {
    return (
        <div className="login-container">
            <div className="login-form">
                <h2 className="login-title">Password Reset Complete</h2>
                
                <div className="success-message">
                    <p>Password changed successfully!</p>
                    <p>You can now sign in with your new password.</p>
                </div>
                
                <Link to="/login" className="form-submit-button">
                    Return to Login
                </Link>
            </div>
        </div>
    );
};

export default PasswordResetComplete; 