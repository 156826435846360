import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate, Link, useParams } from 'react-router-dom';
import CreatorProfileImage from './CreatorProfileImage';
import './CreatorProfile.css';
import { useToast as useChakraToast } from '@chakra-ui/react';
import CreatorDetailsPage from '../CreatorDetails/CreatorDetailsPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timeline } from 'react-twitter-widgets';
import CreatorPayments from '../CreatorPayments/CreatorPayments';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
    Box,
    Flex,
    VStack,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    IconButton,
    Container,
    Heading,
    Text,
    Button,
    useBreakpointValue,
    Stack,
    Progress,
    Grid,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Select,
    Badge,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    ChevronDownIcon,
    Spinner,
    UnorderedList,
    ListItem,
    Icon,
    SimpleGrid
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faEdit, faUser, faShareAlt, faImages, faBriefcase, faChartBar, faCog, faPlus, faTrash, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import CloudinaryUploadWidget from '../../utils/CloudinaryUploadWidget'; // Update the import path to point to the utils folder
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { FaPlay, FaPlus, FaTrash } from 'react-icons/fa';
import toast from 'react-hot-toast';
import MediaUploader from '../../utils/MediaUploader';

library.add(faChevronDown, faEdit, faUser, faShareAlt, faImages, faBriefcase, faChartBar, faCog, faPlus, faTrash, faExternalLinkAlt);

const validateUsername = (username) => {
    // Only allow letters, numbers, and underscores, no spaces
    const regex = /^[a-zA-Z0-9_]+$/;
    return regex.test(username);
};

const getYoutubeVideoId = (url) => {
    if (!url) return '';
    const matches = url.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return matches ? matches[1] : '';
};

const getInstagramUsername = (url) => {
    if (!url) return '';
    const matches = url.match(/instagram\.com\/([^/?]+)/);
    return matches ? matches[1] : '';
};

const getTikTokVideoId = (url) => {
    if (!url) return null;
    const regex = /video\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
};

const getTwitchUsername = (url) => {
    if (!url) return '';
    const matches = url.match(/twitch\.tv\/([^/?]+)/);
    return matches ? matches[1] : '';
};

const getTwitterUsername = (url) => {
    if (!url) return '';
    const matches = url.match(/twitter\.com\/([^/?]+)/);
    return matches ? matches[1] : '';
};

const ProfileCompletionStatus = ({ profile }) => {
    
    const requirements = [
        { name: 'Photo', met: !!profile.profileImageUrl },
        { name: 'Name', met: !!profile.name },
        { name: 'Bio', met: !!profile.bio },
        { name: 'Category', met: !!profile.primaryCategory },
        { name: 'Country', met: !!profile.country },
        { name: 'Social', met: Object.values(profile.socialLinks || {}).some(link => !!link) },
        { name: 'Followers', met: Object.values(profile.followers || {}).reduce((sum, count) => sum + (parseInt(count) || 0), 0) >= 1 },
        { name: 'Username', met: !!profile.username && validateUsername(profile.username) }
    ];

    const completedCount = requirements.filter(req => req.met).length;
    const completionPercentage = Math.round((completedCount / requirements.length) * 100);
    const isComplete = completedCount === requirements.length;

    return (
        <Box p={3} bg="white" borderRadius="md">
            <Flex justify="space-between" align="center" mb={2}>
                <Text fontSize="sm" fontWeight="medium">Profile Status</Text>
                <Badge
                    colorScheme={isComplete ? 'green' : 'yellow'}
                    fontSize="xs"
                    px={2}
                    py={1}
                    borderRadius="full"
                >
                    {isComplete ? 'Complete' : 'Incomplete'}
                </Badge>
            </Flex>
            
            <Progress
                value={completionPercentage}
                size="xs"
                colorScheme={isComplete ? 'green' : 'blue'}
                mb={3}
            />

            <Grid 
                templateColumns={{ 
                    base: "repeat(2, 1fr)", 
                    sm: "repeat(4, 1fr)", 
                    md: "repeat(4, 1fr)" 
                }}
                gap={2}
            >
                {requirements.map((req, index) => (
                    <Flex
                        key={index}
                        align="center"
                        fontSize="xs"
                        color={req.met ? 'green.500' : 'gray.500'}
                    >
                        <Box mr={1}>{req.met ? '✓' : '○'}</Box>
                        <Text>{req.name}</Text>
                    </Flex>
                ))}
            </Grid>
        </Box>
    );
};

const GettingStartedContent = ({ setActiveSection }) => (
    <Box className="content-section" p={{ base: 4, md: 8 }}>
        <VStack 
            spacing={8} 
            align="stretch"
            maxW="500px"
            mx="auto"
            bg="white"
            borderRadius="2xl"
            p={{ base: 6, md: 10 }}
            shadow="xl"
        >
            <VStack spacing={1} mb={2}>
                <Text 
                    fontSize={{ base: "sm", md: "md" }}
                    color="blue.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    textTransform="uppercase"
                >
                    Let's Get Started
                </Text>
                <Heading 
                    size={{ base: "lg", md: "xl" }}
                    color="gray.900"
                    textAlign="center"
                    lineHeight="shorter"
                >
                    Build Your Creator Profile
                </Heading>
            </VStack>
            
            <VStack 
                spacing={3} 
                align="start"
            >
                {[
                    'Professional profile photo',
                    'Compelling bio & niche',
                    'Showcase expertise',
                    'Highlight achievements'
                ].map((item, index) => (
                    <Flex 
                        key={index}
                        align="center"
                        w="full"
                        py={3}
                        borderBottom="1px"
                        borderColor="gray.100"
                    >
                        <Box 
                            color="blue.500"
                            mr={4}
                            fontSize="lg"
                        >
                            ✓
                        </Box>
                        <Text 
                            color="gray.700"
                            fontSize={{ base: "md", md: "lg" }}
                            fontWeight="medium"
                        >
                            {item}
                        </Text>
                    </Flex>
                ))}
            </VStack>

            <Text 
                fontSize={{ base: "sm", md: "md" }}
                color="gray.600"
                fontWeight="medium"
                textAlign="center"
                bg="gray.50"
                p={4}
                borderRadius="lg"
            >
                A complete profile increases your visibility to brands by 80%
            </Text>

            <Button
                colorScheme="blue"
                size="lg"
                width="full"
                onClick={() => setActiveSection('profile')}
                py={7}
                fontSize={{ base: "md", md: "lg" }}
                fontWeight="semibold"
                borderRadius="xl"
                _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                }}
                transition="all 0.2s"
            >
                Build Your Profile
            </Button>
        </VStack>
    </Box>
);

const CreatorProfilePage = ({ creatorId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const chakraToast = useChakraToast();
    const defaultFollowers = {
        youtube: '0',
        instagram: '0',
        tiktok: '0',
        twitter: '0',
        facebook: '0',
        twitch: '0',
        linkedin: '0',
        pinterest: '0',
        snapchat: '0'
    };

    // Add the isProfileComplete function here, inside the component
    const isProfileComplete = (profile) => {
        // Check for essential profile fields
        return (
            profile.profileImageUrl &&
            profile.name &&
            profile.bio &&
            profile.primaryCategory &&
            profile.country &&
            profile.username &&
            validateUsername(profile.username) &&
            // Check if they have at least one social media link
            Object.values(profile.socialLinks || {}).some(link => !!link) &&
            // Check if they have at least some followers
            Object.values(profile.followers || {}).reduce((sum, count) => sum + (parseInt(count) || 0), 0) >= 1
        );
    };

    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('profile');
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        bio: '',
        socialLinks: {
            youtube: '',
            instagram: '',
            tiktok: '',
            twitter: '',
            facebook: '',
            twitch: '',
            linkedin: '',
            pinterest: '',
            snapchat: ''
        },
        followers: defaultFollowers,
        collaborations: [],
        audienceInfo: {
            demographics: {
                ageRanges: [],
                genderDistribution: '',
                locations: []
            },
            interests: [],
            engagementTopics: [],
            metrics: {
                engagementRate: '',
                contentPerformance: ''
            }
        }
    });
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showEmbeds, setShowEmbeds] = useState(false);
    const [offers, setOffers] = useState([]);
    const [loadingOffers, setLoadingOffers] = useState(false);
    const [showAdditionalPlatforms, setShowAdditionalPlatforms] = useState(false);
    const [galleryPhotos, setGalleryPhotos] = useState([]);
    const [portfolioMedia, setPortfolioMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            // Wait for auth to initialize
            await new Promise(resolve => {
                const unsubscribe = auth.onAuthStateChanged(user => {
                    unsubscribe();
                    resolve(user);
                });
            });

            if (!auth.currentUser) {
                navigate('/login');
                return;
            }

            try {
                const docRef = doc(db, 'users', auth.currentUser.uid);
                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setProfile(prevProfile => ({
                        ...prevProfile,
                        ...userData,
                        collaborations: userData.collaborations || []
                    }));
                    // Set initial active section based on profile completion
                    const isNewUser = !userData.profileImageUrl && !userData.bio;
                    setActiveSection(isNewUser ? 'getting-started' : 'profile');
                } else {
                    setProfile(prevProfile => ({
                        ...prevProfile,
                        collaborations: []
                    }));
                    // New user, show getting started
                    setActiveSection('getting-started');
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, [navigate]);

    useEffect(() => {
        const fetchCreatorOffers = async () => {
            try {
                // Get the current user's ID if no creatorId is provided
                const currentUserId = creatorId || auth.currentUser?.uid;
                
                if (!currentUserId) {
                    console.log('No creator ID available');
                    return;
                }
                
                setLoadingOffers(true);
                
                const offersCollection = collection(db, 'creatorOffers');
                const q = query(
                    offersCollection,
                    where('creatorId', '==', currentUserId),
                    where('status', '==', 'active')
                );
                
                const querySnapshot = await getDocs(q);
                const creatorOffers = [];
                
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    creatorOffers.push({
                        id: doc.id,
                        ...data
                    });
                });

                setOffers(creatorOffers);
            } catch (error) {
                console.error('Error fetching creator offers:', error);
                chakraToast({
                    title: 'Error',
                    description: 'Failed to fetch creator offers',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            } finally {
                setLoadingOffers(false);
            }
        };

        fetchCreatorOffers();
    }, [creatorId, chakraToast]); // Add chakraToast to dependencies

    useEffect(() => {
        const fetchGalleryPhotos = async () => {
            if (profile) {
                
                // Check multiple possible property names for gallery photos
                let photos = null;
                
                if (profile.galleryPhotos && Array.isArray(profile.galleryPhotos)) {
                    photos = profile.galleryPhotos;
                } else if (profile.profileGallery && Array.isArray(profile.profileGallery)) {
                    photos = profile.profileGallery;
                } else if (profile.gallery && Array.isArray(profile.gallery)) {
                    photos = profile.gallery;
                }
                
                if (photos && photos.length > 0) {
                    setGalleryPhotos(photos);
                } else {
                }
            }
        };

        fetchGalleryPhotos();
    }, [profile]);

    useEffect(() => {
        if (profile) {
            if (profile.portfolioMedia && Array.isArray(profile.portfolioMedia)) {
                setPortfolioMedia(profile.portfolioMedia);
            } else {
            }
        }
    }, [profile]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const totalFollowers = Object.values(profile.followers || {})
                .reduce((sum, count) => sum + (parseInt(count) || 0), 0);
            
            const updatedProfile = {
                ...profile,
                statistics: {
                    ...profile.statistics,
                    totalFollowers
                },
                updatedAt: new Date().toISOString()
            };

            const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, updatedProfile);
            
            setProfile(updatedProfile);
            setIsEditing(false);
            chakraToast({
                title: 'Success',
                description: 'Profile updated successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
        } catch (error) {
            chakraToast({
                title: 'Error',
                description: 'Failed to update profile: ' + error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
        }
    };

    const handleImageUpdate = (newImageUrl) => {
        setProfile(prev => ({
            ...prev,
            profileImageUrl: newImageUrl
        }));
    };

    const handleSaveProfile = async () => {
        try {
            setIsSaving(true);
            
            // Create a copy of the profile object with only the fields we want to update
            const profileUpdate = {
                name: profile.name || '',
                bio: profile.bio || '',
                city: profile.city || '',
                country: profile.country || '',
                primaryCategory: profile.primaryCategory || '',
                // Only include secondaryCategories if it exists
                ...(profile.secondaryCategories ? { secondaryCategories: profile.secondaryCategories } : {}),
                socialLinks: profile.socialLinks || {},
                followers: profile.followers || {},
                // Make sure to include portfolioMedia
                portfolioMedia: profile.portfolioMedia || [],
                // Include other fields as needed
            };
            
            console.log("Saving profile with data:", profileUpdate);
            
            // Update the user document in Firestore
            const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, profileUpdate);
            
            toast.success('Profile saved successfully');
            setIsSaving(false);
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving profile:', error);
            toast.error('Failed to save profile: ' + error.message);
            setIsSaving(false);
        }
    };

    const handlePortfolioMediaUpload = async (mediaInfo) => {
      try {
        // Create a copy of the current portfolioMedia array or initialize a new one if it doesn't exist
        const updatedPortfolioMedia = [...(profile.portfolioMedia || [])];
        
        // Add the new media item with a unique ID
        const newMediaItem = {
          id: Date.now().toString(), // Generate a unique ID
          url: mediaInfo.secure_url || mediaInfo.url, // Handle both response formats
          public_id: mediaInfo.public_id,
          type: mediaInfo.resource_type || (mediaInfo.url?.includes('video') ? 'video' : 'image'),
          title: mediaInfo.title || '',
          description: mediaInfo.description || '',
          createdAt: new Date().toISOString()
        };
        
        updatedPortfolioMedia.push(newMediaItem);
        
        // Update the local state
        setProfile(prevProfile => ({
          ...prevProfile,
          portfolioMedia: updatedPortfolioMedia
        }));
        
        // Save to Firestore immediately
            const userRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userRef, {
          portfolioMedia: updatedPortfolioMedia
        });
        
        console.log('Portfolio media updated in Firestore:', updatedPortfolioMedia);
        toast.success('Media added to portfolio');
        
        return newMediaItem;
      } catch (error) {
        console.error('Error adding portfolio media:', error);
        toast.error('Failed to add media to portfolio');
        return null;
      }
    };

    const handleDeletePortfolioMedia = async (mediaId) => {
      try {
        if (!mediaId) {
          return;
        }
        
        
        // Filter out the media item by ID
        const updatedPortfolioMedia = profile.portfolioMedia.filter(
          media => (media.id !== mediaId && media.public_id !== mediaId)
        );
        
        // Update local state
        setProfile(prevProfile => ({
          ...prevProfile,
          portfolioMedia: updatedPortfolioMedia
        }));
        
        // Update Firestore
        const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, {
          portfolioMedia: updatedPortfolioMedia
        });
        
        console.log("Media deleted successfully");
        chakraToast({
          title: "Media deleted",
          description: "Portfolio item has been removed",
          status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
        console.error("Error deleting portfolio media:", error);
        chakraToast({
          title: "Error",
          description: "Failed to delete media: " + error.message,
          status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleUpdateMediaDetails = (mediaId, field, value) => {
        const updatedMedia = portfolioMedia.map(media => {
            if (media.id === mediaId) {
                return { ...media, [field]: value };
            }
            return media;
        });
        
        setPortfolioMedia(updatedMedia);
        
        // Update the profile object as well
        setProfile(prev => ({
            ...prev,
            portfolioMedia: updatedMedia
        }));
    };

    const renderOffers = () => {
        
        if (loadingOffers) {
            return <Spinner size="xl" />;
        }

        if (!offers || offers.length === 0) {
            return (
                <Box textAlign="center" p={6}>
                    <Text>No offers available from this creator.</Text>
                </Box>
            );
        }

        return (
            <Grid 
                templateColumns={{ 
                    base: "1fr", 
                    md: "repeat(2, 1fr)", 
                    lg: "repeat(3, 1fr)" 
                }} 
                gap={4}
            >
                {offers.map((offer) => (
            <Box
                key={offer.id}
                p={4}
                bg="white"
                shadow="md"
                borderRadius="lg"
                transition="all 0.2s"
                _hover={{
                    transform: 'translateY(-2px)',
                    shadow: 'lg'
                }}
                border="1px"
                borderColor="gray.100"
                        height="fit-content"
                        width="100%"
            >
                <Flex justify="space-between" align="center" mb={3}>
                    <Heading size="sm" color="blue.600">{offer.type}</Heading>
                    <Button
                        leftIcon={<FontAwesomeIcon icon="edit" />}
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => navigate(`/creator/offers/edit/${offer.id}`)}
                    >
                        Edit
                    </Button>
                </Flex>
                
                <Box 
                    p={2} 
                    bg="blue.50" 
                    borderRadius="md"
                    mb={3}
                >
                    <Flex justify="space-between" align="center">
                        <Text fontSize="sm" color="gray.600">Price</Text>
                        <Text fontSize="lg" fontWeight="bold" color="blue.600">
                            ${offer.price}
                        </Text>
                    </Flex>
                </Box>

                <Grid templateColumns="repeat(2, 1fr)" gap={2} mb={3}>
                    <Box p={2} bg="gray.50" borderRadius="md">
                                <Text fontSize="xs" color="gray.500">Quantity</Text>
                                <Text fontSize="sm" fontWeight="medium">{offer.numberOfPosts || 1}</Text>
                    </Box>
                    <Box p={2} bg="gray.50" borderRadius="md">
                        <Text fontSize="xs" color="gray.500">Delivery</Text>
                                <Text fontSize="sm" fontWeight="medium">{offer.deliveryTime || 7} day(s)</Text>
                    </Box>
                </Grid>

                        {offer.description && (
                <Box p={2} bg="gray.50" borderRadius="md" mb={3}>
                                <Text fontSize="xs" color="gray.500">Description</Text>
                                <Text fontSize="sm" noOfLines={3}>{offer.description}</Text>
                </Box>
                        )}

                <Box>
                    <Text fontSize="xs" fontWeight="medium" color="gray.600" mb={2}>
                        What's Included:
                    </Text>
                    <UnorderedList spacing={1} pl={4} mb={2}>
                        {offer.platforms && offer.platforms.map((platform, index) => (
                            <ListItem key={index} fontSize="sm">
                                {platform}
                            </ListItem>
                        ))}
                        <ListItem fontSize="sm">
                                    {offer.numberOfPosts || 1} {offer.type && offer.type.split(' ')[0]}(s)
                        </ListItem>
                                {offer.maxRevisions && (
                                    <ListItem fontSize="sm">
                                        {offer.maxRevisions} revision(s)
                                    </ListItem>
                                )}
                    </UnorderedList>
                </Box>
            </Box>
                ))}
            </Grid>
        );
    };

    if (loading) {
        return <div className="loading"></div>;
    }

    const renderSocialSection = () => {
        // Helper function to convert numeric values to ranges if needed
        const formatFollowerValue = (value) => {
            if (!value || value === '0') return '0';
            
            // If it's already a range format (contains a dash or plus sign), return as is
            if (value.includes('-') || value.includes('+')) return value;
            
            // Try to parse as a number and convert to a range
                                            const num = parseInt(value.replace(/,/g, ''));
            if (isNaN(num)) return value; // If not a number, return as is
            
            // Convert to appropriate range
            if (num <= 1000) return '1-1000';
            if (num <= 5000) return '1000-5000';
            if (num <= 10000) return '5000-10000';
            if (num <= 50000) return '10000-50000';
            if (num <= 100000) return '50000-100000';
            if (num <= 500000) return '100000-500000';
            if (num <= 1000000) return '500000-1000000';
            return '1000000+';
        };

                return (
                    <div className="content-section">
                        <div className="section-header">
                    <h2>Social Media Profiles</h2>
                    <button 
                        className="edit-button"
                        onClick={() => setIsEditing(!isEditing)}
                    >
                        {isEditing ? 'Cancel' : 'Edit'}
                    </button>
                </div>

                <form onSubmit={handleUpdate}>
                    <div className="profile-section-card">
                        <h3>Social Media Links</h3>
                        
                        {/* Consolidated Social Media Grid */}
                        <Box>
                            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
                                {[
                                    { platform: 'youtube', icon: 'fa-youtube', label: 'YouTube' },
                                    { platform: 'instagram', icon: 'fa-instagram', label: 'Instagram' },
                                    { platform: 'tiktok', icon: 'fa-tiktok', label: 'TikTok' },
                                    { platform: 'twitter', icon: 'fa-twitter', label: 'Twitter' },
                                    { platform: 'facebook', icon: 'fa-facebook', label: 'Facebook' },
                                    { platform: 'twitch', icon: 'fa-twitch', label: 'Twitch' }
                                ].map(({ platform, icon, label }) => {
                                    // Get the current follower value and format it if needed
                                    const followerValue = formatFollowerValue(profile.followers[platform]);
                                    
                                    return (
                                        <Flex key={platform} direction="column" p={3} borderWidth="1px" borderRadius="md" borderColor="gray.200">
                                            <Flex align="center" mb={2}>
                                                <Box as="i" className={`fab ${icon}`} mr={2} color={`${platform}.500`} />
                                                <Text fontWeight="medium">{label}</Text>
                                            </Flex>
                                            
                                            <Input
                                                size="sm"
                                                mb={2}
                                                value={profile.socialLinks[platform] || ''}
                                                onChange={(e) => setProfile({
                                                    ...profile,
                                                    socialLinks: {
                                                        ...profile.socialLinks,
                                                        [platform]: e.target.value
                                                    }
                                                })}
                                                disabled={!isEditing}
                                                placeholder={`Enter your ${platform} profile URL`}
                                            />
                                            
                                            <Flex align="center" mb={1}>
                                                <Select
                                                    size="sm"
                                                    width="180px"
                                                    value={followerValue}
                                                    onChange={(e) => setProfile({
                                                        ...profile,
                                                        followers: {
                                                            ...profile.followers,
                                                            [platform]: e.target.value
                                                        }
                                                    })}
                                                    disabled={!isEditing}
                                                >
                                                    <option value="0">0</option>
                                                    <option value="1-1000">1-1,000</option>
                                                    <option value="1000-5000">1,000-5,000</option>
                                                    <option value="5000-10000">5,000-10,000</option>
                                                    <option value="10000-50000">10,000-50,000</option>
                                                    <option value="50000-100000">50,000-100,000</option>
                                                    <option value="100000-500000">100,000-500,000</option>
                                                    <option value="500000-1000000">500,000-1,000,000</option>
                                                    <option value="1000000+">1,000,000+</option>
                                                </Select>
                                                <Text ml={2} fontSize="sm" color="gray.600">followers</Text>
                                            </Flex>
                                        </Flex>
                                    );
                                })}
                            </Grid>
                            
                            {/* Additional platforms in a collapsible section */}
                            {(profile.socialLinks.linkedin || profile.socialLinks.pinterest || 
                              profile.socialLinks.snapchat || isEditing) && (
                                <Box mt={4}>
                                    <Flex 
                                        justify="space-between" 
                                        align="center" 
                                        p={2} 
                                        bg="gray.50" 
                                        borderRadius="md"
                                        cursor="pointer"
                                        onClick={() => setShowAdditionalPlatforms(!showAdditionalPlatforms)}
                                    >
                                        <Text fontWeight="medium">Additional Platforms</Text>
                                        <Icon as={FontAwesomeIcon} icon={showAdditionalPlatforms ? "chevron-up" : "chevron-down"} />
                                    </Flex>
                                    
                                    {showAdditionalPlatforms && (
                                        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} mt={3}>
                                            {[
                                                { platform: 'linkedin', icon: 'fa-linkedin', label: 'LinkedIn' },
                                                { platform: 'pinterest', icon: 'fa-pinterest', label: 'Pinterest' },
                                                { platform: 'snapchat', icon: 'fa-snapchat', label: 'Snapchat' }
                                            ].map(({ platform, icon, label }) => {
                                                // Get the current follower value and format it if needed
                                                const followerValue = formatFollowerValue(profile.followers[platform]);
                                                
                                                return (
                                                    <Flex key={platform} direction="column" p={3} borderWidth="1px" borderRadius="md" borderColor="gray.200">
                                                        <Flex align="center" mb={2}>
                                                            <Box as="i" className={`fab ${icon}`} mr={2} />
                                                            <Text fontWeight="medium">{label}</Text>
                                                        </Flex>
                                                        
                                                        <Input
                                                            size="sm"
                                                            mb={2}
                                                            value={profile.socialLinks[platform] || ''}
                                                            onChange={(e) => setProfile({
                                                                ...profile,
                                                                socialLinks: {
                                                                    ...profile.socialLinks,
                                                                    [platform]: e.target.value
                                                                }
                                                            })}
                                                            disabled={!isEditing}
                                                            placeholder={`Enter your ${platform} profile URL`}
                                                        />
                                                        
                                                        <Flex align="center" mb={1}>
                                                            <Select
                                                                size="sm"
                                                                width="180px"
                                                                value={followerValue}
                                                                onChange={(e) => setProfile({
                                                                    ...profile,
                                                                    followers: {
                                                                        ...profile.followers,
                                                                        [platform]: e.target.value
                                                                    }
                                                                })}
                                                                disabled={!isEditing}
                                                            >
                                                                <option value="0">0</option>
                                                                <option value="1-1000">1-1,000</option>
                                                                <option value="1000-5000">1,000-5,000</option>
                                                                <option value="5000-10000">5,000-10,000</option>
                                                                <option value="10000-50000">10,000-50,000</option>
                                                                <option value="50000-100000">50,000-100,000</option>
                                                                <option value="100000-500000">100,000-500,000</option>
                                                                <option value="500000-1000000">500,000-1,000,000</option>
                                                                <option value="1000000+">1,000,000+</option>
                                                            </Select>
                                                            <Text ml={2} fontSize="sm" color="gray.600">followers</Text>
                                                        </Flex>
                                                    </Flex>
                                                );
                                            })}
                                        </Grid>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </div>

                    <div className="profile-section-card">
                        <div className="total-followers">
                            <h4>Total Followers Across Platforms</h4>
                            <div className="follower-count">
                                {(() => {
                                    if (!profile.followers) return '0';
                                    
                                    // Calculate total followers from ranges
                                    const calculateTotalFollowers = () => {
                                        let total = 0;
                                        Object.values(profile.followers).forEach(value => {
                                            if (!value || value === '0') {
                                                // Do nothing for zero values
                                            } else if (value === '1000000+') {
                                                total += 1000000;
                                            } else if (value.includes('-')) {
                                                // For ranges, take the average
                                                const [min, max] = value.split('-').map(v => parseInt(v.replace(/,/g, '')));
                                                total += Math.floor((min + max) / 2);
                                            } else {
                                                // Try to parse as a number
                                                const num = parseInt(value.replace(/,/g, ''));
                                                if (!isNaN(num)) total += num;
                                            }
                                        });
                                        return total;
                                    };
                                    
                                    return calculateTotalFollowers().toLocaleString();
                                })()}
                            </div>
                            <span className="last-updated">
                                Last updated: {profile.updatedAt ? new Date(profile.updatedAt).toLocaleDateString() : 'Never'}
                            </span>
                        </div>
                    </div>

                    {isEditing && (
                        <div className="form-actions">
                            <button type="submit" className="save-button">
                                Save Changes
                            </button>
                        </div>
                    )}
                </form>
            </div>
        );
    };

    const renderPortfolioSection = () => {
      
      return (
        <Box>
          <Heading size="md" mb={4}>Portfolio Media</Heading>
          
          {/* Use the MediaUploader component from utils */}
          <MediaUploader 
            onUploadSuccess={handlePortfolioMediaUpload}
            buttonText="Add Media"
            acceptTypes="image/*,video/*"
          />
          
          {/* Portfolio media grid */}
          {profile.portfolioMedia && profile.portfolioMedia.length > 0 ? (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
              {profile.portfolioMedia.map((media) => (
                <Box 
                  key={media.id || media.public_id || Date.now()} 
                  borderWidth="1px" 
                  borderRadius="lg" 
                  overflow="hidden"
                  position="relative"
                  boxShadow="sm"
                >
                  {(media.type === 'video' || media.url?.includes('video') || media.url?.includes('.mp4')) ? (
                    <Box position="relative">
                      <Box 
                        as="video"
                        src={media.url}
                        width="100%"
                        height="200px"
                        objectFit="cover"
                        onClick={(e) => {
                          if (e.currentTarget.paused) {
                            e.currentTarget.play();
                          } else {
                            e.currentTarget.pause();
                          }
                        }}
                      />
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        bg="rgba(0, 0, 0, 0.5)"
                        borderRadius="full"
                        width="40px"
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        pointerEvents="none"
                      >
                        <Icon as={FaPlay} color="white" />
                      </Box>
                    </Box>
                  ) : (
                    <Image 
                      src={media.url} 
                      alt={media.title || 'Portfolio media'} 
                      width="100%" 
                      height="200px" 
                      objectFit="cover"
                    />
                  )}
                  
                  <Box p={3}>
                    <Flex justify="space-between" align="center">
                      <Heading size="sm" isTruncated>{media.title || 'Untitled'}</Heading>
                      <IconButton
                        icon={<FaTrash />}
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => handleDeletePortfolioMedia(media.id || media.public_id)}
                        aria-label="Delete media"
                      />
                    </Flex>
                    {media.description && (
                      <Text fontSize="sm" mt={1} color="gray.600" noOfLines={2}>
                        {media.description}
                      </Text>
                    )}
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <Box textAlign="center" p={6} bg="gray.50" borderRadius="md">
              <Text>No portfolio media yet. Add some to showcase your work!</Text>
            </Box>
          )}
        </Box>
      );
    };

    const renderProfilePhotosSection = () => (
        <div className="content-section">
            <div className="section-header">
                <h2>Profile Photos</h2>
                <div className="action-buttons">
                    {isEditing && (
                        <button 
                            className="save-button"
                            onClick={handleSaveProfile}
                        >
                            Save Changes
                        </button>
                    )}
                    <button 
                        className="edit-button"
                        onClick={() => setIsEditing(!isEditing)}
                    >
                        {isEditing ? 'Cancel' : 'Edit'}
                    </button>
                </div>
            </div>

            <div className="profile-section-card">
                <h3>Profile Avatar</h3>
                <Box 
                    className="profile-image-section"
                    position="relative"
                    width="200px"
                    height="200px"
                    margin="0 auto 2rem"
                >
                    <CreatorProfileImage 
                        currentImageUrl={profile.profileImageUrl}
                        onImageUpdate={handleImageUpdate}
                        isEditing={isEditing}
                    />
                </Box>
                <Text fontSize="sm" color="gray.500" textAlign="center" mb={6}>
                    This is your main profile image that appears across the platform
                </Text>
            </div>

            <div className="profile-section-card">
                <Flex justify="space-between" align="center" mb={4}>
                    <h3>Gallery Photos</h3>
                    {isEditing && (
                        <Button
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<Icon as={FontAwesomeIcon} icon="plus" />}
                            onClick={() => {
                                // Add handler for adding new gallery photos
                                // This would typically open a file picker or Cloudinary widget
                            }}
                        >
                            Add Photo
                        </Button>
                    )}
                </Flex>

                {(!galleryPhotos || galleryPhotos.length === 0) ? (
                    <Box textAlign="center" p={6} bg="gray.50" borderRadius="md">
                        <Text color="gray.500">No gallery photos added yet.</Text>
                        {isEditing && (
                            <Button
                                mt={4}
                                colorScheme="blue"
                                leftIcon={<Icon as={FontAwesomeIcon} icon="plus" />}
                                onClick={() => {
                                    // Add handler for adding new gallery photos
                                }}
                            >
                                Add Your First Photo
                            </Button>
                        )}
                    </Box>
                ) : (
                    <Grid 
                        templateColumns={{ 
                            base: "repeat(1, 1fr)", 
                            sm: "repeat(2, 1fr)", 
                            md: "repeat(3, 1fr)" 
                        }} 
                        gap={4}
                    >
                        {galleryPhotos.map((photo, index) => (
                            <Box 
                                key={index} 
                                position="relative" 
                                borderRadius="md"
                                overflow="hidden"
                                boxShadow="sm"
                                height="200px"
                            >
                                <Image 
                                    src={photo.url} 
                                    alt={`Gallery photo ${index + 1}`}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                />
                                
                                {isEditing && (
                                    <Flex 
                                        position="absolute" 
                                        top={0} 
                                        right={0}
                                        p={1}
                                    >
                                        <IconButton
                                            icon={<Icon as={FontAwesomeIcon} icon="trash" />}
                                            size="sm"
                                            colorScheme="red"
                                            variant="solid"
                                            onClick={() => {
                                                // Add handler for removing gallery photos
                                                const updatedPhotos = [...galleryPhotos];
                                                updatedPhotos.splice(index, 1);
                                                setGalleryPhotos(updatedPhotos);
                                            }}
                                            aria-label="Delete photo"
                                        />
                                    </Flex>
                                )}
                                
                                {photo.caption && (
                                    <Box
                                        position="absolute"
                                        bottom="0"
                                        left="0"
                                        right="0"
                                        bg="rgba(0,0,0,0.7)"
                                        color="white"
                                        p={2}
                                        fontSize="sm"
                                    >
                                        {photo.caption}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Grid>
                )}
                
                <Text fontSize="sm" color="gray.500" mt={4}>
                    These photos appear in your profile gallery and help showcase your style and work
                </Text>
            </div>
        </div>
    );

    const renderContent = () => {
        switch(activeSection) {
            case 'getting-started':
                return <GettingStartedContent setActiveSection={setActiveSection} />;
            case 'profile':
                return (
                    <div className="content-section">
                        <div className="section-header">
                            <h2>Profile Information</h2>
                            <div className="action-buttons">
                                <Button
                                    as={Link}
                                    to="/creator-profile-form"
                                    colorScheme="blue"
                                    size="md"
                                    mr={3}
                                    leftIcon={<Icon as={FontAwesomeIcon} icon="edit" />}
                                    fontSize="16px"
                                    fontWeight="bold"
                                    px={6}
                                    py={5}
                                    _hover={{
                                        transform: "translateY(-2px)",
                                        boxShadow: "lg"
                                    }}
                                    transition="all 0.2s"
                                >
                                    Build Your Profile
                                </Button>
                                
                                {isEditing && (
                                    <button 
                                        className="save-button"
                                        onClick={handleSaveProfile}
                                    >
                                        Save Changes
                                    </button>
                                )}
                                <button 
                                    className="edit-button"
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    Edit Profile
                                </button>
                            </div>
                        </div>

                        <form onSubmit={handleUpdate}>
                            <Box 
                                bg="green.50"
                                borderRadius="md"
                                p={4}
                                mb={5}
                                mt={-2}
                                boxShadow="md"
                                borderLeft="5px solid"
                                borderColor="green.500"
                            >
                                <Flex align="center" justify="space-between">
                                    <Box textAlign="center">
                                        <Heading 
                                            fontSize={{ base: "md", md: "xl" }}
                                            color="green.700"
                                            mb={1}
                                            fontWeight="600"
                                        >
                                            Profile Complete! 🎉
                                        </Heading>
                                        <Text 
                                            color="green.600"
                                            fontSize={{ base: "xs", md: "sm" }}
                                            fontWeight="medium"
                                            px={2}
                                        >
                                            Ready for brand collaborations
                                        </Text>
                                    </Box>
                                    <Button
                                        leftIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                                        colorScheme="green"
                                        variant="solid"
                                        size="sm"
                                        width="auto"  // Set width to auto instead of default full width
                                        px={3}        // Add horizontal padding
                                        ml={3}        // Add margin to the left for spacing from the text
                                        onClick={() => window.open(`/creator/${profile.username || creatorId}`, '_blank')}
                                    >
                                        View Profile
                                    </Button>
                                </Flex>
                            </Box>

                            <div className="profile-section-card">
                                <h3>Basic Information</h3>
                                <Box 
                                    className="profile-image-section"
                                    position="relative"
                                    width="200px"
                                    height="200px"
                                    margin="0 auto 2rem"
                                >
                                    <CreatorProfileImage 
                                        currentImageUrl={profile.profileImageUrl}
                                        onImageUpdate={handleImageUpdate}
                                        isEditing={isEditing}
                                    />
                                </Box>
                                <div className="info-grid">
                                    <div className="info-item">
                                        <label>Full Name*</label>
                                        <input
                                            type="text"
                                            value={profile.name || ''}
                                            onChange={(e) => setProfile({...profile, name: e.target.value})}
                                            disabled={!isEditing}
                                            placeholder="Your full name"
                                            required
                                        />
                                    </div>
                                    <div className="info-item">
                                        <label>Username*</label>
                                        <input
                                            type="text"
                                            value={profile.username || ''}
                                            onChange={(e) => {
                                                const newUsername = e.target.value.toLowerCase(); // Convert to lowercase
                                                if (newUsername === '' || validateUsername(newUsername)) {
                                                    setProfile({...profile, username: newUsername});
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value && !validateUsername(e.target.value)) {
                                                    chakraToast({
                                                        title: 'Invalid Username',
                                                        description: 'Username can only contain letters, numbers, and underscores',
                                                        status: 'error',
                                                        duration: 5000,
                                                        isClosable: true,
                                                        position: 'top'
                                                    });
                                                }
                                            }}
                                            disabled={!isEditing}
                                            placeholder="your_username"
                                            required
                                            className="username-input"
                                        />
                                        <small className="input-helper">
                                            Letters, numbers, and underscores only. No spaces allowed.
                                        </small>
                                    </div>
                                    <div className="info-item">
                                        <label>Email*</label>
                                        <input
                                            type="email"
                                            value={profile.email || ''}
                                            onChange={(e) => setProfile({...profile, email: e.target.value})}
                                            disabled={!isEditing}
                                            required
                                        />
                                    </div>
                                    <div className="info-item">
                                        <label>Account Type</label>
                                        <input
                                            type="text"
                                            value={profile.role || 'creator'}
                                            disabled={true}
                                            className="role-input"
                                        />
                                    </div>
                                    <div className="info-item">
                                        <label>Phone Number</label>
                                        <input
                                            type="tel"
                                            value={profile.phone || ''}
                                            onChange={(e) => setProfile({...profile, phone: e.target.value})}
                                            disabled={!isEditing}
                                            placeholder="Your contact number"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="profile-section-card">
                                <h3>Location & Language</h3>
                                <div className="info-grid">
                                    <div className="info-item">
                                        <label>Country*</label>
                                        <input
                                            type="text"
                                            value={profile.country || ''}
                                            onChange={(e) => setProfile({...profile, country: e.target.value})}
                                            disabled={!isEditing}
                                            placeholder="Your country"
                                            required
                                        />
                                    </div>
                                    <div className="info-item">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            value={profile.city || ''}
                                            onChange={(e) => setProfile({...profile, city: e.target.value})}
                                            disabled={!isEditing}
                                            placeholder="Your city"
                                        />
                                    </div>
                                    <div className="info-item">
                                        <label>Languages</label>
                                        <input
                                            type="text"
                                            value={profile.languages || ''}
                                            onChange={(e) => setProfile({...profile, languages: e.target.value})}
                                            disabled={!isEditing}
                                            placeholder="e.g., English, Spanish"
                                        />
                                    </div>
                                    <div className="info-item">
                                        <label>Timezone</label>
                                        <input
                                            type="text"
                                            value={profile.timezone || ''}
                                            onChange={(e) => setProfile({...profile, timezone: e.target.value})}
                                            disabled={!isEditing}
                                            placeholder="e.g., GMT+1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Box 
                                mt={6} 
                                p={5} 
                                bg="blue.50" 
                                borderRadius="lg"
                                textAlign="center"
                                boxShadow="sm"
                            >
                                <Heading as="h3" size="md" mb={3} color="blue.700">
                                    Ready to pitch to brands?
                                </Heading>
                                <Text color="blue.600" mb={4}>
                                    Complete your professional details to appear in creator feeds and increase your chances of brand collaborations.
                                </Text>
                                <Button
                                    as={Link}
                                    to="/creator-profile-form"
                                    colorScheme="blue"
                                    size="lg"
                                    leftIcon={<Icon as={FontAwesomeIcon} icon="edit" />}
                                    fontWeight="bold"
                                    px={8}
                                    py={6}
                                    _hover={{
                                        transform: "translateY(-2px)",
                                        boxShadow: "lg"
                                    }}
                                    transition="all 0.2s"
                                >
                                    Build Your Complete Profile
                                </Button>
                            </Box>

                            {isEditing && (
                                <div className="form-actions">
                                    <button type="submit" className="save-button">
                                        Save Changes
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                );
            case 'professional':
                return (
                    <div className="content-section">
                        <div className="section-header">
                            <h2>Professional Details</h2>
                            <div className="action-buttons">
                                <Button
                                    as={Link}
                                    to="/creator-profile-form"
                                    colorScheme="blue"
                                    size="md"
                                    mr={3}
                                    leftIcon={<Icon as={FontAwesomeIcon} icon="edit" />}
                                >
                                    Build Your Profile
                                </Button>
                                
                                {isEditing && (
                                    <button 
                                        className="save-button"
                                        onClick={handleSaveProfile}
                                    >
                                        Save Changes
                                    </button>
                                )}
                                <button 
                                    className="edit-button"
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    {isEditing ? 'Cancel' : 'Edit'}
                                </button>
                            </div>
                        </div>

                        <div className="profile-section-card">
                            <div className="info-grid">
                                <div className="info-item full-width">
                                    <label>Bio</label>
                                    <textarea
                                        value={profile.bio || ''}
                                        onChange={(e) => setProfile({...profile, bio: e.target.value})}
                                                    disabled={!isEditing}
                                        placeholder="Tell brands about yourself..."
                                        rows="8"
                                        className="bio-textarea"
                                    />
                                </div>
                                <div className="info-item">
                                    <label>Primary Category*</label>
                                    <select
                                        value={profile.primaryCategory || ''}
                                        onChange={(e) => setProfile({...profile, primaryCategory: e.target.value})}
                                                    disabled={!isEditing}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        <option value="Lifestyle">Lifestyle</option>
                                        <option value="Fashion">Fashion</option>
                                        <option value="Beauty">Beauty</option>
                                        <option value="Travel">Travel</option>
                                        <option value="Food">Food</option>
                                        <option value="Fitness">Fitness</option>
                                        <option value="Technology">Technology</option>
                                        <option value="Gaming">Gaming</option>
                                        <option value="Education">Education</option>
                                        <option value="Business">Business</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Crypto">Crypto</option>
                                    </select>
                                            </div>
                                <div className="info-item">
                                    <label>Years of Experience</label>
                                                <input
                                        type="number"
                                        value={profile.yearsOfExperience || ''}
                                        onChange={(e) => setProfile({...profile, yearsOfExperience: e.target.value})}
                                                    disabled={!isEditing}
                                        min="0"
                                        placeholder="Years as a creator"
                                                />
                                            </div>
                            </div>
                        </div>
                    </div>
                );
            case 'social':
                return renderSocialSection();
            case 'portfolio':
                return renderPortfolioSection();
            case 'audience':
                return (
                    <div className="content-section">
                        <div className="section-header">
                            <h2>Audience Information</h2>
                            <div className="action-buttons">
                                {isEditing && (
                                    <button 
                                        className="save-button"
                                        onClick={handleSaveProfile}
                                    >
                                        Save Changes
                                    </button>
                                )}
                                <button 
                                    className="edit-button"
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    {isEditing ? 'Cancel' : 'Edit'}
                                </button>
                            </div>
                        </div>

                        <div className="profile-section-card">
                            <h3>Audience Demographics</h3>
                            <div className="info-grid">
                                <div className="info-item">
                                    <label>Primary Age Ranges</label>
                                    <div className="checkbox-group">
                                        {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map(range => (
                                            <label key={range} className="checkbox-label">
                                                <input
                                                    type="checkbox"
                                                    checked={profile.audienceInfo.demographics.ageRanges.includes(range)}
                                                    onChange={(e) => {
                                                        const updatedAgeRanges = e.target.checked
                                                            ? [...profile.audienceInfo.demographics.ageRanges, range]
                                                            : profile.audienceInfo.demographics.ageRanges.filter(r => r !== range);
                                                        setProfile({
                                                            ...profile,
                                                            audienceInfo: {
                                                                ...profile.audienceInfo,
                                                                demographics: {
                                                                    ...profile.audienceInfo.demographics,
                                                                    ageRanges: updatedAgeRanges
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    disabled={!isEditing}
                                                />
                                                {range}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <label>Gender Distribution</label>
                                    <input
                                        type="text"
                                        value={profile.audienceInfo.demographics.genderDistribution || ''}
                                        onChange={(e) => setProfile({
                                            ...profile,
                                            audienceInfo: {
                                                ...profile.audienceInfo,
                                                demographics: {
                                                    ...profile.audienceInfo.demographics,
                                                    genderDistribution: e.target.value
                                                }
                                            }
                                        })}
                                        disabled={!isEditing}
                                        placeholder="e.g., 60% Female, 40% Male"
                                    />
                                </div>
                                <div className="info-item">
                                    <label>Top Locations</label>
                                    <div className="tag-input">
                                        {profile.audienceInfo.demographics.locations.map((location, index) => (
                                            <div key={index} className="tag">
                                                {location}
                                                {isEditing && (
                                                    <button
                                                        onClick={() => {
                                                            const updatedLocations = profile.audienceInfo.demographics.locations
                                                                .filter((_, i) => i !== index);
                                                            setProfile({
                                                                ...profile,
                                                                audienceInfo: {
                                                                    ...profile.audienceInfo,
                                                                    demographics: {
                                                                        ...profile.audienceInfo.demographics,
                                                                        locations: updatedLocations
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        ×
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                        {isEditing && (
                                            <input
                                                type="text"
                                                placeholder="Add location and press Enter"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && e.target.value.trim()) {
                                                        e.preventDefault();
                                                        const newLocation = e.target.value.trim();
                                                        setProfile({
                                                            ...profile,
                                                            audienceInfo: {
                                                                ...profile.audienceInfo,
                                                                demographics: {
                                                                    ...profile.audienceInfo.demographics,
                                                                    locations: [...profile.audienceInfo.demographics.locations, newLocation]
                                                                }
                                                            }
                                                        });
                                                        e.target.value = '';
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profile-section-card">
                            <h3>Audience Interests & Engagement</h3>
                            <div className="info-grid">
                                <div className="info-item full-width">
                                    <label>Primary Interest Categories</label>
                                    <div className="tag-input">
                                        {profile.audienceInfo.interests.map((interest, index) => (
                                            <div key={index} className="tag">
                                                {interest}
                                                {isEditing && (
                                                    <button
                                                        onClick={() => {
                                                            const updatedInterests = profile.audienceInfo.interests
                                                                .filter((_, i) => i !== index);
                                                            setProfile({
                                                                ...profile,
                                                                audienceInfo: {
                                                                    ...profile.audienceInfo,
                                                                    interests: updatedInterests
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        ×
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                        {isEditing && (
                                            <input
                                                type="text"
                                                placeholder="Add interest and press Enter"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && e.target.value.trim()) {
                                                        e.preventDefault();
                                                        const newInterest = e.target.value.trim();
                                                        setProfile({
                                                            ...profile,
                                                            audienceInfo: {
                                                                ...profile.audienceInfo,
                                                                interests: [...profile.audienceInfo.interests, newInterest]
                                                            }
                                                        });
                                                        e.target.value = '';
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="info-item full-width">
                                    <label>Engagement Topics</label>
                                    <div className="tag-input">
                                        {profile.audienceInfo.engagementTopics.map((topic, index) => (
                                            <div key={index} className="tag">
                                                {topic}
                                                {isEditing && (
                                                    <button
                                                        onClick={() => {
                                                            const updatedTopics = profile.audienceInfo.engagementTopics
                                                                .filter((_, i) => i !== index);
                                                            setProfile({
                                                                ...profile,
                                                                audienceInfo: {
                                                                    ...profile.audienceInfo,
                                                                    engagementTopics: updatedTopics
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        ×
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                        {isEditing && (
                                            <input
                                                type="text"
                                                placeholder="Add topic and press Enter"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && e.target.value.trim()) {
                                                        e.preventDefault();
                                                        const newTopic = e.target.value.trim();
                                                        setProfile({
                                                            ...profile,
                                                            audienceInfo: {
                                                                ...profile.audienceInfo,
                                                                engagementTopics: [...profile.audienceInfo.engagementTopics, newTopic]
                                                            }
                                                        });
                                                        e.target.value = '';
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <label>Average Engagement Rate</label>
                                    <input
                                        type="text"
                                        value={profile.audienceInfo.metrics.engagementRate || ''}
                                        onChange={(e) => setProfile({
                                            ...profile,
                                            audienceInfo: {
                                                ...profile.audienceInfo,
                                                metrics: {
                                                    ...profile.audienceInfo.metrics,
                                                    engagementRate: e.target.value
                                                }
                                            }
                                        })}
                                        disabled={!isEditing}
                                        placeholder="e.g., 5.2%"
                                    />
                                </div>
                                <div className="info-item">
                                    <label>Content Performance</label>
                                    <input
                                        type="text"
                                        value={profile.audienceInfo.metrics.contentPerformance || ''}
                                        onChange={(e) => setProfile({
                                            ...profile,
                                            audienceInfo: {
                                                ...profile.audienceInfo,
                                                metrics: {
                                                    ...profile.audienceInfo.metrics,
                                                    contentPerformance: e.target.value
                                                }
                                            }
                                        })}
                                        disabled={!isEditing}
                                        placeholder="e.g., 10K avg. likes per post"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'preview':
                return (
                    <div className="content-section">
                        <div className="section-header">
                            <h2>Public Profile Preview</h2>
                            <div className="preview-notice">
                                This is how your profile appears to brands and visitors
                            </div>
                        </div>
                        <div className="preview-container">
                            {/* Hero Section */}
                            <div className="creator-hero">
                                <div className="creator-card-glass profile-card">
                                    <div className="profile-info-group">
                                        <img 
                                            src={profile.profileImageUrl} 
                                            alt={profile.name} 
                                            className="creator-profile-image" 
                                        />
                                        <div className="creator-info">
                                            <h1>{profile.name}</h1>
                                            <div className="creator-meta">
                                                {profile.primaryCategory && (
                                                    <div className="meta-item">
                                                        <strong>Niche:</strong> 
                                                        {profile.primaryCategory}
                                                    </div>
                                                )}
                                                {(profile.city || profile.country) && (
                                                    <div className="meta-item">
                                                        <strong>Locale:</strong> 
                                                        {profile.city ? `${profile.city}, ${profile.country}` : profile.country}
                                                    </div>
                                                )}
                                                {profile.languages && (
                                                    <div className="meta-item">
                                                        <strong>Language:</strong> 
                                                        {profile.languages}
                                                    </div>
                                                )}
                                                {profile.yearsOfExperience && (
                                                    <div className="meta-item">
                                                        <strong>Experience:</strong> 
                                                        {profile.yearsOfExperience} years
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="creator-card-glass stats-card">
                                    <div className="total-followers-display">
                                        <span className="total-count">
                                            {Object.values(profile?.followers || {})
                                                .reduce((sum, count) => sum + (parseInt(count) || 0), 0)
                                                .toLocaleString()}
                                        </span>
                                        <span className="total-label">Total Followers</span>
                                    </div>
                                    
                                    <div className="social-links">
                                        {Object.entries(profile?.socialLinks || {}).map(([platform, url]) => {
                                            if (!url) return null;
                                            const icon = {
                                                instagram: 'instagram',
                                                tiktok: 'tiktok',
                                                youtube: 'youtube',
                                                twitter: 'twitter',
                                                facebook: 'facebook-f',
                                                twitch: 'twitch'
                                            }[platform];
                                            
                                            return icon ? (
                                                <a 
                                                    key={platform}
                                                    href={url} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className={`social-link ${platform}`}
                                                >
                                                    <FontAwesomeIcon icon={['fab', icon]} />
                                                </a>
                                            ) : null;
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* Followers Breakdown */}
                            <section className="creator-section">
                                <h2>Social Media Presence</h2>
                                <div className="followers-grid">
                                    {Object.entries(profile.followers || {}).map(([platform, count]) => {
                                        if (!count || count === '0' || !profile.socialLinks?.[platform]) return null;
                                        
                                        const icons = {
                                            youtube: ['fab', 'youtube'],
                                            instagram: ['fab', 'instagram'],
                                            tiktok: ['fab', 'tiktok'],
                                            twitter: ['fab', 'twitter'],
                                            facebook: ['fab', 'facebook'],
                                            twitch: ['fab', 'twitch'],
                                            linkedin: ['fab', 'linkedin'],
                                            pinterest: ['fab', 'pinterest'],
                                            snapchat: ['fab', 'snapchat']
                                        };

                                        return (
                                            <div key={platform} className={`follower-card ${platform}`}>
                                                <div className="follower-icon">
                                                    <FontAwesomeIcon icon={icons[platform]} />
                                                </div>
                                                <div className="follower-details">
                                                    <span className="follower-count">
                                                        {parseInt(count).toLocaleString()}
                                                    </span>
                                                    <span className="follower-platform">
                                                        {platform.charAt(0).toUpperCase() + platform.slice(1)} Followers
                                                    </span>
                                                </div>
                                                <a 
                                                    href={profile.socialLinks[platform]}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="platform-link"
                                                >
                                                    View Profile
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </section>

                            {/* Bio Section */}
                            {profile.bio && (
                                <section className="creator-section bio-section">
                                    <h2>About</h2>
                                    <p className="creator-bio">{profile.bio}</p>
                                </section>
                            )}

                            {/* Audience Demographics */}
                            <section className="creator-section">
                                <h2>Audience Demographics</h2>
                                <div className="audience-info-grid">
                                    {profile.audienceInfo?.demographics?.ageRanges?.length > 0 && (
                                        <div className="audience-card">
                                            <h3>Age Distribution</h3>
                                            <div className="age-ranges">
                                                {profile.audienceInfo.demographics.ageRanges.map((range, index) => (
                                                    <div key={index} className="age-range-item">
                                                        <span className="age-label">{range}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {profile.audienceInfo?.demographics?.genderDistribution && (
                                        <div className="audience-card">
                                            <h3>Gender Distribution</h3>
                                            <p>{profile.audienceInfo.demographics.genderDistribution}</p>
                                        </div>
                                    )}

                                    {profile.audienceInfo?.demographics?.locations?.length > 0 && (
                                        <div className="audience-card">
                                            <h3>Top Locations</h3>
                                            <div className="location-tags">
                                                {profile.audienceInfo.demographics.locations.map((location, index) => (
                                                    <span key={index} className="location-tag">{location}</span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </section>

                            {/* Brand Collaborations */}
                            {profile.collaborations && profile.collaborations.length > 0 && (
                                <section className="creator-section">
                                    <h2>Brand Collaborations</h2>
                                    <div className="collaborations-grid">
                                        {profile.collaborations.map((collab, index) => (
                                            <div key={index} className="collaboration-card">
                                                <div className="collab-header">
                                                    <h3>{collab.brandName}</h3>
                                                    {collab.date && (
                                                        <span className="collab-date">
                                                            {new Date(collab.date).toLocaleDateString()}
                                                        </span>
                                                    )}
                                                </div>
                                                <p className="collab-description">{collab.description}</p>
                                                {collab.metrics && (
                                                    <div className="collab-metrics">
                                                        <h4>Results</h4>
                                                        <p>{collab.metrics}</p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            )}

                            {/* Creator Offers Section - Add this before the Social Media Embeds section */}
                            <section className="creator-section">
                                <h2>Content Packages</h2>
                                <Box className="creator-offers-grid">
                                    {renderOffers()}
                                </Box>
                            </section>

                            {/* Social Media Embeds */}
                            <section className="creator-section">
                                <div className="section-header-with-toggle">
                                    <h2>Recent Content</h2>
                                    <button 
                                        className="toggle-embeds-button"
                                        onClick={() => setShowEmbeds(!showEmbeds)}
                                    >
                                        {showEmbeds ? 'Hide Content' : 'Show Content'} 
                                        <FontAwesomeIcon icon={showEmbeds ? 'chevron-up' : 'chevron-down'} />
                                    </button>
                                </div>
                                
                                {showEmbeds && (
                                    <div className="platform-embeds">
                                        {profile.socialLinks?.youtube && (
                                            <div className="platform-card youtube">
                                                <div className="platform-info">
                                                    <h3>YouTube</h3>
                                                    <div className="embed-container">
                                                        <iframe
                                                            width="100%"
                                                            height="315"
                                                            src={`https://www.youtube.com/embed/${getYoutubeVideoId(profile.socialLinks.youtube)}`}
                                                            title="YouTube video player"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {profile.socialLinks?.instagram && (
                                            <div className="platform-card instagram">
                                                <div className="platform-info">
                                                    <h3>Instagram</h3>
                                                    <div className="embed-container">
                                                        <iframe
                                                            src={`https://www.instagram.com/${getInstagramUsername(profile.socialLinks.instagram)}/embed`}
                                                            width="100%"
                                                            height="450"
                                                            frameBorder="0"
                                                            scrolling="no"
                                                            allowTransparency={true}
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {profile.socialLinks?.tiktok && (
                                            <div className="platform-card tiktok">
                                                <div className="platform-info">
                                                    <h3>TikTok</h3>
                                                    <div className="embed-container">
                                                        <iframe
                                                            src={`https://www.tiktok.com/embed/${getTikTokVideoId(profile.socialLinks.tiktok)}?paused=1`}
                                                            width="100%"
                                                            height="700"
                                                            frameBorder="0"
                                                            allow="autoplay; fullscreen"
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {profile.socialLinks?.twitch && (
                                            <div className="platform-card twitch">
                                                <div className="platform-info">
                                                    <h3>Twitch</h3>
                                                    <div className="embed-container">
                                                        <iframe
                                                            src={`https://player.twitch.tv/?channel=${getTwitchUsername(profile.socialLinks.twitch)}&parent=${window.location.hostname}`}
                                                            frameBorder="0"
                                                            allowFullScreen={true}
                                                            scrolling="no"
                                                            height="315"
                                                            width="100%"
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {profile.socialLinks?.twitter && (
                                            <div className="platform-card twitter">
                                                <div className="platform-info">
                                                    <h3>Twitter</h3>
                                                    <div className="embed-container">
                                                        <Timeline
                                                            dataSource={{
                                                                sourceType: 'profile',
                                                                screenName: getTwitterUsername(profile.socialLinks.twitter)
                                                            }}
                                                            options={{
                                                                height: '400',
                                                                width: '100%'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </section>
                        </div>
                    </div>
                );
            case 'payments':
                return <CreatorPayments />;
            case 'creator-offers':
                return (
                    <div className="content-section">
                        <div className="section-header">
                            <h2>Content Packages</h2>
                            <Link to="/creator-profile-form" className="create-button">
                                Manage Packages
                            </Link>
                        </div>

                        <Box mb={6}>
                            <Text fontSize="sm" mb={2}>
                                Create content packages that brands can purchase directly from your profile.
                            </Text>
                            <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                Note: Hypeo takes a 15% fee from each transaction.
                            </Text>
                        </Box>

                        <Box>
                            {renderOffers()}
                        </Box>

                        <Box mt={6} p={5} bg="gray.50" borderRadius="lg">
                            <Flex direction={{ base: "column", md: "row" }} align="center" justify="space-between">
                                <Box mb={{ base: 4, md: 0 }}>
                                    <Heading size="md" mb={2}>Need to update your packages?</Heading>
                                    <Text>You can add, edit or remove content packages from your profile form.</Text>
                                </Box>
                                <Button
                                    as={Link}
                                    to="/creator-profile-form"
                                    colorScheme="blue"
                                    size="md"
                                    rightIcon={<Icon as={FontAwesomeIcon} icon="arrow-right" />}
                                >
                                    Edit Content Packages
                                </Button>
                            </Flex>
                        </Box>
                    </div>
                );
            case 'billing':
                return (
                    <Box className="content-section" p={6}>
                        <Flex direction="column" align="center" justify="center" minH="400px" textAlign="center">
                            <Box mb={8}>
                                <Heading size="lg" mb={4} color="gray.700">
                                    Earnings and Payments
                                </Heading>
                                <Text fontSize="md" color="gray.600" maxW="600px">
                                    Your earnings and payment information will be displayed here once brands start purchasing your content packages.
                                </Text>
                            </Box>

                            <Box 
                                p={6} 
                                bg="blue.50" 
                                borderRadius="lg" 
                                maxW="600px" 
                                w="100%"
                            >
                                <Heading size="sm" mb={3} color="blue.700">
                                    Getting Started
                                </Heading>
                                <Text color="blue.600" mb={4}>
                                    To start earning:
                                </Text>
                                <UnorderedList spacing={2} textAlign="left" color="blue.700">
                                    <ListItem>Create compelling content packages</ListItem>
                                    <ListItem>Complete your creator profile</ListItem>
                                    <ListItem>Share your profile with potential brands</ListItem>
                                    <ListItem>Respond promptly to brand inquiries</ListItem>
                                </UnorderedList>
                            </Box>
                        </Flex>
                    </Box>
                );
            case 'profile-photos':
                return renderProfilePhotosSection();
            default:
                return null;
        }
    };

    const SidebarContent = () => {
        const navItems = [
            { id: 'getting-started', label: 'Getting Started', icon: 'rocket' },
            { id: 'profile', label: 'Basic Info', icon: 'user' },
            { id: 'professional', label: 'Professional', icon: 'briefcase' },
            { id: 'social', label: 'Social Media', icon: 'share-alt' },
            { id: 'profile-photos', label: 'Profile Photos', icon: 'images' },
            { id: 'portfolio', label: 'Portfolio', icon: 'folder-open' },
            { id: 'audience', label: 'Audience', icon: 'users' },
            { id: 'creator-offers', label: 'Offers', icon: 'tag' },
            { id: 'billing', label: 'Earnings and Payments', icon: 'credit-card' }
        ];

        return (
            <Box 
                w="240px"
                bg="white" 
                borderRight="1px" 
                borderColor="gray.200"
                h="100%"
            >
                <VStack 
                    spacing={1} 
                    align="stretch" 
                    p={4}
                >
                    {navItems.map(item => (
                        <Button
                            key={item.id}
                            onClick={() => setActiveSection(item.id)}
                            variant="ghost"
                            justifyContent="flex-start"
                            leftIcon={<FontAwesomeIcon icon={['fas', item.icon]} />}
                            isActive={activeSection === item.id}
                            size="sm"
                            h="40px"
                            fontSize="14px"
                            _active={{
                                bg: 'blue.50',
                                color: 'blue.600',
                            }}
                        >
                            {item.label}
                        </Button>
                    ))}
                </VStack>
            </Box>
        );
    };

    // Update the MobileNavMenu component for better mobile UX
    const MobileNavMenu = () => {
        const navItems = [
            { id: 'getting-started', label: 'Getting Started', icon: 'rocket' },
            { id: 'profile', label: 'Basic Info', icon: 'user' },
            { id: 'professional', label: 'Professional', icon: 'briefcase' },
            { id: 'social', label: 'Social Media', icon: 'share-alt' },
            { id: 'profile-photos', label: 'Profile Photos', icon: 'images' },
            { id: 'portfolio', label: 'Portfolio', icon: 'folder-open' },
            { id: 'audience', label: 'Audience', icon: 'users' },
            { id: 'creator-offers', label: 'Offers', icon: 'tag' },
            { id: 'billing', label: 'Earnings and Payments', icon: 'credit-card' }
        ];

        return (
            <Box position="sticky" top="60px" zIndex={2} bg="white" shadow="sm">
                <Menu>
                    <MenuButton
                        as={Button}
                        w="full"
                        py={4}
                        px={6}
                        bg="white"
                        borderBottom="1px"
                        borderColor="gray.200"
                        borderRadius="0"
                        _hover={{ bg: 'gray.100' }}
                        _active={{ bg: 'gray.100' }}
                    >
                        <Flex justify="space-between" align="center">
                            <Flex align="center">
                                <FontAwesomeIcon 
                                    icon={navItems.find(item => item.id === activeSection)?.icon || 'bars'} 
                                    style={{ marginRight: '12px' }}
                                />
                                <Text fontWeight="medium" color="gray.800">
                                    {navItems.find(item => item.id === activeSection)?.label || 'Menu'}
                                </Text>
                            </Flex>
                            <FontAwesomeIcon icon="chevron-down" />
                        </Flex>
                    </MenuButton>
                    <MenuList 
                        maxH="70vh" 
                        overflowY="auto"
                        borderRadius="md"
                        shadow="lg"
                        bg="white"
                        zIndex={1}
                    >
                        {navItems.map(item => (
                            <MenuItem
                                key={item.id}
                                onClick={() => setActiveSection(item.id)}
                                py={3}
                                px={4}
                                icon={<FontAwesomeIcon icon={['fas', item.icon]} />}
                                bg={activeSection === item.id ? 'blue.500' : 'white'}
                                color={activeSection === item.id ? 'white' : 'gray.800'}
                                _hover={{
                                    bg: 'blue.500',
                                    color: 'white'
                                }}
                                _active={{
                                    bg: 'blue.600',
                                    color: 'white'
                                }}
                                _focus={{
                                    bg: 'blue.500',
                                    color: 'white'
                                }}
                            >
                                <Text fontWeight={activeSection === item.id ? "medium" : "normal"}>
                                    {item.label}
                                </Text>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
        );
    };

    // Update the main layout container for better mobile spacing
    return (
        <Box bg="gray.50" minH="100vh">
            <Box pt={{ base: "60px", md: "80px" }}> {/* Adjusted padding for mobile */}
                {/* Mobile Navigation */}
                {isMobile && <MobileNavMenu />}

                {/* Main Content Container */}
                <Container 
                    maxW="1400px" 
                    px={{ base: 2, md: 6 }} 
                    py={{ base: 2, md: 4 }}
                >
                    <Flex gap={{ base: 0, md: 6 }}>
                        {/* Desktop Sidebar */}
                        {!isMobile && <SidebarContent />}

                        {/* Main Content */}
                        <Box 
                            flex="1"
                            bg="white"
                            borderRadius={{ base: "none", md: "lg" }}
                            shadow={{ base: "none", md: "sm" }}
                            overflow="hidden"
                        >
                            {renderContent()}
                        </Box>
                    </Flex>
                </Container>
            </Box>
        </Box>
    );
};

const CreatorProfilePageWrapper = () => {
    const { creatorId } = useParams(); // Get creatorId from URL parameters
    
    return <CreatorProfilePage creatorId={creatorId} />;
};

export default CreatorProfilePageWrapper; 
