import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, VStack, Icon, Spinner } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useCart } from '../../contexts/CartContext';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';

const CheckoutSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clearCart } = useCart();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        // Get session ID from URL
        const params = new URLSearchParams(location.search);
        const sessionId = params.get('session_id');
        
        if (!sessionId) {
          throw new Error('No session ID found');
        }
        
        // Check if user is authenticated
        const auth = getAuth();
        const currentUser = auth.currentUser;
        
        if (!currentUser) {
          throw new Error('User not authenticated');
        }
        
        // Find order with this session ID and the current user's ID
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef, 
          where('stripeSessionId', '==', sessionId),
          where('userId', '==', currentUser.uid)
        );
        
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          throw new Error('Order not found');
        }
        
        const orderDoc = querySnapshot.docs[0];
        const orderRef = doc(db, 'orders', orderDoc.id);

        // Get current order data
        const currentOrderData = orderDoc.data();

        // Only update if not already paid
        if (currentOrderData.orderStatus?.isPaid !== true) {
          await updateDoc(orderRef, {
            status: 'paid',
            orderStatus: {
              isPaid: true,
              isCompleted: false,
              paymentPending: false
            },
            paymentDetails: {
              status: 'completed',
              attemptCount: 1,
              stripeSessionId: sessionId,
              paidAt: new Date().toISOString()
            },
            updatedAt: new Date().toISOString()
          });
        }

        // Get fresh order data
        const updatedOrderDoc = await getDoc(orderRef);
        setOrderDetails({
          id: updatedOrderDoc.id,
          ...updatedOrderDoc.data()
        });
        
        // Clear the cart after successful checkout
        clearCart();
        localStorage.removeItem('pendingCheckoutCart');
      } catch (err) {
        console.error('Error fetching order details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchOrderDetails();
  }, [location.search, clearCart]);
  
  // Helper function to safely format dates
  const formatDate = (timestamp) => {
    if (!timestamp) return 'Processing';
    
    try {
      // Check if it's a Firestore timestamp with toDate method
      if (typeof timestamp.toDate === 'function') {
        return timestamp.toDate().toLocaleString();
      }
      
      // Check if it's a string or number timestamp
      if (typeof timestamp === 'string' || typeof timestamp === 'number') {
        return new Date(timestamp).toLocaleString();
      }
      
      // If it's already a Date object
      if (timestamp instanceof Date) {
        return timestamp.toLocaleString();
      }
      
      return 'Invalid date format';
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };
  
  if (loading) {
    return (
      <Box textAlign="center" py={20}>
        <Spinner size="xl" />
        <Text mt={4}>Loading order details...</Text>
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box textAlign="center" py={20}>
        <Heading color="red.500">Error</Heading>
        <Text mt={4}>{error}</Text>
        <Button mt={6} colorScheme="blue" onClick={() => navigate('/cart')}>
          Return to Cart
        </Button>
      </Box>
    );
  }
  
  return (
    <Box maxW="800px" mx="auto" p={8} textAlign="center">
      <VStack spacing={6}>
        <Icon as={CheckCircleIcon} w={20} h={20} color="green.500" />
        
        <Heading>Thank You for Your Order!</Heading>
        
        <Text fontSize="lg">
          Your order has been successfully processed. You will receive a confirmation email shortly.
        </Text>
        
        <Box p={6} bg="gray.50" borderRadius="md" width="100%">
          <Heading size="md" mb={4}>Order Summary</Heading>
          
          <Text mb={2}>
            <strong>Order ID:</strong> {orderDetails?.id || 'Processing'}
          </Text>
          
          <Text mb={2}>
            <strong>Date:</strong> {formatDate(orderDetails?.createdAt)}
          </Text>
          
          <Text mb={2}>
            <strong>Total Amount:</strong> ${orderDetails?.total?.toFixed(2) || '0.00'}
          </Text>
          
          <Text mb={2}>
            <strong>Status:</strong> {orderDetails?.orderStatus?.isPaid ? 'Paid' : 'Processing'}
          </Text>

          <Text mb={2}>
            <strong>Payment Status:</strong> {orderDetails?.paymentDetails?.status || 'Processing'}
          </Text>
        </Box>
        
        <Text>
          You can view your order details and track progress in your dashboard.
        </Text>
        
        <Button
          as={Link}
          to="/dashboard"
          colorScheme="green"
          bgGradient="linear(to-r, green.400, green.500, green.600)"
          color="white"
          variant="solid"
        >
          Go to Dashboard
        </Button>
      </VStack>
    </Box>
  );
};

export default CheckoutSuccess; 