import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Heading, 
  Text, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Badge,
  useColorModeValue,
  Tooltip
} from '@chakra-ui/react';
import { collection, query, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { format } from 'date-fns';

const CreatorEarnings = () => {
  const [earnings, setEarnings] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [pendingEarnings, setPendingEarnings] = useState(0);
  const [paidEarnings, setPaidEarnings] = useState(0);

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;

        const ordersRef = collection(db, 'orders');
        const querySnapshot = await getDocs(ordersRef);
        const earningsData = [];
        let total = 0;
        let pending = 0;
        let paid = 0;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          
          if (data.items && Array.isArray(data.items)) {
            data.items.forEach(item => {
              if (item.creatorId === userId) {
                const amount = item.price * item.quantity;
                const orderStatus = data.orderStatus || {};
                const creatorPayout = item.creatorPayout || {
                  status: 'pending',
                  amount: amount,
                  payoutDate: null,
                  payoutMethod: null,
                  payoutReference: null,
                  payoutScheduledDate: null,
                  notes: null,
                  history: []
                };
                
                console.log('Found matching order:', {
                  orderId: doc.id,
                  amount,
                  orderStatus,
                  creatorPayout
                });
                
                const earningEntry = {
                  id: doc.id,
                  orderId: doc.id,
                  date: data.createdAt ? new Date(data.createdAt) : new Date(),
                  amount: amount,
                  orderAmount: amount,
                  brandName: data.brandName,
                  campaignName: item.title,
                  type: item.type,
                  status: creatorPayout.status,
                  orderPaid: orderStatus.isPaid,
                  orderCompleted: orderStatus.isCompleted,
                  quantity: item.quantity,
                  price: item.price,
                  payoutDetails: {
                    scheduledDate: creatorPayout.payoutScheduledDate,
                    payoutDate: creatorPayout.payoutDate,
                    method: creatorPayout.payoutMethod,
                    reference: creatorPayout.payoutReference,
                    notes: creatorPayout.notes
                  }
                };

                earningsData.push(earningEntry);

                total += amount;
                if (creatorPayout.status === 'paid') {
                  paid += amount;
                } else {
                  pending += amount;
                }
              }
            });
          }
        });

        console.log('Processed earnings data:', {
          total,
          paid,
          pending,
          entriesCount: earningsData.length
        });

        // Sort by date descending
        earningsData.sort((a, b) => b.date - a.date);

        setEarnings(earningsData);
        setTotalEarnings(total);
        setPendingEarnings(pending);
        setPaidEarnings(paid);

      } catch (error) {
        console.error('Error fetching earnings:', error);
      }
    };

    fetchEarnings();
  }, []);

  const getStatusDisplay = (earning) => {
    if (!earning.orderPaid) {
      return {
        label: 'Order Pending',
        color: 'yellow'
      };
    }
    
    switch (earning.status) {
      case 'paid':
        return {
          label: `Paid${earning.payoutDetails.payoutDate ? ` on ${format(new Date(earning.payoutDetails.payoutDate), 'MMM d, yyyy')}` : ''}`,
          color: 'green'
        };
      case 'scheduled':
        return {
          label: `Scheduled for ${format(new Date(earning.payoutDetails.scheduledDate), 'MMM d, yyyy')}`,
          color: 'purple'
        };
      case 'processing':
        return {
          label: 'Processing Payout',
          color: 'blue'
        };
      case 'pending':
        if (earning.orderCompleted) {
          return {
            label: 'Ready for Payout',
            color: 'cyan'
          };
        }
        return {
          label: 'In Progress',
          color: 'orange'
        };
      default:
        return {
          label: earning.status,
          color: 'gray'
        };
    }
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const updateCreatorPayout = async (orderId, creatorId, payoutDetails) => {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (orderDoc.exists()) {
      const data = orderDoc.data();
      const updatedItems = data.items.map(item => {
        if (item.creatorId === creatorId) {
          return {
            ...item,
            creatorPayout: {
              ...item.creatorPayout,
              ...payoutDetails,
              history: [
                ...(item.creatorPayout?.history || []),
                {
                  status: payoutDetails.status,
                  timestamp: new Date().toISOString(),
                  note: payoutDetails.notes
                }
              ]
            }
          };
        }
        return item;
      });
      
      await updateDoc(orderRef, {
        items: updatedItems
      });
    }
  };

  return (
    <Box>
      <Stack spacing={8}>
        <Box>
          <Heading size="lg" mb={6}>Earnings Overview</Heading>
          
          <StatGroup 
            bg={bgColor} 
            p={6} 
            borderRadius="lg" 
            border="1px" 
            borderColor={borderColor}
            mb={8}
          >
            <Stat>
              <StatLabel>Total Earnings</StatLabel>
              <StatNumber>${totalEarnings.toFixed(2)}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Pending Earnings</StatLabel>
              <StatNumber>${pendingEarnings.toFixed(2)}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Paid Earnings</StatLabel>
              <StatNumber>${paidEarnings.toFixed(2)}</StatNumber>
            </Stat>
          </StatGroup>
        </Box>

        <Box 
          bg={bgColor} 
          p={6} 
          borderRadius="lg" 
          border="1px" 
          borderColor={borderColor}
        >
          <Heading size="md" mb={4}>Earnings History</Heading>
          
          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Brand</Th>
                  <Th>Campaign</Th>
                  <Th>Type</Th>
                  <Th>Quantity</Th>
                  <Th>Price</Th>
                  <Th>Total</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {earnings.map((earning) => {
                  const status = getStatusDisplay(earning);
                  return (
                    <Tr key={earning.id}>
                      <Td>{format(earning.date, 'MMM d, yyyy')}</Td>
                      <Td>{earning.brandName}</Td>
                      <Td>
                        <Tooltip label={earning.campaignName}>
                          <Text isTruncated maxW="200px">
                            {earning.campaignName}
                          </Text>
                        </Tooltip>
                      </Td>
                      <Td>{earning.type}</Td>
                      <Td>{earning.quantity}</Td>
                      <Td>${earning.price.toFixed(2)}</Td>
                      <Td>${earning.amount.toFixed(2)}</Td>
                      <Td>
                        <Tooltip 
                          label={earning.payoutDetails.notes} 
                          isDisabled={!earning.payoutDetails.notes}
                        >
                          <Badge colorScheme={status.color}>
                            {status.label}
                          </Badge>
                        </Tooltip>
                      </Td>
                    </Tr>
                  );
                })}
                {earnings.length === 0 && (
                  <Tr>
                    <Td colSpan={8} textAlign="center">No earnings to display</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreatorEarnings; 