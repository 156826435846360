import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignupChoice.css';

const SignupChoice = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="signup-choice-container">
            <h1 className="signup-choice-title">Join HYPE</h1>
            <p className="signup-choice-subtitle">Choose how you want to join</p>
            
            <div className="choice-cards">
                <div className="choice-card" onClick={() => navigate('/signup/creator')}>
                    <h2 className="choice-card-title">Join as a Creator</h2>
                    <p>Promote products and earn commissions</p>
                    <button className="choice-btn">Sign up as Creator</button>
                </div>
                
                <div className="choice-card" onClick={() => navigate('/signup/brand')}>
                    <h2 className="choice-card-title">Join as a Brand</h2>
                    <p>List your products and grow your sales</p>
                    <button className="choice-btn">Sign up as Brand</button>
                </div>
            </div>
        </div>
    );
};

export default SignupChoice; 