import React, { useState, useEffect, useMemo } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalBody, 
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Stack,
    useToast
} from '@chakra-ui/react';
import './UserManagement.css';

const defaultAvatar = 'https://res.cloudinary.com/dtkagg3bc/image/upload/v1734495230/profile-images/default-avatar.png';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editedUser, setEditedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'displayName', direction: 'asc' });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'users'));
            const fetchedUsers = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUsers(fetchedUsers || []);
        } catch (error) {
            console.error('Error fetching users:', error);
            toast({
                title: 'Error fetching users',
                status: 'error',
                duration: 3000,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (key) => {
        setSortConfig(prev => ({
            key,
            direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const sortUsers = (usersToSort, key, direction) => {
        return [...usersToSort].sort((a, b) => {
            let aValue = a[key] || '';
            let bValue = b[key] || '';

            if (key === 'createdAt') {
                aValue = new Date(aValue || 0);
                bValue = new Date(bValue || 0);
            }

            if (direction === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
            }
        });
    };

    const filteredAndSortedUsers = useMemo(() => {
        let filtered = users.filter(user => {
            const searchLower = searchTerm.toLowerCase();
            return (
                user.email?.toLowerCase().includes(searchLower) ||
                user.displayName?.toLowerCase().includes(searchLower) ||
                user.role?.toLowerCase().includes(searchLower)
            );
        });
        return sortUsers(filtered, sortConfig.key, sortConfig.direction);
    }, [users, searchTerm, sortConfig]);

    const handleEdit = (user) => {
        setSelectedUser(user);
        setEditedUser({ ...user });
        onOpen();
    };

    const handleSaveEdit = async () => {
        try {
            const userRef = doc(db, 'users', editedUser.id);
            await updateDoc(userRef, {
                displayName: editedUser.displayName,
                email: editedUser.email,
                role: editedUser.role,
                status: editedUser.status,
            });
            
            setUsers(prev => prev.map(user => 
                user.id === editedUser.id ? editedUser : user
            ));
            
            toast({
                title: 'User updated successfully',
                status: 'success',
                duration: 3000,
            });
            onClose();
        } catch (error) {
            console.error('Error updating user:', error);
            toast({
                title: 'Error updating user',
                status: 'error',
                duration: 3000,
            });
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="user-management">
            <div className="table-controls">
                <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>

            <div className="users-table">
                <div className="table-header">
                    <div className="header-cell clickable" onClick={() => handleSort('displayName')}>
                        User {sortConfig.key === 'displayName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                    </div>
                    <div className="header-cell clickable" onClick={() => handleSort('email')}>
                        Email {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                    </div>
                    <div className="header-cell clickable" onClick={() => handleSort('role')}>
                        Role {sortConfig.key === 'role' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                    </div>
                    <div className="header-cell clickable" onClick={() => handleSort('status')}>
                        Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                    </div>
                    <div className="header-cell clickable" onClick={() => handleSort('createdAt')}>
                        Joined {sortConfig.key === 'createdAt' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                    </div>
                    <div className="header-cell">Actions</div>
                </div>

                <div className="table-body">
                    {filteredAndSortedUsers.map(user => (
                        <div key={user.id} className="table-row">
                            <div className="table-cell">
                                <div className="user-info">
                                    <img 
                                        src={user.profileImageUrl || defaultAvatar} 
                                        alt={user.displayName || 'User'} 
                                        className="user-avatar"
                                    />
                                    <div className="user-details">
                                        <div className="user-displayname">{user.displayName || 'No name'}</div>
                                        <div className="user-email">{user.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-cell">{user.email}</div>
                            <div className="table-cell">{user.role}</div>
                            <div className="table-cell">
                                <span className={`status-badge ${user.status?.toLowerCase() || 'active'}`}>
                                    {user.status || 'Active'}
                                </span>
                            </div>
                            <div className="table-cell">
                                {user.createdAt ? new Date(user.createdAt).toLocaleDateString() : ''}
                            </div>
                            <div className="table-cell">
                                <Button size="sm" onClick={() => handleEdit(user)}>Edit</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} pb={4}>
                            <FormControl>
                                <FormLabel>Display Name</FormLabel>
                                <Input
                                    value={editedUser?.displayName || ''}
                                    onChange={(e) => setEditedUser({
                                        ...editedUser,
                                        displayName: e.target.value
                                    })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    value={editedUser?.email || ''}
                                    onChange={(e) => setEditedUser({
                                        ...editedUser,
                                        email: e.target.value
                                    })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Role</FormLabel>
                                <Select
                                    value={editedUser?.role || ''}
                                    onChange={(e) => setEditedUser({
                                        ...editedUser,
                                        role: e.target.value
                                    })}
                                >
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="creator">Creator</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Status</FormLabel>
                                <Select
                                    value={editedUser?.status || 'active'}
                                    onChange={(e) => setEditedUser({
                                        ...editedUser,
                                        status: e.target.value
                                    })}
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="suspended">Suspended</option>
                                </Select>
                            </FormControl>
                            <Button colorScheme="blue" onClick={handleSaveEdit}>
                                Save Changes
                            </Button>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default UserManagement; 