import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './CreatorProfileImage.css';

const defaultAvatar = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3ClinearGradient id='grad' x1='0%25' y1='0%25' x2='100%25' y2='100%25'%3E%3Cstop offset='0%25' style='stop-color:%23f2f2f2;stop-opacity:1' /%3E%3Cstop offset='100%25' style='stop-color:%23e6e6e6;stop-opacity:1' /%3E%3C/linearGradient%3E%3C/defs%3E%3Ccircle cx='12' cy='12' r='12' fill='url(%23grad)' /%3E%3Cpath d='M12 13.5c-2.65 0-8 1.33-8 4V19h16v-1.5c0-2.67-5.35-4-8-4zm0-9C9.79 4.5 8 6.29 8 8.5s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z' fill='%23999999'/%3E%3C/svg%3E`;

const CreatorProfileImage = ({ currentImageUrl, onImageUpdate }) => {
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const auth = getAuth();

    // Monitor online/offline status
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!isOnline) {
            setError('You are offline. Please check your internet connection.');
            return;
        }

        try {
            setUploading(true);
            setError(null);

            // Get the current user's token
            const token = await auth.currentUser.getIdToken();

            // Create form data
            const formData = new FormData();
            formData.append('image', file);

            // Upload to your backend with auth token
            const response = await fetch('http://localhost:5001/api/upload/upload-profile-image', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Upload failed');
            }

            try {
                // Update the user's profile in Firestore
                const userRef = doc(db, 'users', auth.currentUser.uid);
                await updateDoc(userRef, {
                    profileImageUrl: data.url
                });

                // Call the callback with the new URL
                if (onImageUpdate) {
                    onImageUpdate(data.url);
                }
            } catch (firestoreError) {
                console.error('Firestore update error:', firestoreError);
                // Image uploaded but Firestore update failed
                setError('Image uploaded but profile update failed. Please try again.');
            }

        } catch (err) {
            console.error('Error uploading image:', err);
            setError('Failed to upload image. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="profile-image-container">
            <div className="profile-image-wrapper">
                <img 
                    src={currentImageUrl || defaultAvatar} 
                    alt="Profile" 
                    className="profile-image"
                />
                <div className="profile-image-overlay">
                    <label htmlFor="profile-image-upload" className="upload-button">
                        {uploading ? 'Uploading...' : 'Add Photo'}
                    </label>
                    <input
                        id="profile-image-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        disabled={uploading}
                        style={{ display: 'none' }}
                    />
                </div>
            </div>
            {error && <div className="upload-error">{error}</div>}
        </div>
    );
};

export default CreatorProfileImage; 