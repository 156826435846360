import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { 
    collection, 
    query, 
    where, 
    getDocs, 
    orderBy 
} from 'firebase/firestore';
import { 
    Box, 
    Text, 
    SimpleGrid, 
    Spinner, 
    Button,
    useToast,
    Heading,
    VStack,
    Alert,
    AlertIcon,
    useColorModeValue,
    Code
} from '@chakra-ui/react';
import OrderCard from './OrderCard';
import EmptyState from '../common/EmptyState';
import { FiShoppingBag } from 'react-icons/fi';

const BrandWorkOrders = ({ status }) => {
    const { currentUser } = useAuth();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [debugInfo, setDebugInfo] = useState(null);
    const toast = useToast();
    const bgColor = useColorModeValue('white', 'gray.800');

    useEffect(() => {
        const fetchOrders = async () => {
            if (!currentUser) return;
            
            try {
                setLoading(true);
                setError(null);
                
                // Fetch all orders
                const ordersQuery = query(
                    collection(db, 'orders')
                );
                
                const querySnapshot = await getDocs(ordersQuery);
                let allOrdersData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                // Filter by userId field instead of brandId
                let ordersData = allOrdersData.filter(order => 
                    order.userId === currentUser.uid
                );
                
                // Filter by status in JavaScript
                if (status === 'active') {
                    ordersData = ordersData.filter(order => 
                        ['pending', 'in_progress', 'review', 'active'].includes(order.status)
                    );
                } else if (status === 'completed') {
                    ordersData = ordersData.filter(order => 
                        ['completed', 'cancelled', 'done'].includes(order.status)
                    );
                }
                
                // Sort by createdAt or timestamp in JavaScript
                ordersData.sort((a, b) => {
                    const dateA = a.createdAt?.toDate?.() || a.timestamp?.toDate?.() || new Date(a.createdAt || a.timestamp || 0);
                    const dateB = b.createdAt?.toDate?.() || b.timestamp?.toDate?.() || new Date(b.createdAt || b.timestamp || 0);
                    return dateB - dateA; // Sort descending (newest first)
                });
                
                setOrders(ordersData);
                setDebugInfo(null);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setError('Failed to load orders. Please try again later.');
                setLoading(false);
            }
        };
        
        fetchOrders();
    }, [currentUser, status]);

    if (loading) {
        return (
            <Box textAlign="center" py={10}>
                <Spinner size="xl" />
                <Text mt={4}>Loading orders...</Text>
            </Box>
        );
    }

    if (error) {
        return (
            <Alert status="error" borderRadius="md">
                <AlertIcon />
                {error}
            </Alert>
        );
    }

    if (debugInfo) {
        return (
            <Box mb={6} p={4} bg="gray.100" borderRadius="md">
                <Heading size="sm" mb={2}>Debug Information</Heading>
                <Text>Total orders in database: {debugInfo.totalOrders}</Text>
                <Text>Current user ID: {debugInfo.currentUserId}</Text>
                <Text>Orders with brandId matching current user: {debugInfo.ordersWithBrandId}</Text>
                <Text>Orders with brandUID matching current user: {debugInfo.ordersWithBrandUID}</Text>
                <Text>Orders with uid matching current user: {debugInfo.ordersWithUID}</Text>
                <Text mt={2}>Possible fields in order document:</Text>
                <Code p={2} mt={1}>{JSON.stringify(debugInfo.possibleBrandIdFields)}</Code>
                
                <Button 
                    mt={4} 
                    colorScheme="blue" 
                    onClick={() => setDebugInfo(null)}
                >
                    Continue to Orders
                </Button>
            </Box>
        );
    }

    if (orders.length === 0) {
        return (
            <Box 
                bg={bgColor} 
                p={6} 
                borderRadius="lg" 
                boxShadow="md" 
                textAlign="center"
            >
                <Heading size="md" mb={2}>
                    No {status} orders found
                </Heading>
                <Text>
                    {status === 'active' 
                        ? "You don't have any active orders at the moment." 
                        : "You don't have any completed orders yet."}
                </Text>
            </Box>
        );
    }

    return (
        <VStack spacing={6} align="stretch" width="100%">
            {orders.map(order => (
                <OrderCard 
                    key={order.id} 
                    order={order} 
                    userRole="brand"
                />
            ))}
        </VStack>
    );
};

export default BrandWorkOrders; 