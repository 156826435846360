import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Setting up auth listener");
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth state changed:", { 
        user: user ? {
          uid: user.uid,
          email: user.email,
        } : 'No user'
      });

      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log("User data:", userData);
            setUserRole(userData.role);
          } else {
            console.log("No user document found");
            setUserRole(null);
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
          // Handle permission errors gracefully
          if (error.code === 'permission-denied') {
            console.log("Permission denied accessing user document");
            setUserRole(null);
          }
        }
      } else {
        setUserRole(null);
      }

      setCurrentUser(user);
      setLoading(false);
    });

    return () => {
      // Cleanup subscription
      unsubscribe();
    };
  }, []);

  const value = {
    currentUser,
    userRole,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
} 