const config = {
  development: {
    api: {
      baseURL: 'http://localhost:5001/api',
    }
  },
  production: {
    api: {
      baseURL: 'https://us-central1-hype-9b87b.cloudfunctions.net/api',
    }
  },
  
  // Firebase Configuration
  firebase: {
    // Your existing Firebase config
  },
  
  // Other configurations
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY
  }
};

const environment = process.env.NODE_ENV || 'development';
export default config[environment]; 