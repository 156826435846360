import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { 
    collection, 
    addDoc, 
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy
} from 'firebase/firestore';
import { 
    Text, 
    Textarea,
    IconButton,
    Avatar,
    Button,
    Spinner
} from '@chakra-ui/react';
import { IoSend } from 'react-icons/io5';
import { FaPaperclip, FaSmile } from 'react-icons/fa';
import { RiText } from 'react-icons/ri';
import { FiSettings } from 'react-icons/fi';
import './MessageThread.css';

const MessageThread = ({ 
    orderId,
    selectedOrder,
    userRole,
    handleStatusUpdate,
    offerData,
    defaultTab = 'messages'
}) => {
    const { currentUser } = useAuth();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const messagesContainerRef = useRef(null);
    const [userJustSentMessage, setUserJustSentMessage] = useState(false);

    // Simple fetch function without listeners
    const fetchMessages = async () => {
        if (!orderId || !currentUser) {
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            
            // Create a simple query
            const q = query(
                collection(db, 'messages'),
                where('orderId', '==', orderId),
                orderBy('timestamp', 'asc')
            );
            
            // Execute the query
            const querySnapshot = await getDocs(q);
            
            // Process the results
            const messageList = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                messageList.push({
                    id: doc.id,
                    ...data,
                    timestamp: data.timestamp?.toDate() || new Date()
                });
            });
            
            setMessages(messageList);
            setError(null);
        } catch (err) {
            console.error('Error fetching messages:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Initial fetch
    useEffect(() => {
        if (currentUser) {
            fetchMessages();
            
            // Set up polling
            const interval = setInterval(fetchMessages, 5000);
            
            return () => {
                clearInterval(interval);
            };
        } else {
            setLoading(false);
            setMessages([]);
        }
    }, [orderId, currentUser]);

    // Send message function
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !currentUser || !orderId) return;

        const messageText = newMessage.trim();
        setNewMessage('');
        setUserJustSentMessage(true); // Set flag to indicate user just sent a message

        try {
            // Add optimistic message
            const optimisticMessage = {
                id: `temp-${Date.now()}`,
                orderId,
                senderId: currentUser.uid,
                text: messageText,
                timestamp: new Date()
            };
            
            setMessages(prev => [...prev, optimisticMessage]);
            
            // Add to Firestore
            await addDoc(collection(db, 'messages'), {
                orderId,
                senderId: currentUser.uid,
                text: messageText,
                timestamp: serverTimestamp()
            });
            
            // Fetch latest messages
            fetchMessages();
        } catch (err) {
            console.error('Error sending message:', err);
        }
    };

    // Handle key press in the textarea
    const handleKeyPress = (e) => {
        // Check if Enter is pressed without Shift key
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent default behavior (new line)
            handleSubmit(e); // Submit the form
        }
    };

    // Scroll the message container to the bottom only when user sends a new message
    useEffect(() => {
        if (userJustSentMessage && messagesContainerRef.current) {
            const container = messagesContainerRef.current;
            container.scrollTop = container.scrollHeight;
            setUserJustSentMessage(false); // Reset the flag
        }
    }, [messages, userJustSentMessage]);

    // Group messages by date
    const groupMessagesByDate = () => {
        const groups = {};
        
        messages.forEach(message => {
            const messageDate = message.timestamp || new Date();
            const dateKey = messageDate.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            });
            
            if (!groups[dateKey]) {
                groups[dateKey] = [];
            }
            
            groups[dateKey].push(message);
        });
        
        return groups;
    };

    const messageGroups = groupMessagesByDate();

    // If no user is logged in, show a message
    if (!currentUser) {
        return (
            <div className="message-thread">
                <div className="messages-container">
                    <div className="empty-container">
                        <Text color="gray.500">Please sign in to view messages.</Text>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="message-thread">
            {/* Messages List */}
            <div className="messages-container" ref={messagesContainerRef}>
                {loading && messages.length === 0 ? (
                    <div className="loading-container">
                        <Spinner size="lg" color="blue.500" />
                    </div>
                ) : error ? (
                    <div className="error-container">
                        <Text color="red.500" mb={4}>
                            {error}
                        </Text>
                        <Button 
                            colorScheme="blue" 
                            onClick={fetchMessages}
                            size="sm"
                        >
                            Retry
                        </Button>
                    </div>
                ) : messages.length === 0 ? (
                    <div className="empty-container">
                        <Text color="gray.500">No messages yet. Start the conversation!</Text>
                    </div>
                ) : (
                    <div className="messages-wrapper">
                        {Object.entries(messageGroups).map(([date, dateMessages]) => (
                            <div key={date} className="message-date-group">
                                <div className="date-header">
                                    {date}
                                </div>
                                
                                {dateMessages.map((message) => {
                                    const isCurrentUser = message.senderId === currentUser?.uid;
                                    const senderName = isCurrentUser 
                                        ? (currentUser?.displayName || 'You') 
                                        : (userRole === 'creator' 
                                            ? selectedOrder?.brand?.name 
                                            : selectedOrder?.creator?.name);
                                    
                                    return (
                                        <div key={message.id} className="message-item">
                                            <div className="message-header">
                                                <Avatar 
                                                    size="sm"
                                                    name={senderName}
                                                    src={isCurrentUser ? currentUser?.photoURL : null}
                                                    bg="gray.200"
                                                    className="message-avatar"
                                                />
                                                <div className="message-sender-info">
                                                    <div className="message-sender-name">
                                                        {senderName}
                                                    </div>
                                                    <div className="message-time">
                                                        {message.timestamp ? 
                                                            message.timestamp.toLocaleTimeString([], {
                                                                hour: '2-digit', 
                                                                minute: '2-digit'
                                                            }) : 
                                                            'Sending...'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="message-body">
                                                <div>{message.text}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Message Input */}
            <div className="message-input-container">
                <form onSubmit={handleSubmit}>
                    <Textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        placeholder="Send a message..."
                        className="message-input"
                    />
                    <div className="message-controls">
                        <div className="formatting-buttons">
                            <IconButton
                                icon={<RiText />}
                                aria-label="Text formatting"
                                className="format-button"
                            />
                            <IconButton
                                icon={<FaPaperclip />}
                                aria-label="Attach file"
                                className="format-button"
                            />
                            <IconButton
                                icon={<FaSmile />}
                                aria-label="Emoji"
                                className="format-button"
                            />
                        </div>
                        <div className="send-controls">
                            <IconButton
                                icon={<FiSettings />}
                                aria-label="Settings"
                                className="settings-button"
                            />
                            <IconButton
                                icon={<IoSend />}
                                type="submit"
                                aria-label="Send message"
                                isDisabled={!newMessage.trim()}
                                className="send-button"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MessageThread; 