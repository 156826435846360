import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import './CreatorPayments.css';

const CreatorPayments = () => {
    const [earnings, setEarnings] = useState({
        affiliate: {
            availableBalance: 0,
            pendingBalance: 0,
            nextPayout: null,
            recentTransactions: []
        },
        partnerships: {
            availableBalance: 0,
            pendingBalance: 0,
            nextPayout: null,
            recentTransactions: []
        }
    });
    const [loading, setLoading] = useState(true);

    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.toDate) return 'Not available';
        try {
            return timestamp.toDate().toLocaleDateString();
        } catch (error) {
            return 'Invalid date';
        }
    };

    useEffect(() => {
        const fetchEarnings = async () => {
            if (!auth.currentUser) return;

            try {
                // Get affiliate conversions
                const conversionsRef = collection(db, 'conversions');
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

                const conversionsQuery = query(
                    conversionsRef,
                    where('affiliateId', '==', auth.currentUser.uid),
                    where('timestamp', '>', thirtyDaysAgo)
                );

                // Get partnerships
                const partnershipsRef = collection(db, 'partnerships');
                const partnershipsQuery = query(
                    partnershipsRef,
                    where('creatorId', '==', auth.currentUser.uid)
                );

                // Fetch both in parallel
                const [conversionsSnapshot, partnershipsSnapshot] = await Promise.all([
                    getDocs(conversionsQuery),
                    getDocs(partnershipsQuery)
                ]);

                // Process affiliate conversions
                const campaignIds = [...new Set(conversionsSnapshot.docs.map(doc => doc.data().campaignId))];
                const campaignDetails = {};
                await Promise.all(campaignIds.map(async (campaignId) => {
                    const campaignDoc = await getDoc(doc(db, 'campaigns', campaignId));
                    if (campaignDoc.exists()) {
                        campaignDetails[campaignId] = campaignDoc.data();
                    }
                }));

                // Process affiliate transactions
                const processedConversions = conversionsSnapshot.docs.map(doc => {
                    const conversion = doc.data();
                    const campaign = campaignDetails[conversion.campaignId];
                    
                    let conversionDate;
                    try {
                        conversionDate = conversion.timestamp?.toDate() || new Date();
                    } catch (error) {
                        conversionDate = new Date();
                    }

                    const daysSinceConversion = Math.floor(
                        (new Date() - conversionDate) / (1000 * 60 * 60 * 24)
                    );

                    return {
                        id: doc.id,
                        amount: Number(campaign?.payout || conversion.amount || 0),
                        timestamp: conversionDate,
                        orderId: conversion.orderId || 'Unknown',
                        campaignName: campaign?.offerName || 'Unknown Campaign',
                        isPending: daysSinceConversion < 30,
                        status: daysSinceConversion < 30 ? 'Pending' : 'Cleared',
                        clearanceDate: new Date(conversionDate.getTime() + (30 * 24 * 60 * 60 * 1000))
                    };
                });

                // Process partnership transactions
                const processedPartnerships = await Promise.all(partnershipsSnapshot.docs.map(async docSnapshot => {
                    const partnership = docSnapshot.data();
                    
                    let brandName = 'Unknown Brand';
                    try {
                        if (!partnership.brandId) {
                            throw new Error('No brandId found');
                        }

                        const brandDocRef = doc(db, 'users', partnership.brandId);
                        const brandSnapshot = await getDoc(brandDocRef);
                        
                        if (brandSnapshot.exists()) {
                            const brandData = brandSnapshot.data();
                            brandName = brandData.brandName || 'Unknown Brand';
                        }
                    } catch (error) {
                        console.error('Error fetching brand:', partnership.brandId, error);
                        brandName = 'Unknown Brand';
                    }

                    let partnershipDate;
                    try {
                        partnershipDate = partnership.createdAt?.toDate() || new Date();
                    } catch (error) {
                        partnershipDate = new Date();
                    }

                    const daysSinceCreation = Math.floor(
                        (new Date() - partnershipDate) / (1000 * 60 * 60 * 24)
                    );

                    return {
                        id: docSnapshot.id,
                        amount: Number(partnership.offerPrice || 0),
                        timestamp: partnershipDate,
                        type: `Partnership with ${brandName}`,
                        campaignName: partnership.offerTitle || 'Partnership Offer',
                        isPending: daysSinceCreation < 14,
                        status: partnership.status || 'Pending',
                        clearanceDate: new Date(partnershipDate.getTime() + (14 * 24 * 60 * 60 * 1000))
                    };
                }));

                // Calculate affiliate balances
                const { pendingBalance: affiliatePending, availableBalance: affiliateAvailable } = 
                    processedConversions.reduce(
                        (acc, conversion) => {
                            const amount = Number(conversion.amount) || 0;
                            if (conversion.isPending) {
                                acc.pendingBalance += amount;
                            } else {
                                acc.availableBalance += amount;
                            }
                            return acc;
                        },
                        { pendingBalance: 0, availableBalance: 0 }
                    );

                // Calculate partnership balances
                const { pendingBalance: partnershipPending, availableBalance: partnershipAvailable } = 
                    processedPartnerships.reduce(
                        (acc, partnership) => {
                            const amount = Number(partnership.amount) || 0;
                            if (partnership.isPending || partnership.status === 'pending') {
                                acc.pendingBalance += amount;
                            } else if (partnership.status === 'completed') {
                                acc.availableBalance += amount;
                            }
                            return acc;
                        },
                        { pendingBalance: 0, availableBalance: 0 }
                    );

                setEarnings({
                    affiliate: {
                        availableBalance: Number(affiliateAvailable) || 0,
                        pendingBalance: Number(affiliatePending) || 0,
                        nextPayout: null,
                        recentTransactions: processedConversions.map(conv => ({
                            ...conv,
                            amount: Number(conv.amount) || 0,
                            date: conv.timestamp,
                            clearanceDate: conv.clearanceDate,
                            type: 'Affiliate Sale'
                        }))
                    },
                    partnerships: {
                        availableBalance: Number(partnershipAvailable) || 0,
                        pendingBalance: Number(partnershipPending) || 0,
                        nextPayout: null,
                        recentTransactions: processedPartnerships.map(partnership => ({
                            ...partnership,
                            amount: Number(partnership.amount) || 0,
                            date: partnership.timestamp,
                            clearanceDate: partnership.clearanceDate
                        }))
                    }
                });

            } catch (error) {
                setEarnings({
                    affiliate: {
                        availableBalance: 0,
                        pendingBalance: 0,
                        nextPayout: null,
                        recentTransactions: []
                    },
                    partnerships: {
                        availableBalance: 0,
                        pendingBalance: 0,
                        nextPayout: null,
                        recentTransactions: []
                    }
                });
            } finally {
                setLoading(false);
            }
        };

        fetchEarnings();
    }, []);

    const renderTransactionItem = (transaction) => (
        <div className="transaction-item">
            <div className="transaction-info">
                <span className="transaction-date">
                    {transaction.date instanceof Date 
                        ? transaction.date.toLocaleDateString()
                        : formatDate(transaction.date)}
                </span>
                <span className="transaction-type">{transaction.type}</span>
                {transaction.isPending && (
                    <span className="transaction-clearance">
                        Clears: {transaction.clearanceDate instanceof Date 
                            ? transaction.clearanceDate.toLocaleDateString()
                            : 'Processing'}
                    </span>
                )}
            </div>
            <div className="transaction-amount-info">
                <span className={`transaction-status ${(transaction.status || 'pending').toLowerCase()}`}>
                    {transaction.status || 'Pending'}
                </span>
                <span className="transaction-amount">
                    ${Number(transaction.amount).toFixed(2)}
                </span>
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    return (
        <div className="creator-payments">
            <div className="payments-header">
                <h1>Creator Earnings</h1>
                <p>Manage your earnings and schedule payouts</p>
            </div>

            <div className="earnings-grid">
                <div className="earnings-card">
                    <div className="earnings-card-header">
                        <h2>Affiliate Revenue</h2>
                        <div className="payout-schedule">
                            Next payout: {earnings.affiliate.nextPayout ? 
                                new Date(earnings.affiliate.nextPayout).toLocaleDateString() : 
                                'Not scheduled'}
                        </div>
                    </div>
                    
                    <div className="balance-section">
                        <div className="balance-item">
                            <span className="balance-label">Available for Withdrawal</span>
                            <span className="balance-amount">${earnings.affiliate.availableBalance.toFixed(2)}</span>
                        </div>
                        <div className="balance-item pending">
                            <span className="balance-label">Pending Clearance</span>
                            <span className="balance-amount">${earnings.affiliate.pendingBalance.toFixed(2)}</span>
                            <span className="balance-info">Clears in 30 days</span>
                        </div>
                    </div>

                    <button 
                        className="withdraw-button"
                        disabled={earnings.affiliate.availableBalance <= 0}
                    >
                        Withdraw Funds
                    </button>

                    <div className="transactions-section">
                        <h3>Recent Activity</h3>
                        {earnings.affiliate.recentTransactions.length > 0 ? (
                            <div className="transactions-list">
                                {earnings.affiliate.recentTransactions.map((transaction, index) => (
                                    <div key={transaction.id || `affiliate-${index}`}>
                                        {renderTransactionItem(transaction)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="no-transactions">No recent transactions</p>
                        )}
                    </div>
                </div>

                <div className="earnings-card">
                    <div className="earnings-card-header">
                        <h2>Partnership Revenue</h2>
                        <div className="payout-schedule">
                            Next payout: {earnings.partnerships.nextPayout ? 
                                new Date(earnings.partnerships.nextPayout).toLocaleDateString() : 
                                'Not scheduled'}
                        </div>
                    </div>
                    
                    <div className="balance-section">
                        <div className="balance-item">
                            <span className="balance-label">Available for Withdrawal</span>
                            <span className="balance-amount">${earnings.partnerships.availableBalance.toFixed(2)}</span>
                        </div>
                        <div className="balance-item pending">
                            <span className="balance-label">Pending Clearance</span>
                            <span className="balance-amount">${earnings.partnerships.pendingBalance.toFixed(2)}</span>
                            <span className="balance-info">Clears in 14 days</span>
                        </div>
                    </div>

                    <button 
                        className="withdraw-button"
                        disabled={earnings.partnerships.availableBalance <= 0}
                    >
                        Withdraw Funds
                    </button>

                    <div className="transactions-section">
                        <h3>Recent Activity</h3>
                        {earnings.partnerships.recentTransactions.length > 0 ? (
                            <div className="transactions-list">
                                {earnings.partnerships.recentTransactions.map((transaction, index) => (
                                    <div key={transaction.id || `partnership-${index}`}>
                                        {renderTransactionItem(transaction)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="no-transactions">No recent transactions</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatorPayments; 