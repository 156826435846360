import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Select, Input, Button, Badge, Heading, SimpleGrid, Image, Skeleton, SkeletonCircle, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { getCreators } from '../../api/api';

const CreatorListing = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [creators, setCreators] = useState([]);
  
  // Define filter options with their values
  const contentTypeOptions = ['All', 'Video', 'Photo', 'Audio', 'Text'];
  const followerRanges = ['All', '1K-10K', '10K-50K', '50K-100K', '100K-500K', '500K-1M', '1M+'];
  const locationOptions = ['All', 'United States', 'Canada', 'United Kingdom', 'Australia', 'Europe', 'Asia', 'Other'];
  const priceRanges = ['All', 'Under $100', '$100-$500', '$500-$1000', '$1000-$5000', '$5000+'];
  
  const [filters, setFilters] = useState({
    platform: 'all',
    minFollowers: '',
    category: 'all',
    sortBy: 'followers',
    sortDirection: 'desc',
    contentType: 'All',  // Capitalized to match options
    followers: 'All',    // Added this field
    location: 'All',     // Capitalized to match options
    price: 'All'         // Capitalized to match options
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const creatorsPerPage = 9;

  // Auth check
  useEffect(() => {
    const checkAuth = async () => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        setIsAuthenticated(!!user);
      });

      return () => unsubscribe();
    };

    checkAuth();
  }, [auth]);

  // Fetch creators
  useEffect(() => {
    const fetchCreators = async () => {
      try {
        setLoading(true);
        const response = await getCreators();
        
        if (!response || (Array.isArray(response) && response.length === 0)) {
          setCreators([]);
          setHasMore(false);
          setLoading(false);
          return;
        }

        const creatorsArray = Array.isArray(response) ? response : 
                            response.creators ? response.creators : [];
        
        const verifiedCreators = creatorsArray.filter(creator => {
          if (!creator) return false;

          const hasProfileImage = creator.profileImageUrl && 
            creator.profileImageUrl.trim() !== '';
          
          const totalFollowers = creator.statistics?.totalFollowers || 
            (creator.followers ? Object.values(creator.followers)
              .reduce((sum, count) => sum + Number(count), 0) : 0);

          const hasMinimumFollowers = totalFollowers >= 1;
          
          const hasRequiredFields = (creator.name || creator.displayName) && 
            creator.bio && 
            creator.primaryCategory;

          return hasProfileImage && hasMinimumFollowers && hasRequiredFields;
        });

        // Fetch offers for each creator
        const creatorsWithOffers = await Promise.all(
          verifiedCreators.map(async (creator) => {
            try {
              // Query Firestore for creator offers
              const offersQuery = query(
                collection(db, 'creatorOffers'),
                where('creatorId', '==', creator.id),
                where('status', '==', 'active')
              );
              
              const offersSnapshot = await getDocs(offersQuery);
              
              const offersData = offersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }));
              
              // Add offers to creator object
              return {
                ...creator,
                creatorOffers: offersData
              };
            } catch (error) {
              console.error(`Error fetching offers for creator ${creator.id}:`, error);
              return creator; // Return creator without offers if there's an error
            }
          })
        );

        setCreators(creatorsWithOffers);
        setHasMore(creatorsWithOffers.length >= page * creatorsPerPage);
      } catch (error) {
        console.error('Error fetching creators:', error);
        setCreators([]);
        setHasMore(false);
      } finally {
        setLoading(false);
      }
    };

    fetchCreators();
  }, [page]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setPage(1); // Reset page when filters change
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset page when searching
  };

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
  };

  // Helper function to check if a creator's follower count is within a range
  const isInFollowerRange = (creator, range) => {
    if (range === 'All' || !range) return true;
    
    const totalFollowers = creator.statistics?.totalFollowers || 
      (creator.followers ? Object.values(creator.followers)
        .reduce((sum, count) => sum + Number(count), 0) : 0);
    
    const ranges = {
      '1K-10K': [1000, 10000],
      '10K-50K': [10000, 50000],
      '50K-100K': [50000, 100000],
      '100K-500K': [100000, 500000],
      '500K-1M': [500000, 1000000],
      '1M+': [1000000, Infinity]
    };
    
    const [min, max] = ranges[range] || [0, Infinity];
    return totalFollowers >= min && totalFollowers < max;
  };

  // Get the lowest price from creator's offers
  const getCreatorPrice = (creator) => {
    // Check if creator has creatorOffers
    if (creator.creatorOffers && Array.isArray(creator.creatorOffers) && creator.creatorOffers.length > 0) {
      // Find the lowest price from all offers
      const prices = creator.creatorOffers
        .filter(offer => offer.price && !isNaN(offer.price))
        .map(offer => Number(offer.price));
      
      if (prices.length > 0) {
        return Math.min(...prices);
      }
    }
    
    // Fallback to rate if no offers with prices
    if (creator.rate && !isNaN(creator.rate)) {
      return Number(creator.rate);
    }
    
    // If no offers or rate found, return null
    return null;
  };

  // Helper function to check if a creator's price is within a range
  const isInPriceRange = (creator, range) => {
    if (range === 'All' || !range) return true;
    
    const price = getCreatorPrice(creator);
    if (price === null) {
      // If no price is found, only include this creator when "All" is selected
      return range === 'All';
    }
    
    const ranges = {
      'Under $100': [0, 100],
      '$100-$500': [100, 500],
      '$500-$1000': [500, 1000],
      '$1000-$5000': [1000, 5000],
      '$5000+': [5000, Infinity]
    };
    
    const [min, max] = ranges[range] || [0, Infinity];
    return price >= min && price < max;
  };

  // Format price display
  const formatPrice = (price) => {
    if (price === null) {
      return null;
    }
    return `$${price}`;
  };

  // Helper function to check if a creator's content type matches
  const hasContentType = (creator, type) => {
    if (type === 'All' || !type) return true;
    
    // Check if creator has content of this type
    const contentTypes = creator.contentTypes || [];
    const offerTypes = creator.creatorOffers?.map(offer => offer.type) || [];
    
    // More lenient check - look for partial matches in offer types
    return contentTypes.includes(type.toLowerCase()) || 
           offerTypes.some(offerType => 
             offerType?.toLowerCase().includes(type.toLowerCase()) ||
             type.toLowerCase().includes(offerType?.toLowerCase() || '')
           );
  };

  // Helper function to check if a creator is in a location
  const isInLocation = (creator, location) => {
    if (location === 'All' || !location) return true;
    
    // Check multiple location fields
    const creatorLocation = creator.country || creator.location || creator.city || '';
    
    // Handle common country variations
    const locationMappings = {
      'United States': ['us', 'usa', 'united states', 'america', 'u.s.a', 'u.s'],
      'United Kingdom': ['uk', 'united kingdom', 'britain', 'great britain', 'england', 'scotland', 'wales'],
      'Canada': ['ca', 'canada'],
      'Australia': ['au', 'australia', 'aus'],
      'Europe': ['eu', 'europe', 'european union'],
      'Asia': ['asia']
    };
    
    // Check if the selected location has mappings
    if (locationMappings[location]) {
      // Check if any of the mapped values match the creator's location
      return locationMappings[location].some(variant => 
        creatorLocation.toLowerCase().includes(variant)
      );
    }
    
    // For locations without specific mappings, do a simple check
    return creatorLocation.toLowerCase().includes(location.toLowerCase());
  };

  // Handle filter selection
  const handleFilterSelect = (filterType, value) => {
    console.log(`Setting ${filterType} filter to:`, value);
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
    setActiveFilter(null); // Close the dropdown
    setPage(1); // Reset to first page
  };

  const clearAllFilters = () => {
    setFilters({
      platform: 'all',
      minFollowers: '',
      category: 'all',
      sortBy: 'followers',
      sortDirection: 'desc',
      contentType: 'All',  // Capitalized to match options
      followers: 'All',    // Added this field
      location: 'All',     // Capitalized to match options
      price: 'All'         // Capitalized to match options
    });
    setSearchTerm('');
    setPage(1);
    setActiveFilter(null);
  };

  // Update the filtered creators logic to work with all filters
  const filteredCreators = creators.filter(creator => {
    // Search filter - more lenient
    if (searchTerm && 
        !creator.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !creator.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !creator.primaryCategory?.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !creator.secondaryCategory?.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !creator.bio?.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }

    // Platform filter
    if (filters.platform !== 'all') {
      const hasPlatform = creator.socialLinks && 
        creator.socialLinks[filters.platform];
      if (!hasPlatform) return false;
    }

    // Category filter
    if (filters.category !== 'all' && 
        creator.primaryCategory?.toLowerCase() !== filters.category.toLowerCase() &&
        creator.secondaryCategory?.toLowerCase() !== filters.category.toLowerCase()) {
      return false;
    }
    
    // Content Type filter
    if (filters.contentType !== 'All' && !hasContentType(creator, filters.contentType)) {
      return false;
    }
    
    // Followers filter
    if (filters.followers && filters.followers !== 'All' && !isInFollowerRange(creator, filters.followers)) {
      return false;
    }
    
    // Location filter
    if (filters.location !== 'All' && !isInLocation(creator, filters.location)) {
      return false;
    }
    
    // Price filter
    if (filters.price !== 'All' && !isInPriceRange(creator, filters.price)) {
      return false;
    }

    return true;
  });

  // Sort creators based on filters
  const sortedCreators = [...filteredCreators].sort((a, b) => {
    const direction = filters.sortDirection === 'asc' ? 1 : -1;
    
    switch (filters.sortBy) {
      case 'followers':
        const followersA = a.statistics?.totalFollowers || 0;
        const followersB = b.statistics?.totalFollowers || 0;
        return (followersB - followersA) * direction;
      
      case 'name':
        return (a.displayName || '').localeCompare(b.displayName || '') * direction;
      
      case 'createdAt':
        const dateA = new Date(a.createdAt || 0);
        const dateB = new Date(b.createdAt || 0);
        return (dateB - dateA) * direction;
      
      default:
        return 0;
    }
  });

  const handleProfileClick = () => {
    if (isAuthenticated) {
      navigate('/creator/profile');
    } else {
      navigate('/login', { state: { from: '/creator/profile' } });
    }
  };

  // Add this effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsFiltersVisible(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Format follower count with K/M suffix
  const formatFollowerCount = (count) => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    } else if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count.toString();
  };

  // Loading card skeleton component
  const CreatorCardSkeleton = () => (
    <Box 
      borderWidth="1px" 
      borderRadius="lg" 
      overflow="hidden" 
      bg="white"
      boxShadow="sm"
      height="100%"
    >
      <Box position="relative" height="200px">
        <Skeleton height="200px" width="100%" />
        </Box>
      <Box p={4}>
        <Flex mb={2} alignItems="center">
          <SkeletonCircle size="8" mr={2} />
          <Skeleton height="20px" width="70%" />
      </Flex>
        <Skeleton height="16px" width="90%" mb={2} />
        <Skeleton height="16px" width="60%" mb={4} />
        <Skeleton height="24px" width="40%" />
      </Box>
    </Box>
  );

  // Filter options
  const filterOptions = [
    { name: "Content Type", value: "contentType", options: contentTypeOptions },
    { name: "Followers", value: "followers", options: followerRanges },
    { name: "Location", value: "location", options: locationOptions },
    { name: "Price", value: "price", options: priceRanges }
  ];

  const navigateToCreatorProfile = (creator) => {
    navigate(`/creator/${creator.username || creator.id}`);
  };

  return (
    <Box bg="gray.50" minH="100vh" py={8}>
      <Box maxW="1400px" mx="auto" px={4}>
        {/* Search and Filter Section */}
        <Flex 
          direction={{ base: "column", md: "row" }} 
          mb={8} 
          gap={4}
          bg="white"
          p={4}
          borderRadius="lg"
          boxShadow="sm"
        >
          <Box flex="1">
            <Text fontWeight="medium" mb={2}>Platform</Text>
            <Select 
              name="platform"
              value={filters.platform}
              onChange={handleFilterChange}
              size="md"
            >
              <option value="all">Any</option>
              <option value="instagram">Instagram</option>
              <option value="tiktok">TikTok</option>
              <option value="youtube">YouTube</option>
              <option value="twitter">Twitter</option>
            </Select>
          </Box>
          
          <Box flex="2">
            <Text fontWeight="medium" mb={2}>Category</Text>
            <Input 
              placeholder="Enter keywords, niches or categories" 
              value={searchTerm}
              onChange={handleSearch}
              size="md"
            />
          </Box>
          
          <Box alignSelf="flex-end">
            <Button 
              colorScheme="gray" 
              bg="gray.800" 
              color="white" 
              size="md" 
              borderRadius="full"
              px={6}
              _hover={{ bg: "black" }}
            >
              <SearchIcon mr={2} />
              Search
            </Button>
          </Box>
        </Flex>
        
        {/* Filter Pills */}
        <Flex mb={8} flexWrap="wrap" gap={2} alignItems="center">
          {filterOptions.map(filter => (
            <Box key={filter.value} position="relative">
                  <Button 
                variant="outline"
                borderRadius="full"
                size="sm"
                color="black"
                borderColor="black"
                bg={filters[filter.value] !== 'all' && filters[filter.value] !== 'All' ? "gray.100" : "white"}
                _hover={{ bg: "gray.100" }}
                onClick={() => setActiveFilter(activeFilter === filter.value ? null : filter.value)}
              >
                {filter.name} {filters[filter.value] !== 'all' && filters[filter.value] !== 'All' && 
                  <Badge ml={1} colorScheme="blue" variant="subtle" borderRadius="full">{filters[filter.value]}</Badge>
                } <ChevronDownIcon ml={1} />
                  </Button>
              
              {/* Dropdown for filter options */}
              {activeFilter === filter.value && (
                <Box 
                  position="absolute" 
                  top="100%" 
                  left="0" 
                  mt={2} 
                  zIndex={10}
                  bg="white" 
                  borderWidth="1px" 
                  borderRadius="md" 
                  boxShadow="md"
                  width="200px"
                  p={2}
                >
                  {filter.options.map(option => (
                    <Button
                      key={option}
                      variant="ghost"
                      size="sm"
                      justifyContent="flex-start"
                      width="100%"
                      fontWeight={filters[filter.value] === option ? "bold" : "normal"}
                      bg={filters[filter.value] === option ? "blue.50" : "white"}
                      onClick={() => handleFilterSelect(filter.value, option)}
                      leftIcon={filters[filter.value] === option ? <CheckIcon /> : undefined}
                    >
                      {option}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          ))}
          <Button
            variant="link"
            colorScheme="blue"
            size="sm"
            onClick={clearAllFilters}
          >
            Clear All
          </Button>
        </Flex>
        
        {/* Influencers Heading */}
        <Heading as="h1" size="xl" mb={6}>Influencers</Heading>
        
        {/* Creators Grid */}
          {loading ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
            {[...Array(8)].map((_, index) => (
                <CreatorCardSkeleton key={index} />
              ))}
          </SimpleGrid>
          ) : !sortedCreators.length ? (
          <Box 
            bg="white" 
            p={8} 
            borderRadius="lg" 
            textAlign="center"
            boxShadow="sm"
          >
            <Heading size="md" mb={4}>No creators found</Heading>
            <Text>Try adjusting your filters or search criteria.</Text>
          </Box>
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
              {sortedCreators.map(creator => (
              <Box 
                key={creator.id} 
                borderWidth="1px" 
                borderRadius="lg" 
                overflow="hidden" 
                bg="white"
                boxShadow="sm"
                position="relative"
                transition="transform 0.2s, box-shadow 0.2s"
                _hover={{
                  transform: "translateY(-4px)",
                  boxShadow: "md"
                }}
              >
                {/* Favorite Button */}
                <Button
                  position="absolute"
                  top={2}
                  right={2}
                  size="sm"
                  borderRadius="full"
                  variant="ghost"
                  zIndex={1}
                  _hover={{ bg: "rgba(255,255,255,0.8)" }}
                >
                  ♡
                </Button>
                
                {/* Creator Image */}
                <Box 
                  position="relative" 
                  height="200px" 
                  overflow="hidden"
                  onClick={() => navigateToCreatorProfile(creator)}
                  cursor="pointer"
                >
                      <Image
                        src={creator.profileImageUrl || '/default-avatar.png'}
                        alt={creator.name}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    fallback={<Skeleton height="200px" />}
                  />
                </Box>
                
                {/* Creator Info */}
                <Box p={4}>
                  <Flex mb={1} alignItems="center">
                    <Text 
                      fontWeight="bold" 
                      fontSize="lg" 
                      mb={1}
                      cursor="pointer"
                      onClick={() => navigateToCreatorProfile(creator)}
                    >
                      {creator.name || creator.username || "Creator"}
                    </Text>
                  </Flex>
                  
                  <Text fontSize="xs" color="gray.500" mb={1}>
                    {creator.city ? 
                      `${creator.city}, ${creator.country || 'US'}` : 
                      creator.location || 
                      (creator.country ? `${creator.country}` : 'US')}
                  </Text>
                  
                  <Text fontSize="sm" color="gray.700" mb={3} noOfLines={2}>
                    {creator.primaryCategory ? 
                      `${creator.primaryCategory} ${creator.secondaryCategory ? `• ${creator.secondaryCategory}` : ''}` : 
                      'Category not specified'}
                  </Text>
                  
                  {/* Platform and Price */}
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text 
                      fontSize="sm" 
                      color="gray.500"
                    >
                      {Object.keys(creator.socialLinks || {})[0] || 'Platform'} • {formatFollowerCount(creator.statistics?.totalFollowers || 0)} followers
                    </Text>
                    
                    {getCreatorPrice(creator) !== null && (
                      <Text 
                        fontWeight="bold" 
                        color="gray.800"
                      >
                        {formatPrice(getCreatorPrice(creator))}
                      </Text>
                    )}
                  </Flex>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        )}
        
        {/* Load More Button */}
          {hasMore && !loading && (
          <Flex justify="center" mt={8}>
              <Button
                onClick={handleLoadMore}
                isLoading={loading}
                loadingText="Loading more..."
              variant="outline"
              size="md"
              >
                Load More
              </Button>
            </Flex>
          )}
      </Box>
    </Box>
  );
};

export default CreatorListing; 