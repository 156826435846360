import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';

// API Configuration
const API_CONFIG = {
  instagram: {
    baseUrl: 'https://graph.instagram.com/v12.0',
    accessToken: process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN
  },
  tiktok: {
    baseUrl: 'https://open-api.tiktok.com/v2',
    clientKey: process.env.REACT_APP_TIKTOK_CLIENT_KEY,
    clientSecret: process.env.REACT_APP_TIKTOK_CLIENT_SECRET
  },
  youtube: {
    baseUrl: 'https://www.googleapis.com/youtube/v3',
    apiKey: process.env.REACT_APP_YOUTUBE_API_KEY
  },
  twitter: {
    baseUrl: 'https://api.twitter.com/2',
    bearerToken: process.env.REACT_APP_TWITTER_BEARER_TOKEN
  }
};

// Add OpenAI configuration
const OPENAI_CONFIG = {
  baseUrl: 'https://api.openai.com/v1',
  apiKey: process.env.REACT_APP_OPENAI_API_KEY
};

const fetchInstagramData = async (username) => {
  try {
    const { baseUrl, accessToken } = API_CONFIG.instagram;
    
    // First get user ID from username
    const userResponse = await axios.get(
      `${baseUrl}/users/search?q=${username}&access_token=${accessToken}`
    );
    
    const userId = userResponse.data.data[0]?.id;
    if (!userId) throw new Error('Instagram user not found');

    // Get user profile data
    const profileResponse = await axios.get(
      `${baseUrl}/${userId}?fields=id,username,media_count,followers_count&access_token=${accessToken}`
    );

    // Get recent media
    const mediaResponse = await axios.get(
      `${baseUrl}/${userId}/media?fields=id,caption,media_type,media_url,permalink&access_token=${accessToken}`
    );

    return {
      profile: profileResponse.data,
      media: mediaResponse.data.data
    };
  } catch (error) {
    console.error('Error fetching Instagram data:', error);
    return null;
  }
};

const fetchTikTokData = async (username) => {
  try {
    const { baseUrl, clientKey } = API_CONFIG.tiktok;
    
    // Get user info
    const response = await axios.get(
      `${baseUrl}/user/info/?username=${username}`,
      {
        headers: {
          'Authorization': `Bearer ${clientKey}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching TikTok data:', error);
    return null;
  }
};

const fetchYouTubeData = async (username) => {
  try {
    const { baseUrl, apiKey } = API_CONFIG.youtube;
    
    // First get channel ID from username
    const channelResponse = await axios.get(
      `${baseUrl}/search?part=snippet&type=channel&q=${username}&key=${apiKey}`
    );
    
    const channelId = channelResponse.data.items[0]?.id.channelId;
    if (!channelId) throw new Error('YouTube channel not found');

    // Get channel statistics
    const statsResponse = await axios.get(
      `${baseUrl}/channels?part=statistics,snippet&id=${channelId}&key=${apiKey}`
    );

    return statsResponse.data.items[0];
  } catch (error) {
    console.error('Error fetching YouTube data:', error);
    return null;
  }
};

const fetchTwitterData = async (username) => {
  try {
    const { baseUrl, bearerToken } = API_CONFIG.twitter;
    
    // Get user data
    const response = await axios.get(
      `${baseUrl}/users/by/username/${username}?user.fields=public_metrics,description`,
      {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error fetching Twitter data:', error);
    return null;
  }
};

const analyzeContentWithAI = async (platformData, platform) => {
  try {
    const { apiKey } = OPENAI_CONFIG;
    
    // Prepare the content for analysis
    let contentToAnalyze = '';
    switch (platform) {
      case 'instagram':
        contentToAnalyze = platformData.media
          ?.map(post => post.caption)
          .filter(Boolean)
          .join('\n');
        break;
      case 'tiktok':
        contentToAnalyze = platformData.user_info?.bio_description || '';
        break;
      case 'youtube':
        contentToAnalyze = platformData.snippet?.description || '';
        break;
      case 'twitter':
        contentToAnalyze = platformData.description || '';
        break;
    }

    // Call OpenAI API
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content: "You are an AI assistant that analyzes social media content to extract relevant information about a creator's profile. Focus on financial and investment-related content."
          },
          {
            role: "user",
            content: `Analyze this content from a ${platform} creator and provide structured information about their:
            1. Main topics and themes
            2. Content style and tone
            3. Target audience demographics
            4. Key interests and expertise areas
            5. Potential brand collaboration categories
            
            Content to analyze:
            ${contentToAnalyze}`
          }
        ],
        temperature: 0.7
      },
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        }
      }
    );

    // Parse the AI response
    const analysis = JSON.parse(response.data.choices[0].message.content);
    
    return {
      interests: analysis.interests || ['Finance', 'Technology', 'Investment'],
      topics: analysis.topics || ['Financial Education', 'Market Analysis'],
      demographics: {
        ageRanges: analysis.demographics?.ageRanges || ['25-34', '35-44'],
        locations: analysis.demographics?.locations || ['United States'],
        genderDistribution: analysis.demographics?.genderDistribution || '60% Male, 40% Female'
      },
      contentStyle: analysis.contentStyle || 'Educational',
      brandCategories: analysis.brandCategories || ['FinTech', 'Investment Platforms']
    };
  } catch (error) {
    console.error('Error analyzing content with AI:', error);
    return null;
  }
};

export const generateCreatorProfile = async (platform, username) => {
  try {
    let platformData;
    switch (platform) {
      case 'instagram':
        platformData = await fetchInstagramData(username);
        break;
      case 'tiktok':
        platformData = await fetchTikTokData(username);
        break;
      case 'youtube':
        platformData = await fetchYouTubeData(username);
        break;
      case 'twitter':
        platformData = await fetchTwitterData(username);
        break;
      default:
        throw new Error('Unsupported platform');
    }

    if (!platformData) {
      return generateMockProfile(platform, username);
    }

    // Transform platform data with AI analysis
    return await transformPlatformData(platform, platformData);
  } catch (error) {
    console.error('Error generating profile:', error);
    return generateMockProfile(platform, username);
  }
};

// Helper function to generate mock data (existing implementation)
const generateMockProfile = (platform, username) => {
  const mockData = {
    name: username,
    displayName: username,
    bio: `${platform.charAt(0).toUpperCase() + platform.slice(1)} creator focused on financial education and technology.`,
    category: 'investing',
    followerCount: '10k-50k',
    demographics: {
      ageRanges: ['18-24', '25-34', '35-44'],
      locations: ['United States', 'United Kingdom', 'Canada'],
      genderDistribution: '60% Male, 40% Female'
    },
    interests: ['Finance', 'Technology', 'Entrepreneurship', 'Crypto'],
    topics: ['Investment Tips', 'Market Analysis', 'Financial Education', 'Tech Trends'],
    metrics: {
      engagementRate: '4.5%',
      contentPerformance: 'High'
    },
    contentTypes: ['videos', 'posts', 'stories'],
    preferredCategories: ['Finance', 'Technology', 'Business'],
    availableFor: ['Sponsored Posts', 'Brand Collaborations', 'Product Reviews'],
    collaborations: [
      {
        brandName: 'Example Finance App',
        description: 'Educational content about personal finance management',
        metrics: '5.2% Engagement'
      }
    ]
  };

  return {
    name: mockData.name,
    displayName: mockData.displayName,
    bio: mockData.bio,
    primaryCategory: mockData.category,
    followers: {
      [platform]: mockData.followerCount
    },
    audienceInfo: {
      demographics: {
        ageRanges: mockData.demographics.ageRanges,
        locations: mockData.demographics.locations,
        genderDistribution: mockData.demographics.genderDistribution
      },
      interests: mockData.interests,
      engagementTopics: mockData.topics,
      metrics: {
        engagementRate: mockData.metrics.engagementRate,
        contentPerformance: mockData.metrics.contentPerformance
      }
    },
    contentTypes: mockData.contentTypes,
    preferredCategories: mockData.preferredCategories,
    availableFor: mockData.availableFor,
    collaborations: mockData.collaborations,
    metrics: {
      engagementRate: mockData.metrics.engagementRate,
      contentPerformance: mockData.metrics.contentPerformance
    },
    socialLinks: {
      [platform]: username
    }
  };
};

const generateAIProfile = async (profileData) => {
  try {
    // Note: Replace with your actual OpenAI API call
    // This is a mock implementation
    return {
      primaryCategory: 'lifestyle',
      audienceInfo: {
        demographics: {
          ageRanges: ['18-24', '25-34'],
          locations: ['United States', 'United Kingdom']
        },
        interests: ['Fashion', 'Beauty', 'Lifestyle'],
        engagementTopics: ['Style Tips', 'Product Reviews'],
        metrics: {
          engagementRate: '3.5%',
          contentPerformance: 'High'
        }
      }
    };
  } catch (error) {
    console.error('Error generating AI profile:', error);
    return {};
  }
};

const extractUsername = (socialLinks) => {
  // Try to extract username from any available social link
  if (socialLinks.instagram) {
    return socialLinks.instagram.split('/').pop();
  }
  if (socialLinks.tiktok) {
    return socialLinks.tiktok.split('@').pop();
  }
  if (socialLinks.youtube) {
    const match = socialLinks.youtube.match(/youtube\.com\/(channel|user)\/([^/?]+)/);
    return match ? match[2] : '';
  }
  return '';
};

// Function to validate social media links
export const validateSocialLinks = (links) => {
  const patterns = {
    instagram: /instagram\.com\/([^/?]+)/,
    tiktok: /@([^/?]+)/,
    youtube: /youtube\.com\/(channel|user)\/([^/?]+)/,
    twitter: /twitter\.com\/([^/?]+)/,
    facebook: /facebook\.com\/([^/?]+)/,
    twitch: /twitch\.tv\/([^/?]+)/
  };

  const validLinks = {};
  let hasValidLink = false;

  Object.entries(links).forEach(([platform, url]) => {
    if (url && patterns[platform]?.test(url)) {
      validLinks[platform] = url;
      hasValidLink = true;
    }
  });

  return hasValidLink ? validLinks : null;
};

// Function to create multiple unclaimed profiles from a list
export const batchCreateUnclaimedProfiles = async (creatorsList) => {
  const results = {
    success: [],
    failed: []
  };

  for (const creator of creatorsList) {
    try {
      const validLinks = validateSocialLinks(creator.socialLinks);
      if (!validLinks) {
        results.failed.push({
          ...creator,
          error: 'No valid social media links provided'
        });
        continue;
      }

      const profile = await generateCreatorProfile(validLinks);
      results.success.push(profile);
    } catch (error) {
      results.failed.push({
        ...creator,
        error: error.message
      });
    }
  }

  return results;
};

const transformPlatformData = async (platform, data) => {
  // Get AI analysis of the content
  const aiAnalysis = await analyzeContentWithAI(data, platform);
  
  switch (platform) {
    case 'instagram':
      return {
        name: data.profile.username,
        displayName: data.profile.username,
        bio: data.profile.biography || '',
        primaryCategory: aiAnalysis?.contentStyle || 'investing',
        followers: {
          [platform]: formatFollowerCount(data.profile.followers_count)
        },
        audienceInfo: {
          demographics: aiAnalysis?.demographics || {
            ageRanges: ['18-24', '25-34', '35-44'],
            locations: ['United States'],
            genderDistribution: '60% Male, 40% Female'
          },
          interests: aiAnalysis?.interests || ['Finance', 'Technology'],
          engagementTopics: aiAnalysis?.topics || ['Investment Tips'],
          metrics: {
            engagementRate: calculateEngagementRate(data.profile, data.media),
            contentPerformance: 'High'
          }
        },
        contentTypes: ['photos', 'videos', 'stories'],
        preferredCategories: aiAnalysis?.brandCategories || ['Finance', 'Technology'],
        availableFor: ['Sponsored Posts', 'Brand Collaborations'],
        collaborations: [],
        metrics: {
          engagementRate: calculateEngagementRate(data.profile, data.media),
          contentPerformance: 'High'
        },
        socialLinks: {
          [platform]: data.profile.username
        }
      };

    case 'tiktok':
      return {
        name: data.user_info?.username || '',
        displayName: data.user_info?.display_name || '',
        bio: data.user_info?.bio_description || '',
        primaryCategory: 'investing',
        followers: {
          [platform]: formatFollowerCount(data.user_info?.follower_count)
        },
        audienceInfo: {
          demographics: {
            ageRanges: ['18-24', '25-34'],
            locations: ['United States'],
            genderDistribution: '50% Male, 50% Female'
          },
          interests: ['Finance', 'Technology'],
          engagementTopics: ['Investment Tips', 'Financial Education'],
          metrics: {
            engagementRate: calculateTikTokEngagement(data.user_info),
            contentPerformance: 'High'
          }
        },
        contentTypes: ['videos', 'lives'],
        preferredCategories: ['Finance', 'Technology'],
        availableFor: ['Sponsored Videos', 'Brand Collaborations'],
        collaborations: [],
        metrics: {
          engagementRate: calculateTikTokEngagement(data.user_info),
          contentPerformance: 'High'
        },
        socialLinks: {
          [platform]: data.user_info?.username
        }
      };

    case 'youtube':
      return {
        name: data.snippet?.title || '',
        displayName: data.snippet?.title || '',
        bio: data.snippet?.description || '',
        primaryCategory: 'investing',
        followers: {
          [platform]: formatFollowerCount(data.statistics?.subscriberCount)
        },
        audienceInfo: {
          demographics: {
            ageRanges: ['18-24', '25-34', '35-44'],
            locations: ['United States'],
            genderDistribution: '60% Male, 40% Female'
          },
          interests: ['Finance', 'Technology'],
          engagementTopics: ['Investment Education', 'Market Analysis'],
          metrics: {
            engagementRate: calculateYouTubeEngagement(data.statistics),
            contentPerformance: 'High'
          }
        },
        contentTypes: ['videos', 'shorts'],
        preferredCategories: ['Finance', 'Technology', 'Education'],
        availableFor: ['Sponsored Videos', 'Brand Integrations'],
        collaborations: [],
        metrics: {
          engagementRate: calculateYouTubeEngagement(data.statistics),
          contentPerformance: 'High'
        },
        socialLinks: {
          [platform]: data.snippet?.customUrl || ''
        }
      };

    case 'twitter':
      return {
        name: data.name || '',
        displayName: data.name || '',
        bio: data.description || '',
        primaryCategory: 'investing',
        followers: {
          [platform]: formatFollowerCount(data.public_metrics?.followers_count)
        },
        audienceInfo: {
          demographics: {
            ageRanges: ['25-34', '35-44'],
            locations: ['United States'],
            genderDistribution: '65% Male, 35% Female'
          },
          interests: ['Finance', 'Technology', 'Crypto'],
          engagementTopics: ['Market Analysis', 'Investment Tips'],
          metrics: {
            engagementRate: calculateTwitterEngagement(data.public_metrics),
            contentPerformance: 'High'
          }
        },
        contentTypes: ['tweets', 'threads'],
        preferredCategories: ['Finance', 'Technology', 'Crypto'],
        availableFor: ['Sponsored Tweets', 'Brand Collaborations'],
        collaborations: [],
        metrics: {
          engagementRate: calculateTwitterEngagement(data.public_metrics),
          contentPerformance: 'High'
        },
        socialLinks: {
          [platform]: data.username
        }
      };

    default:
      throw new Error('Unsupported platform');
  }
};

// Helper functions for transformPlatformData
const formatFollowerCount = (count) => {
  if (!count) return '0-1k';
  count = parseInt(count);
  if (count < 1000) return '0-1k';
  if (count < 10000) return '1k-10k';
  if (count < 50000) return '10k-50k';
  if (count < 100000) return '50k-100k';
  if (count < 500000) return '100k-500k';
  if (count < 1000000) return '500k-1M';
  return '1M+';
};

const calculateEngagementRate = (profile, media) => {
  if (!profile || !media || media.length === 0) return '0%';
  const totalEngagements = media.reduce((sum, post) => {
    return sum + (post.like_count || 0) + (post.comments_count || 0);
  }, 0);
  const avgEngagement = totalEngagements / media.length;
  const engagementRate = (avgEngagement / (profile.followers_count || 1)) * 100;
  return engagementRate.toFixed(2) + '%';
};

const calculateTikTokEngagement = (userInfo) => {
  if (!userInfo) return '0%';
  const totalEngagements = (userInfo.heart_count || 0);
  const engagementRate = (totalEngagements / (userInfo.follower_count || 1)) * 100;
  return engagementRate.toFixed(2) + '%';
};

const calculateYouTubeEngagement = (statistics) => {
  if (!statistics) return '0%';
  const totalEngagements = (parseInt(statistics.likeCount) || 0) + (parseInt(statistics.commentCount) || 0);
  const engagementRate = (totalEngagements / (parseInt(statistics.viewCount) || 1)) * 100;
  return engagementRate.toFixed(2) + '%';
};

const calculateTwitterEngagement = (metrics) => {
  if (!metrics) return '0%';
  const totalEngagements = (metrics.like_count || 0) + (metrics.retweet_count || 0);
  const engagementRate = (totalEngagements / (metrics.followers_count || 1)) * 100;
  return engagementRate.toFixed(2) + '%';
};

const extractInterestsFromPosts = (media) => {
  // This would ideally use AI to analyze post content and extract interests
  // For now, return default interests
  return ['Finance', 'Technology', 'Entrepreneurship'];
};

const extractTopicsFromPosts = (media) => {
  // This would ideally use AI to analyze post content and extract topics
  // For now, return default topics
  return ['Investment Tips', 'Market Analysis', 'Financial Education'];
}; 