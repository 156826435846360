import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Button,
  Text,
  Icon,
  useColorModeValue,
  Container,
  Heading,
  Divider,
  IconButton,
  useDisclosure,
  Alert,
  AlertIcon,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  InputGroup,
  InputLeftAddon,
  Select,
  Image,
  Progress,
} from '@chakra-ui/react';
import './CampaignEditForm.css';
import axios from 'axios';
import { HamburgerIcon, CloseIcon, ArrowBackIcon } from '@chakra-ui/icons';

const CampaignEditForm = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const sidebarBg = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('blue.50', 'blue.900');
  
  // Add state for mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saveMessage, setSaveMessage] = useState('');

  // Update the initial state setup
  const initialState = {
    programName: '',
    campaignHeadline: '',
    payout: '',
    startDate: '',
    endDate: '',
    promoCode: '',
    productUrl: '',
    campaignType: '',
    productCategory: '',
    brand: '',
    productName: '',
    productDescription: '',
    keyFeatures: '',
    uniqueSellingPoints: '',
    brandGuidelines: '',
    targetAudience: {
      interests: ''
    },
    pricing: {
      retailPrice: '',
      specialOffers: ''
    },
    platformRequirements: {
      allowedPlatforms: '',
      postingSchedule: '',
      contentApprovalProcess: ''
    },
    creatorRequirements: {
      idealCreators: '',
      creatorGuidelines: ''
    }
  };

  // Update the component state initialization
  const [campaign, setCampaign] = useState(initialState);

  const [isDeleting, setIsDeleting] = useState(false);
  const [brandImages, setBrandImages] = useState([]);
  const [socialPostExamples, setSocialPostExamples] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({
    brand: campaign?.brandImages || [],
    social: campaign?.socialPostExamples || []
  });

  const [activeSection, setActiveSection] = useState('basic');

  const sections = [
    { id: 'basic', title: 'Basic Information' },
    { id: 'product', title: 'Product & Content' },
    { id: 'requirements', title: 'Audience' },
    { id: 'assets', title: 'Brand Assets' }
  ];

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const campaignDoc = await getDoc(doc(db, 'campaigns', id));
        if (campaignDoc.exists()) {
          const data = campaignDoc.data();
          setCampaign(prev => ({
            ...initialState,
            ...data,
            startDate: data.startDate || '',
            endDate: data.endDate || '',
            payout: data.payout?.toString() || '',
            targetAudience: {
              ...initialState.targetAudience,
              ...data.targetAudience
            },
            pricing: {
              ...initialState.pricing,
              ...data.pricing
            },
            platformRequirements: {
              ...initialState.platformRequirements,
              ...data.platformRequirements
            },
            creatorRequirements: {
              ...initialState.creatorRequirements,
              ...data.creatorRequirements
            }
          }));
        } else {
          console.error('Campaign not found');
          navigate('/brand/dashboard');
        }
      } catch (error) {
        console.error('Error fetching campaign:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [id, navigate]);

  useEffect(() => {
    if (campaign) {
      setUploadedImages({
        brand: campaign.brandImages || [],
        social: campaign.socialPostExamples || []
      });
    }
  }, [campaign]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setCampaign(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setCampaign(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Update the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaveMessage('');

    try {
      if (!currentUser) {
        navigate('/login');
        return;
      }

      await currentUser.getIdToken(true);

      await updateDoc(doc(db, 'campaigns', id), {
        ...campaign,
        brandImages: uploadedImages.brand,
        socialPostExamples: uploadedImages.social,
        status: 'active',
        updatedAt: new Date().toISOString(),
        updatedBy: currentUser.uid
      });

      setSaveMessage('Campaign updated successfully!');
      setTimeout(() => {
        setSaveMessage('');
      }, 3000);

    } catch (error) {
      console.error('Error updating campaign:', error);
      if (error.code === 'auth/invalid-user-token') {
        navigate('/login');
        return;
      }
      setSaveMessage(`Error updating campaign: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    if (window.confirm('Are you sure you want to delete this campaign? This action cannot be undone.')) {
      try {
        setIsDeleting(true);
        await currentUser.getIdToken(true); // Force token refresh
        await deleteDoc(doc(db, 'campaigns', id));
        navigate('/brand/dashboard');
      } catch (error) {
        console.error('Error deleting campaign:', error);
        setSaveMessage('Error deleting campaign. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  // Update the handleImageUpload function
  const handleImageUpload = async (e, type) => {
    try {
      if (!currentUser) {
        setSaveMessage('Please log in to upload images');
        return;
      }

      setUploading(true);
      const file = e.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch('http://localhost:5001/api/upload/upload-campaign-image', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        },
        body: formData
      });

      const data = await response.json();

      if (!data.success) {
        throw new Error(data.message || 'Upload failed');
      }

      setUploadedImages(prev => ({
        ...prev,
        [type]: [...prev[type], data.url]
      }));

    } catch (error) {
      console.error('Error uploading image:', error);
      setSaveMessage('Failed to upload image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleBrandImageUpload = async (e) => {
    await handleImageUpload(e, 'brand');
  };

  const handleRemoveImage = (type, index) => {
    setUploadedImages(prev => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index)
    }));
  };

  const renderFormSection = () => {
    switch (activeSection) {
      case 'basic':
        return (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <FormControl>
              <FormLabel>Program Name</FormLabel>
              <Input name="programName" value={campaign.programName} onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Campaign Headline</FormLabel>
              <Input name="campaignHeadline" value={campaign.campaignHeadline} onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Campaign Type</FormLabel>
              <Select 
                name="campaignType" 
                value={campaign.campaignType} 
                onChange={handleChange}
                sx={{
                  paddingLeft: '8px',
                  '& option': {
                    textAlign: 'left'
                  }
                }}
              >
                <option value="">Select Campaign Type</option>
                <option value="Brand Awareness">Brand Awareness</option>
                <option value="Conversion">Conversion</option>
                <option value="Lead Generation">Lead Generation</option>
                <option value="Sales">Sales</option>
                <option value="Traffic">Traffic</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Product Category</FormLabel>
              <Select 
                name="productCategory" 
                value={campaign.productCategory} 
                onChange={handleChange}
                sx={{
                  paddingLeft: '8px',
                  '& option': {
                    textAlign: 'left'
                  }
                }}
              >
                <option value="">Select Category</option>
                <option value="Crypto">Crypto</option>
                <option value="Investing">Investing</option>
                <option value="Budgeting">Budgeting</option>
                <option value="Banking">Banking</option>
                <option value="Retirement">Retirement</option>
                <option value="Fintech">Fintech</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Credit">Credit</option>
                <option value="Financial Education">Financial Education</option>
                <option value="Student Finance">Student Finance</option>
                <option value="Business Finance">Business Finance</option>
                <option value="Sustainable Finance">Sustainable Finance</option>
                <option value="Innovation">Innovation</option>
                <option value="Security">Security</option>
              </Select>
            </FormControl>
            <SimpleGrid columns={2} spacing={4}>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input type="date" name="startDate" value={campaign.startDate} onChange={handleChange} />
              </FormControl>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input type="date" name="endDate" value={campaign.endDate} onChange={handleChange} />
              </FormControl>
            </SimpleGrid>
            <FormControl>
              <FormLabel>Commision Per Conversion</FormLabel>
              <InputGroup>
                <InputLeftAddon>$</InputLeftAddon>
                <Input type="number" name="payout" value={campaign.payout} onChange={handleChange} />
              </InputGroup>
            </FormControl>
          </SimpleGrid>
        );

      case 'product':
        return (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <FormControl>
              <FormLabel>Product Name</FormLabel>
              <Input name="productName" value={campaign.productName} onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Product URL</FormLabel>
              <Input name="productUrl" value={campaign.productUrl} onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Special Offers</FormLabel>
              <Input 
                name="pricing.specialOffers" 
                value={campaign.pricing.specialOffers} 
                onChange={handleChange}
                placeholder="e.g., 20% off with code SPECIAL20" 
              />
            </FormControl>
            <FormControl colSpan={2}>
              <FormLabel>Product Description</FormLabel>
              <Textarea name="productDescription" value={campaign.productDescription} onChange={handleChange} />
            </FormControl>
          </SimpleGrid>
        );

      case 'requirements':
        return (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <FormControl>
              <FormLabel>Target Interests</FormLabel>
              <Textarea 
                name="targetAudience.interests" 
                value={campaign.targetAudience.interests} 
                onChange={handleChange}
                rows={6}
                height="300px"
                placeholder="Describe your customer target interests..."
                fontSize="md"
                p={4}
                sx={{
                  '&:focus': {
                    borderColor: 'blue.500',
                    boxShadow: '0 0 0 1px blue.500'
                  }
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ideal Creators</FormLabel>
              <Textarea 
                name="creatorRequirements.idealCreators" 
                value={campaign.creatorRequirements.idealCreators} 
                onChange={handleChange} 
                rows={6}
                height="300px"
                placeholder="Describe your ideal creators..."
                fontSize="md"
                p={4}
                sx={{
                  '&:focus': {
                    borderColor: 'blue.500',
                    boxShadow: '0 0 0 1px blue.500'
                  }
                }}
              />
            </FormControl>
          </SimpleGrid>
        );

      case 'assets':
        return (
          <VStack spacing={6} align="stretch" pb={6}>
            <Box>
              <FormLabel mb={3}>Brand Images</FormLabel>
              <Box
                border="2px dashed"
                borderColor="gray.200"
                borderRadius="md"
                p={6}
                textAlign="center"
                bg="gray.50"
                _hover={{
                  borderColor: "gray.300",
                  bg: "gray.100"
                }}
              >
                <Input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleBrandImageUpload}
                  disabled={uploading}
                  height="auto"
                  padding="20px"
                  sx={{
                    '::file-selector-button': {
                      height: '40px',
                      padding: '0 20px',
                      background: 'white',
                      border: '1px solid',
                      borderColor: 'gray.200',
                      borderRadius: 'md',
                      cursor: 'pointer',
                      _hover: {
                        bg: 'gray.50'
                      }
                    }
                  }}
                />
              </Box>
              {uploading && (
                <Progress size="xs" isIndeterminate mb={4} mt={4} />
              )}
              <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4} mt={6}>
                {uploadedImages.brand.map((url, index) => (
                  <Box 
                    key={index} 
                    position="relative"
                    borderRadius="md"
                    overflow="hidden"
                    boxShadow="sm"
                  >
                    <Image 
                      src={url} 
                      alt={`Brand ${index + 1}`} 
                      width="100%"
                      height="auto"
                      objectFit="cover"
                    />
                    <IconButton
                      icon={<CloseIcon />}
                      size="sm"
                      position="absolute"
                      top={2}
                      right={2}
                      onClick={() => handleRemoveImage('brand', index)}
                      colorScheme="red"
                      variant="solid"
                      opacity={0.8}
                      _hover={{ opacity: 1 }}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          </VStack>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return <div className="brand-loading">Loading...</div>;
  }

  return (
    <Box minH="100vh" bg={bgColor}>
      <Flex direction={{ base: 'column', md: 'row' }}>
        {/* Mobile Menu Button */}
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          position="fixed"
          top="70px"
          left="4"
          zIndex={1000}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          icon={isMobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
          variant="ghost"
          size="lg"
          aria-label="Toggle Menu"
        />

        {/* Sidebar */}
        <Box
          w={{ base: 'full', md: '280px' }}
          bg={sidebarBg}
          borderRight="1px"
          borderRightColor={borderColor}
          pos="fixed"
          h="calc(100vh - 64px)"
          top="64px"
          left="0"
          overflowY="auto"
          display={{ base: isMobileMenuOpen ? 'block' : 'none', md: 'block' }}
          zIndex={999}
          className="nav-sidebar"
        >
          <VStack spacing={2} align="stretch" p={4}>
            {sections.map(section => (
              <Button
                key={section.id}
                variant={activeSection === section.id ? 'solid' : 'ghost'}
                colorScheme="blue"
                justifyContent="flex-start"
                onClick={() => {
                  setActiveSection(section.id);
                  setIsMobileMenuOpen(false);
                }}
                w="full"
                py={3}
                px={4}
                fontSize="sm"
                fontWeight={activeSection === section.id ? "600" : "400"}
                bg={activeSection === section.id ? 'blue.500' : 'transparent'}
                color={activeSection === section.id ? 'white' : 'gray.700'}
                _hover={{
                  bg: activeSection === section.id ? 'blue.600' : 'blue.50',
                  color: activeSection === section.id ? 'white' : 'blue.500'
                }}
                borderRadius="md"
                transition="all 0.2s"
              >
                {section.title}
              </Button>
            ))}
          </VStack>
        </Box>

        {/* Main Content */}
        <Box
          ml={{ base: 0, md: '280px' }}
          w={{ base: 'full', md: 'calc(100% - 280px)' }}
          p={{ base: 4, md: 8 }}
          mt="64px"
        >
          <Container maxW="1200px">
            <Flex 
              direction={{ base: 'column', sm: 'row' }}
              justify="space-between" 
              align={{ base: 'stretch', sm: 'center' }}
              mb={6}
              gap={4}
            >
              <HStack spacing={4}>
                <Button
                  variant="ghost"
                  onClick={() => navigate('/brand/dashboard')}
                  leftIcon={<ArrowBackIcon />}
                  colorScheme="blue"
                  size={{ base: 'sm', md: 'md' }}
                >
                  Back to Dashboard
                </Button>
                <Heading size={{ base: 'md', md: 'lg' }}>Edit Campaign</Heading>
              </HStack>
              <Button
                colorScheme="red"
                variant="outline"
                onClick={handleDelete}
                isLoading={isDeleting}
                size={{ base: 'sm', md: 'md' }}
              >
                Delete Campaign
              </Button>
            </Flex>

            {saveMessage && (
              <Alert
                status={saveMessage.includes('Error') ? 'error' : 'success'}
                mb={6}
                borderRadius="md"
              >
                <AlertIcon />
                {saveMessage}
              </Alert>
            )}

            <Box
              bg="white"
              borderRadius="lg"
              shadow="md"
              p={{ base: 4, md: 6 }}
            >
              <form onSubmit={handleSubmit}>
                {renderFormSection()}
                
                <Divider my={6} />
                
                <Flex 
                  justify="flex-end" 
                  gap={4}
                  direction={{ base: 'column', sm: 'row' }}
                >
                  <Button
                    variant="solid"
                    bg="gray.600"
                    color="white"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/brand/dashboard');
                    }}
                    size={{ base: 'sm', md: 'md' }}
                    w={{ base: 'full', sm: 'auto' }}
                    _hover={{ bg: 'gray.700' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    size={{ base: 'sm', md: 'md' }}
                    w={{ base: 'full', sm: 'auto' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    Save Changes
                  </Button>
                </Flex>
              </form>
            </Box>
          </Container>
        </Box>
      </Flex>
    </Box>
  );
};

export default CampaignEditForm; 