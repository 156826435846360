import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './BrandProfilePage.css';
import BrandProfileImage from './BrandProfileImage';
import BrandBilling from './BrandBilling';
import {
  Box,
  Flex,
  Button,
  Text,
  Input,
  Textarea,
  Grid,
  VStack,
  HStack,
  Link,
  useBreakpointValue,
  Heading,
  UnorderedList,
  ListItem,
  Icon
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import config from '../../config/config';

const TRACKING_DOMAIN = process.env.NODE_ENV === 'production' 
  ? config.api.baseURL
  : 'http://localhost:5001/api';

const BrandProfilePage = () => {
  const [email, setEmail] = useState('');
  const [brandName, setBrandName] = useState('');
  const [website, setWebsite] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [role, setRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [logo, setLogo] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const auth = getAuth();
  const [activeSection, setActiveSection] = useState('general');
  const { currentUser } = useAuth();

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchBrandProfileData = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await fetch('http://localhost:5001/api/brand/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data) {
          setEmail(data.email);
          setBrandName(data.brandName);
          setWebsite(data.website);
          setOwnerName(data.ownerName || '');
          setCompanyDescription(data.companyDescription || '');
          setPhoneNumber(data.phoneNumber || '');
          setAddress(data.address || '');
          setLogo(data.logo || '');
        }
      } catch (error) {
        console.error('Error fetching brand profile data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchUserRole = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role);
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    if (auth.currentUser) {
      fetchBrandProfileData();
      fetchUserRole();
    }
  }, [auth.currentUser]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    const updatedProfile = { 
      email, 
      brandName, 
      website, 
      ownerName, 
      companyDescription, 
      phoneNumber, 
      address,
      logo 
    };

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch('http://localhost:5001/api/brand/update-profile', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedProfile),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        alert('Brand profile updated successfully!');
        setIsEditing(false);
      } else {
        alert('Failed to update brand profile');
      }
    } catch (error) {
      console.error('Error updating brand profile:', error);
      alert('An error occurred while updating the brand profile');
    }
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'your_upload_preset'); // Replace with your upload preset

      const response = await fetch(
        'https://api.cloudinary.com/v1_1/your_cloud_name/image/upload', // Replace with your cloud name
        {
          method: 'POST',
          body: formData,
        }
      );

      const data = await response.json();
      setLogo(data.secure_url);
    } catch (error) {
      console.error('Error uploading logo:', error);
      alert('Failed to upload logo');
    } finally {
      setIsUploading(false);
    }
  };

  if (isLoading) return <div className="loading">Loading...</div>;

  const renderEditForm = () => (
    <Box>
      <Box p={6} borderBottomWidth="1px">
        <Flex 
          direction={{ base: "column", md: "row" }} 
          gap={6} 
          align="center"
        >
          <BrandProfileImage 
            currentImageUrl={logo} 
            onImageUpdate={(newUrl) => setLogo(newUrl)} 
          />
          <Input 
            value={brandName} 
            onChange={(e) => setBrandName(e.target.value)}
            placeholder="Brand Name"
            size="lg"
            fontWeight="600"
          />
        </Flex>
      </Box>

      <VStack spacing={8} p={6}>
        <Box w="100%">
          <Text fontSize="lg" fontWeight="600" mb={4}>Basic Information</Text>
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
            <Input type="email" value={email} isDisabled />
            <Input type="text" value={role} isDisabled />
          </Grid>
        </Box>

        <Box w="100%">
          <Text fontSize="lg" fontWeight="600" mb={4}>Company Details</Text>
          <VStack spacing={4}>
            <Input 
              type="url" 
              value={website} 
              onChange={(e) => setWebsite(e.target.value)} 
              placeholder="https://example.com"
            />
            <Textarea 
              value={companyDescription} 
              onChange={(e) => setCompanyDescription(e.target.value)} 
              placeholder="Tell us about your company..."
              rows={4}
            />
          </VStack>
        </Box>

        <Box w="100%">
          <Text fontSize="lg" fontWeight="600" mb={4}>Contact Information</Text>
          <VStack spacing={4}>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6} w="100%">
              <Input 
                value={ownerName} 
                onChange={(e) => setOwnerName(e.target.value)} 
                placeholder="Owner Name"
              />
              <Input 
                value={phoneNumber} 
                onChange={(e) => setPhoneNumber(e.target.value)} 
                placeholder="Phone Number"
              />
            </Grid>
            <Input 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              placeholder="Address"
            />
          </VStack>
        </Box>
      </VStack>

      <Flex 
        p={6} 
        borderTopWidth="1px" 
        justify="flex-end" 
        gap={4}
        direction={{ base: "column", md: "row" }}
      >
        <Button 
          w={{ base: "100%", md: "auto" }}
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </Button>
        <Button 
          colorScheme="blue"
          w={{ base: "100%", md: "auto" }}
          onClick={handleUpdateProfile}
        >
          Save Changes
        </Button>
      </Flex>
    </Box>
  );

  const renderProfile = () => (
    <Box>
      <Flex 
        p={6} 
        borderBottomWidth="1px"
        direction={{ base: "column", md: "row" }}
        align={{ base: "flex-start", md: "center" }}
        gap={4}
      >
        <Flex flex="1" align="center" gap={4}>
          {logo && (
            <Box 
              boxSize={{ base: "80px", md: "100px" }}
              borderRadius="md"
              overflow="hidden"
            >
              <img src={logo} alt="Brand logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </Box>
          )}
          <Text fontSize="2xl" fontWeight="600">{brandName || 'Brand Name Not Set'}</Text>
        </Flex>
        <Button 
          colorScheme="blue"
          w={{ base: "100%", md: "auto" }}
          onClick={() => setIsEditing(true)}
        >
          Edit Profile
        </Button>
      </Flex>

      <VStack spacing={8} p={6} align="stretch">
        <Box>
          <Text fontSize="lg" fontWeight="600" mb={2}>About</Text>
          <Text>{companyDescription || 'No description provided'}</Text>
        </Box>

        <Box>
          <Text fontSize="lg" fontWeight="600" mb={4}>Contact Information</Text>
          <Grid 
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={6}
          >
            <VStack align="stretch">
              <Text fontSize="sm" color="gray.500">Website</Text>
              {website ? (
                <Link href={website} isExternal color="blue.500">
                  {website}
                </Link>
              ) : 'Not provided'}
            </VStack>
            <VStack align="stretch">
              <Text fontSize="sm" color="gray.500">Owner</Text>
              <Text>{ownerName || 'Not provided'}</Text>
            </VStack>
            <VStack align="stretch">
              <Text fontSize="sm" color="gray.500">Email</Text>
              <Text>{email}</Text>
            </VStack>
            <VStack align="stretch">
              <Text fontSize="sm" color="gray.500">Phone</Text>
              <Text>{phoneNumber || 'Not provided'}</Text>
            </VStack>
            <VStack align="stretch" gridColumn={{ base: "1", md: "span 2" }}>
              <Text fontSize="sm" color="gray.500">Address</Text>
              <Text>{address || 'Not provided'}</Text>
            </VStack>
          </Grid>
        </Box>
      </VStack>
    </Box>
  );

  const GettingStartedContent = ({ setActiveSection }) => {
    const getHeaderScript = () => {
      return `<!-- Hype Tracking Header Script -->
<script>
  (function() {
    const config = {
      brandId: '${currentUser?.uid}',
      trackingDomain: '${TRACKING_DOMAIN}'
    };

    // Link click tracking
    document.addEventListener('DOMContentLoaded', function() {
      var links = document.getElementsByTagName('a');
      for(var i = 0; i < links.length; i++) {
        var link = links[i];
        // Track all links
        link.href = createTrackingUrl(link.href);
      }
    });

    function createTrackingUrl(originalUrl) {
      return \`\${config.trackingDomain}/tracking/redirect?\` + 
             \`aid=\${localStorage.getItem('hype_aid') || ''}&\` +
             \`url=\${encodeURIComponent(originalUrl)}\`;
    }
  })();
</script>`;
    };

    return (
      <Box className="content-section" maxW="1200px" mx="auto" p={6}>
        <VStack spacing={10} align="stretch">
          {/* Header Section */}
          <Box textAlign="center" mb={8}>
            <Heading as="h2" size="xl" color="navy.900" mb={4}>
              Launch Your Brand Campaign
            </Heading>
            <Text fontSize="lg" color="gray.600">
              Start collaborating with creators and grow your brand's presence
            </Text>
          </Box>

          {/* Steps Grid */}
          <Grid 
            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
            gap={8}
          >
            {/* Step 1 - Create Campaign */}
            <Box 
              bg="white" 
              p={6} 
              borderRadius="xl" 
              shadow="md"
              border="1px"
              borderColor="gray.100"
            >
              <VStack align="start" spacing={4}>
                <Box 
                  bg="blue.50" 
                  p={4} 
                  borderRadius="lg"
                  color="blue.500"
                >
                  <Icon as={FontAwesomeIcon} icon="bullhorn" boxSize={6} />
                </Box>
                <Heading as="h3" size="md">Create Your Campaign</Heading>
                <UnorderedList spacing={3} styleType="none" ml={0}>
                  <ListItem>✓ Set campaign objectives</ListItem>
                  <ListItem>✓ Define your target audience</ListItem>
                  <ListItem>✓ Set your budget</ListItem>
                  <ListItem>✓ Add tracking tags</ListItem>
                  <ListItem>✓ Specify creator requirements</ListItem>
                </UnorderedList>
                <Text fontSize="sm" color="gray.600">
                  Launch your first campaign to start attracting creators
                </Text>
                <Button 
                  as={RouterLink}
                  to="/create-campaign"
                  colorScheme="blue" 
                  size="md" 
                  width="full"
                >
                  Create Campaign
                </Button>
              </VStack>
            </Box>

            {/* Step 2 - Find Creators */}
            <Box 
              bg="white" 
              p={6} 
              borderRadius="xl" 
              shadow="md"
              border="1px"
              borderColor="gray.100"
            >
              <VStack align="start" spacing={4}>
                <Box 
                  bg="purple.50" 
                  p={4} 
                  borderRadius="lg"
                  color="purple.500"
                >
                  <Icon as={FontAwesomeIcon} icon="users" boxSize={6} />
                </Box>
                <Heading as="h3" size="md">Discover Creators</Heading>
                <UnorderedList spacing={3} styleType="none" ml={0}>
                  <ListItem>✓ Browse creator profiles</ListItem>
                  <ListItem>✓ Filter by niche & audience</ListItem>
                  <ListItem>✓ Review engagement rates</ListItem>
                  <ListItem>✓ View past collaborations</ListItem>
                  <ListItem>✓ Purchase creator offers </ListItem>
                </UnorderedList>
                <Text fontSize="sm" color="gray.600">
                  Find the perfect creators for your brand
                </Text>
                <Button 
                  as={RouterLink}
                  to="/creators"
                  colorScheme="purple" 
                  size="md" 
                  width="full"
                >
                  Browse Creators
                </Button>
              </VStack>
            </Box>

            {/* Step 3 - Track Results */}
            <Box 
              bg="white" 
              p={6} 
              borderRadius="xl" 
              shadow="md"
              border="1px"
              borderColor="gray.100"
            >
              <VStack align="start" spacing={4}>
                <Box 
                  bg="green.50" 
                  p={4} 
                  borderRadius="lg"
                  color="green.500"
                >
                  <Icon as={FontAwesomeIcon} icon="chart-line" boxSize={6} />
                </Box>
                <Heading as="h3" size="md">Measure Success</Heading>
                <UnorderedList spacing={3} styleType="none" ml={0}>
                  <ListItem>✓ Track campaign performance</ListItem>
                  <ListItem>✓ Monitor engagement metrics</ListItem>
                  <ListItem>✓ Analyze ROI</ListItem>
                  <ListItem>✓ Optimize your strategy</ListItem>
                </UnorderedList>
                <Text fontSize="sm" color="gray.600">
                  Track and optimize your campaign results
                </Text>
                <Button 
                  as={RouterLink}
                  to="/dashboard"
                  colorScheme="green" 
                  size="md" 
                  width="full"
                >
                  View Analytics
                </Button>
              </VStack>
            </Box>
          </Grid>

          {/* Bottom Section */}
          <Box 
            mt={8} 
            p={8} 
            bg="blue.50" 
            borderRadius="xl"
            textAlign="center"
          >
            <Heading as="h3" size="md" mb={4} color="blue.700">
              Ready to Grow Your Brand?
            </Heading>
            <Text color="blue.600" mb={6} maxW="600px" mx="auto">
              Join hundreds of brands already leveraging creator partnerships. 
              Create your first campaign and start connecting with creators today!
            </Text>
            <HStack spacing={4} justify="center">
              <Button
                as={RouterLink}
                to="/create-campaign"
                colorScheme="blue"
                size="lg"
                leftIcon={<Icon as={FontAwesomeIcon} icon="plus" />}
              >
                Create Campaign
              </Button>
              <Button
                as={RouterLink}
                to="/creators"
                colorScheme="blue"
                variant="outline"
                size="lg"
                leftIcon={<Icon as={FontAwesomeIcon} icon="search" />}
              >
                Find Creators
              </Button>
            </HStack>
          </Box>

          {/* Implementation Guide */}
          <Box 
            mt={8} 
            p={6} 
            bg="white" 
            borderRadius="xl" 
            shadow="md"
            border="1px"
            borderColor="gray.100"
          >
            <Heading as="h3" size="md" mb={4}>
              Quick Implementation Guide
            </Heading>
            
            {/* Header Tag Section */}
            <Box mb={6}>
              <Text fontWeight="medium" mb={2}>1. Add the Header Tag</Text>
              <Text mb={4}>
                Add this script to the {'<head>'} section of your website:
              </Text>
              <Box 
                bg="gray.50" 
                p={4} 
                borderRadius="md" 
                fontFamily="mono"
                fontSize="sm"
                mb={2}
                position="relative"
              >
                <pre>{getHeaderScript()}</pre>
                <Button
                  position="absolute"
                  top={2}
                  right={2}
                  size="sm"
                  onClick={() => navigator.clipboard.writeText(getHeaderScript())}
                >
                  Copy
                </Button>
              </Box>
            </Box>

            {/* Conversion Tag Note */}
            <Box>
              <Text fontWeight="medium" mb={2}>2. Add the Conversion Tag</Text>
              <Text mb={4} color="gray.600">
                The conversion tracking tag will be available after you set up your first campaign. 
                You'll be able to access campaign-specific tracking code from your campaign dashboard.
              </Text>
            </Box>
          </Box>
        </VStack>
      </Box>
    );
  };

  return (
    <Box 
      maxW="1200px" 
      mx="auto" 
      p={4}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={6}
      >
        {/* Navigation Sidebar */}
        <VStack
          w={{ base: "100%", md: "240px" }}
          spacing={0}
          bg="white"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="sm"
          mb={{ base: 4, md: 0 }}
        >
          <Button
            w="100%"
            variant="ghost"
            justifyContent="flex-start"
            px={6}
            py={4}
            leftIcon={<span>🚀</span>}
            borderLeftWidth="3px"
            borderLeftColor={activeSection === 'getting-started' ? 'blue.500' : 'transparent'}
            bg={activeSection === 'getting-started' ? 'gray.50' : 'transparent'}
            onClick={() => setActiveSection('getting-started')}
          >
            Getting Started
          </Button>
          <Button
            w="100%"
            variant="ghost"
            justifyContent="flex-start"
            px={6}
            py={4}
            leftIcon={<span>⚙️</span>}
            borderLeftWidth="3px"
            borderLeftColor={activeSection === 'general' ? 'blue.500' : 'transparent'}
            bg={activeSection === 'general' ? 'gray.50' : 'transparent'}
            onClick={() => setActiveSection('general')}
          >
            General
          </Button>
          <Button
            w="100%"
            variant="ghost"
            justifyContent="flex-start"
            px={6}
            py={4}
            leftIcon={<span>💳</span>}
            borderLeftWidth="3px"
            borderLeftColor={activeSection === 'billing' ? 'blue.500' : 'transparent'}
            bg={activeSection === 'billing' ? 'gray.50' : 'transparent'}
            onClick={() => setActiveSection('billing')}
          >
            Billing & Payments
          </Button>
        </VStack>

        {/* Main Content */}
        <Box
          flex="1"
          bg="white"
          borderRadius="lg"
          boxShadow="sm"
          overflow="hidden"
        >
          {activeSection === 'getting-started' ? 
            <GettingStartedContent setActiveSection={setActiveSection} /> :
            activeSection === 'general' ? 
              (isEditing ? renderEditForm() : renderProfile()) :
              <BrandBilling />
          }
        </Box>
      </Flex>
    </Box>
  );
};

export default BrandProfilePage;
