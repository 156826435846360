import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Heading,
  Button,
  Box,
  Text,
  Stack,
  Flex,
  useToast,
  VStack,
  Divider,
  HStack,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../contexts/CartContext';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const CartPage = () => {
  const { 
    cartItems, 
    removeFromCart, 
    cartTotal, 
    updateQuantity, 
    saveCartForCheckout 
  } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  // Add environment verification
  useEffect(() => {
    console.log('Environment:', process.env.REACT_APP_ENV);
    console.log('API URL:', process.env.REACT_APP_API_URL);
  }, []);

  const handleCheckout = async () => {
    if (!currentUser) {
      toast({
        title: "Please log in",
        description: "You need to be logged in to checkout",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
      return;
    }

    setIsLoading(true);
    let requestData; // Declare outside try block to make it accessible in catch
    try {
      saveCartForCheckout();

      // Get user details from Firestore using userId
      const userDocRef = doc(db, 'users', 'w3RZJzxcC9bijGgONWjO9UPQwTN2');
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      // Generate a unique orderId with updated format
      const timestamp = Date.now();
      const orderId = `order_${timestamp}_${currentUser.uid.slice(0, 8)}`;

      // Create the order in Firestore first with more detailed information
      await setDoc(doc(db, 'orders', orderId), {
        items: cartItems.map(item => ({
          offerId: item.offerId,
          title: item.title || item.type,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          creatorId: item.creatorId,
          creatorName: item.creatorName,
          creatorImage: item.creatorImage
        })),
        userId: currentUser.uid,  // Use actual user ID
        userEmail: currentUser.email,
        brandId: currentUser.uid, // Use actual user ID
        brandName: userData?.brandName || userData?.displayName || 'Unknown Brand',
        status: 'pending_payment',
        orderStatus: {
          isPaid: false,
          isCompleted: false,
          paymentPending: true
        },
        total: cartTotal,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        paymentDetails: {
          status: 'pending',
          attemptCount: 1
        },
        metadata: {
          source: 'web',
          userAgent: navigator.userAgent,
          ipAddress: '',
          timestamp: timestamp
        }
      });

      // Create requestData
      requestData = {
        orderId,
        items: cartItems.map(item => ({
          offerId: item.offerId,
          title: item.title || item.type,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          creatorId: item.creatorId,
          creatorName: item.creatorName,
          creatorImage: item.creatorImage
        })),
        userId: currentUser.uid,  // Use actual user ID
        brandName: userData?.brandName || userData?.displayName || 'Unknown Brand',
        total: cartTotal
      };

      // Debug info
      console.log({
        environment: process.env.NODE_ENV,
        apiUrl: process.env.REACT_APP_API_URL,
        functionUrl: `${process.env.REACT_APP_API_URL}/checkout/create-checkout-session`,
        auth: !!currentUser,
        token: !!(await currentUser?.getIdToken())
      });

      const checkoutResponse = await fetch(`${process.env.REACT_APP_API_URL}/checkout/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        },
        body: JSON.stringify(requestData)
      });

      console.log('Response:', {
        status: checkoutResponse.status,
        ok: checkoutResponse.ok,
        statusText: checkoutResponse.statusText
      });

      const responseData = await checkoutResponse.json();
      console.log('Response data:', responseData);

      if (!checkoutResponse.ok) {
        toast({
          title: "Checkout failed",
          description: responseData.error || "Server error occurred",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (responseData.url) {
        window.location.href = responseData.url;
      } else {
        toast({
          title: "Checkout failed",
          description: "No checkout URL received from server",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Checkout error:', {
        message: error.message,
        stack: error.stack,
        environment: process.env.NODE_ENV,
        apiUrl: process.env.REACT_APP_API_URL
      });
      
      toast({
        title: "Checkout Error",
        description: "Failed to process checkout. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Shopping Cart</Heading>
      {cartItems.length === 0 ? (
        <Box textAlign="center" py={10}>
          <Text fontSize="xl" mb={4}>Your cart is empty</Text>
          <Button
            as={Link}
            to="/marketplace"
            colorScheme="blue"
            leftIcon={<FontAwesomeIcon icon={faShoppingBag} />}
          >
            Continue Shopping
          </Button>
        </Box>
      ) : (
        <Flex gap={8} direction={{ base: 'column', md: 'row' }}>
          {/* Left Side - Cart Items */}
          <Box flex="1">
            {cartItems.map((item) => (
              <Box key={item.offerId} p={4} borderWidth="1px" borderRadius="lg" mb={4}>
                <Flex gap={4}>
                  <Image
                    src={item.creatorImage}
                    alt={item.creatorName}
                    boxSize="100px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                  <VStack align="stretch" spacing={2} flex="1">
                    <Text fontSize="xl" fontWeight="bold">{item.type}</Text>
                    <Text>by {item.creatorName}</Text>
                    <HStack justify="space-between" align="center">
                      <Text>Quantity:</Text>
                      <NumberInput 
                        value={item.quantity}
                        min={1}
                        max={10}
                        onChange={(value) => updateQuantity(item.offerId, parseInt(value))}
                        size="sm"
                        maxW="100px"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </HStack>
                    <Text fontWeight="bold" fontSize="lg">${item.price.toFixed(2)}</Text>
                  </VStack>
                </Flex>
              </Box>
            ))}
          </Box>

          {/* Right Side - Order Summary */}
          <Box w={{ base: "100%", md: "400px" }}>
            <Box p={6} borderWidth="1px" borderRadius="lg" bg="white">
              <Text fontSize="xl" fontWeight="bold" mb={4}>Order Summary</Text>
              <VStack spacing={4} align="stretch">
                {cartItems.map((item) => (
                  <Flex key={item.offerId} justify="space-between">
                    <Text>{item.type}</Text>
                    <Text>${(item.price * item.quantity).toFixed(2)}</Text>
                  </Flex>
                ))}
                <Divider />
                <Flex justify="space-between" fontWeight="bold">
                  <Text>Total</Text>
                  <Text>${cartTotal.toFixed(2)}</Text>
                </Flex>
                <Button
                  colorScheme="green"
                  size="lg"
                  width="100%"
                  onClick={handleCheckout}
                  isLoading={isLoading}
                  loadingText="Processing..."
                >
                  Proceed to Checkout
                </Button>
              </VStack>
            </Box>
          </Box>
        </Flex>
      )}
    </Container>
  );
};

export default CartPage; 