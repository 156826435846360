import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { sendEmailVerification } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import './styles/verify-email.css';

const VerifyEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [resendCooldown, setResendCooldown] = useState(0);
    const [status, setStatus] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const email = location.state?.email;
    const message = location.state?.message;

    const updateFirestore = async (userId) => {
        try {
            // First, check current Firestore state
            const userRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userRef);
            
            if (userDoc.exists()) {
                console.log('Current Firestore state:', userDoc.data());
                
                // Update the document
                await updateDoc(userRef, {
                    emailVerified: true,
                    updatedAt: new Date().toISOString()
                });
                
                // Verify the update
                const updatedDoc = await getDoc(userRef);
                console.log('Updated Firestore state:', updatedDoc.data());
                
                if (updatedDoc.data().emailVerified) {
                    console.log('Firestore update confirmed');
                    return true;
                } else {
                    console.error('Firestore update failed to persist');
                    return false;
                }
            }
            return false;
        } catch (error) {
            console.error('Firestore update error:', error);
            return false;
        }
    };

    useEffect(() => {
        if (!email) {
            navigate('/login');
            return;
        }

        const checkVerification = async () => {
            if (!auth.currentUser || isVerified) return;

            try {
                // Force reload the user
                await auth.currentUser.reload();
                const user = auth.currentUser;
                console.log('Checking verification status for:', user.email);
                console.log('Current verification status:', user.emailVerified);

                if (user.emailVerified) {
                    setStatus('Email verified, updating Firestore...');
                    
                    // Update Firestore and verify the update
                    const updated = await updateFirestore(user.uid);
                    
                    if (updated) {
                        console.log('Verification process complete');
                        setStatus('Email verified! You can now return to login.');
                        setIsVerified(true);
                    } else {
                        console.error('Failed to update Firestore');
                        setError('Failed to update verification status. Please try again.');
                    }
                } else {
                    setStatus('Waiting for email verification...');
                }
            } catch (error) {
                console.error('Verification check failed:', error);
                setError('Error checking verification status: ' + error.message);
            }
        };

        // Initial check
        checkVerification();

        // Set up interval for periodic checks
        const interval = setInterval(checkVerification, 3000);

        return () => clearInterval(interval);
    }, [email, navigate, isVerified]);

    const handleResendVerification = async () => {
        if (resendCooldown > 0) return;

        try {
            const user = auth.currentUser;
            if (user) {
                await sendEmailVerification(user);
                setResendCooldown(60);
                const timer = setInterval(() => {
                    setResendCooldown((prev) => {
                        if (prev <= 1) {
                            clearInterval(timer);
                            return 0;
                        }
                        return prev - 1;
                    });
                }, 1000);
            }
        } catch (error) {
            setError('Failed to resend verification email: ' + error.message);
        }
    };

    return (
        <div className="verify-email-container">
            <h2>Verify Your Email</h2>
            <p>We've sent a verification email to: <strong>{email}</strong></p>
            <p>{message}</p>
            
            {error && <div className="error-message">{error}</div>}
            {status && <div className="status-message">{status}</div>}
            
            {!isVerified && (
                <button 
                    onClick={handleResendVerification}
                    disabled={resendCooldown > 0}
                    className="resend-button"
                >
                    {resendCooldown > 0 
                        ? `Resend in ${resendCooldown}s` 
                        : 'Resend Verification Email'}
                </button>
            )}

            <Link to="/login" className="login-button">
                Return to Login
            </Link>

            <div className="verification-instructions">
                <h3>Next Steps:</h3>
                <ol>
                    <li>Check your email inbox (and spam folder)</li>
                    <li>Click the verification link in the email</li>
                    <li>Return to login to access your account</li>
                </ol>
            </div>

            {/* Debug information */}
            <div className="debug-info" style={{marginTop: '20px', fontSize: '12px', color: '#666'}}>
                <p>Current Status: {status}</p>
                <p>User ID: {auth.currentUser?.uid}</p>
                <p>Email Verified: {auth.currentUser?.emailVerified ? 'Yes' : 'No'}</p>
                <p>Verification Complete: {isVerified ? 'Yes' : 'No'}</p>
            </div>
        </div>
    );
};

export default VerifyEmail; 