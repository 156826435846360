import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { 
    collection, 
    query, 
    where, 
    orderBy, 
    onSnapshot, 
    doc as firestoreDoc,
    getDoc,
    updateDoc,
    getDocs,
    limit
} from 'firebase/firestore';
import MessageThread from './MessageThread';
import {
    Box,
    Flex,
    Text,
    VStack,
    HStack,
    Image,
    Badge,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    useBreakpointValue,
    Spinner,
    Avatar,
    IconButton,
    Input
} from '@chakra-ui/react';
import { ChevronLeftIcon, SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FaEllipsisH, FaLink, FaVideo, FaCog, FaRegSmile } from 'react-icons/fa';
import { IoSend, IoChevronDown } from 'react-icons/io5';

const MessagesHub = () => {
    const { currentUser, userRole } = useAuth();
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        if (!currentUser || !userRole) return;

        const partnershipsQuery = query(
            collection(db, 'partnerships'),
            where(userRole === 'brand' ? 'brandId' : 'creatorId', '==', currentUser.uid)
        );

        const unsubscribe = onSnapshot(partnershipsQuery, async (snapshot) => {
            const partnershipsData = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const partnership = doc.data();
                    
                    // Get the last message
                    const messagesQuery = query(
                        collection(db, 'messages'),
                        where('partnershipId', '==', doc.id),
                        orderBy('timestamp', 'desc'),
                        limit(1)
                    );
                    
                    const messagesSnapshot = await getDocs(messagesQuery);
                    const lastMessageDoc = messagesSnapshot.docs[0];
                    const lastMessage = lastMessageDoc ? lastMessageDoc.data().text : 'No messages yet';
                    const lastMessageTime = lastMessageDoc ? lastMessageDoc.data().timestamp?.toDate() : null;
                    
                    // Get other party's data based on role
                    const otherPartyId = userRole === 'brand' ? partnership.creatorId : partnership.brandId;
                    const otherPartyDoc = await getDoc(firestoreDoc(db, 'users', otherPartyId));
                    const otherPartyData = otherPartyDoc.data();

                    // Get brand data
                    const brandDoc = await getDoc(firestoreDoc(db, 'users', partnership.brandId));
                    const brandData = brandDoc.data();

                    return {
                        id: doc.id,
                        brandId: partnership.brandId,
                        creatorId: partnership.creatorId,
                        partnerName: userRole === 'brand' 
                            ? otherPartyData?.name || 'Unknown Creator'  // Show creator name for brands
                            : brandData?.brandName || 'Unknown Brand',   // Show brand name for creators
                        partnerImage: userRole === 'brand'
                            ? otherPartyData?.profileImage              // Show creator image for brands
                            : brandData?.logo,                         // Show brand logo for creators
                        partnerId: otherPartyId, // Store the partner's ID for consolidation
                        lastMessage,
                        lastUpdated: lastMessageTime || partnership.lastUpdated?.toDate() || new Date(),
                        status: partnership.status,
                        offerTitle: partnership.offerTitle,
                        offerPrice: partnership.offerPrice,
                        brand: {
                            name: brandData?.brandName || 'Unknown Brand',
                            logo: brandData?.logo,
                            companyDescription: brandData?.companyDescription
                        }
                    };
                })
            );

            // Consolidate conversations by partner ID
            const consolidatedConversations = [];
            const partnerMap = new Map();

            // Group partnerships by partner ID
            partnershipsData.forEach(partnership => {
                const partnerId = partnership.partnerId;
                
                if (!partnerMap.has(partnerId)) {
                    // First time seeing this partner, create a new entry
                    partnerMap.set(partnerId, {
                        ...partnership,
                        partnerships: [partnership]
                    });
                } else {
                    // Already have this partner, add this partnership to the list
                    const existingEntry = partnerMap.get(partnerId);
                    existingEntry.partnerships.push(partnership);
                    
                    // Update the lastUpdated time if this partnership is more recent
                    if (partnership.lastUpdated > existingEntry.lastUpdated) {
                        existingEntry.lastUpdated = partnership.lastUpdated;
                        existingEntry.lastMessage = partnership.lastMessage;
                    }
                }
            });

            // Convert the map to an array
            partnerMap.forEach(value => {
                consolidatedConversations.push(value);
            });

            // Sort by most recent activity
            const sortedConversations = consolidatedConversations.sort((a, b) => 
                (b.lastUpdated || new Date()) - (a.lastUpdated || new Date())
            );

            setConversations(sortedConversations);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [currentUser, userRole]);

    const handleStatusUpdate = async (partnershipId, newStatus) => {
        try {
            const partnershipRef = firestoreDoc(db, 'partnerships', partnershipId);
            await updateDoc(partnershipRef, {
                status: newStatus,
                lastUpdated: new Date()
            });
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleConversationSelect = (conversation) => {
        // If there are multiple partnerships, use the most recent one
        const activePartnership = conversation.partnerships ? 
            conversation.partnerships.sort((a, b) => 
                (b.lastUpdated || new Date()) - (a.lastUpdated || new Date())
            )[0] : conversation;
        
        setSelectedConversation({
            ...conversation,
            id: activePartnership.id, // Use the ID of the most recent partnership
            activePartnership
        });
        
        if (isMobile) onOpen();
    };

    const formatMessageDate = (date) => {
        if (!date) return '';
        
        const today = new Date();
        const messageDate = new Date(date);
        
        // Format exactly like in the screenshot
        // For recent dates, show time
        if (messageDate.toDateString() === today.toDateString()) {
            const hours = messageDate.getHours();
            const minutes = messageDate.getMinutes();
            return `${hours % 12 || 12}:${String(minutes).padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
        }
        
        // For older dates, show month/day format
        return `${messageDate.getMonth() + 1}/${messageDate.getDate()}/${messageDate.getFullYear().toString().substr(2, 2)}`;
    };

    const renderConversationItem = (conversation, index) => {
        // Determine if this is the selected conversation
        const isSelected = selectedConversation?.partnerId === conversation.partnerId;
        
        return (
            <Box
                key={conversation.partnerId}
                px={4}
                py={3}
                borderBottomWidth="1px"
                borderColor="gray.200"
                cursor="pointer"
                bg={isSelected ? "#34c759" : "white"}
                onClick={() => handleConversationSelect(conversation)}
                _hover={{ bg: isSelected ? "#34c759" : "gray.50" }}
                transition="background 0.2s"
                position="relative"
            >
                <HStack spacing={3} align="center">
                    {/* Avatar/Initial */}
                    <Flex 
                        w="38px" 
                        h="38px" 
                        borderRadius="full" 
                        bg={isSelected ? "white" : "gray.300"}
                        color={isSelected ? "#34c759" : "gray.600"}
                        justify="center" 
                        align="center"
                        fontWeight="600"
                        fontSize="16px"
                    >
                        {conversation.partnerName?.charAt(0) || 'U'}
                    </Flex>
                    
                    <Box flex={1} overflow="hidden">
                        <Flex justify="space-between" align="center">
                            <Text 
                                fontSize="15px" 
                                fontWeight="500" 
                                color={isSelected ? "white" : "black"}
                            >
                                {conversation.partnerName}
                            </Text>
                            <Text 
                                fontSize="12px" 
                                color={isSelected ? "white" : "gray.500"}
                            >
                                {formatMessageDate(conversation.lastUpdated)}
                            </Text>
                        </Flex>
                        <Text 
                            fontSize="13px" 
                            color={isSelected ? "white" : "gray.600"} 
                            noOfLines={1}
                            overflow="hidden"
                            textOverflow="ellipsis"
                        >
                            {conversation.lastMessage}
                        </Text>
                    </Box>
                </HStack>
                
                {/* Green dot for active status - only on selected item */}
                {isSelected && (
                    <Box 
                        position="absolute" 
                        left="2px" 
                        top="50%" 
                        transform="translateY(-50%)" 
                        w="8px" 
                        h="8px" 
                        borderRadius="full" 
                        bg="#34c759"
                    />
                )}
            </Box>
        );
    };

    return (
        <Box h="calc(100vh - 64px)" bg="#fbfbfb">
            <Flex
                maxW="1400px"
                mx="auto"
                h="full"
                direction={{ base: 'column', md: 'row' }}
            >
                {/* Conversation List - Exact iMessage style */}
                <Box
                    w={{ base: '100%', md: '350px' }}
                    bg="white"
                    borderRightWidth={{ base: 0, md: '1px' }}
                    h="full"
                    position="relative"
                    flexShrink={0}
                >
                    <Box 
                        py={3}
                        px={4}
                        borderBottomWidth="1px"
                        borderColor="gray.200"
                        bg="white"
                        position="sticky"
                        top={0}
                        zIndex={1}
                    >
                        <Flex justify="space-between" align="center" mb={3}>
                            <Text fontSize="28px" fontWeight="600">Messages</Text>
                            <IconButton
                                icon={<FaEllipsisH />}
                                variant="ghost"
                                colorScheme="gray"
                                borderRadius="full"
                                aria-label="Options"
                                size="sm"
                            />
                        </Flex>
                        
                        <Flex 
                            align="center" 
                            bg="gray.100" 
                            borderRadius="full" 
                            px={3} 
                            py={1.5}
                        >
                            <SearchIcon color="gray.500" mr={2} />
                            <Input 
                                variant="unstyled" 
                                placeholder="Search" 
                                size="sm"
                                _placeholder={{ color: 'gray.500' }}
                            />
                            <IconButton
                                icon={<ChevronDownIcon />}
                                variant="ghost"
                                colorScheme="gray"
                                size="xs"
                                borderRadius="full"
                                aria-label="Filter"
                            />
                        </Flex>
                    </Box>
                    
                    {loading ? (
                        <Flex justify="center" align="center" h="200px">
                            <Spinner />
                        </Flex>
                    ) : conversations.length === 0 ? (
                        <Box p={4} textAlign="center" color="gray.500">
                            <Text>No conversations yet</Text>
                            <Text fontSize="sm" mt={2}>
                                When you connect with partners, your messages will appear here
                            </Text>
                        </Box>
                    ) : (
                        <VStack 
                            spacing={0} 
                            overflowY="auto" 
                            h="calc(100vh - 160px)"
                            w="full"
                            align="stretch"
                            className="messages-scroll"
                        >
                            {/* Use the actual conversations data */}
                            {conversations.map(renderConversationItem)}
                        </VStack>
                    )}
                </Box>

                {/* Message Thread - Using real data */}
                {!isMobile ? (
                    <Box flex={1} bg="#fbfbfb" position="relative">
                        {selectedConversation ? (
                            <MessageThread
                                partnershipId={selectedConversation.id}
                                selectedPartnership={selectedConversation}
                                userRole={userRole}
                                handleStatusUpdate={handleStatusUpdate}
                            />
                        ) : (
                                    <Flex 
                                h="full" 
                                justify="center" 
                                align="center" 
                                direction="column"
                                bg="#fbfbfb"
                                p={8}
                                                >
                                                    <Box 
                                    p={6} 
                                                borderRadius="full"
                                    bg="blue.50" 
                                    mb={4}
                                >
                                    <Box as="span" fontSize="4xl">💬</Box>
                                </Box>
                                <Text fontSize="xl" fontWeight="600" mb={2}>Your Messages</Text>
                                <Text textAlign="center" color="gray.500" maxW="400px">
                                    Select a conversation from the list to view messages
                                </Text>
                            </Flex>
                        )}
                    </Box>
                ) : null}

                {/* Mobile Drawer */}
                {isMobile && (
                    <Drawer
                        isOpen={isOpen}
                        placement="right"
                        onClose={onClose}
                        size="full"
                    >
                        <DrawerOverlay />
                        <DrawerContent bg="#fbfbfb">
                            <DrawerHeader borderBottomWidth="1px" bg="white">
                                <Flex align="center">
                                    <IconButton
                                        icon={<ChevronLeftIcon />}
                                        onClick={onClose}
                                        variant="ghost"
                                        mr={2}
                                        aria-label="Back"
                                    />
                                    <Text fontWeight="600" textAlign="center" flex="1">
                                        {selectedConversation?.partnerName}
                                    </Text>
                                </Flex>
                            </DrawerHeader>
                            <DrawerBody p={0}>
                                {selectedConversation && (
                                    <MessageThread
                                        partnershipId={selectedConversation.id}
                                        selectedPartnership={selectedConversation}
                                        userRole={userRole}
                                        handleStatusUpdate={handleStatusUpdate}
                                    />
                                )}
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                )}
            </Flex>
        </Box>
    );
};

export default MessagesHub; 