import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import CreatorOfferService from '../../services/creatorOfferService';
import './CreatorOfferForm.css';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    Button,
    Heading,
    Text,
    Flex,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Alert,
    AlertIcon,
    Container,
    VStack,
    Divider,
    SimpleGrid,
    useColorModeValue
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const CreatorOfferForm = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { offerId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const isEditing = !!offerId;
    
    // Button colors
    const primaryBtnColor = useColorModeValue('gray.800', 'gray.200');
    const secondaryBtnColor = useColorModeValue('gray.500', 'gray.600');
    
    const [offerData, setOfferData] = useState({
        type: 'Instagram Photo',
        description: '',
        price: '',
        expectedViews: '',
        deliveryTime: '7',
        status: 'active'
    });

    // Fetch existing offer data if editing
    useEffect(() => {
        const fetchOffer = async () => {
            if (offerId) {
                try {
                    setLoading(true);
                    const offer = await CreatorOfferService.getOffer(offerId);
                    if (offer) {
                        setOfferData({
                            ...offer,
                            price: offer.price.toString(),
                            expectedViews: offer.expectedViews || '',
                            deliveryTime: offer.deliveryTime.toString(),
                            status: offer.status || 'active'
                        });
                    }
                } catch (error) {
                    console.error('Error fetching offer:', error);
                    setError('Failed to load offer details');
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchOffer();
    }, [offerId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOfferData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleNumberInputChange = (name, value) => {
        setOfferData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const offerPayload = {
                ...offerData,
                price: Number(offerData.price),
                deliveryTime: Number(offerData.deliveryTime),
                creatorId: auth.currentUser.uid,
                status: 'active'
            };

            if (isEditing) {
                await CreatorOfferService.updateOffer(offerId, offerPayload);
                console.log('Offer updated successfully');
                // Show success message
                setError(null);
                alert('Package updated successfully!');
            } else {
                const newOfferId = await CreatorOfferService.createOffer(offerPayload);
                console.log('Offer created successfully');
                // Update URL to edit mode without navigating away
                window.history.replaceState(null, '', `/creator/offers/edit/${newOfferId}`);
                // Show success message
                setError(null);
                alert('Package created successfully!');
            }
            
            // Don't reset form or navigate away
        } catch (error) {
            console.error('Error saving offer:', error);
            setError(error.message || `Failed to ${isEditing ? 'update' : 'create'} offer`);
        } finally {
            setLoading(false);
        }
    };

    if (loading && isEditing) {
        return (
            <Container maxW="320px" mt="40px">
                <Text>Loading offer details...</Text>
            </Container>
        );
    }

    return (
        <Container maxW="100%" p={4}>
            <Box 
                bg="white" 
                p={6} 
                borderRadius="lg" 
                boxShadow="none"
                mb={4}
                width="100%"
                maxW="600px"
                mx="auto"
            >
                <Heading size="lg" mb={6} fontWeight="bold">
                    {isEditing ? 'Edit Package' : 'Create Package'}
                </Heading>
                
                {error && (
                    <Alert status="error" mb={4} borderRadius="md">
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                
                <form onSubmit={handleSubmit}>
                    <VStack spacing={6} align="stretch">
                        {/* Content Type */}
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold">
                                Type<Text as="span" color="red.500">*</Text>
                            </FormLabel>
                            <Select 
                                name="type" 
                                value={offerData.type}
                                onChange={handleInputChange}
                                size="lg"
                                borderRadius="lg"
                                borderColor="gray.200"
                                h="60px"
                                fontSize="md"
                                _hover={{ borderColor: "gray.300" }}
                                icon={<ChevronDownIcon boxSize={6} />}
                            >
                                <option value="Instagram Photo">Instagram Photo</option>
                                <option value="Instagram Reel">Instagram Reel</option>
                                <option value="Instagram Story">Instagram Story</option>
                                <option value="TikTok Video">TikTok Video</option>
                                <option value="YouTube Video">YouTube Video</option>
                                <option value="YouTube Short">YouTube Short</option>
                            </Select>
                        </FormControl>

                        {/* Price and Delivery */}
                        <SimpleGrid columns={2} spacing={6}>
                            <FormControl isRequired>
                                <FormLabel fontWeight="bold">
                                    Price ($)<Text as="span" color="red.500">*</Text>
                                </FormLabel>
                                <NumberInput
                                    min={1}
                                    max={10000}
                                    value={offerData.price}
                                    onChange={(value) => handleNumberInputChange('price', value)}
                                    size="lg"
                                    borderRadius="lg"
                                    h="60px"
                                >
                                    <NumberInputField 
                                        borderColor="gray.200" 
                                        h="60px"
                                        fontSize="md"
                                        _hover={{ borderColor: "gray.300" }}
                                    />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel fontWeight="bold">
                                    Delivery (days)<Text as="span" color="red.500">*</Text>
                                </FormLabel>
                                <NumberInput
                                    min={1}
                                    max={90}
                                    value={offerData.deliveryTime}
                                    onChange={(value) => handleNumberInputChange('deliveryTime', value)}
                                    size="lg"
                                    borderRadius="lg"
                                    h="60px"
                                >
                                    <NumberInputField 
                                        borderColor="gray.200" 
                                        h="60px"
                                        fontSize="md"
                                        _hover={{ borderColor: "gray.300" }}
                                    />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>
                        </SimpleGrid>

                        {/* Expected Views */}
                        <FormControl>
                            <FormLabel fontWeight="bold">Expected Views</FormLabel>
                            <Input
                                name="expectedViews"
                                value={offerData.expectedViews}
                                onChange={handleInputChange}
                                placeholder="e.g., 10K"
                                size="lg"
                                borderRadius="lg"
                                borderColor="gray.200"
                                h="60px"
                                fontSize="md"
                                _hover={{ borderColor: "gray.300" }}
                            />
                        </FormControl>

                        {/* Description */}
                        <FormControl isRequired>
                            <FormLabel fontWeight="bold">
                                Description<Text as="span" color="red.500">*</Text>
                            </FormLabel>
                            <Textarea
                                name="description"
                                value={offerData.description}
                                onChange={handleInputChange}
                                placeholder="Describe what you'll deliver..."
                                size="lg"
                                borderRadius="lg"
                                borderColor="gray.200"
                                rows={4}
                                resize="vertical"
                                fontSize="md"
                                _hover={{ borderColor: "gray.300" }}
                                py={3}
                            />
                        </FormControl>

                        <Divider my={4} />

                        <Flex justify="space-between" mt={4}>
                            <Button 
                                variant="solid"
                                onClick={() => navigate('/creator/dashboard')}
                                size="lg"
                                bg="#f87171"
                                color="white"
                                borderRadius="lg"
                                width="48%"
                                _hover={{ bg: "#ef4444" }}
                                h="60px"
                                fontSize="md"
                            >
                                Cancel
                            </Button>
                            <Button 
                                type="submit"
                                isLoading={loading}
                                loadingText={isEditing ? "Updating..." : "Creating..."}
                                size="lg"
                                bg="black"
                                color="white"
                                borderRadius="lg"
                                width="48%"
                                _hover={{ bg: "#333333" }}
                                h="60px"
                                fontSize="md"
                            >
                                {isEditing ? 'Update' : 'Create'}
                            </Button>
                        </Flex>
                    </VStack>
                </form>
            </Box>
        </Container>
    );
};

export default CreatorOfferForm; 