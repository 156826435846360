import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, query, where, getDocs, collection, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './CreatorDetailsPage.css';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tweet, Timeline } from 'react-twitter-widgets';
import { faInstagram, faTiktok, faYoutube, faTwitter, faFacebook, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { faUsers, faPlay, faMapMarkerAlt, faShoppingCart, faLock } from '@fortawesome/free-solid-svg-icons';
import { Button, Box, Flex, Text, Heading, Image, Grid, Badge, Spinner, Stat, StatLabel, StatNumber, StatGroup, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, FormControl, FormLabel, Input, FormHelperText, Alert, AlertIcon } from '@chakra-ui/react';
import { useTracking } from '../../contexts/TrackingContext';
import { CloudinaryContext, Image as CloudinaryImage } from 'cloudinary-react';
import { useCart } from '../../contexts/CartContext';
import { generateCreatorProfile } from '../../utils/aiProfileGenerator';

// Helper functions to extract IDs from social media URLs
const getInstagramPostId = (url) => {
    // Extract post ID from Instagram URL
    const regex = /instagram.com\/p\/([^/?]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
};

const getTikTokVideoId = (url) => {
    if (!url) return null;
    const regex = /video\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
};

const getYoutubeChannelId = (url) => {
    // Extract channel ID from YouTube URL
    const regex = /channel\/([^/?]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
};

const getInstagramUsername = (username) => {
    // Now just returns the username since that's what we're storing
    return username;
};

const getYoutubeVideoId = (url) => {
    if (!url) return null;
    
    // Handle different YouTube URL formats
    const patterns = [
        /youtube\.com\/watch\?v=([^&]+)/,    // Regular watch URL
        /youtu\.be\/([^?]+)/,                // Shortened URL
        /youtube\.com\/embed\/([^?]+)/,      // Embed URL
        /youtube\.com\/v\/([^?]+)/,          // Another format
        /youtube\.com\/\?v=([^&]+)/          // Another format
    ];

    for (let pattern of patterns) {
        const match = url.match(pattern);
        if (match) return match[1];
    }
    return null;
};

const getTikTokUsername = (username) => {
    // Now just returns the username since that's what we're storing
    return username;
};

const getYoutubeChannelHandle = (username) => {
    // Now just returns the username since that's what we're storing
    return username;
};

// Add this helper function at the top with the other helpers
const getTweetId = (url) => {
    if (!url) return null;
    
    // Extract tweet ID from various Twitter URL formats
    const regex = /twitter\.com\/\w+\/status\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
};

// Update the TikTok embed component to show profile
const TikTokEmbed = ({ username }) => {
    useEffect(() => {
        const loadTikTokScript = () => {
            const existingScript = document.getElementById('tiktok-embed-script');
            if (!existingScript) {
                const script = document.createElement('script');
                script.id = 'tiktok-embed-script';
                script.src = 'https://www.tiktok.com/embed.js';
                script.async = true;
                document.body.appendChild(script);
                
                script.onload = () => {
                    if (window.TikTok) {
                        window.TikTok.reload();
                    }
                };
            } else if (window.TikTok) {
                window.TikTok.reload();
            }
        };

        loadTikTokScript();

        return () => {
            const script = document.getElementById('tiktok-embed-script');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [username]);

    return (
        <Box display="flex" justifyContent="center" w="100%" maxW="550px" mx="auto">
            <blockquote 
                className="tiktok-embed" 
                cite={`https://www.tiktok.com/@${username}`}
                data-unique-id={username}
                data-embed-type="creator"
                style={{ maxWidth: "780px", minWidth: "288px" }}
            >
                <section>
                    <a target="_blank" href={`https://www.tiktok.com/@${username}?refer=creator_embed`}>@{username}</a>
                </section>
            </blockquote>
        </Box>
    );
};

// Update the helper function to get Twitter username
const getTwitterUsername = (username) => {
    // Now just returns the username since that's what we're storing
    return username;
};

// Add this helper function at the top
const getTwitchUsername = (username) => {
    // Now just returns the username since that's what we're storing
    return username;
};

// Add this component at the top of your file
const TwitchEmbed = ({ channelName }) => {
  useEffect(() => {
    // Load the Twitch embed script
    const script = document.createElement('script');
    script.src = "https://player.twitch.tv/js/embed/v1.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      new window.Twitch.Player("twitch-embed", {
        channel: channelName,
        width: "100%",
        height: "500",
        parent: [window.location.hostname]
      });
    };

    return () => {
      // Cleanup
      document.body.removeChild(script);
    };
  }, [channelName]);

  return <div id="twitch-embed"></div>;
};

// Add this helper function to format the category
const formatCategory = (category) => {
    if (!category) return 'Content Creator';
    // Convert snake_case or kebab-case to Title Case and append "Influencer"
    return category
        .split(/[_-]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ') + ' Influencer';
};

// Add this helper function to format follower counts
const formatFollowerCount = (count) => {
    if (!count && count !== 0) return 'N/A';
    
    if (count >= 1000000) {
        return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    
    if (count >= 1000) {
        return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    
    return count.toString();
};

// Add this helper function to calculate total followers
const calculateTotalFollowers = (followers) => {
    if (!followers) return 0;
    
    // Helper function to convert ranges like "50k-100k" to numbers
    const rangeToNumber = (range) => {
        if (!range || range === "") return 0;
        
        // If it's a range like "50k-100k"
        if (range.includes('-')) {
            // Extract the upper end of the range
            const upperEnd = range.split('-')[1];
            return convertToNumber(upperEnd);
        }
        
        // If it's a single value
        return convertToNumber(range);
    };
    
    // Helper function to convert string values with k, m suffixes to numbers
    const convertToNumber = (value) => {
        if (!value || value === "") return 0;
        
        // Convert to lowercase for consistency
        value = value.toLowerCase();
        
        // Handle k (thousands)
        if (value.includes('k')) {
            return parseFloat(value.replace('k', '')) * 1000;
        }
        
        // Handle m (millions)
        if (value.includes('m')) {
            return parseFloat(value.replace('m', '')) * 1000000;
        }
        
        // Handle plain numbers
        return parseFloat(value) || 0;
    };
    
    // Calculate total from all platforms
    let total = 0;
    
    // Add up followers from each platform
    if (followers.instagram) total += rangeToNumber(followers.instagram);
    if (followers.tiktok) total += rangeToNumber(followers.tiktok);
    if (followers.youtube) total += rangeToNumber(followers.youtube);
    if (followers.twitter) total += rangeToNumber(followers.twitter);
    if (followers.facebook) total += rangeToNumber(followers.facebook);
    if (followers.twitch) total += rangeToNumber(followers.twitch);
    
    return total;
};

const getSocialMediaPlatform = (url) => {
  if (!url) return null;
  
  const platforms = {
    instagram: /instagram\.com/,
    tiktok: /tiktok\.com/,
    youtube: /(youtube\.com|youtu\.be)/,
    twitter: /twitter\.com/,
    facebook: /facebook\.com/,
    twitch: /twitch\.tv/
  };

  for (const [platform, regex] of Object.entries(platforms)) {
    if (regex.test(url)) return platform;
  }
  
  return null;
};

const generateProfileFromSocialMedia = async (url) => {
  try {
    const platform = getSocialMediaPlatform(url);
    if (!platform) {
      throw new Error('Unsupported social media platform');
    }

    // Extract username/handle from URL
    let username = '';
    switch (platform) {
      case 'instagram':
        username = url.split('instagram.com/')[1]?.split('/')[0];
        break;
      case 'tiktok':
        username = url.split('@')[1]?.split('?')[0] || url.split('tiktok.com/')[1]?.split('?')[0];
        break;
      case 'youtube':
        if (url.includes('/channel/')) {
          username = url.split('/channel/')[1]?.split('/')[0];
        } else if (url.includes('/c/')) {
          username = url.split('/c/')[1]?.split('/')[0];
        } else if (url.includes('/user/')) {
          username = url.split('/user/')[1]?.split('/')[0];
        }
        break;
      case 'twitter':
        username = url.split('twitter.com/')[1]?.split('/')[0];
        break;
      case 'twitch':
        username = url.split('twitch.tv/')[1]?.split('/')[0];
        break;
      default:
        throw new Error('Unable to extract username from URL');
    }

    if (!username) {
      throw new Error('Unable to extract username from URL');
    }

    // Generate profile using AI
    const profileData = await generateCreatorProfile(platform, username);
    
    if (!profileData) {
      throw new Error('Failed to generate profile data');
    }

    return {
      ...profileData,
      socialLinks: {
        [platform]: username
      },
      primaryPlatform: platform
    };

  } catch (error) {
    console.error('Error generating profile:', error);
    throw error;
  }
};

const CreatorProfileSetup = () => {
  const [socialUrl, setSocialUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!socialUrl) {
        throw new Error('Please enter a social media URL');
      }

      // Generate profile data
      const profileData = await generateProfileFromSocialMedia(socialUrl);

      // Save to Firebase
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        ...profileData,
        updatedAt: serverTimestamp()
      });

      // Navigate to profile page
      navigate(`/creator/${currentUser.username}`);
      
      toast.success('Profile updated successfully!');

    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box maxW="600px" mx="auto" p={6}>
      <Heading mb={6}>Create Your Profile</Heading>
      <Text mb={4} color="gray.600">
        Choose how you'd like to set up your profile
      </Text>

      <form onSubmit={handleSubmit}>
        <FormControl isRequired mb={4}>
          <FormLabel>Social Media URL</FormLabel>
          <Input
            type="url"
            placeholder="Paste your Instagram, TikTok, YouTube, etc. profile URL"
            value={socialUrl}
            onChange={(e) => setSocialUrl(e.target.value)}
          />
          <FormHelperText>
            We'll analyze your public profile to pre-fill your information
          </FormHelperText>
        </FormControl>

        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}

        <Button
          type="submit"
          colorScheme="blue"
          isLoading={loading}
          loadingText="Analyzing Profile..."
          width="full"
        >
          Generate Profile
        </Button>
      </form>
    </Box>
  );
};

const CreatorDetailsPage = () => {
  const navigate = useNavigate();
  const { currentUser, loading: authLoading } = useAuth();
  const { generateAffiliateLink } = useTracking();
  const [creator, setCreator] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showAllPlatforms, setShowAllPlatforms] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [affiliateLink, setAffiliateLink] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [activePlatform, setActivePlatform] = useState('instagram');
  const [instagramPosts, setInstagramPosts] = useState([
    // Sample Instagram posts for testing - these would be fetched from Instagram API in production
    {
      id: 'post1',
      imageUrl: 'https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      caption: 'Dance performance at the studio',
      likes: 1245,
      comments: 89
    },
    {
      id: 'post2',
      imageUrl: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80',
      caption: 'New photoshoot for summer collection',
      likes: 2389,
      comments: 156
    },
    {
      id: 'post3',
      imageUrl: 'https://images.unsplash.com/photo-1554244933-d876deb6b2ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80',
      caption: 'Dance is poetry in motion',
      likes: 1876,
      comments: 124
    }
  ]);

  // Add new state for package filter
  const [packageFilter, setPackageFilter] = useState('All');

  // Update the state to include Cloudinary photos
  const [profilePhotos, setProfilePhotos] = useState([]);

  // Update the state declaration for gallery photos
  const [galleryPhotos, setGalleryPhotos] = useState([]);

  // Add this near the top of your component function, after the state declarations
  const cloudinaryConfig = {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'demo',
    apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
    apiSecret: process.env.REACT_APP_CLOUDINARY_API_SECRET
  };

  // Add this effect to fetch profile photos after creator data is loaded
  useEffect(() => {
    const fetchProfilePhotos = async () => {
      if (creator?.profilePhotos && Array.isArray(creator.profilePhotos)) {
        // Use the first 3 photos from the creator's profile photos
        setProfilePhotos(creator.profilePhotos.slice(0, 3));
      }
    };

    if (creator) {
      fetchProfilePhotos();
    }
  }, [creator]);

  // Update the useEffect for fetching photos to check multiple possible property names
  useEffect(() => {
    const fetchGalleryPhotos = async () => {
      if (creator) {
        
        // Check multiple possible property names for gallery photos
        let photos = null;
        
        if (creator.galleryPhotos && Array.isArray(creator.galleryPhotos)) {
          photos = creator.galleryPhotos;
        } else if (creator.profileGallery && Array.isArray(creator.profileGallery)) {
          photos = creator.profileGallery;
        } else if (creator.gallery && Array.isArray(creator.gallery)) {
          photos = creator.gallery;
        }
        
        if (photos && photos.length > 0) {
          setGalleryPhotos(photos);
        } else {
        }
      }
    };

    if (creator) {
      fetchGalleryPhotos();
    }
  }, [creator]);

  // Add this state for portfolio media
  const [portfolioMedia, setPortfolioMedia] = useState([]);

  // Update the useEffect to fetch portfolio media after creator data is loaded
  useEffect(() => {
    const fetchPortfolioMedia = async () => {
      if (creator) {
        
        // Check for portfolioMedia property
        if (creator.portfolioMedia && Array.isArray(creator.portfolioMedia)) {
          setPortfolioMedia(creator.portfolioMedia);
        } else {
          // Try alternative property names if needed
          if (creator.portfolio && Array.isArray(creator.portfolio)) {
            setPortfolioMedia(creator.portfolio);
          }
        }
      }
    };

    if (creator) {
      fetchPortfolioMedia();
    }
  }, [creator]);

  const [isClaimed, setIsClaimed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        setLoading(true);
        
        // Query users collection to find the user with the matching username
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('username', '==', id));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          // Check unclaimed profiles collection
          const unclaimedRef = collection(db, 'unclaimedProfiles');
          const unclaimedQuery = query(unclaimedRef, where('username', '==', id));
          const unclaimedSnapshot = await getDocs(unclaimedQuery);
          
          if (unclaimedSnapshot.empty) {
            setError('Creator not found');
            return;
          }
          
          const unclaimedDoc = unclaimedSnapshot.docs[0];
          const unclaimedData = unclaimedDoc.data();
          
          setCreator({
            id: unclaimedDoc.id,
            ...unclaimedData,
            isClaimed: false
          });
          setIsClaimed(false);
          return;
        }

        const creatorDoc = querySnapshot.docs[0];
        const creatorData = creatorDoc.data();
        
        if (!creatorData) {
          setError('Creator data is missing');
          return;
        }

        setCreator({
          id: creatorDoc.id,
          ...creatorData,
          isClaimed: true
        });
        setIsClaimed(true);
        
        if (creatorData.socialLinks) {
          if (creatorData.socialLinks.instagram) {
            setActivePlatform('instagram');
          } else {
            const availablePlatforms = Object.keys(creatorData.socialLinks).filter(key => 
              creatorData.socialLinks[key] && ['instagram', 'tiktok', 'youtube', 'twitter', 'twitch'].includes(key)
            );
            if (availablePlatforms.length > 0) {
              setActivePlatform(availablePlatforms[0]);
            }
          }
        }
        
        const creatorId = creatorDoc.id;
        
        const offersQuery = query(
          collection(db, 'creatorOffers'),
          where('creatorId', '==', creatorId),
          where('status', '==', 'active')
        );
        
        const offersSnapshot = await getDocs(offersQuery);
        
        const offersData = offersSnapshot.docs.map(doc => {
          const data = doc.data();
          let createdAt = data.createdAt;
          if (createdAt && typeof createdAt.toDate === 'function') {
            createdAt = createdAt.toDate();
          } else if (createdAt) {
            createdAt = new Date(createdAt);
          }
          
          return {
            id: doc.id,
            ...data,
            createdAt
          };
        });
        
        setOffers(offersData);
      } catch (error) {
        console.error('Error:', error);
        setError('Error loading creator profile');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchCreatorData();
    }
  }, [id]);

  useEffect(() => {
    const fetchCampaign = async () => {
        try {
            setLoading(true);

            const campaignDoc = await getDoc(doc(db, 'campaigns', campaignId));
            
            if (!campaignDoc.exists()) {
                setError('Campaign not found');
                return;
            }

            const campaignData = campaignDoc.data();
            
            // Safely log campaign data
            console.log('Campaign Data:', {
                ...(campaignData || {}),
                imageUrl: campaignData?.imageUrl || 'No image URL'
            });

            if (campaignData) {
                setCampaign(campaignData);
                
                if (currentUser) {
                    const link = await generateAffiliateLink(
                        campaignId,
                        currentUser.uid,
                        campaignData.productUrl
                    );
                    console.log('Generated link with URL:', {
                        campaignId,
                        creatorId: currentUser.uid,
                        productUrl: campaignData.productUrl,
                        finalLink: link
                    });
                    setAffiliateLink(link);
                }
            }
        } catch (err) {
            console.error('Error:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (campaignId && currentUser) {
        fetchCampaign();
    }
}, [campaignId, currentUser, generateAffiliateLink]);

  // Add this helper function near the top
  const loadInstagramEmbed = () => {
    return new Promise((resolve) => {
      // Remove any existing script first
      const existingScript = document.getElementById('instagram-embed-script');
      if (existingScript) {
        existingScript.remove();
      }

      // Create and add the new script
      const script = document.createElement('script');
      script.id = 'instagram-embed-script';
      script.src = 'https://www.instagram.com/embed.js';
      script.async = true;
      
      // Set up onload handler
      script.onload = () => {
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
        resolve();
      };

      document.body.appendChild(script);
    });
  };

  // Update the Instagram platform section in the render
  {activePlatform === 'instagram' && creator?.socialLinks?.instagram && (
    <Box 
      className="instagram-embed-container" 
      height="100%" 
      display="flex" 
      justifyContent="center" 
      alignItems="flex-start" 
      p={{ base: 0, md: 4 }} 
      overflow="hidden"
      width="100%"
    >
      <Box 
        width="100%"
        maxWidth={{ base: "100%", md: "540px" }} 
        margin="0 auto"
        transform={{ base: "scale(0.8)", md: "scale(1)" }}
        transformOrigin="top center"
        px={{ base: 2, md: 0 }}
      >
        <div 
          className="instagram-wrapper"
          style={{
            width: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center"
          }}
          key={`instagram-${creator.socialLinks.instagram}`} // Add key to force re-render
          dangerouslySetInnerHTML={{ 
            __html: `
              <blockquote 
                class="instagram-media" 
                data-instgrm-permalink="https://www.instagram.com/${creator.socialLinks.instagram}/" 
                data-instgrm-version="14"
                style="background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 0 auto; max-width:100%; padding:0; width:99.375%; min-width:300px !important;"
              ></blockquote>
            `
          }} 
        />
      </Box>
    </Box>
  )}

  // Update the useEffect for Instagram embed
  useEffect(() => {
    if (activePlatform !== 'instagram' || !creator?.socialLinks?.instagram) return;

    let timeoutId;
    let retryCount = 0;
    const maxRetries = 3;

    const loadEmbed = async () => {
      try {
        await loadInstagramEmbed();
      } catch (error) {
        console.error('Error loading Instagram embed:', error);
        if (retryCount < maxRetries) {
          retryCount++;
          timeoutId = setTimeout(loadEmbed, 1000); // Retry after 1 second
        }
      }
    };

    // Initial load with a small delay
    timeoutId = setTimeout(loadEmbed, 300);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      // Clean up script on unmount
      const scriptToRemove = document.getElementById('instagram-embed-script');
      if (scriptToRemove && scriptToRemove.parentNode) {
        scriptToRemove.parentNode.removeChild(scriptToRemove);
      }
    };
  }, [activePlatform, creator?.socialLinks?.instagram]);

  // Add this useEffect to fetch user role
  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };
    fetchUserRole();
  }, [currentUser]);

  // Add this useEffect for TikTok script loading
  useEffect(() => {
    if (activePlatform !== 'tiktok') return;

    const loadTikTokScript = () => {
      // Remove any existing TikTok embed scripts
      const existingScript = document.getElementById('tiktok-embed-script');
      if (existingScript) {
        existingScript.remove();
      }

      const script = document.createElement('script');
      script.src = 'https://www.tiktok.com/embed.js';
      script.async = true;
      script.id = 'tiktok-embed-script';
      
      // Add onload handler
      script.onload = () => {
        // Force TikTok to process embeds after script loads
        if (window.tiktok) {
          window.tiktok.reload();
        }
      };
      
      document.body.appendChild(script);
    };

    loadTikTokScript();

    return () => {
      const scriptToRemove = document.getElementById('tiktok-embed-script');
      if (scriptToRemove) {
        scriptToRemove.remove();
      }
    };
  }, [activePlatform]);

  const { addToCart } = useCart();

  // Function to render an individual package card
  const renderPackageCard = (offer) => {
    return (
      <Box 
        key={offer.id} 
        borderWidth="1px" 
        borderRadius="lg" 
        overflow="hidden" 
        className="package-card"
        boxShadow="sm"
        transition="all 0.2s"
        _hover={{ boxShadow: "md", transform: "translateY(-2px)" }}
      >
        <Box p={5}>
          <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
            <Heading as="h3" size="md">
              {offer.numberOfPosts ? `${offer.numberOfPosts} ${offer.type}` : `1 ${offer.type}`}
            </Heading>
            <Text fontWeight="bold" fontSize="xl">${offer.price}</Text>
          </Flex>
          
          <Text fontSize="sm" color="gray.600" mb={4}>
            {offer.description || "This package includes content creation services tailored to your brand needs."}
          </Text>
          
          {/* Only show Expected Results section if there are expected views */}
          {offer.expectedViews && (
          <Box mb={3}>
              <Text fontSize="sm" fontWeight="medium" mb={1}>Expected Results:</Text>
            <Flex flexWrap="wrap" gap={2}>
                {/* Add Expected Views badge */}
                <Badge colorScheme="green" fontSize="xs" px={2} py={1} borderRadius="full">
                  {offer.expectedViews} Views
                </Badge>
                
                {/* Still show platforms if available */}
              {offer.platforms && offer.platforms.map((platform, idx) => (
                <Badge key={idx} colorScheme="gray" fontSize="xs" px={2} py={1} borderRadius="full">
                                {platform}
                </Badge>
              ))}
            </Flex>
          </Box>
          )}
          
          {/* Show platforms section without Expected Results label if no expected views */}
          {!offer.expectedViews && offer.platforms && offer.platforms.length > 0 && (
            <Box mb={3}>
              <Text fontSize="sm" fontWeight="medium" mb={1}>Platforms:</Text>
              <Flex flexWrap="wrap" gap={2}>
                {offer.platforms.map((platform, idx) => (
                  <Badge key={idx} colorScheme="gray" fontSize="xs" px={2} py={1} borderRadius="full">
                    {platform}
                  </Badge>
                ))}
              </Flex>
            </Box>
          )}
          
          <Flex mt={4} justifyContent="flex-start">
            <Button
              size="sm"
              colorScheme="blue"
              leftIcon={<FontAwesomeIcon icon={faShoppingCart} />}
              onClick={() => handleAddToCart(offer)}
              borderRadius="md"
              _hover={{ transform: "translateY(-1px)", boxShadow: "sm" }}
            >
              Add to Cart
            </Button>
          </Flex>
        </Box>
        
        <Box 
          borderTopWidth="1px" 
          p={4} 
          bg="gray.50" 
          display="flex" 
          alignItems="center" 
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            {offer.platforms && offer.platforms.includes('Instagram') && <FontAwesomeIcon icon={faInstagram} style={{ marginRight: '8px' }} />}
            {offer.platforms && offer.platforms.includes('TikTok') && <FontAwesomeIcon icon={faTiktok} style={{ marginRight: '8px' }} />}
            {offer.platforms && offer.platforms.includes('YouTube') && <FontAwesomeIcon icon={faYoutube} style={{ marginRight: '8px' }} />}
            {!offer.platforms && <FontAwesomeIcon icon={faPlay} style={{ marginRight: '8px' }} />}
            <Text fontSize="sm" fontWeight="medium">
              {offer.type}
            </Text>
          </Flex>
        </Box>
      </Box>
    );
  };

  const handleNonBrandClick = () => {
    if (!currentUser) {
      toast.error('Please log in to select offers');
    } else if (currentUser.uid === id) {
      toast.error('You cannot select your own offers');
    } else {
      toast.error('You need a brand account to select offers');
    }
  };

  const handleAddToCart = (offer) => {
    try {
        if (!currentUser) {
        toast.error('Please log in to add offers to cart', {
          position: 'top-center',
          duration: 3000,
          style: {
            marginTop: '70px',
            zIndex: 9999,
          },
        });
            return;
        }

        if (userRole !== 'brand') {
        toast.error('You need a brand account to purchase offers', {
          position: 'top-center',
          duration: 3000,
          style: {
            marginTop: '70px',
            zIndex: 9999,
          },
        });
            return;
        }

        if (currentUser.uid === id) {
        toast.error('You cannot purchase your own offers', {
          position: 'top-center',
          duration: 3000,
          style: {
            marginTop: '70px',
            zIndex: 9999,
          },
        });
            return;
        }

      addToCart(offer, {
        id: creator.id,
        name: creator.name,
        profileImageUrl: creator.profileImageUrl
      });

        setSelectedOffer(null);
      
      // Success toast positioned below the header
      toast.success('Offer added to cart!', {
        position: 'top-center',
        duration: 3000,
        style: {
          marginTop: '70px',
          zIndex: 9999,
        },
      });

    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add offer to cart. Please try again.', {
        position: 'top-center',
        duration: 3000,
        style: {
          marginTop: '70px',
          zIndex: 9999,
        },
      });
    }
  };

  const handleClaimProfile = () => {
    if (!currentUser) {
      onOpen(); // Open the modal for non-logged-in users
      return;
    }
    // If user is logged in, redirect to profile setup
    navigate(`/creator/setup?claim=${id}`);
  };

  const ClaimModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Claim Your Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text mb={4}>To claim this profile, you'll need to create an account or sign in.</Text>
          <Button colorScheme="blue" mr={3} onClick={() => navigate('/signup?claim=' + id)}>
            Create Account
          </Button>
          <Button onClick={() => navigate('/login?claim=' + id)}>
            Sign In
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  if (!creator) {
    return null;
  }


  // For displaying total followers
  const totalFollowers = calculateTotalFollowers(creator?.followers);
  const formattedTotalFollowers = formatFollowerCount(totalFollowers);

  // For displaying individual platform followers
  const renderPlatformFollowers = (platform) => {
    if (!creator?.followers?.[platform]) return null;
    
    // Get the upper end of the range for this platform
    const rangeToNumber = (range) => {
      if (!range || range === "") return 0;
      if (range.includes('-')) {
        const upperEnd = range.split('-')[1];
        // Convert k, m suffixes
        if (upperEnd.toLowerCase().includes('k')) {
          return parseFloat(upperEnd.toLowerCase().replace('k', '')) * 1000;
        }
        if (upperEnd.toLowerCase().includes('m')) {
          return parseFloat(upperEnd.toLowerCase().replace('m', '')) * 1000000;
        }
        return parseFloat(upperEnd) || 0;
      }
      // Handle single values
      if (range.toLowerCase().includes('k')) {
        return parseFloat(range.toLowerCase().replace('k', '')) * 1000;
      }
      if (range.toLowerCase().includes('m')) {
        return parseFloat(range.toLowerCase().replace('m', '')) * 1000000;
      }
      return parseFloat(range) || 0;
    };
    
    const followerCount = rangeToNumber(creator.followers[platform]);
    const formattedCount = formatFollowerCount(followerCount);

  return (
      <Stat key={platform}>
        <StatLabel>{platform.charAt(0).toUpperCase() + platform.slice(1)}</StatLabel>
        <StatNumber>{formattedCount}</StatNumber>
      </Stat>
    );
  };

  const renderSocialCards = () => {
    // Helper function to check if a follower count is effectively zero
    const isZeroFollowers = (followerStr) => {
      if (!followerStr || followerStr === "" || followerStr === "0") {
        return true;
      }
      
      // Handle ranges like "0-1k" - check if upper end is effectively zero
      if (followerStr.includes('-')) {
        const upperEnd = followerStr.split('-')[1].toLowerCase();
        // If upper end is "0k" or just "0"
        if (upperEnd === "0k" || upperEnd === "0m" || upperEnd === "0") {
          return true;
        }
      }
      
      return false;
    };

    // Define rangeToNumber inside this function to avoid scope issues
    const rangeToNumber = (range) => {
      if (!range || range === "") return 0;
      if (range.includes('-')) {
        const upperEnd = range.split('-')[1];
        // Convert k, m suffixes
        if (upperEnd.toLowerCase().includes('k')) {
          return parseFloat(upperEnd.toLowerCase().replace('k', '')) * 1000;
        }
        if (upperEnd.toLowerCase().includes('m')) {
          return parseFloat(upperEnd.toLowerCase().replace('m', '')) * 1000000;
        }
        return parseFloat(upperEnd) || 0;
      }
      // Handle single values
      if (range.toLowerCase().includes('k')) {
        return parseFloat(range.toLowerCase().replace('k', '')) * 1000;
      }
      if (range.toLowerCase().includes('m')) {
        return parseFloat(range.toLowerCase().replace('m', '')) * 1000000;
      }
      return parseFloat(range) || 0;
    };

    // Platform colors, icons and gradient backgrounds
    const platformInfo = {
      instagram: { 
        icon: faInstagram, 
        gradient: "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
        textColor: "white"
      },
      tiktok: { 
        icon: faTiktok, 
        gradient: "linear-gradient(45deg, #25F4EE 0%, #000000 100%)",
        textColor: "white"
      },
      youtube: { 
        icon: faYoutube, 
        gradient: "linear-gradient(45deg, #FF0000 0%, #CC0000 100%)",
        textColor: "white"
      },
      twitter: { 
        icon: faTwitter, 
        gradient: "linear-gradient(45deg, #1DA1F2 0%, #0D8ECF 100%)",
        textColor: "white"
      },
      facebook: { 
        icon: faFacebook, 
        gradient: "linear-gradient(45deg, #3B5998 0%, #2D4373 100%)",
        textColor: "white"
      },
      twitch: { 
        icon: faTwitch, 
        gradient: "linear-gradient(45deg, #6441A4 0%, #4B367C 100%)",
        textColor: "white"
      }
    };

    // List of platforms to check
    const platforms = ['instagram', 'tiktok', 'youtube', 'twitter', 'facebook', 'twitch'];
    
    // Calculate total followers
    let totalFollowers = 0;
    platforms.forEach(platform => {
      if (creator?.followers?.[platform] && !isZeroFollowers(creator.followers[platform])) {
        totalFollowers += rangeToNumber(creator.followers[platform]);
      }
    });
    
    // Filter out platforms with zero followers
    const validPlatforms = platforms.filter(platform => {
      if (!creator?.followers?.[platform] || isZeroFollowers(creator.followers[platform])) {
        return false;
      }
      const followerCount = rangeToNumber(creator.followers[platform]);
      return followerCount > 0;
    });
    
    return (
      <Flex
        width="100%"
        px={2}
        mt={4}
        gap={2}
        justifyContent={{ base: "center", md: "flex-start" }}
      >
        {/* Total Followers Card */}
        {totalFollowers > 0 && (
          <Box 
            flex={{ base: "1", md: "0 0 180px" }}
            maxWidth={{ base: "140px", md: "180px" }}
            borderRadius="lg" 
            overflow="hidden"
            height={{ base: "85px", md: "120px" }}
            bg="linear-gradient(45deg, #6366F1 0%, #8B5CF6 100%)"
            color="white"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={2}
          >
            <Box fontSize={{ base: "sm", md: "lg" }} mb={1}>
              <FontAwesomeIcon icon={faUsers} />
            </Box>
            <Text 
              fontSize={{ base: "sm", md: "xl" }} 
              fontWeight="bold"
              lineHeight="1"
              mb={1}
            >
              56K
            </Text>
            <Text 
              fontSize={{ base: "2xs", md: "sm" }} 
              textTransform="uppercase" 
              letterSpacing="wide"
              lineHeight="1"
              opacity="0.9"
            >
              TOTAL
            </Text>
          </Box>
        )}
        
        {/* Instagram Card */}
        <Box 
          flex={{ base: "1", md: "0 0 180px" }}
          maxWidth={{ base: "140px", md: "180px" }}
          borderRadius="lg" 
          overflow="hidden"
          height={{ base: "85px", md: "120px" }}
          bg="linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)"
          color="white"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={2}
          cursor="pointer"
          transition="transform 0.2s"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg'
          }}
          onClick={() => {
            window.open(`https://instagram.com/${creator.socialLinks.instagram}`, '_blank');
          }}
        >
          <Box fontSize={{ base: "sm", md: "lg" }} mb={1}>
            <FontAwesomeIcon icon={faInstagram} />
          </Box>
          <Text 
            fontSize={{ base: "sm", md: "xl" }} 
            fontWeight="bold"
            lineHeight="1"
            mb={1}
          >
            50K
          </Text>
          <Text 
            fontSize={{ base: "2xs", md: "sm" }} 
            textTransform="uppercase" 
            letterSpacing="wide"
            lineHeight="1"
            opacity="0.9"
          >
            INSTAGRAM
          </Text>
        </Box>

        {/* TikTok Card */}
        <Box 
          flex={{ base: "1", md: "0 0 180px" }}
          maxWidth={{ base: "140px", md: "180px" }}
          borderRadius="lg" 
          overflow="hidden"
          height={{ base: "85px", md: "120px" }}
          bg="linear-gradient(45deg, #25F4EE 0%, #000000 100%)"
          color="white"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={2}
          cursor="pointer"
          transition="transform 0.2s"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg'
          }}
          onClick={() => {
            window.open(`https://tiktok.com/@${creator.socialLinks.tiktok}`, '_blank');
          }}
        >
          <Box fontSize={{ base: "sm", md: "lg" }} mb={1}>
            <FontAwesomeIcon icon={faTiktok} />
          </Box>
          <Text 
            fontSize={{ base: "sm", md: "xl" }} 
            fontWeight="bold"
            lineHeight="1"
            mb={1}
          >
            1K
          </Text>
          <Text 
            fontSize={{ base: "2xs", md: "sm" }} 
            textTransform="uppercase" 
            letterSpacing="wide"
            lineHeight="1"
            opacity="0.9"
          >
            TIKTOK
          </Text>
        </Box>

        {/* YouTube Card */}
        <Box 
          flex={{ base: "1", md: "0 0 180px" }}
          maxWidth={{ base: "140px", md: "180px" }}
          borderRadius="lg" 
          overflow="hidden"
          height={{ base: "85px", md: "120px" }}
          bg="linear-gradient(45deg, #FF0000 0%, #CC0000 100%)"
          color="white"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={2}
          cursor="pointer"
          transition="transform 0.2s"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg'
          }}
          onClick={() => {
            window.open(`https://youtube.com/@${creator.socialLinks.youtube}`, '_blank');
          }}
        >
          <Box fontSize={{ base: "sm", md: "lg" }} mb={1}>
            <FontAwesomeIcon icon={faYoutube} />
          </Box>
          <Text 
            fontSize={{ base: "sm", md: "xl" }} 
            fontWeight="bold"
            lineHeight="1"
            mb={1}
          >
            5K
          </Text>
          <Text 
            fontSize={{ base: "2xs", md: "sm" }} 
            textTransform="uppercase" 
            letterSpacing="wide"
            lineHeight="1"
            opacity="0.9"
          >
            YOUTUBE
          </Text>
        </Box>
      </Flex>
    );
  };

  return (
    <div className="creator-details-container" style={{ maxWidth: "100%" }}>
      {/* Add claim banner if profile is not claimed */}
      {!isClaimed && (
        <Box 
          bg="blue.500" 
          color="white" 
          p={4} 
          textAlign="center"
          position="relative"
          zIndex="banner"
        >
          <Flex justify="center" align="center" gap={2}>
            <FontAwesomeIcon icon={faLock} />
            <Text>This profile is not yet claimed.</Text>
            <Button
              size="sm"
              colorScheme="whiteAlpha"
              onClick={handleClaimProfile}
              ml={2}
            >
              Claim Profile
            </Button>
          </Flex>
        </Box>
      )}

      {/* New Hero Gallery Section */}
      <div className="creator-gallery-hero">
        {/* Use a hardcoded cloud name since the environment variable isn't loading */}
        <CloudinaryContext cloudName="dtkagg3bc">
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={2} width="100%">
            {galleryPhotos && galleryPhotos.length > 0 ? (
              <>
                {/* On mobile, show only the first photo */}
                <Box 
                  key={galleryPhotos[0]?.public_id || 'gallery-photo-0'} 
                  position="relative" 
                  height={{base: "250px", md: "300px"}}
                  overflow="hidden"
                  borderRadius="md"
                  gridColumn={{ base: "1 / -1", md: "auto" }}
                  display={{ base: "block", md: "block" }}
                >
                  <Image 
                    src={galleryPhotos[0]?.url} 
                    alt="Gallery photo 1"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    transition="transform 0.3s ease"
                    _hover={{
                      transform: 'scale(1.05)'
                    }}
                  />
                  {galleryPhotos[0]?.caption && (
                    <Box
                      position="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                      bg="rgba(0,0,0,0.7)"
                      color="white"
                      p={2}
                    >
                      {galleryPhotos[0].caption}
                    </Box>
                  )}
                </Box>
                
                {/* Additional photos only visible on desktop */}
                {galleryPhotos.slice(1, 3).map((photo, index) => (
                  <Box 
                    key={photo.public_id || `gallery-photo-${index + 1}`} 
                    position="relative" 
                    height="300px"
                    overflow="hidden"
                    borderRadius="md"
                    display={{ base: "none", md: "block" }}
                  >
                    <Image 
                      src={photo.url} 
                      alt={`Gallery photo ${index + 2}`}
                      width="100%"
                      height="100%"
                      objectFit="cover"
                      transition="transform 0.3s ease"
                    _hover={{
                      transform: 'scale(1.05)'
                    }}
                  />
                  {photo.caption && (
                    <Box
                      position="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                      bg="rgba(0,0,0,0.7)"
                      color="white"
                      p={2}
                    >
                      {photo.caption}
                    </Box>
                  )}
                </Box>
                ))}
              </>
            ) : (
              <Box 
                gridColumn="span 3"
                height={{base: "200px", md: "300px"}}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="gray.100"
                borderRadius="md"
              >
                <Text color="gray.500">No gallery photos available</Text>
              </Box>
            )}
          </Grid>
        </CloudinaryContext>
        
        {galleryPhotos.length > 3 && (
          <Button 
            position="absolute" 
            bottom="10px" 
            right="10px" 
            size="sm" 
            bg="white" 
            color="black"
            borderRadius="full"
            _hover={{ bg: "gray.100" }}
            onClick={() => {/* Add handler for viewing all photos */}}
            display={{ base: "none", md: "block" }} // Only show on desktop
          >
            View All Photos ({galleryPhotos.length})
          </Button>
        )}
        
        {/* Mobile-only indicator for more photos */}
        {galleryPhotos.length > 1 && (
          <Box
            position="absolute"
            bottom="10px"
            right="10px"
            bg="rgba(0,0,0,0.7)"
            color="white"
            px={3}
            py={1}
            borderRadius="full"
            fontSize="sm"
            display={{ base: "block", md: "none" }}
          >
            +{galleryPhotos.length - 1} more
          </Box>
        )}
      </div>

      {/* Creator Profile Section - Adjust width */}
      <Box 
        className="creator-profile-section" 
        py={{ base: 4, md: 8 }} 
        px={{ base: 4, md: 4 }}
        width="100%"
        maxWidth="100%"
        bg="white"
      >
        <Box
          maxWidth={{ base: "100%", md: "1200px" }}
          mx="auto"
        >
          <Flex 
            direction={{ base: "column", md: "row" }} 
            align="center"
            gap={{ base: 4, md: 6 }}
            width="100%"
          >
            <Box 
              className="creator-avatar-container" 
              display={{ base: "none", md: "block" }}
            >
              <Image 
                src={creator.profileImageUrl} 
                alt={creator.name} 
                borderRadius="full" 
                boxSize={{ base: "100px", md: "150px" }}
                border="3px solid white"
                boxShadow="lg"
              />
            </Box>
            
            <Box className="creator-info-container" width="100%">
              <Heading as="h1" size={{ base: "lg", md: "xl" }} mb={2} textAlign={{ base: "center", md: "left" }}>{creator.name}</Heading>
              <Text fontSize="lg" color="gray.600" mb={4} textAlign={{ base: "center", md: "left" }}>
                {formatCategory(creator?.primaryCategory)}
              </Text>
              
              <Flex 
                align="center" 
                mb={3} 
                fontSize={{ base: "sm", md: "md" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <FontAwesomeIcon icon={faUsers} />
                <Text ml={2} color="gray.600">
                  {formattedTotalFollowers} Followers
                </Text>
              </Flex>
              
              <Flex 
                align="center" 
                mb={3} 
                fontSize={{ base: "sm", md: "md" }}
                justifyContent={{ base: "center", md: "flex-start" }}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <Text ml={2} color="gray.600">
                  {creator.city || "Toronto"}, {creator.country || "Canada"}
                </Text>
              </Flex>
              
              <Text 
                color="gray.700" 
                fontSize={{ base: "sm", md: "md" }} 
                maxW="800px"
                textAlign={{ base: "center", md: "left" }}
              >
                {creator.bio}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      {/* Main content container - Expand width on mobile */}
      <div 
        className="creator-details-content"
        style={{ 
          width: "100%", 
          maxWidth: "100vw", 
          padding: "0", 
          overflow: "hidden",
          boxSizing: "border-box"
        }}
      >
        {/* Followers Stats Section */}
        <div className="creator-follower-stats">
          {renderSocialCards()}
        </div>

        {/* New Packages Section */}
        <section className="creator-section packages-section">
          <Heading as="h2" size="lg" mb={4}>Packages</Heading>
          <Text fontSize="sm" color="gray.600" mb={6}>
            Choose from a variety of content creation packages
          </Text>
          
          {/* Package Filter Tabs */}
          <Flex className="package-filter-tabs" mb={6}>
            <Button 
              className={`filter-tab ${packageFilter === 'All' ? 'active' : ''}`}
              onClick={() => setPackageFilter('All')}
              bg={packageFilter === 'All' ? 'pink.500' : 'gray.200'}
              color={packageFilter === 'All' ? 'white' : 'gray.700'}
              borderRadius="full"
              mr={2}
            >
              All
            </Button>
            <Button 
              className={`filter-tab ${packageFilter === 'Instagram' ? 'active' : ''}`}
              onClick={() => setPackageFilter('Instagram')}
              bg={packageFilter === 'Instagram' ? 'pink.500' : 'gray.200'}
              color={packageFilter === 'Instagram' ? 'white' : 'gray.700'}
              borderRadius="full"
              mr={2}
            >
              Instagram
            </Button>
            <Button 
              className={`filter-tab ${packageFilter === 'TikTok' ? 'active' : ''}`}
              onClick={() => setPackageFilter('TikTok')}
              bg={packageFilter === 'TikTok' ? 'pink.500' : 'gray.200'}
              color={packageFilter === 'TikTok' ? 'white' : 'gray.700'}
              borderRadius="full"
            >
              TikTok
            </Button>
          </Flex>
          
          {/* Packages Grid */}
          <Grid 
            templateColumns={{ 
              base: "repeat(1, 1fr)", 
              md: "repeat(2, 1fr)", 
              lg: "repeat(3, 1fr)" 
            }} 
            gap={{ base: 4, md: 6 }} 
            className="packages-grid"
            px={{ base: 3, md: 0 }}
          >
            {offers
              .filter(offer => packageFilter === 'All' || 
                (offer.platforms && offer.platforms.includes(packageFilter)))
              .map(offer => renderPackageCard(offer))}
          </Grid>
          
                {(!offers || offers.length === 0) && (
            <Box textAlign="center" py={10} color="gray.500">
              <Text>No offers available at the moment</Text>
            </Box>
                )}
        </section>

        {/* Portfolio Media Section */}
        <section className="creator-section packages-section">
          <Heading as="h2" size="lg" mb={4}>Portfolio</Heading>
          
          {portfolioMedia && portfolioMedia.length > 0 ? (
            <Grid 
              templateColumns={{ 
                base: "repeat(1, 1fr)", 
                sm: "repeat(2, 1fr)", 
                md: "repeat(3, 1fr)", 
                lg: "repeat(4, 1fr)" 
              }} 
              gap={4} 
              className="portfolio-media-grid"
              px={{ base: 3, md: 0 }}
            >
              {portfolioMedia.map((media, index) => {
                const isVideo = media.type === 'video' || media.url?.includes('.mp4') || media.url?.includes('video');
                
                return (
                  <Box 
                    key={media.id || media.public_id || index} 
                    borderRadius="lg" 
                    overflow="hidden"
                    position="relative"
                    boxShadow="sm"
                    height="0"
                    paddingBottom="100%" /* Creates a perfect square aspect ratio */
                    width="100%"
                  >
                    {isVideo ? (
                      <Box 
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                      >
                        {/* Thumbnail image that shows before video loads */}
                        <Image
                          src={media.thumbnail || `${media.url.split('upload/')[0]}upload/w_600,h_600,c_fill,q_auto,f_jpg/${media.url.split('upload/')[1]}`}
                          alt="Video thumbnail"
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          borderRadius="lg"
                          fallbackSrc="https://via.placeholder.com/600x600?text=Loading..."
                        />
                        <Box 
                          as="video"
                          src={media.url}
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          borderRadius="lg"
                          position="absolute"
                          top="0"
                          left="0"
                          playsInline
                          preload="metadata" // Removed controls
                          sx={{
                            '@media (max-width: 480px)': {
                              opacity: 0,
                              '&.loaded': {
                                opacity: 1
                              }
                            },
                            transition: 'opacity 0.3s ease'
                          }}
                          onClick={(e) => {
                            if (e.currentTarget.paused) {
                              e.currentTarget.play().catch(console.error);
                            } else {
                              e.currentTarget.pause();
                            }
                          }}
                          onLoadStart={(e) => {
                            if (window.innerWidth <= 480) {
                              e.currentTarget.style.opacity = '0';
                            }
                          }}
                          onCanPlay={(e) => {
                            if (window.innerWidth <= 480) {
                              e.currentTarget.style.opacity = '1';
                              e.currentTarget.classList.add('loaded');
                            }
                          }}
                        />
                        {/* Play button overlay */}
                        <Box
                          position="absolute"
                          bottom="10px"
                          left="10px"
                          bg="rgba(0, 0, 0, 0.5)"
                          borderRadius="full"
                          width="40px"
                          height="40px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer" // Added cursor pointer
                          onClick={(e) => {
                            const video = e.currentTarget.parentElement.querySelector('video');
                            if (video) {
                              if (video.paused) {
                                video.play().catch(console.error);
                              } else {
                                video.pause();
                              }
                            }
                          }}
                          sx={{
                            '@media (max-width: 480px)': {
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              bottom: 'auto'
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faPlay} color="white" size="sm" />
                        </Box>
                      </Box>
                    ) : (
                      <Image 
                        src={media.url} 
                        alt="Portfolio media" 
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%" 
                        height="100%" 
                        objectFit="cover"
                        borderRadius="lg"
                      />
                    )}
                  </Box>
                );
              })}
            </Grid>
          ) : (
            <Box textAlign="center" py={6} color="gray.500">
              <Text>No portfolio media available</Text>
            </Box>
          )}
        </section>

        {/* Social Media Presence section - Mobile optimized */}
        <section className="creator-section social-media-section">
          <Heading as="h2" size="lg" mb={4}>Social Media Presence</Heading>
          
          {/* Platform Selection Tabs - Improved for mobile */}
          <Box 
            width="100%"
            position="relative"
            mb={6}
          >
            <Flex 
              className="platform-tabs" 
              overflowX="auto"
              width="100%"
              pb={2}
              px={4}
              mx="auto"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  display: 'none'
                },
                WebkitOverflowScrolling: 'touch',
                msOverflowStyle: 'none',
                gap: '8px',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
            >
              <Flex
                gap={2}
                minWidth="min-content"
              >
                {Object.entries({
                  instagram: { name: 'Instagram', icon: faInstagram, color: '#E1306C' },
                  tiktok: { name: 'TikTok', icon: faTiktok, color: '#000000' },
                  youtube: { name: 'YouTube', icon: faYoutube, color: '#FF0000' },
                  twitter: { name: 'Twitter/X', icon: faTwitter, color: '#1DA1F2' },
                  twitch: { name: 'Twitch', icon: faTwitch, color: '#6441A4' }
                })
                .filter(([key, _]) => creator?.socialLinks?.[key])
                .map(([platform, info]) => (
                  <Button
                    key={platform}
                    onClick={() => setActivePlatform(platform)}
                    bg={activePlatform === platform ? info.color : 'gray.100'}
                    color={activePlatform === platform ? 'white' : 'gray.700'}
                    _hover={{ bg: activePlatform === platform ? info.color : 'gray.200' }}
                    px={4}
                    py={2}
                    height="36px"
                    fontSize={{ base: "sm", md: "md" }}
                    borderRadius="full"
                    flexShrink={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    whiteSpace="nowrap"
                    leftIcon={<FontAwesomeIcon icon={info.icon} />}
                  >
                    {info.name}
                  </Button>
                ))}
              </Flex>
            </Flex>
            {/* Add fade effect on the right side */}
            <Box
              position="absolute"
              right={0}
              top={0}
              bottom={2}
              width="40px"
              pointerEvents="none"
              background="linear-gradient(to left, white, transparent)"
              display={{ base: 'block', md: 'none' }}
            />
          </Box>
          
          {/* Platform Content Display - Further optimized for mobile */}
          <Box 
            className="embed-container" 
            height={{ base: "auto", md: "550px" }}
            minHeight={{ base: "300px", md: "550px" }}
            p={0}
            overflowY="auto"
            overflowX="hidden"
            position="relative"
          >
              {activePlatform === 'instagram' && creator?.socialLinks?.instagram && (
                <Box 
                  className="instagram-embed-container" 
                  height="100%" 
                  display="flex" 
                  justifyContent="center" 
                  alignItems="flex-start" 
                p={{ base: 0, md: 4 }} 
                  overflow="hidden"
                width="100%"
                >
                  <Box 
                    width="100%"
                  maxWidth={{ base: "100%", md: "540px" }} 
                    margin="0 auto"
                  transform={{ base: "scale(0.8)", md: "scale(1)" }}
                  transformOrigin="top center"
                  px={{ base: 2, md: 0 }}
                >
                  <div 
                    className="instagram-wrapper"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center"
                    }}
                    dangerouslySetInnerHTML={{ 
                      __html: `
                        <blockquote 
                          class="instagram-media" 
                          data-instgrm-permalink="https://www.instagram.com/${creator.socialLinks.instagram}/" 
                          data-instgrm-version="14"
                          style="background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 0 auto; max-width:100%; padding:0; width:99.375%; min-width:300px !important;"
                        ></blockquote>
                        <script async src="https://www.instagram.com/embed.js"></script>
                      `
                    }} 
                  />
                  </Box>
                </Box>
              )}
              
              {activePlatform === 'youtube' && creator?.socialLinks?.youtube && (
              <Box height="100%" width="100%" overflow="hidden" px={{ base: 2, md: 0 }}>
                <Box 
                  width="100%" 
                  height={{ base: "250px", md: "100%" }}
                  maxWidth="800px"
                  mx="auto"
                >
                                            <iframe
                  width="100%"
                  height="100%"
                    src={`https://www.youtube.com/embed/${getYoutubeVideoId(creator.socialLinks.youtube)}?playsinline=1`}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen
                                            ></iframe>
                </Box>
              </Box>
              )}
              
              {activePlatform === 'tiktok' && creator?.socialLinks?.tiktok && (
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="flex-start" 
                height="100%" 
                width="100%" 
                overflow="auto"
                p={{ base: 0, md: 4 }}
                transform={{ base: "scale(0.85)", md: "scale(1)" }}
                transformOrigin="top center"
              >
                    <TikTokEmbed username={getTikTokUsername(creator.socialLinks.tiktok)} />
                </Box>
              )}
              
              {activePlatform === 'twitter' && creator?.socialLinks?.twitter && (
              <Box 
                display="flex" 
                justifyContent="center" 
                height="100%" 
                p={{ base: 0, md: 4 }}
                overflow="auto"
              >
                <Box 
                  maxW={{ base: "100%", md: "550px" }} 
                  w="100%"
                  transform={{ base: "scale(0.85)", md: "scale(1)" }}
                  transformOrigin="top center"
                  px={{ base: 2, md: 0 }}
                >
                        <Timeline
                            dataSource={{
                                sourceType: 'profile',
                                screenName: getTwitterUsername(creator.socialLinks.twitter)
                            }}
                            options={{
                      height: '350',
                      chrome: "nofooter noheader",
                      width: "100%",
                      tweetLimit: 3
                            }}
                        />
                    </Box>
                </Box>
              )}
              
              {activePlatform === 'twitch' && creator?.socialLinks?.twitch && (
              <Box height="100%" width="100%" overflow="hidden" px={{ base: 2, md: 0 }}>
                <Box 
                  width="100%" 
                  height={{ base: "250px", md: "100%" }}
                  maxWidth="800px"
                  mx="auto"
                >
                                            <iframe
                  src={`https://player.twitch.tv/?channel=${getTwitchUsername(creator.socialLinks.twitch)}&parent=${window.location.hostname}&autoplay=false&muted=true`}
                  height="100%"
                                                width="100%"
                                                allowFullScreen
                                                frameBorder="0"
                                                title="Twitch Stream"
                                            ></iframe>
                </Box>
              </Box>
              )}
              
              {!activePlatform && (
              <Flex height="100%" justify="center" align="center" direction="column" p={{ base: 4, md: 8 }}>
                <Text fontSize={{ base: "md", md: "lg" }} mb={4} textAlign="center">Select a platform to view content</Text>
                <Flex wrap="wrap" justify="center" gap={{ base: 2, md: 4 }}>
                    {Object.entries({
                      instagram: { name: 'Instagram', icon: faInstagram, color: '#E1306C' },
                      tiktok: { name: 'TikTok', icon: faTiktok, color: '#000000' },
                      youtube: { name: 'YouTube', icon: faYoutube, color: '#FF0000' },
                      twitter: { name: 'Twitter/X', icon: faTwitter, color: '#1DA1F2' },
                      twitch: { name: 'Twitch', icon: faTwitch, color: '#6441A4' }
                    })
                    .filter(([key, _]) => creator?.socialLinks?.[key])
                    .map(([platform, info]) => (
                      <Button
                        key={platform}
                        onClick={() => setActivePlatform(platform)}
                        leftIcon={<FontAwesomeIcon icon={info.icon} />}
                        colorScheme={
                          platform === 'instagram' ? 'pink' :
                          platform === 'tiktok' ? 'blackAlpha' :
                          platform === 'youtube' ? 'red' :
                          platform === 'twitter' ? 'twitter' :
                          platform === 'twitch' ? 'purple' : 'blue'
                        }
                      size={{ base: "sm", md: "lg" }}
                      m={1}
                      >
                        {info.name}
                      </Button>
                    ))}
                  </Flex>
                </Flex>
              )}
          </Box>
        </section>

        {/* Audience Demographics Section - Compact Version */}
        {(creator.audienceInfo?.demographics?.ageRanges?.length > 0 || 
          creator.audienceInfo?.demographics?.genderDistribution || 
          creator.audienceInfo?.demographics?.locations?.length > 0 ||
          creator.audienceInfo?.interests?.length > 0 ||
          creator.audienceInfo?.engagementTopics?.length > 0 ||
          creator.audienceInfo?.metrics?.engagementRate ||
          creator.audienceInfo?.metrics?.contentPerformance) && (
          <section className="creator-section">
            <Heading as="h2" size="lg" mb={4} px={2}>Audience Demographics</Heading>
            
            <Grid 
              templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
              gap={4}
              px={2}
            >
              {/* Left Column */}
              <Box>
                {/* Age Distribution */}
                {creator.audienceInfo?.demographics?.ageRanges?.length > 0 && (
                  <Box 
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                    mb={4}
                  >
                    <Heading as="h3" size="sm" mb={2}>Age Distribution</Heading>
                    <Flex flexWrap="wrap" gap={2}>
                            {creator.audienceInfo.demographics.ageRanges.map((range, index) => (
                        <Badge 
                          key={index} 
                          colorScheme="blue" 
                          px={2} 
                          py={1} 
                          borderRadius="full"
                          fontSize="xs"
                        >
                          {range}
                        </Badge>
                      ))}
                    </Flex>
                  </Box>
                )}
                
                {/* Locations */}
                {creator.audienceInfo?.demographics?.locations?.length > 0 && (
                  <Box 
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <Heading as="h3" size="sm" mb={2}>Top Locations</Heading>
                    <Flex flexWrap="wrap" gap={2}>
                            {creator.audienceInfo.demographics.locations.map((location, index) => (
                        <Badge 
                          key={index} 
                          colorScheme="green" 
                          px={2} 
                          py={1} 
                          borderRadius="full"
                          fontSize="xs"
                        >
                          {location}
                        </Badge>
                      ))}
                    </Flex>
                  </Box>
                )}
              </Box>
              
              {/* Right Column */}
              <Box>
                {/* Engagement Metrics */}
                {(creator.audienceInfo?.metrics?.engagementRate || 
              creator.audienceInfo?.metrics?.contentPerformance) && (
                  <Box 
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                    mb={4}
                  >
                    <Heading as="h3" size="sm" mb={3}>Engagement Metrics</Heading>
                    <Grid templateColumns="1fr 1fr" gap={3}>
                      {creator.audienceInfo?.metrics?.engagementRate && (
                        <Box>
                          <Text fontSize="xs" color="gray.500">Engagement Rate</Text>
                          <Text fontWeight="bold" color="blue.600">
                            {creator.audienceInfo.metrics.engagementRate}
                          </Text>
                        </Box>
                      )}
                      {creator.audienceInfo?.metrics?.contentPerformance && (
                        <Box>
                          <Text fontSize="xs" color="gray.500">Content Performance</Text>
                          <Text fontWeight="bold" color="blue.600">
                            {creator.audienceInfo.metrics.contentPerformance}
                          </Text>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                )}
                
                {/* Interests & Topics - Combined */}
                {(creator.audienceInfo?.interests?.length > 0 || 
                  creator.audienceInfo?.engagementTopics?.length > 0) && (
                  <Box 
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <Heading as="h3" size="sm" mb={2}>Interests & Topics</Heading>
                    
                        {creator.audienceInfo?.interests?.length > 0 && (
                      <Box mb={3}>
                        <Text fontSize="xs" color="gray.500" mb={1}>Interests</Text>
                        <Flex flexWrap="wrap" gap={1}>
                                    {creator.audienceInfo.interests.map((interest, index) => (
                            <Badge 
                              key={index} 
                              colorScheme="purple" 
                              px={2} 
                              py={1} 
                              borderRadius="full"
                              fontSize="xs"
                            >
                              {interest}
                            </Badge>
                          ))}
                        </Flex>
                      </Box>
                        )}

                        {creator.audienceInfo?.engagementTopics?.length > 0 && (
                      <Box>
                        <Text fontSize="xs" color="gray.500" mb={1}>Topics</Text>
                        <Flex flexWrap="wrap" gap={1}>
                                    {creator.audienceInfo.engagementTopics.map((topic, index) => (
                            <Badge 
                              key={index} 
                              colorScheme="orange" 
                              px={2} 
                              py={1} 
                              borderRadius="full"
                              fontSize="xs"
                            >
                              {topic}
                            </Badge>
                          ))}
                        </Flex>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
        </section>
        )}

        {/* Brand Collaborations Section - Narrow and Compact */}
        {creator.collaborations && creator.collaborations.length > 0 && (
          <section className="creator-section">
            <Heading as="h2" size="lg" mb={4} px={4}>Brand Collaborations</Heading>
            <Box px={4}>
              <SimpleGrid 
                columns={{ base: 1, md: 2 }}
                spacing={4}
                maxWidth="900px"
                mx={0}  // Changed from mx="auto" to mx={0}
                ml={4}  // Added left margin for alignment with heading
              >
                {creator.collaborations.map((collab, index) => (
                  <Box 
                    key={index} 
                    className="collaboration-card"
                    p={5}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg="white"
                    boxShadow="sm"
                    maxWidth={{ base: "100%", md: "420px" }}  // Added max-width for cards
                  >
                    {/* Brand Name and Metrics Row */}
                    <Flex justify="space-between" align="center" mb={3}>
                      <Heading as="h3" size="md">
                        {collab.brandName}
                      </Heading>
                      {collab.metrics && (
                        <Badge 
                          colorScheme="blue" 
                          borderRadius="full"
                          px={2}
                          py={1}
                          fontSize="sm"
                        >
                          {collab.metrics}
                        </Badge>
                      )}
                    </Flex>
                    
                    {/* Description - increased line limit */}
                    <Text 
                      fontSize="sm" 
                      color="gray.600" 
                      mb={4}
                      noOfLines={3}
                      lineHeight="1.6"
                    >
                      {collab.description}
                    </Text>
                    
                    {/* Additional Details */}
                    <Flex wrap="wrap" gap={2}>
                      {collab.tags && collab.tags.map((tag, idx) => (
                        <Badge 
                          key={idx}
                          colorScheme="gray"
                          borderRadius="full"
                          px={2}
                          py={1}
                          fontSize="xs"
                        >
                          {tag}
                        </Badge>
                      ))}
                      {collab.date && (
                        <Text 
                          fontSize="sm" 
                          color="gray.500"
                          ml="auto"
                        >
                          {new Date(collab.date).toLocaleDateString(undefined, {
                            month: 'short', 
                            year: 'numeric'
                          })}
                        </Text>
                      )}
                    </Flex>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          </section>
        )}

        {/* Previous Brand Collaborations - Narrow and Compact */}
        {creator.brandCollaborations && creator.brandCollaborations.length > 0 ? (
          <section className="creator-section">
            <Heading as="h2" size="lg" mb={2} px={4}>Previous Collaborations</Heading>
            <Flex 
              px={4}
              flexWrap="wrap"
              gap={1}
              maxWidth={{ base: "100%", md: "600px" }}
              mx="auto"
            >
              {creator.brandCollaborations.map((collab, index) => (
                <Badge 
                  key={index} 
                  colorScheme="blue" 
                  px={2} 
                  py={0.5} 
                  borderRadius="full"
                  fontSize="2xs"
                >
                  {collab}
                </Badge>
              ))}
            </Flex>
          </section>
        ) : null}

        {/* Updated Modal for Offer Selection */}
        {selectedOffer && (
          <div className="modal">
            <div className="modal-content">
              <h2>Add to Cart</h2>
              <div className="selected-offer-details">
                <h3>{selectedOffer.title}</h3>
                <p className="offer-price">${selectedOffer.price}</p>
                <div className="offer-summary">
                  <p><strong>Type:</strong> {selectedOffer.type}</p>
                  <p><strong>Delivery Time:</strong> {selectedOffer.deliveryTime} day(s)</p>
                  <p><strong>Max Revisions:</strong> {selectedOffer.maxRevisions}</p>
                  <p><strong>Requirements:</strong> {selectedOffer.requirements}</p>
                </div>
              </div>
              <div className="modal-actions">
                <button 
                  className="confirm-button"
                  onClick={() => handleAddToCart(selectedOffer)}
                >
                  Add to Cart
                </button>
                <button 
                  className="cancel-button"
                  onClick={() => setSelectedOffer(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Add the claim modal */}
      <ClaimModal />
    </div>
  );
};

export default CreatorDetailsPage; 