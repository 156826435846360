import React, { createContext, useContext } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { API_BASE_URL } from '../api/api';

const TrackingContext = createContext();

// Firebase Dynamic Links configuration
const DYNAMIC_LINKS_DOMAIN = 'hypeo.page.link';
const WEB_API_KEY = 'YOUR_WEB_API_KEY';

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};

export const TrackingProvider = ({ children }) => {
  const createDynamicLink = async (destinationUrl, username, shortCampaignId) => {
    try {
      // Add affiliate parameters directly to the destination URL
      const affiliateUrl = `${destinationUrl}${destinationUrl.includes('?') ? '&' : '?'}u=${username}&c=${shortCampaignId}`;
      
      const response = await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${WEB_API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: `https://${DYNAMIC_LINKS_DOMAIN}`,
            link: affiliateUrl,
            socialMetaTagInfo: {
              socialTitle: "Hypeo Affiliate Link",
              socialDescription: "Check out this offer"
            }
          },
          suffix: {
            option: "SHORT"
          }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create dynamic link');
      }

      const data = await response.json();
      return data.shortLink;
    } catch (error) {
      console.error('Error creating dynamic link:', error);
      return null;
    }
  };

  const generateAffiliateLink = async (campaignId, userId, destinationUrl) => {
    try {
      if (!campaignId || !userId || !destinationUrl) {
        console.error('Missing required parameters:', { campaignId, userId, destinationUrl });
        return '';
      }

      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        console.error('User not found:', userId);
        return '';
      }

      const username = userDoc.data().username;
      if (!username) {
        console.error('Username not found for user:', userId);
        return '';
      }

      // Generate shorter campaign ID (first 6 characters)
      const shortCampaignId = campaignId.slice(0, 6);

      // Create short link directly to destination with affiliate parameters
      const shortUrl = await createDynamicLink(destinationUrl, username, shortCampaignId);
      console.log('Generated short URL:', shortUrl);

      // Track the click with full campaign ID
      await fetch(`${API_BASE_URL}/tracking/click`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          userId: userId,
          cid: campaignId, // Store full campaign ID in our database
          shortCid: shortCampaignId, // Also store short version
          url: destinationUrl,
          shortUrl: shortUrl
        })
      });

      // Fallback to regular URL if dynamic link creation fails
      return shortUrl || `${destinationUrl}${destinationUrl.includes('?') ? '&' : '?'}u=${username}&c=${shortCampaignId}`;
    } catch (error) {
      console.error('Error generating affiliate link:', error);
      return '';
    }
  };

  const value = {
    generateAffiliateLink
  };

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
};

export { TrackingContext };
