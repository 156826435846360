import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, Timestamp, addDoc, doc, updateDoc, arrayUnion, increment, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';  // Make sure this import is correct
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './BrandBilling.css';
import { serverTimestamp } from 'firebase/firestore';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
  Stack,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Initialize Stripe with explicit key
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;


// Make sure the key exists before initializing
if (!STRIPE_PUBLISHABLE_KEY) {
    console.error('Missing Stripe Publishable Key');
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// Add PaymentMethodForm component
const PaymentMethodForm = ({ onSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const auth = getAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
        setError(null);

        if (!stripe || !elements) {
            return;
        }

        try {
            const token = await auth.currentUser.getIdToken(true);
            const setupIntent = await fetch('http://localhost:5001/api/brand/billing/create-setup-intent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const { clientSecret } = await setupIntent.json();

            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: auth.currentUser.email,
                    }
                }
            });

            if (result.error) {
                setError(result.error.message);
                console.error('Stripe setup error:', result.error);
            } else {
                const saveResult = await fetch('http://localhost:5001/api/brand/billing/save-payment-method', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        paymentMethodId: result.setupIntent.payment_method,
                        type: 'card'
                    })
                });

                if (!saveResult.ok) {
                    throw new Error('Failed to save payment method');
                }

                const savedPaymentMethod = await saveResult.json();
                onSuccess(savedPaymentMethod);
            }
        } catch (err) {
            console.error('Payment method error:', err);
            setError('Failed to set up payment method. Please try again.');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="payment-form">
            <div className="form-row">
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>
            {error && <div className="error-message">{error}</div>}
            <button 
                type="submit" 
                disabled={!stripe || processing}
                className="submit-button"
            >
                {processing ? 'Processing...' : 'Add Payment Method'}
            </button>
        </form>
    );
};

// Add PaymentMethodModal component
const AddPaymentMethodModal = ({ isOpen, onClose, onSuccess }) => {
    if (!isOpen) return null;  // Don't render anything if not open

    return (
        <div className="modal">
            <div className="modal-content">
                <button 
                    onClick={onClose}
                    className="modal-close-btn"
                    aria-label="Close modal"
                >
                    ×
                </button>
                <h3>Add Payment Method</h3>
                <Elements stripe={stripePromise}>
                    <PaymentMethodForm onSuccess={(method) => {
                        onSuccess(method);
                        onClose();
                    }} />
                </Elements>
            </div>
        </div>
    );
};

// Add this function to get brandId from campaign
const getBrandIdFromCampaign = async (campaignId) => {
    console.log('🔍 Looking up campaign:', campaignId);
    try {
        const campaignRef = doc(db, 'campaigns', campaignId);
        const campaignDoc = await getDoc(campaignRef);
        
        if (campaignDoc.exists()) {
            const campaignData = campaignDoc.data();
            console.log('✅ Found campaign:', campaignData);
            return campaignData.brandId;
        } else {
            console.error('❌ Campaign not found:', campaignId);
            return null;
        }
    } catch (error) {
        console.error('❌ Error getting campaign:', error);
        return null;
    }
};

const BrandBilling = () => {
    const [billings, setBillings] = useState([]);
    const [partnerships, setPartnerships] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeSection, setActiveSection] = useState('affiliate');
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const auth = getAuth();
    const [selectedTab, setSelectedTab] = useState(0);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    // Fetch payment methods
    useEffect(() => {
        const fetchPaymentMethods = async () => {
            if (!auth.currentUser) return;
            
            setIsLoading(true);
            setError(null);
            
            try {
                const paymentMethodsRef = collection(db, 'paymentMethods');
                const q = query(
                    paymentMethodsRef,
                    where('brandId', '==', auth.currentUser.uid)
                );
                
                const snapshot = await getDocs(q);
                const methods = [];
                snapshot.forEach(doc => {
                    methods.push({ id: doc.id, ...doc.data() });
                });
                
                setPaymentMethods(methods);
            } catch (err) {
                console.error('Error fetching payment methods:', err);
                setError('Failed to load payment methods. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (auth.currentUser && activeSection === 'payment') {
            fetchPaymentMethods();
        }
    }, [auth.currentUser, activeSection]);

    // Fetch partnerships
    useEffect(() => {
        const fetchPartnerships = async () => {
            if (!auth.currentUser) return;
            
            setIsLoading(true);
            setError(null);
            
            try {
                const partnershipsRef = collection(db, 'partnerships');
                const q = query(
                    partnershipsRef,
                    where('brandId', '==', auth.currentUser.uid),
                    where('status', '==', 'active')
                );
                
                const snapshot = await getDocs(q);
                const partnershipData = [];
                snapshot.forEach(doc => {
                    partnershipData.push({ id: doc.id, ...doc.data() });
                });
                
                setPartnerships(partnershipData);
            } catch (err) {
                console.error('Error fetching partnerships:', err);
                setError('Failed to load partnerships. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (auth.currentUser && activeSection === 'partnerships') {
            fetchPartnerships();
        }
    }, [auth.currentUser, activeSection]);

    // Update billing fetch
    useEffect(() => {
        const fetchBillingData = async () => {
            if (!auth.currentUser) return;
            
            setIsLoading(true);
            setError(null);
            
            try {
                const currentDate = new Date();
                const currentPeriod = `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`;

                const billingsRef = collection(db, 'billings');
                const q = query(
                    billingsRef,
                    where('brandId', '==', auth.currentUser.uid),
                    where('period', '==', currentPeriod)
                );

                const querySnapshot = await getDocs(q);
                const billingsData = [];

                querySnapshot.forEach(doc => {
                    const billing = {
                        id: doc.id,
                        ...doc.data()
                    };
                    
                    if (billing.creatorPayments) {
                        billing.creatorPayments.forEach(payment => {
                            billingsData.push({
                                billingId: doc.id,
                                ...payment,
                                status: billing.status || 'pending'
                            });
                        });
                    }
                });

                setBillings(billingsData);
            } catch (err) {
                console.error('Error fetching billing data:', err);
                setError('Failed to load billing data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (auth.currentUser && activeSection === 'affiliate') {
            fetchBillingData();
        }
    }, [auth.currentUser, activeSection]);

    const handlePaymentMethodSuccess = () => {
        // Refresh payment methods after adding new one
        const fetchPaymentMethods = async () => {
            if (!auth.currentUser) return;
            
            try {
                const paymentMethodsRef = collection(db, 'paymentMethods');
                const q = query(
                    paymentMethodsRef,
                    where('brandId', '==', auth.currentUser.uid)
                );
                
                const snapshot = await getDocs(q);
                const methods = [];
                snapshot.forEach(doc => {
                    methods.push({ id: doc.id, ...doc.data() });
                });
                
                setPaymentMethods(methods);
            } catch (err) {
                console.error('Error fetching payment methods:', err);
            }
        };

        fetchPaymentMethods();
    };

    const renderAffiliateBilling = () => {
        const safeBillings = billings || [];
        
        // Calculate dates for billing period
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const daysInMonth = lastDayOfMonth.getDate();
        const currentDay = today.getDate();
        const nextPayoutDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);

        // Calculate progress percentage
        const progressPercentage = Math.min(Math.max((currentDay / daysInMonth) * 100, 0), 100);

        // Calculate totals
        const monthlyTotal = safeBillings.reduce((sum, billing) => 
            sum + (billing.amount || 0), 0);

        const pendingTotal = safeBillings
            .filter(billing => billing.status === 'pending')
            .reduce((sum, billing) => sum + (billing.amount || 0), 0);

        const approvedTotal = safeBillings
            .filter(billing => billing.status === 'approved')
            .reduce((sum, billing) => sum + (billing.amount || 0), 0);

        return (
            <div className="affiliate-section">
                <div className="affiliate-billing">
                    <div className="billing-overview">
                        {/* Billing Cycle Information */}
                        <div className="billing-cycle-info">
                            <div className="cycle-header">
                                <h4>Current Billing Period</h4>
                                <span className="cycle-dates">
                                    {firstDayOfMonth.toLocaleDateString()} - {lastDayOfMonth.toLocaleDateString()}
                                </span>
                            </div>
                            
                            <div className="cycle-progress">
                                <div className="progress-bar-background" />
                                <div 
                                    className="progress-bar-fill"
                                    style={{ 
                                        width: `${progressPercentage}%`,
                                        backgroundColor: '#2563eb'
                                    }}
                                />
                                <div 
                                    className="progress-marker" 
                                    style={{ left: `${progressPercentage}%` }}
                                >
                                    <div className="marker-line"></div>
                                    <div className="marker-label">Today</div>
                                </div>
                            </div>
                            <div className="progress-labels">
                                <span>{firstDayOfMonth.toLocaleDateString()}</span>
                                <span>{lastDayOfMonth.toLocaleDateString()}</span>
                            </div>
                            <div className="cycle-details">
                                <div className="cycle-stat">
                                    <span className="label">Days Remaining</span>
                                    <span className="value">{daysInMonth - currentDay}</span>
                                </div>
                                <div className="cycle-stat">
                                    <span className="label">Next Payout Date</span>
                                    <span className="value">{nextPayoutDate.toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>

                        {/* Updated Summary Cards */}
                        <div className="summary-grid">
                            <div className="summary-card primary">
                                <div className="summary-details">
                                    <h4>Current Period Total</h4>
                                    <p className="summary-number">${monthlyTotal.toFixed(2)}</p>
                                    <p className="summary-subtext">This billing period</p>
                                </div>
                            </div>
                            <div className="summary-card approved">
                                <div className="summary-details">
                                    <h4>Reviewed Billing</h4>
                                    <p className="summary-number">${approvedTotal.toFixed(2)}</p>
                                    <p className="summary-subtext">Will be billed on {nextPayoutDate.toLocaleDateString()}</p>
                                </div>
                            </div>
                            <div className="summary-card">
                                <div className="summary-details">
                                    <h4>Pending Verification</h4>
                                    <p className="summary-number">${pendingTotal.toFixed(2)}</p>
                                    <p className="summary-subtext">From {safeBillings.filter(c => c.status === 'pending').length} conversions</p>
                                </div>
                            </div>
                            <div className="summary-card">
                                <div className="summary-details">
                                    <h4>Period Conversions</h4>
                                    <p className="summary-number">{safeBillings.length}</p>
                                    <p className="summary-subtext">This billing period</p>
                                </div>
                            </div>
                        </div>

                        {/* Recent Activity Table */}
                        <div className="recent-activity">
                            <div className="section-header">
                                <h4>Recent Activity</h4>
                            </div>
                            <div className="activity-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Creator</th>
                                            <th>Campaign</th>
                                            <th>Order Amount</th>
                                            <th>Payout</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {safeBillings
                                            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                            .map(billing => (
                                                <tr key={`${billing.billingId}-${billing.conversionId}`}>
                                                    <td>{new Date(billing.timestamp).toLocaleDateString()}</td>
                                                    <td>{billing.creatorName}</td>
                                                    <td>{billing.programName || 'Unknown Campaign'}</td>
                                                    <td>${billing.orderAmount?.toFixed(2) || '0.00'}</td>
                                                    <td>${billing.amount?.toFixed(2) || '0.00'}</td>
                                                    <td>
                                                        <div className="status-container">
                                                            <span className={`status-badge ${billing.status}`}>
                                                                {billing.status}
                                                            </span>
                                                            {billing.status === 'rejected' && billing.rejectionReason && (
                                                                <div className="tooltip">
                                                                    ⓘ
                                                                    <span className="tooltip-text">
                                                                        {billing.rejectionReason}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderPartnerships = () => {
        return (
            <div className="partnerships-section">
                <div className="section-header">
                    <h3>Partnership Billing</h3>
                </div>

                {partnerships.length === 0 ? (
                    <div className="no-partnerships">
                        <p>No active partnerships found.</p>
                    </div>
                ) : (
                    <div className="partnerships-list">
                        {partnerships.map(partnership => (
                            <div key={partnership.id} className="partnership-card">
                                <div className="partnership-header">
                                    <div className="partnership-info">
                                        <h4>{partnership.creatorName}</h4>
                                        <span className="partnership-type">
                                            {partnership.type === 'revenue_share' ? 'Revenue Share' : 'Fixed Rate'}
                                        </span>
                                    </div>
                                    <div className="partnership-status">
                                        <span className={`status-badge ${partnership.status}`}>
                                            {partnership.status}
                                        </span>
                                    </div>
                                </div>

                                <div className="partnership-details">
                                    <div className="detail-row">
                                        <span className="label">Agreement Terms:</span>
                                        <span className="value">
                                            {partnership.type === 'revenue_share' 
                                                ? `${partnership.revenueShare}% Revenue Share`
                                                : `$${partnership.fixedRate} per ${partnership.billingCycle}`
                                            }
                                        </span>
                                    </div>
                                    <div className="detail-row">
                                        <span className="label">Start Date:</span>
                                        <span className="value">
                                            {new Date(partnership.startDate).toLocaleDateString()}
                                        </span>
                                    </div>
                                    {partnership.endDate && (
                                        <div className="detail-row">
                                            <span className="label">End Date:</span>
                                            <span className="value">
                                                {new Date(partnership.endDate).toLocaleDateString()}
                                            </span>
                                        </div>
                                    )}
                                    <div className="detail-row">
                                        <span className="label">Next Payment:</span>
                                        <span className="value">
                                            {partnership.nextPaymentDate 
                                                ? new Date(partnership.nextPaymentDate).toLocaleDateString()
                                                : 'Not scheduled'
                                            }
                                        </span>
                                    </div>
                                </div>

                                <div className="partnership-stats">
                                    <div className="stat-card">
                                        <span className="stat-label">Total Paid</span>
                                        <span className="stat-value">
                                            ${partnership.totalPaid?.toFixed(2) || '0.00'}
                                        </span>
                                    </div>
                                    <div className="stat-card">
                                        <span className="stat-label">Current Period</span>
                                        <span className="stat-value">
                                            ${partnership.currentPeriodAmount?.toFixed(2) || '0.00'}
                                        </span>
                                    </div>
                                    <div className="stat-card">
                                        <span className="stat-label">Last Payment</span>
                                        <span className="stat-value">
                                            ${partnership.lastPaymentAmount?.toFixed(2) || '0.00'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const renderPaymentMethods = () => {
        return (
            <div className="payment-methods-section">
                <div className="section-header">
                    <h3>Payment Methods</h3>
                    <button 
                        className="add-payment-button"
                        onClick={() => setShowAddPaymentModal(true)}
                    >
                        Add Payment Method
                    </button>
                </div>

                <div className="payment-methods-list">
                    {paymentMethods.length === 0 ? (
                        <div className="no-payment-methods">
                            <p>No payment methods added yet.</p>
                            <button 
                                className="add-first-payment-button"
                                onClick={() => setShowAddPaymentModal(true)}
                            >
                                Add Your First Payment Method
                            </button>
                        </div>
                    ) : (
                        <div className="payment-methods-grid">
                            {paymentMethods.map(method => (
                                <div key={method.id} className="payment-method-card">
                                    <div className="card-header">
                                        <h4>{method.type === 'card' ? 'Credit Card' : 'Bank Account'}</h4>
                                        {method.isDefault && (
                                            <span className="default-badge">Default</span>
                                        )}
                                    </div>
                                    <div className="card-body">
                                        {method.type === 'card' ? (
                                            <>
                                                <p>•••• •••• •••• {method.last4}</p>
                                                <p>Expires {method.expMonth}/{method.expYear}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p>{method.bankName}</p>
                                                <p>•••• {method.last4}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Box>
            <Stack spacing={8}>
                <Box>
                    <Heading size="lg" mb={6}>Billing & Payments</Heading>
                    
                    <Box 
                        bg={bgColor} 
                        p={6} 
                        borderRadius="lg" 
                        border="1px" 
                        borderColor={borderColor}
                    >
                        <Text>Your billing information and payment history will appear here.</Text>
                    </Box>
                </Box>
            </Stack>

            <Tabs 
                index={selectedTab} 
                onChange={setSelectedTab}
                orientation="horizontal"
                variant="enclosed"
                isLazy
            >
                <Stack 
                    direction={{ base: 'column', md: 'row' }}
                    spacing={0}
                    mb={4}
                >
                    <TabList
                        display="flex"
                        flexDirection={{ base: 'row', md: 'row' }}
                        width="100%"
                        overflowX="auto"
                        overflowY="hidden"
                        sx={{
                            scrollbarWidth: 'none',
                            '::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                        borderBottom="1px solid"
                        borderColor="gray.200"
                    >
                        <Tab
                            flex={{ base: '1', md: '0 1 auto' }}
                            _selected={{ 
                                color: 'blue.500', 
                                borderColor: 'blue.500',
                                borderBottom: '2px solid',
                                marginBottom: '-1px'
                            }}
                            py={3}
                            px={4}
                            fontSize={{ base: 'sm', md: 'md' }}
                            whiteSpace="nowrap"
                            minWidth="auto"
                        >
                            Affiliate Billing
                        </Tab>
                        <Tab
                            flex={{ base: '1', md: '0 1 auto' }}
                            _selected={{ 
                                color: 'blue.500', 
                                borderColor: 'blue.500',
                                borderBottom: '2px solid',
                                marginBottom: '-1px'
                            }}
                            py={3}
                            px={4}
                            fontSize={{ base: 'sm', md: 'md' }}
                            whiteSpace="nowrap"
                            minWidth="auto"
                        >
                            Partnership Billing
                        </Tab>
                        <Tab
                            flex={{ base: '1', md: '0 1 auto' }}
                            _selected={{ 
                                color: 'blue.500', 
                                borderColor: 'blue.500',
                                borderBottom: '2px solid',
                                marginBottom: '-1px'
                            }}
                            py={3}
                            px={4}
                            fontSize={{ base: 'sm', md: 'md' }}
                            whiteSpace="nowrap"
                            minWidth="auto"
                        >
                            Payment Methods
                        </Tab>
                    </TabList>
                </Stack>

                <TabPanels>
                    <TabPanel p={4} overflowX="auto">
                        {/* Original Affiliate Billing Content */}
                        {renderAffiliateBilling()}
                    </TabPanel>

                    <TabPanel p={4} overflowX="auto">
                        {/* Original Partnership Billing Content */}
                        {renderPartnerships()}
                    </TabPanel>

                    <TabPanel p={4} overflowX="auto">
                        {/* Original Payment Methods Content */}
                        {renderPaymentMethods()}
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <AddPaymentMethodModal
                isOpen={showAddPaymentModal}
                onClose={() => setShowAddPaymentModal(false)}
                onSuccess={handlePaymentMethodSuccess}
            />
        </Box>
    );
};

export default BrandBilling; 