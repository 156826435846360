import { getAuth } from 'firebase/auth';
import { API_BASE_URL } from '../api/api';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';

class CreatorOfferService {
    static async getToken() {
        const auth = getAuth();
        const user = auth.currentUser;
        
        console.log('Current user:', user?.uid); // Debug log
        
        if (!user) {
            throw new Error('No authenticated user found');
        }
        
        try {
            const token = await user.getIdToken();
            console.log('Token obtained:', token.substring(0, 20) + '...'); // Debug log
            return token;
        } catch (error) {
            console.error('Error getting token:', error);
            throw new Error('Failed to get authentication token');
        }
    }

    static async getOffers() {
        try {
            const auth = getAuth();
            if (!auth.currentUser) {
                throw new Error('No authenticated user found');
            }

            // Query Firestore for offers created by the current user
            const offersCollection = collection(db, 'creatorOffers');
            const q = query(
                offersCollection,
                where('creatorId', '==', auth.currentUser.uid)
            );

            const querySnapshot = await getDocs(q);
            const offers = [];

            querySnapshot.forEach((doc) => {
                offers.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            console.log('Fetched offers:', offers); // Debug log
            return offers;

        } catch (error) {
            console.error('Error fetching offers:', error);
            throw error;
        }
    }

    static async createOffer(offerData) {
        try {
            const offersCollection = collection(db, 'creatorOffers');
            const docRef = await addDoc(offersCollection, {
                ...offerData,
                status: 'active'
            });
            return docRef.id;
        } catch (error) {
            console.error('Error creating offer:', error);
            throw error;
        }
    }

    static async getOffer(offerId) {
        try {
            const offerRef = doc(db, 'creatorOffers', offerId);
            const offerDoc = await getDoc(offerRef);
            
            if (offerDoc.exists()) {
                return {
                    id: offerDoc.id,
                    ...offerDoc.data()
                };
            } else {
                throw new Error('Offer not found');
            }
        } catch (error) {
            console.error('Error fetching offer:', error);
            throw error;
        }
    }

    static async updateOffer(offerId, offerData) {
        try {
            const offerRef = doc(db, 'creatorOffers', offerId);
            await updateDoc(offerRef, {
                ...offerData,
                updatedAt: new Date().toISOString()
            });
            return offerId;
        } catch (error) {
            console.error('Error updating offer:', error);
            throw error;
        }
    }

    static async deleteOffer(offerId) {
        try {
            const offerRef = doc(db, 'creatorOffers', offerId);
            await deleteDoc(offerRef);
            return true;
        } catch (error) {
            throw new Error('Failed to delete offer: ' + error.message);
        }
    }

    static async getCreatorOffers(creatorId) {
        try {
            const offersCollection = collection(db, 'creatorOffers');
            const q = query(
                offersCollection,
                where('creatorId', '==', creatorId),
                where('status', '==', 'active')
            );
            
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error('Error fetching creator offers:', error);
            throw error;
        }
    }
}

// Export the class directly instead of an instance
export default CreatorOfferService; 