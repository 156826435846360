import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../../firebase';
import { 
    createUserWithEmailAndPassword, 
    signInWithPopup,
    sendEmailVerification 
} from 'firebase/auth';
import './styles/register.css';
import './Login.css';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const BrandRegister = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [brandName, setBrandName] = useState('');
    const [website, setWebsite] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [verificationSent, setVerificationSent] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        console.log('Registration form submitted');

        try {
            // Validation checks
            if (!email || !password || !brandName || !website) {
                setError('All fields are required.');
                return;
            }

            if (password.length < 6) {
                setError('Password must be at least 6 characters.');
                return;
            }

            let cleanWebsite = website;
            if (!website.match(/^[a-zA-Z]+:\/\//)) {
                cleanWebsite = 'http://' + website;
            }

            // Create auth user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Force reload user to ensure fresh state
            await user.reload();

            // Send verification email
            await sendEmailVerification(user);
            setVerificationSent(true);

            // Create user document in Firestore with explicit verification check
            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: email,
                brandName: brandName,
                website: cleanWebsite,
                role: 'brand',
                emailVerified: user.emailVerified, // Get fresh verification status
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });

            console.log('Brand registration complete, verification email sent');
            console.log('Initial verification status:', user.emailVerified);
            
            navigate('/verify-email', { 
                state: { 
                    email: email,
                    message: 'Please verify your email to complete brand registration.' 
                } 
            });

        } catch (error) {
            console.error('Registration failed:', error);
            setError('Registration failed: ' + error.message);
        }
    };

    const handleGoogleSignup = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Force reload user to ensure fresh state
            await user.reload();

            // Check if user already exists
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role !== 'brand') {
                    setError('This account is already registered as a ' + userData.role);
                    return;
                }
            }

            // Create/Update user document in Firestore with fresh verification status
            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: user.email,
                brandName: user.displayName || '',
                role: 'brand',
                emailVerified: user.emailVerified, // Get fresh verification status
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });

            console.log('Brand registered successfully with Google');
            console.log('Initial verification status:', user.emailVerified);
            
            if (!user.emailVerified) {
                await sendEmailVerification(user);
                navigate('/verify-email', { 
                    state: { 
                        email: user.email,
                        message: 'Please verify your email to complete brand registration.' 
                    } 
                });
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error('Google signup failed:', error);
            setError('Google signup failed: ' + error.message);
        }
    };

    return (
        <div className="form-page">
            <div className="form-container">
                <h2>Register as Brand</h2>
                {error && <div className="error-message">{error}</div>}
                {verificationSent && (
                    <div className="success-message">
                        Verification email sent! Please check your inbox.
                    </div>
                )}
                
                <form onSubmit={handleRegister}>
                    <input
                        type="text"
                        placeholder="Brand Name"
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Register</button>
                </form>

                <div className="login-divider">
                    <span>or</span>
                </div>

                <button 
                    type="button" 
                    onClick={handleGoogleSignup}
                    className="google-signin-button"
                >
                    <img 
                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
                        alt="Google" 
                        className="google-icon"
                    />
                    Sign up with Google
                </button>
            </div>
        </div>
    );
};

export default BrandRegister;
