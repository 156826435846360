import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import config from '../../config/config';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Button,
  Alert,
  AlertIcon,
  Code,
  useClipboard,
  Divider
} from '@chakra-ui/react';
import './BrandCampaignPage.css';
import { ArrowBackIcon } from '@chakra-ui/icons';

const TRACKING_DOMAIN = process.env.NODE_ENV === 'production' 
  ? 'https://us-central1-hype-9b87b.cloudfunctions.net/api'
  : 'http://localhost:5001/api';

const BrandCampaignPage = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({
    clicks: 0,
    conversions: 0,
    revenue: 0,
    conversionRate: 0
  });

  const headerScript = `<!-- Hype Tracking Header Script -->
<script>
  (function() {
    const config = {
      brandId: '${currentUser?.uid}',
      campaignId: '${id}',
      trackingDomain: '${config.api.baseURL}'
    };

    // Link click tracking
    document.addEventListener('DOMContentLoaded', function() {
      var links = document.getElementsByTagName('a');
      for(var i = 0; i < links.length; i++) {
        var link = links[i];
        // Track all links
        link.href = createTrackingUrl(link.href);
        console.log('Original URL:', link.href);
        console.log('Tracking URL created:', link.href);
      }
    });

    function createTrackingUrl(originalUrl) {
      return \`\${config.trackingDomain}/tracking/redirect?\` + 
             \`aid=\${localStorage.getItem('hype_aid') || ''}&\` +
             \`cid=\${config.campaignId}&\` +
             \`url=\${encodeURIComponent(originalUrl)}\`;
    }
  })();
</script>`;

  const conversionScript = `<!-- Hype Conversion Tracking Script -->
<script>
    window.trackConversion = async function(orderId, amount) {
        try {
            const response = await fetch('${TRACKING_DOMAIN}/tracking/convert', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: '${currentUser?.uid}',
                    campaignId: '${id}',
                    orderId: orderId,
                    amount: parseFloat(amount)
                })
            });

            if (!response.ok) {
                throw new Error(\`HTTP error! status: \${response.status}\`);
            }
            
            const data = await response.json();
            console.log('Conversion tracked successfully:', data);
            return true;
        } catch (error) {
            console.error('Conversion tracking error:', error);
            return false;
        }
    }
</script>`;

  const { hasCopied: hasHeaderCopied, onCopy: onHeaderCopy } = useClipboard(headerScript);
  const { hasCopied: hasConversionCopied, onCopy: onConversionCopy } = useClipboard(conversionScript);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const campaignDoc = await getDoc(doc(db, 'campaigns', id));
        if (campaignDoc.exists()) {
          setCampaign(campaignDoc.data());
        } else {
          setError('Campaign not found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser && id) {
      fetchCampaign();
    }
  }, [currentUser, id]);

  if (loading) return <Box className="loading-state">Loading...</Box>;
  if (error) return <Box className="error-state">Error: {error}</Box>;

  return (
    <div className="brand-campaign-page">
      <Container maxW="container.lg">
        <div className="brand-campaign-wrapper">
          <div className="brand-campaign-topbar">
            <div className="brand-campaign-nav">
              <Button
                leftIcon={<ArrowBackIcon />}
                variant="outline"
                onClick={() => navigate('/brand/dashboard')}
              >
                Back to Dashboard
              </Button>
              <Button
                variant="outline"
                onClick={() => navigate(`/campaigns/${id}/edit`)}
              >
                Edit Campaign
              </Button>
            </div>
          </div>

          <Alert status="info" mb={6}>
            <AlertIcon />
            <Box>
              <Text fontWeight="bold">Important: Setup Your Tracking Tags First</Text>
              <Text>
                To properly track conversions and campaign performance, you must add the tracking scripts below to your website. Without these tags, campaign metrics won't be recorded.
              </Text>
            </Box>
          </Alert>

          <div className="brand-campaign-header">
            <Heading as="h1">{campaign?.programName}</Heading>
            <Text>{campaign?.description}</Text>
          </div>

          <div className="brand-campaign-overview">
            <div className="brand-campaign-grid">
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Campaign Headline</div>
                <div className="brand-campaign-value">{campaign?.campaignHeadline}</div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Goal</div>
                <div className="brand-campaign-value">{campaign?.campaignGoal}</div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Duration</div>
                <div className="brand-campaign-value">
                  {new Date(campaign?.startDate).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })} - {new Date(campaign?.endDate).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Payout</div>
                <div className="brand-campaign-value highlight">
                  ${parseFloat(campaign?.payout).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Clicks</div>
                <div className="brand-campaign-value">{metrics.clicks}</div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Conversions</div>
                <div className="brand-campaign-value">{metrics.conversions}</div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Revenue</div>
                <div className="brand-campaign-value highlight">
                  ${metrics.revenue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div>
              </div>
              <div className="brand-campaign-item">
                <div className="brand-campaign-label">Conv. Rate</div>
                <div className="brand-campaign-value">{metrics.conversionRate.toFixed(1)}%</div>
              </div>
            </div>
          </div>

          <Divider my={8} />

          <div className="brand-campaign-tracking">
            <Heading size="md" mb={4}>Tracking Scripts</Heading>
            <div className="brand-campaign-script">
              <div className="brand-campaign-script-header">
                <div className="brand-campaign-script-title">1. Header Script</div>
              </div>
              <Text mb={2}>Add this script to the <Code>&lt;head&gt;</Code> section of your website:</Text>
              <div className="brand-campaign-code">
                <Code p={4} display="block" whiteSpace="pre-wrap">
                  {headerScript}
                </Code>
                <Button
                  className="brand-campaign-copy"
                  onClick={onHeaderCopy}
                >
                  {hasHeaderCopied ? 'Copied!' : 'Copy'}
                </Button>
              </div>
            </div>

            <div className="brand-campaign-script">
              <div className="brand-campaign-script-header">
                <div className="brand-campaign-script-title">2. Conversion Script</div>
              </div>
              <Text mb={2}>Add this script before the closing <Code>&lt;/body&gt;</Code> tag:</Text>
              <div className="brand-campaign-code">
                <Code p={4} display="block" whiteSpace="pre-wrap">
                  {conversionScript}
                </Code>
                <Button
                  className="brand-campaign-copy"
                  onClick={onConversionCopy}
                >
                  {hasConversionCopied ? 'Copied!' : 'Copy'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BrandCampaignPage; 