import React, { useEffect, useState } from 'react';
import './CreatorDashboard.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { fetchSelectedCampaigns } from '../../api/api';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CreatorProfileImage from './CreatorProfileImage';
import creatorOfferService from '../../services/creatorOfferService';
import {
    Box,
    Flex,
    Container,
    VStack,
    Heading,
    Text,
    Button,
    SimpleGrid,
    useBreakpointValue,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    IconButton,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Badge,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Icon,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Center,
    ButtonGroup
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FaChartLine, FaCampground, FaGift, FaCheck, FaPlus } from 'react-icons/fa';

const CreatorDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState(() => {
        const params = new URLSearchParams(location.search);
        return params.get('tab') || 'performance';
    });
    const [stats, setStats] = useState({
        totalClicks: 0,
        totalConversions: 0,
        totalSalesRevenue: 0,
        totalCommission: 0,
        overallConversionRate: 0
    });
    const [profileData, setProfileData] = useState({});
    const [offers, setOffers] = useState([]);
    const [loadingOffers, setLoadingOffers] = useState(true);
    const [user, setUser] = useState(null);
    const [archivedCampaigns, setArchivedCampaigns] = useState([]);
    const [showArchived, setShowArchived] = useState(false);

    const handleImageUpdate = async (newImageUrl) => {
        try {
            if (auth.currentUser) {
                const userRef = doc(db, 'users', auth.currentUser.uid);
                await updateDoc(userRef, {
                    profileImageUrl: newImageUrl
                });
                setProfileData(prev => ({
                    ...prev,
                    profileImageUrl: newImageUrl
                }));
            }
        } catch (error) {
            console.error('Error updating profile image:', error);
        }
    };

    useEffect(() => {
        const fetchProfile = async () => {
            if (auth.currentUser) {
                const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
                if (userDoc.exists()) {
                    setProfileData(userDoc.data());
                }
            }
        };

        fetchProfile();
    }, [auth.currentUser]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log('Auth state changed:', user?.uid); // Debug log
            setUser(user);
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        const loadCampaigns = async () => {
            try {
                setLoading(true);
                
                if (!auth.currentUser) {
                    setLoading(false);
                    return;
                }

                // Fetch active campaigns
                const response = await fetchSelectedCampaigns();
                
                // Fetch archived campaigns
                const archivedQuery = query(
                    collection(db, 'archivedCampaigns'),
                    where('userId', '==', auth.currentUser.uid)
                );
                const archivedSnapshot = await getDocs(archivedQuery);
                const archivedIds = new Set(
                    archivedSnapshot.docs.map(doc => doc.data().campaignId)
                );

                if (response?.success && Array.isArray(response.campaigns)) {
                    // Split campaigns into active and archived
                    const active = [];
                    const archived = [];
                    
                    for (const campaign of response.campaigns) {
                        if (archivedIds.has(campaign.id)) {
                            archived.push(campaign);
                        } else {
                            active.push(campaign);
                        }
                    }
                    
                    setSelectedCampaigns(active);
                    setArchivedCampaigns(archived);
                }

                setError(null);
            } catch (error) {
                console.error('Error loading campaigns:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (auth.currentUser) {
            loadCampaigns();
        }
    }, [auth.currentUser]);

    useEffect(() => {
        const fetchOffers = async () => {
            if (!user) {
                console.log('No user found, skipping fetch');
                setError('Please log in to view your offers');
                setLoadingOffers(false);
                return;
            }

            try {
                setLoadingOffers(true);
                console.log('Fetching offers for user:', user.uid);
                const fetchedOffers = await creatorOfferService.getOffers();
                console.log('Fetched offers:', fetchedOffers);
                
                if (Array.isArray(fetchedOffers)) {
                    setOffers(fetchedOffers);
                    console.log('Offers set successfully');
                } else {
                    console.warn('Invalid offers format:', fetchedOffers);
                    setOffers([]);
                }
                
                setError(null);
            } catch (error) {
                console.error('Error fetching offers:', error);
                setError(error.message);
                setOffers([]);
            } finally {
                console.log('Setting loadingOffers to false');
                setLoadingOffers(false);
            }
        };

        if (user) {
            fetchOffers();
        } else {
            setLoadingOffers(false);
        }
    }, [user]);

    const handleArchiveCampaign = async (campaignId, isArchiving = true) => {
        try {
            if (!auth.currentUser) return;
            
            const archiveDocId = `${auth.currentUser.uid}_${campaignId}`;
            
            if (isArchiving) {
                // Archive the campaign
                await setDoc(doc(db, 'archivedCampaigns', archiveDocId), {
                    userId: auth.currentUser.uid,
                    campaignId: campaignId,
                    archivedAt: new Date()
                });
                
                // Update local states
                const campaignToArchive = selectedCampaigns.find(c => c.id === campaignId);
                setSelectedCampaigns(prev => prev.filter(c => c.id !== campaignId));
                setArchivedCampaigns(prev => [...prev, campaignToArchive]);
            } else {
                // Unarchive the campaign
                await deleteDoc(doc(db, 'archivedCampaigns', archiveDocId));
                
                // Update local states
                const campaignToUnarchive = archivedCampaigns.find(c => c.id === campaignId);
                setArchivedCampaigns(prev => prev.filter(c => c.id !== campaignId));
                setSelectedCampaigns(prev => [...prev, campaignToUnarchive]);
            }
        } catch (error) {
            console.error(`Error ${isArchiving ? 'archiving' : 'unarchiving'} campaign:`, error);
        }
    };

    const SidebarContent = () => (
        <VStack 
            spacing={6} 
            align="stretch" 
            w="full" 
            p={6}
            bg="white"
            borderRadius="lg"
            shadow="sm"
        >
            <Box textAlign="center">
                <Box mb={4}>
                    <CreatorProfileImage 
                        currentImageUrl={profileData?.profileImageUrl}
                        onImageUpdate={handleImageUpdate}
                    />
                </Box>
                <Heading size="md" color="gray.800">{profileData?.name || 'Creator'}</Heading>
                <Text color="gray.500" fontSize="sm" mt={1}>Dashboard</Text>
            </Box>
            
            <VStack spacing={2} align="stretch">
                <Button
                    variant={activeTab === 'performance' ? 'subtle' : 'ghost'}
                    colorScheme="gray"
                    onClick={() => setActiveTab('performance')}
                    justifyContent="flex-start"
                    h="45px"
                    leftIcon={<Icon as={FaChartLine} />}
                    color={activeTab === 'performance' ? 'black' : 'gray.600'}
                    bg={activeTab === 'performance' ? 'gray.100' : 'transparent'}
                    _hover={{ bg: 'gray.100' }}
                >
                    Performance Metrics
                </Button>
                <Button
                    variant={activeTab === 'campaigns' ? 'subtle' : 'ghost'}
                    colorScheme="gray"
                    onClick={() => setActiveTab('campaigns')}
                    justifyContent="flex-start"
                    h="45px"
                    leftIcon={<Icon as={FaCampground} />}
                    color={activeTab === 'campaigns' ? 'black' : 'gray.600'}
                    bg={activeTab === 'campaigns' ? 'gray.100' : 'transparent'}
                    _hover={{ bg: 'gray.100' }}
                >
                    Selected Campaigns
                </Button>
                <Button
                    variant={activeTab === 'offers' ? 'subtle' : 'ghost'}
                    colorScheme="gray"
                    onClick={() => setActiveTab('offers')}
                    justifyContent="flex-start"
                    h="45px"
                    leftIcon={<Icon as={FaGift} />}
                    color={activeTab === 'offers' ? 'black' : 'gray.600'}
                    bg={activeTab === 'offers' ? 'gray.100' : 'transparent'}
                    _hover={{ bg: 'gray.100' }}
                >
                    Creator Offers
                </Button>
            </VStack>
        </VStack>
    );

    const renderPerformanceMetrics = () => (
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 2 }} spacing={6}>
            <Card
                bg="white"
                shadow="sm"
                borderRadius="lg"
                _hover={{ shadow: 'md', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
            >
                <CardBody>
                    <Stat>
                        <StatLabel color="gray.600" fontSize="sm">Total Clicks</StatLabel>
                        <StatNumber fontSize="3xl" fontWeight="bold" color="blue.600">
                            {stats.totalClicks}
                        </StatNumber>
                        <StatHelpText color="gray.500">
                            Last 30 days
                        </StatHelpText>
                    </Stat>
                </CardBody>
            </Card>
            <Card
                bg="white"
                shadow="sm"
                borderRadius="lg"
                _hover={{ shadow: 'md', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
            >
                <CardBody>
                    <Stat>
                        <StatLabel color="gray.600" fontSize="sm">Conversions</StatLabel>
                        <StatNumber fontSize="3xl" fontWeight="bold" color="green.600">
                            {stats.totalConversions}
                        </StatNumber>
                        <StatHelpText color="gray.500">
                            {stats.overallConversionRate}% Rate
                        </StatHelpText>
                    </Stat>
                </CardBody>
            </Card>
            <Card
                bg="white"
                shadow="sm"
                borderRadius="lg"
                _hover={{ shadow: 'md', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
            >
                <CardBody>
                    <Stat>
                        <StatLabel color="gray.600" fontSize="sm">Total Revenue</StatLabel>
                        <StatNumber fontSize="3xl" fontWeight="bold" color="purple.600">
                            ${stats.totalSalesRevenue}
                        </StatNumber>
                    </Stat>
                </CardBody>
            </Card>
            <Card
                bg="white"
                shadow="sm"
                borderRadius="lg"
                _hover={{ shadow: 'md', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
            >
                <CardBody>
                    <Stat>
                        <StatLabel color="gray.600" fontSize="sm">Your Commission</StatLabel>
                        <StatNumber fontSize="3xl" fontWeight="bold" color="green.600">
                            ${stats.totalCommission}
                        </StatNumber>
                        <StatHelpText color="gray.500">
                            {((stats.totalCommission / stats.totalSalesRevenue) * 100).toFixed(2)}% of Revenue
                        </StatHelpText>
                    </Stat>
                </CardBody>
            </Card>
        </SimpleGrid>
    );

    const renderCampaigns = () => (
        <Box>
            <Flex justify="space-between" align="center" mb={6}>
                <Button
                    size="sm"
                    colorScheme={showArchived ? "gray" : "blue"}
                    onClick={() => setShowArchived(!showArchived)}
                >
                    {showArchived ? "Show Active Campaigns" : "Show Archived Campaigns"}
                </Button>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {(showArchived ? archivedCampaigns : selectedCampaigns).map((campaign, index) => (
                    <Card
                        key={`${campaign.id}-${index}`}
                        bg="white"
                        shadow="sm"
                        borderRadius="lg"
                        _hover={{ shadow: 'md' }}
                        transition="all 0.2s"
                    >
                        <CardHeader pb={2}>
                            <Flex justify="space-between" align="center">
                                <Heading size="md" color="gray.800">
                                    {`${campaign.campaignDetails?.campaignHeadline || 'Drive Sales'} for ${campaign.campaignDetails?.brand || 'Brand'}`}
                                </Heading>
                                <Button
                                    size="sm"
                                    colorScheme={showArchived ? "green" : "red"}
                                    variant="ghost"
                                    onClick={() => handleArchiveCampaign(campaign.id, !showArchived)}
                                >
                                    {showArchived ? "Reapply" : "Archive"}
                                </Button>
                            </Flex>
                        </CardHeader>
                        <CardBody pt={2}>
                            <VStack align="stretch" spacing={4}>
                                <Badge 
                                    colorScheme={showArchived ? "gray" : "green"}
                                    p={2} 
                                    borderRadius="md"
                                    textAlign="center"
                                >
                                    {showArchived ? "Archived" : `Payout: $${campaign.campaignDetails?.payout || 0}`}
                                </Badge>
                            </VStack>
                        </CardBody>
                        <CardFooter pt={2}>
                            <Button 
                                as={Link}
                                to={`/creator/campaigns/${campaign.id}`}
                                colorScheme="blue"
                                width="full"
                                size="sm"
                                borderRadius="md"
                            >
                                View Details
                            </Button>
                        </CardFooter>
                    </Card>
                ))}
            </SimpleGrid>
        </Box>
    );

    const renderOffers = () => (
        <Box>
            <Flex 
                direction={{ base: "column", md: "row" }} 
                justify="space-between" 
                align={{ base: "stretch", md: "center" }}
                mb={6}
            >
                <Button
                    as={Link}
                    to="/creator/offers/create"
                    colorScheme="blue"
                    size="md"
                    leftIcon={<Icon as={FaPlus} />}
                    w={{ base: "full", md: "auto" }}
                    mb={{ base: 4, md: 0 }}
                >
                    Create New Offer
                </Button>
            </Flex>
            
            {error ? (
                <Alert status="error" borderRadius="lg">
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            ) : loadingOffers ? (
                <Center py={8}>
                    <Spinner size="lg" color="blue.500" />
                </Center>
            ) : offers.length > 0 ? (
                <SimpleGrid 
                    columns={{ base: 1, md: 2, lg: 3 }} 
                    spacing={6}
                >
                    {offers.map(offer => (
                        <Card
                            key={offer.id}
                            bg="white"
                            shadow="sm"
                            borderRadius="lg"
                            overflow="hidden"
                            _hover={{ shadow: 'md' }}
                            transition="all 0.2s"
                        >
                            <CardHeader pb={2}>
                                <Badge 
                                    colorScheme="blue" 
                                    mb={2}
                                    borderRadius="full"
                                    px={3}
                                    py={1}
                                >
                                    {offer.type}
                                </Badge>
                                <Heading size="md" color="gray.800">
                                    {offer.title}
                                </Heading>
                            </CardHeader>
                            
                            <CardBody pt={2}>
                                <VStack align="stretch" spacing={4}>
                                    <Flex justify="space-between" align="center">
                                        <Text 
                                            fontSize="2xl" 
                                            fontWeight="bold" 
                                            color="green.600"
                                        >
                                            ${offer.price}
                                        </Text>
                                        <Badge colorScheme="gray">
                                            {offer.deliveryTime} days delivery
                                        </Badge>
                                    </Flex>
                                    
                                    <Box>
                                        <Text 
                                            fontWeight="medium" 
                                            color="gray.700" 
                                            mb={2}
                                        >
                                            What's Included:
                                        </Text>
                                        <VStack 
                                            align="stretch" 
                                            spacing={2}
                                            pl={2}
                                        >
                                            {offer.includes.map((item, index) => (
                                                <Flex 
                                                    key={index} 
                                                    align="center"
                                                    gap={2}
                                                >
                                                    <Icon 
                                                        as={FaCheck} 
                                                        color="green.500" 
                                                        boxSize={3}
                                                    />
                                                    <Text 
                                                        fontSize="sm" 
                                                        color="gray.600"
                                                    >
                                                        {item}
                                                    </Text>
                                                </Flex>
                                            ))}
                                        </VStack>
                                    </Box>
                                </VStack>
                            </CardBody>
                            
                            <CardFooter pt={2}>
                                <ButtonGroup spacing={2} width="full">
                                    <Button
                                        flex={1}
                                        size="sm"
                                        colorScheme="blue"
                                        variant="outline"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        flex={1}
                                        size="sm"
                                        colorScheme="red"
                                        variant="ghost"
                                    >
                                        Delete
                                    </Button>
                                </ButtonGroup>
                            </CardFooter>
                        </Card>
                    ))}
                </SimpleGrid>
            ) : (
                <Box 
                    textAlign="center" 
                    py={10} 
                    px={6} 
                    bg="white" 
                    borderRadius="lg" 
                    shadow="sm"
                >
                    <Icon 
                        as={FaGift} 
                        boxSize={12} 
                        color="gray.400" 
                        mb={4} 
                    />
                    <Text 
                        fontSize="xl" 
                        fontWeight="medium" 
                        color="gray.700" 
                        mb={2}
                    >
                        No Offers Yet
                    </Text>
                    <Text color="gray.500" mb={6}>
                        Create your first offer to start receiving requests from brands!
                    </Text>
                    <Button
                        as={Link}
                        to="/creator/offers/create"
                        colorScheme="blue"
                        leftIcon={<Icon as={FaPlus} />}
                    >
                        Create New Offer
                    </Button>
                </Box>
            )}
        </Box>
    );

    if (loading) return (
        <Flex justify="center" align="center" minH="100vh">
            <Spinner size="xl" color="blue.500" />
        </Flex>
    );
    
    if (error) return (
        <Box p={8} textAlign="center">
            <Alert status="error" borderRadius="lg">
                <AlertIcon />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        </Box>
    );

    return (
        <Box minH="100vh" bg="gray.50">
            {/* Mobile Header */}
            {isMobile && (
                <Box>
                    <Flex
                        bg="white"
                        px={4}
                        py={4}
                        borderBottom="1px"
                        borderColor="gray.200"
                        position="fixed"
                        top={0}
                        left={0}
                        right={0}
                        width="100%"
                        zIndex={20}
                        shadow="sm"
                        justify="space-between"
                        align="center"
                        h="64px"
                    >
                        <IconButton
                            icon={<HamburgerIcon />}
                            onClick={onOpen}
                            variant="ghost"
                            color="gray.600"
                            aria-label="Menu"
                        />
                        <Heading size="md" color="gray.800">
                            {activeTab === 'performance' ? 'Performance' : 
                             activeTab === 'campaigns' ? 'Campaigns' : 'Offers'}
                        </Heading>
                        <Box w="40px" />
                    </Flex>
                    
                    {/* Mobile Tab Navigation */}
                    <Box
                        position="fixed"
                        top="64px"
                        left={0}
                        right={0}
                        width="100%"
                        zIndex={19}
                        bg="white"
                        borderBottom="1px"
                        borderColor="gray.200"
                        px={4}
                        h="56px"
                    >
                        <Flex
                            justify="space-between"
                            py={2}
                            gap={2}
                            h="full"
                            align="center"
                        >
                            <Button
                                flex={1}
                                size="md"
                                variant={activeTab === 'performance' ? 'subtle' : 'ghost'}
                                colorScheme="gray"
                                onClick={() => setActiveTab('performance')}
                                leftIcon={<Icon as={FaChartLine} />}
                                color={activeTab === 'performance' ? 'black' : 'gray.600'}
                                bg={activeTab === 'performance' ? 'gray.100' : 'transparent'}
                                _hover={{ bg: 'gray.100' }}
                            >
                                Performance
                            </Button>
                            <Button
                                flex={1}
                                size="md"
                                variant={activeTab === 'campaigns' ? 'subtle' : 'ghost'}
                                colorScheme="gray"
                                onClick={() => setActiveTab('campaigns')}
                                leftIcon={<Icon as={FaCampground} />}
                                color={activeTab === 'campaigns' ? 'black' : 'gray.600'}
                                bg={activeTab === 'campaigns' ? 'gray.100' : 'transparent'}
                                _hover={{ bg: 'gray.100' }}
                            >
                                Campaigns
                            </Button>
                            <Button
                                flex={1}
                                size="md"
                                variant={activeTab === 'offers' ? 'subtle' : 'ghost'}
                                colorScheme="gray"
                                onClick={() => setActiveTab('offers')}
                                leftIcon={<Icon as={FaGift} />}
                                color={activeTab === 'offers' ? 'black' : 'gray.600'}
                                bg={activeTab === 'offers' ? 'gray.100' : 'transparent'}
                                _hover={{ bg: 'gray.100' }}
                            >
                                Offers
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            )}

            {/* Mobile Drawer */}
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px">Dashboard Menu</DrawerHeader>
                    <DrawerBody>
                        <SidebarContent />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            {/* Main Layout */}
            <Container 
                maxW="1400px" 
                pt={{ base: "120px", md: 8 }}
                px={{ base: 4, md: 6 }}
            >
                <Flex gap={8}>
                    {/* Desktop Sidebar */}
                    {!isMobile && (
                        <Box w="280px" flexShrink={0}>
                            <SidebarContent />
                        </Box>
                    )}

                    {/* Main Content */}
                    <Box flex={1}>
                        <Box mb={6}>
                            <Heading color="gray.800" size="lg">
                                {activeTab === 'performance' ? 'Performance Metrics' : 
                                 activeTab === 'campaigns' ? 'Active Campaigns' : 'Creator Offers'}
                            </Heading>
                            <Text color="gray.500" mt={1}>
                                {activeTab === 'performance' ? 'Track your performance metrics and earnings' :
                                 activeTab === 'campaigns' ? 'Manage your active campaign selections' :
                                 'Create and manage your creator offers'}
                            </Text>
                        </Box>
                        {activeTab === 'performance' && renderPerformanceMetrics()}
                        {activeTab === 'campaigns' && renderCampaigns()}
                        {activeTab === 'offers' && renderOffers()}
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};

export default CreatorDashboard;