import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, VStack, Container } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../contexts/CartContext';

function CheckoutCancel() {
  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    // Restore cart from pendingCheckoutCart if it exists
    const pendingCart = localStorage.getItem('pendingCheckoutCart');
    if (pendingCart) {
      try {
        const cartItems = JSON.parse(pendingCart);
        cartItems.forEach(item => addToCart(item));
        localStorage.removeItem('pendingCheckoutCart'); // Clean up after restore
      } catch (error) {
        console.error('Error restoring cart:', error);
      }
    }
  }, [addToCart]);

  const handleReturn = () => {
    // Get the base URL based on environment
    const baseUrl = process.env.REACT_APP_ENV === 'production' 
      ? 'https://hypeo.io'        // Production URL
      : 'http://localhost:4003';  // Local development URL

    // Navigate to the cart page with the correct base URL
    window.location.href = `${baseUrl}/cart`;
  };

  return (
    <Container maxW="800px" py={12}>
      <Box 
        p={8} 
        borderWidth="1px" 
        borderRadius="lg" 
        boxShadow="md"
        textAlign="center"
        bg="white"
      >
        <VStack spacing={6}>
          <Heading size="xl">Checkout Cancelled</Heading>
          
          <Text fontSize="lg" color="gray.600">
            Your order has been cancelled and you have not been charged.
            Your cart items have been restored and you can return to your cart to complete your purchase when you're ready.
          </Text>
          
          <Button 
            onClick={handleReturn}
            colorScheme="blue" 
            size="lg"
            leftIcon={<FontAwesomeIcon icon={faShoppingCart} />}
            mt={4}
          >
            Return to Cart
          </Button>
        </VStack>
      </Box>
    </Container>
  );
}

export default CheckoutCancel; 