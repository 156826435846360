import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Login from './components/Auth/Login';
import CampaignList from './components/Campaigns/CampaignList';
import { auth, db } from './firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './App.css';
import CampaignForm from './components/Campaigns/CampaignForm';
import CreatorRegister from './components/Auth/RegisterCreator';
import BrandRegister from './components/Auth/RegisterBrand';
import SuccessPage from './components/Auth/SuccessPage';
import BrandProfilePage from './components/BrandUser/BrandProfilePage';
import DashboardPage from './components/BrandUser/BrandDashboard';
import CampaignPage from './components/Campaigns/CampaignPage';
import SignupChoice from './components/Auth/SignupChoice';
import CreatorProfilePage from './components/CreatorUser/CreatorProfilePage';
import CreatorDashboard from './components/CreatorUser/CreatorDashboard';
import CreatorCampaignDetails from './components/CreatorUser/CreatorCampaignDetails';
import BrandDashboard from './components/BrandUser/BrandDashboard';
import { TrackingProvider } from './contexts/TrackingContext';
import TrackingTester from './components/Testing/TrackingTester';
import CreatorOfferForm from './components/CreatorUser/CreatorOfferForm';
import CreatorListing from './components/CreatorListing/CreatorListing';
import CreatorDetailsPage from './components/CreatorDetails/CreatorDetailsPage';
import SplashPage from './components/SplashPage/SplashPage';
import { AuthProvider } from './contexts/AuthContext';
import CampaignEditForm from './components/BrandUser/CampaignEditForm';
import { Toaster } from 'react-hot-toast';
import RegistrationSuccess from './components/Auth/RegistrationSuccess';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import BrandWork from './components/BrandWork/BrandWork';
import MessagesHub from './components/Messages/MessagesHub';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import PasswordResetComplete from './components/Auth/PasswordResetComplete';
import { AdminProvider } from './contexts/AdminContext';
import AdminRoute from './components/Admin/AdminRoute';
import AdminHub from './components/Admin/AdminHub';
import Footer from './components/Footer/Footer';
import VerifyEmail from './components/Auth/VerifyEmail';
import { 
  ChakraProvider,
  Box,
  Flex,
  Button,
  Link as ChakraLink,
  useDisclosure,
  Icon,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Divider,
  useColorModeValue
} from '@chakra-ui/react'
import theme from './chakratheme'
import { createIcon } from '@chakra-ui/react'
import { useParams } from 'react-router-dom';
import CreatorProfilePageWrapper from './components/CreatorUser/CreatorProfilePageWrapper';
import BrandCampaignPage from './components/BrandUser/BrandCampaignPage';
import CreatorProfileForm from './components/CreatorUser/CreatorProfileForm';
import TestUpload from './components/Testing/TestUpload';
import { CartProvider, useCart } from './contexts/CartContext';
import CartIcon from './components/Cart/CartIcon';
import CartPage from './components/Cart/CartPage';
import CheckoutSuccess from './components/Cart/CheckoutSuccess';
import CheckoutCancel from './components/Cart/CheckoutCancel';
import ProjectStatus from './components/BrandWork/ProjectStatus';
import { FaShoppingCart } from 'react-icons/fa';
import CreatorEarnings from './components/CreatorUser/CreatorEarnings';
import BrandBilling from './components/BrandUser/BrandBilling';

library.add(fab, fas);

// Create custom menu icons
const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M3 12h18M3 6h18M3 18h18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  ),
});

const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M18 6L6 18M6 6l12 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  ),
});

const App = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);

  useEffect(() => {
    // Update title and metadata
    document.title = "Hypeo - Creator Marketing Platform";
    
    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Connect with brands and monetize your audience with Hypeo's creator marketing platform";

    // Update OG meta tags
    const ogTags = {
        'og:title': 'Hypeo - Creator Marketing Platform',
        'og:description': "Connect with brands and monetize your audience with Hypeo's creator marketing platform",
        'og:image': '/og.jpg',
        'og:url': 'https://hypeo.io'
    };

    Object.entries(ogTags).forEach(([property, content]) => {
        let metaTag = document.querySelector(`meta[property="${property}"]`);
        if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('property', property);
            document.head.appendChild(metaTag);
        }
        metaTag.setAttribute('content', content);
    });

    // Update favicon
    const favicon = document.querySelector("link[rel='icon']");
    if (!favicon) {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = '/favicon.ico';
        document.head.appendChild(newFavicon);
    } else {
        favicon.href = '/favicon.ico';
    }

    // Update theme color
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
        themeColor = document.createElement('meta');
        themeColor.name = 'theme-color';
        document.head.appendChild(themeColor);
    }
    themeColor.content = '#000000';
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const role = userDoc.data().role;
            setUserRole(role);
            console.log("User role from Firestore:", role);
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      } else {
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Close profile menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuRef]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserRole(null);
      setShowProfileMenu(false);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleNavLinkClick = () => {
    onClose(); // Close the mobile menu when a link is clicked
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  useEffect(() => {
    // Add Google Analytics
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-K4FZGHLBQJ";
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-K4FZGHLBQJ');
    `;
    document.head.appendChild(script2);

    // Cleanup function
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <CartProvider>
          <TrackingProvider>
            <AdminProvider>
              <Router>
                <Box>
                  <Box 
                    as="header" 
                    position="fixed"
                    top={0}
                    w="100%"
                    bg="white"
                    boxShadow="sm"
                    zIndex={1000}
                  >
                    <Flex
                      maxW="1300px"
                      mx="auto"
                      px={6}
                      h="70px"
                      align="center"
                      justify="space-between"
                    >
                      <Link 
                        to={user ? "/creators" : "/"} 
                        style={{ 
                          fontSize: '24px', 
                          fontWeight: 800 
                        }}
                      >
                        HYPEO
                      </Link>

                      <Box display={{ base: 'block', lg: 'none' }}>
                        <Button
                          onClick={onToggle}
                          variant="ghost"
                          p={2}
                        >
                          <Icon as={isOpen ? CloseIcon : MenuIcon} />
                        </Button>
                      </Box>

                      <Flex
                        display={{ base: isOpen ? 'flex' : 'none', lg: 'flex' }}
                        position={{ base: 'absolute', lg: 'static' }}
                        top={{ base: '70px', lg: 'auto' }}
                        left={0}
                        right={0}
                        bg="white"
                        flexDir={{ base: 'column', lg: 'row' }}
                        align={{ base: 'stretch', lg: 'center' }}
                        gap={{ base: 0, lg: 0 }}
                        p={{ base: 0, lg: 0 }}
                        pb={{ base: 6, lg: 0 }}
                        boxShadow={{ base: 'sm', lg: 'none' }}
                        justifyContent={{ lg: "flex-end" }}
                      >
                        {/* Navigation Links */}
                        <Flex
                          flexDir={{ base: 'column', lg: 'row' }}
                          gap={{ base: 0, lg: 4 }}
                          align="center"
                        >
                          <Link 
                            to="/creators"
                            onClick={handleNavLinkClick}
                            style={{
                              padding: '8px 12px',
                              display: 'block',
                              fontWeight: '500',
                              fontSize: '14px'
                            }}
                          >
                            Creators
                          </Link>
                          <Link 
                            to="/campaigns" 
                            onClick={handleNavLinkClick}
                            style={{
                              padding: '8px 12px',
                              display: 'block',
                              fontWeight: '500',
                              fontSize: '14px'
                            }}
                          >
                            Campaigns
                          </Link>
                          {user && (
                            <>
                              <Link 
                                to="/work"
                                onClick={handleNavLinkClick}
                                style={{
                                  padding: '8px 12px',
                                  display: 'block',
                                  fontWeight: '500',
                                  fontSize: '14px'
                                }}
                              >
                                Work
                              </Link>
                              {/* Temporarily hide Messages link
                              <Link 
                                to="/messages"
                                onClick={handleNavLinkClick}
                                style={{
                                  padding: '8px 12px',
                                  display: 'block',
                                  fontWeight: '500',
                                  fontSize: '14px'
                                }}
                              >
                                Messages
                              </Link>
                              */}
                              {userRole === 'admin' && (
                                <Link 
                                  to="/admin"
                                  onClick={handleNavLinkClick}
                                  style={{
                                    padding: '8px 12px',
                                    display: 'block',
                                    fontWeight: '500',
                                    fontSize: '14px'
                                  }}
                                >
                                  Admin
                                </Link>
                              )}
                            </>
                          )}
                        </Flex>

                        {/* Auth Buttons and Cart */}
                        <Flex
                          flexDir={{ base: 'column', lg: 'row' }}
                          gap={{ base: 0, lg: 4 }}
                          mb={{ base: 4, lg: 0 }}
                          align="center"
                          ml={{ lg: 4 }}
                        >
                          {!user ? (
                            <>
                              <Link 
                                to="/login"
                                onClick={handleNavLinkClick}
                                style={{
                                  padding: '8px 12px',
                                  display: 'block',
                                  fontWeight: '500',
                                  fontSize: '14px'
                                }}
                              >
                                Login
                              </Link>
                              <Link 
                                to="/signup"
                                onClick={handleNavLinkClick}
                                style={{
                                  padding: '8px 12px',
                                  display: 'block',
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  background: isOpen ? 'transparent' : '#000',
                                  color: isOpen ? '#333' : 'white',
                                  borderRadius: isOpen ? '0' : '6px'
                                }}
                              >
                                Sign Up
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                to={userRole ? `/${userRole}/dashboard` : '/dashboard'}
                                onClick={handleNavLinkClick}
                                style={{
                                  padding: '8px 12px',
                                  display: 'block',
                                  fontWeight: '500',
                                  fontSize: '14px'
                                }}
                              >
                                Dashboard
                              </Link>
                              
                              {/* Profile Dropdown Menu */}
                              <Box position="relative" ref={profileMenuRef}>
                                <Box 
                                  onClick={toggleProfileMenu}
                                  style={{
                                    padding: '8px 6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                  }}
                                >
                                  <Flex align="center">
                                    <Box mr={1}>
                                      <Icon as={MenuIcon} boxSize={4} />
                                    </Box>
                                    <Avatar 
                                      size="xs" 
                                      bg="yellow.100" 
                                      color="black"
                                      name={user?.displayName || 'User'}
                                    >
                                      {user?.displayName?.charAt(0) || 'D'}
                                    </Avatar>
                                  </Flex>
                                </Box>
                                
                                {showProfileMenu && (
                                  <Box
                                    position="absolute"
                                    right={0}
                                    top="100%"
                                    bg="white"
                                    boxShadow="lg"
                                    borderRadius="md"
                                    width="200px"
                                    zIndex={10}
                                    mt={2}
                                    p={2}
                                  >
                                    <VStack align="stretch" spacing={0}>
                                      <Link 
                                        to={userRole ? `/${userRole}/profile` : '/profile'}
                                        onClick={() => {
                                          handleNavLinkClick();
                                          setShowProfileMenu(false);
                                        }}
                                      >
                                        <Box py={3} px={4} _hover={{ bg: "gray.50" }} borderRadius="md">
                                          Profile
                                        </Box>
                                      </Link>
                                      
                                      <Divider my={1} />
                                      
                                      <Link 
                                        to={userRole === 'creator' ? "/earnings" : "/billing"}
                                        onClick={() => {
                                          handleNavLinkClick();
                                          setShowProfileMenu(false);
                                        }}
                                      >
                                        <Box py={3} px={4} _hover={{ bg: "gray.50" }} borderRadius="md">
                                          {userRole === 'creator' ? 'Earnings' : 'Billing'}
                                        </Box>
                                      </Link>
                                      
                                      <Box 
                                        as="button"
                                        onClick={() => {
                                          handleLogout();
                                          handleNavLinkClick();
                                        }}
                                        py={3}
                                        px={4}
                                        textAlign="left"
                                        width="100%"
                                        _hover={{ bg: "gray.50" }}
                                        borderRadius="md"
                                      >
                                        Sign Out
                                      </Box>
                                    </VStack>
                                  </Box>
                                )}
                              </Box>
                            </>
                          )}
                          
                          {/* Cart Icon - only show when logged in */}
                          {user && (
                            <Box 
                              onClick={handleNavLinkClick}
                              style={{
                                padding: '8px 12px',
                                display: 'block',
                                marginLeft: '0px'
                              }}
                            >
                              <Link to="/cart">
                                <CartIcon />
                              </Link>
                            </Box>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>

                  <Box as="main" mt="70px" minH="calc(100vh - 70px)" bg="gray.50">
                    <Box maxW="1300px" mx="auto" p={6}>
                      <Routes>
                        <Route path="/" element={!user ? <SplashPage /> : <CampaignList />} />
                        <Route path="/register/creator" element={<CreatorRegister />} />
                        <Route path="/register/brand" element={<BrandRegister />} />
                        <Route path="/success" element={<RegistrationSuccess />} />
                        <Route path="/login" element={<Login />} />
                        <Route 
                          path="/earnings" 
                          element={
                            user ? (
                              userRole === 'creator' ? (
                                <CreatorEarnings />
                              ) : (
                                <Navigate to="/dashboard" replace />
                              )
                            ) : (
                              <Navigate to="/login" replace />
                            )
                          } 
                        />
                        <Route 
                          path="/billing" 
                          element={
                            user ? (
                              userRole === 'brand' ? (
                                <Navigate to="/brand/billing" replace />
                              ) : (
                                <Navigate to="/dashboard" replace />
                              )
                            ) : (
                              <Navigate to="/login" replace />
                            )
                          } 
                        />
                        <Route path="/brand/billing" element={<BrandBilling />} />
                        <Route path="/campaigns" element={<CampaignList />} />
                        <Route path="/campaigns/:id" element={<CampaignPage />} />
                        <Route path="/create-campaign" element={<CampaignForm />} />
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/signup" element={<SignupChoice />} />
                        <Route path="/signup/creator" element={<CreatorRegister />} />
                        <Route path="/signup/brand" element={<BrandRegister />} />
                        <Route path="/creator/profile" element={<CreatorProfilePage />} />
                        <Route path="/brand/profile" element={<BrandProfilePage />} />
                        <Route path="/creator/dashboard" element={<CreatorDashboard />} />
                        <Route path="/creator/campaigns/:campaignId" element={<CreatorCampaignDetails />} />
                        <Route path="/brand/dashboard" element={<BrandDashboard />} />
                        <Route path="/profile" element={<Navigate to="/login" />} />
                        <Route path="/tracking-test" element={<TrackingTester />} />
                        <Route path="/creator/offers/create" element={<CreatorOfferForm />} />
                        <Route path="/creator/offers/edit/:offerId" element={<CreatorOfferForm />} />
                        <Route path="/creators" element={<CreatorListing />} />
                        <Route path="/creator/:id" element={<CreatorDetailsPage />} />
                        <Route path="/campaigns/:id/edit" element={<CampaignEditForm />} />
                        <Route path="/work" element={<BrandWork />} />
                        <Route path="/messages" element={<MessagesHub />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/password-reset-complete" element={<PasswordResetComplete />} />
                        <Route 
                          path="/admin" 
                          element={
                            <AdminRoute>
                              <AdminHub />
                            </AdminRoute>
                          } 
                        />
                        <Route 
                          path="/admin/billing" 
                          element={
                            <AdminRoute>
                              <AdminHub />
                            </AdminRoute>
                          } 
                        />
                        <Route path="/verify-email" element={<VerifyEmail />} />
                        <Route path="/creator/:creatorId" element={<CreatorProfilePageWrapper />} />
                        <Route path="/brand/campaigns/:id" element={<BrandCampaignPage />} />
                        <Route path="/creator-profile-form" element={<CreatorProfileForm />} />
                        <Route path="/test-upload" element={<TestUpload />} />
                        <Route path="/cart" element={<CartPage />} />
                        <Route path="/checkout/success" element={<CheckoutSuccess />} />
                        <Route path="/checkout/cancel" element={<CheckoutCancel />} />
                        <Route path="/project-status/:orderId" element={<ProjectStatus />} />
                      </Routes>
                    </Box>
                  </Box>

                  <Footer />
                </Box>
                <Toaster position="top-right" />
              </Router>
            </AdminProvider>
          </TrackingProvider>
        </CartProvider>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
