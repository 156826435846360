import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import config from '../../config/config';
import './BillingApprovals.css';

const API_URL = config.api.baseURL;

const PendingAffiliatePayouts = () => {
    const [pendingApprovals, setPendingApprovals] = useState([]);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rejectionReason, setRejectionReason] = useState('');

    const fetchPendingApprovals = async () => {
        try {
            setLoading(true); // Set loading to true at start
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken(true);
            console.log('Got auth token:', token ? 'Token exists' : 'No token');
            
            const response = await fetch(`${API_URL}/admin/pending-approvals`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log('Response status:', response.status);
            console.log('Response headers:', Object.fromEntries(response.headers.entries()));

            if (!response.ok) {
                if (response.headers.get('content-type')?.includes('application/json')) {
                    const errorData = await response.json();
                    console.error('Error response data:', errorData);
                    throw new Error(errorData.error || 'Failed to fetch approvals');
                } else {
                    const text = await response.text();
                    console.error('Raw error response:', text);
                    throw new Error('Failed to fetch approvals');
                }
            }

            const data = await response.json();
            console.log('Fetched pending approvals:', data);
            setPendingApprovals(data);
        } catch (error) {
            console.error('Error fetching approvals:', error);
            setError(error.message);
        } finally {
            setLoading(false); // Set loading to false whether the request succeeded or failed
        }
    };

    useEffect(() => {
        fetchPendingApprovals();
    }, []);

    const handleApprove = async (billingId) => {
        try {
            console.log('Approving billing:', billingId);
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken(true);
            
            const response = await fetch(`${API_URL}/admin/approve/${billingId}`, {
                method: 'POST',
                headers: { 
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.headers.get('content-type')?.includes('application/json')) {
                    const errorData = await response.json();
                    throw new Error(`Failed to approve: ${errorData.message || response.statusText}`);
                } else {
                    const text = await response.text();
                    console.error('Raw error response:', text);
                    throw new Error(`Failed to approve: ${response.statusText}`);
                }
            }

            console.log('Successfully approved billing');
            await fetchPendingApprovals(); // Refresh the list
            setSelectedBilling(null);
        } catch (error) {
            console.error('Error approving billing:', error);
            setError(`Failed to approve billing: ${error.message}`);
        }
    };

    const handleReject = async (billingId, reason) => {
        try {
            console.log('Rejecting billing:', billingId, 'Reason:', reason);
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken(true);
            
            const response = await fetch(`${API_URL}/admin/reject-billing/${billingId}`, {
                method: 'POST',
                headers: { 
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ reason })
            });

            console.log('Response status:', response.status);
            console.log('Response headers:', Object.fromEntries(response.headers.entries()));

            if (!response.ok) {
                if (response.headers.get('content-type')?.includes('application/json')) {
                    const errorData = await response.json();
                    console.log('Error response data:', errorData);
                    throw new Error(`Failed to reject: ${errorData.message || response.statusText}`);
                } else {
                    const text = await response.text();
                    console.error('Raw error response:', text);
                    throw new Error(`Failed to reject: ${response.statusText}`);
                }
            }

            const responseData = await response.json();
            console.log('Success response:', responseData);

            await fetchPendingApprovals(); // Refresh the list
            setSelectedBilling(null);
        } catch (error) {
            console.error('Error rejecting billing:', error);
            setError(`Failed to reject billing: ${error.message}`);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    if (loading) return (
        <div className="loading-state">Loading pending approvals...</div>
    );

    return (
        <div className="container">
            <h2>Pending Affiliate Payouts</h2>
            
            {error && (
                <div className="error-message">{error}</div>
            )}

            {pendingApprovals.length === 0 ? (
                <p className="empty-state">No pending payouts</p>
            ) : (
                <div className="billing-grid">
                    {pendingApprovals.map((billing) => (
                        <div key={billing.id} className="billing-card">
                            <div className="billing-header">
                                <div className="billing-title">
                                    <h3>{billing.brandName}</h3>
                                    <span className="billing-period">{billing.period}</span>
                                </div>
                                <div className="billing-amount">
                                    ${billing.amount}
                                </div>
                            </div>

                            {billing.creatorPayments && (
                                <div className="payments-section">
                                    <h4>Affiliate Payments</h4>
                                    <div className="payments-list">
                                        {billing.creatorPayments.map((payment, index) => (
                                            <div key={index} className="payment-item">
                                                <div className="payment-header">
                                                    <span className="creator-name">
                                                        {payment.creatorName}
                                                        <span className="creator-id">({payment.creatorId})</span>
                                                    </span>
                                                </div>
                                                
                                                <div className="offer-details">
                                                    <span className="offer-name">
                                                        {payment.offerName || 'No offer name'}
                                                    </span>
                                                </div>
                                                
                                                <div className="payment-details">
                                                    <div className="detail-group">
                                                        <div className="detail-item">
                                                            <span>Campaign</span>
                                                            <strong>{payment.campaignName || 'N/A'}</strong>
                                                        </div>
                                                        <div className="detail-item">
                                                            <span>Order Amount</span>
                                                            <strong>${payment.orderAmount || '0'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="detail-group">
                                                        <div className="detail-item">
                                                            <span>Payout ({payment.payoutType})</span>
                                                            <strong>${payment.amount}</strong>
                                                        </div>
                                                        <div className="detail-item">
                                                            <span>Date</span>
                                                            <strong>{formatDate(payment.timestamp)}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="detail-item conversion-item">
                                                        <span>Conversion ID</span>
                                                        <strong className="conversion-id">{payment.conversionId}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className="card-actions">
                                <button
                                    onClick={() => handleApprove(billing.id)}
                                    className="action-button primary"
                                >
                                    Approve Payout
                                </button>
                                <button
                                    onClick={() => setSelectedBilling(billing)}
                                    className="action-button secondary"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {selectedBilling && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>Reject Payout</h3>
                        <p>Are you sure you want to reject this payout from {selectedBilling.brandName}?</p>
                        <textarea
                            value={rejectionReason}
                            onChange={(e) => setRejectionReason(e.target.value)}
                            placeholder="Enter reason for rejection"
                            className="modal-textarea"
                            rows="3"
                        />
                        <div className="modal-actions">
                            <button
                                onClick={() => {
                                    setSelectedBilling(null);
                                    setRejectionReason('');
                                }}
                                className="action-button secondary"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    handleReject(selectedBilling.id, rejectionReason);
                                    setSelectedBilling(null);
                                    setRejectionReason('');
                                }}
                                className="action-button primary"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PendingAffiliatePayouts; 