import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { 
    collection, 
    query, 
    where, 
    getDocs, 
    orderBy,
    doc,
    getDoc 
} from 'firebase/firestore';
import { 
    Box, 
    Text, 
    SimpleGrid, 
    Spinner, 
    Button,
    useToast,
    Heading,
    VStack,
    Alert,
    AlertIcon,
    useColorModeValue
} from '@chakra-ui/react';
import OrderCard from './OrderCard';
import EmptyState from '../common/EmptyState';

const CreatorWorkOrders = ({ status }) => {
    const { currentUser } = useAuth();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const toast = useToast();
    const bgColor = useColorModeValue('white', 'gray.800');

    useEffect(() => {
        const fetchOrders = async () => {
            if (!currentUser) {
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                
                let statusValues = [];
                if (status === 'active') {
                    statusValues = ['pending', 'in_progress', 'review', 'In Progress', 'paid'];
                } else if (status === 'completed') {
                    statusValues = ['completed', 'cancelled'];
                }
                
                const ordersRef = collection(db, 'orders');
                const querySnapshot = await getDocs(ordersRef);
                
                const orderPromises = querySnapshot.docs
                    .map(async (doc) => {
                        const data = doc.data();
                        
                        const hasCreatorItems = data.items?.some(item => 
                            item.creatorId === currentUser.uid
                        );
                        
                        if (!hasCreatorItems) {
                            return null;
                        }

                        if (!statusValues.includes(data.status)) {
                            return null;
                        }

                        let brandName = 'Unknown Brand';
                        if (data.userId) {
                            try {
                                const brandUserRef = doc(db, 'users', data.userId);
                                const brandUserDoc = await getDoc(brandUserRef);
                                if (brandUserDoc.exists()) {
                                    brandName = brandUserDoc.data().brandName || 'Unknown Brand';
                                }
                            } catch (err) {
                                // Silently handle brand data fetch errors
                                brandName = 'Unknown Brand';
                            }
                        }

                        return {
                            ...data,
                            id: doc.id,
                            brandName,
                            brandWebsite: `http://localhost:4003/project-status/${doc.id}`,
                            uid: currentUser.uid
                        };
                    });

                const resolvedOrders = (await Promise.all(orderPromises)).filter(Boolean);
                setOrders(resolvedOrders);
                setError(null);
            } catch (err) {
                setError('Failed to load orders. Please try again later.');
                toast({
                    title: 'Error',
                    description: 'Failed to load orders. Please try again later.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [currentUser, status, toast]);

    if (loading) {
        return (
            <Box textAlign="center" py={10}>
                <Spinner size="xl" color="blue.500" />
                <Text mt={4}>Loading your orders...</Text>
            </Box>
        );
    }

    if (error) {
        return (
            <Alert status="error" borderRadius="md">
                <AlertIcon />
                {error}
            </Alert>
        );
    }

    if (orders.length === 0) {
        return (
            <Box 
                bg={bgColor} 
                p={6} 
                borderRadius="lg" 
                boxShadow="md" 
                textAlign="center"
            >
                <Heading size="md" mb={2}>
                    No {status} orders found
                </Heading>
                <Text>
                    {status === 'active' 
                        ? "You don't have any active orders at the moment." 
                        : "You don't have any completed orders yet."}
                </Text>
            </Box>
        );
    }

    return (
        <VStack spacing={6} align="stretch" width="100%">
            {orders.map(order => (
                <OrderCard 
                    key={order.id} 
                    order={order} 
                    userRole="creator"
                />
            ))}
        </VStack>
    );
};

export default CreatorWorkOrders; 