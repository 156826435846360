import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    VStack,
    Heading,
    Text,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Select,
    SimpleGrid,
    useToast,
    Progress,
    Image,
    Stack,
    Checkbox,
    CheckboxGroup,
    Tag,
    TagLabel,
    TagCloseButton,
    HStack,
    Flex,
    useColorModeValue,
    Radio,
    RadioGroup,
    Divider,
    Spinner,
    Alert,
    AlertIcon,
    FormHelperText,
} from '@chakra-ui/react';
import { doc, updateDoc, getDoc, collection, query, where, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import './CreatorProfileForm.css'; // Import the CSS file
import axios from 'axios';
import config from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import MediaUploader from '../../utils/MediaUploader';
import { generateCreatorProfile } from '../../utils/aiProfileGenerator';

const CreatorProfileForm = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [step, setStep] = useState(0); // Start with method selection step
    const [loading, setLoading] = useState(false);
    const [aiLoading, setAiLoading] = useState(false);
    const [aiError, setAiError] = useState('');
    const [profileMethod, setProfileMethod] = useState('manual');
    const [socialMediaUrl, setSocialMediaUrl] = useState('');
    const totalSteps = 10; // Decreased from 11 to 10
    
    // Color mode values for styling
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const headingColor = useColorModeValue('gray.900', 'gray.100');
    const textColor = useColorModeValue('gray.600', 'gray.400');
    
    const [formData, setFormData] = useState({
        name: '',
        displayName: '',
        email: '',
        bio: '',
        primaryCategory: '',
        country: '',
        city: '',
        location: '',
        profileImageUrl: '',
        coverImage: '',
        role: 'creator',
        username: '',
        languages: '',
        niche: '',
        
        // Social links
        socialLinks: {
            youtube: '',
            instagram: '',
            tiktok: '',
            twitter: '',
            twitch: '',
            facebook: '',
            linkedin: '',
            website: ''
        },
        
        // Followers
        followers: {
            youtube: '0',
            instagram: '0',
            tiktok: '0',
            twitter: '0',
            twitch: '0',
            facebook: '0'
        },
        
        // Demographics
        demographics: {
            genderDistribution: '',
        },
        
        // Audience info
        audienceInfo: {},
        audienceAge: '',
        ageRanges: [],
        locations: [],
        engagementTopics: [],
        interests: [],
        audienceInterests: '',
        audienceLocations: '',
        
        // Content types and categories
        contentTypes: [],
        preferredCategories: [],
        availableFor: [],
        
        // Metrics
        metrics: {
            engagementRate: '',
            contentPerformance: ''
        },
        engagementRate: '',
        contentPerformance: '',
        
        // Collaboration preferences
        collaborations: [
            {
                brandName: 'Apple',
                date: '',
                description: 'As a fintech influencer, I collaborated with Apple on a campaign spotlighting their innovative payment solutions, including Apple Pay and the Apple Card.',
                metrics: '5.2% Engagement'
            },
            {
                brandName: 'Google',
                date: '',
                description: 'Promoted Posts',
                metrics: ''
            }
        ],
        galleryPhotos: [],
        portfolioMedia: [],
    });

    const [galleryUploading, setGalleryUploading] = useState(false);
    const [galleryError, setGalleryError] = useState(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // Add this state near the beginning of the component, with the other state variables
    const [offerCards, setOfferCards] = useState([
        {
            id: 1,
            type: 'Instagram Photo',
            quantity: '',
            price: '',
            description: '',
            expectedViews: '',
            isEditingDescription: false
        },
        {
            id: 2,
            type: 'TikTok Video',
            quantity: '',
            price: '',
            description: '',
            expectedViews: '',
            isEditingDescription: false
        }
    ]);

    // Add this state near the beginning of the component, with the other state variables
    const [creatorOffers, setCreatorOffers] = useState([]);
    const [offersLoading, setOffersLoading] = useState(false);

    // Add a new state to track validation errors
    const [validationErrors, setValidationErrors] = useState({});

    // Add new state variables at the beginning of the component
    const [mediaUploading, setMediaUploading] = useState(false);
    const [mediaError, setMediaError] = useState(null);

    // Add this near your other state declarations
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchError, setSearchError] = useState('');

    // Add this console log near the top of your component
    console.log('API Key:', process.env.REACT_APP_GEOAPIFY_API_KEY);

    // Load existing data if available
    useEffect(() => {
        const loadUserData = async () => {
            try {
                const userId = auth.currentUser?.uid;
                if (!userId) return;
                
                const userDoc = await getDoc(doc(db, 'users', userId));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setFormData(prevData => ({
                        ...prevData,
                        ...userData,
                        // Ensure nested objects are properly merged
                        socialLinks: { ...prevData.socialLinks, ...userData.socialLinks },
                        followers: { ...prevData.followers, ...userData.followers },
                        demographics: { ...prevData.demographics, ...userData.demographics },
                        metrics: { ...prevData.metrics, ...userData.metrics },
                        // Handle top-level fields that might also exist in nested objects
                        engagementRate: userData.engagementRate || userData.metrics?.engagementRate || '',
                        contentPerformance: userData.contentPerformance || userData.metrics?.contentPerformance || '',
                        audienceInterests: userData.audienceInterests || '',
                        audienceLocations: userData.audienceLocations || '',
                        audienceAge: userData.audienceAge || '',
                        ageRanges: userData.ageRanges || [],
                        locations: userData.locations || [],
                        engagementTopics: userData.engagementTopics || [],
                        interests: userData.interests || []
                    }));
                }
            } catch (error) {
                console.error("Error loading user data:", error);
            }
        };
        
        loadUserData();
    }, []);

    // Monitor online/offline status
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Add this useEffect to fetch creator offers
    useEffect(() => {
        const fetchCreatorOffers = async () => {
            try {
                const userId = auth.currentUser?.uid;
                if (!userId) return;
                
                setOffersLoading(true);
                
                // Query the creatorOffers collection for this creator
                const offersQuery = query(
                    collection(db, 'creatorOffers'), 
                    where('creatorId', '==', userId)
                );
                
                const offersSnapshot = await getDocs(offersQuery);
                const offersData = offersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                // Convert offers to the format needed for offer cards
                const formattedOffers = offersData.map(offer => ({
                    id: offer.id,
                    type: offer.type || 'Instagram Photo',
                    quantity: offer.numberOfPosts || '1',
                    price: offer.price?.toString() || '',
                    description: offer.description || '',
                    expectedViews: offer.expectedViews || '',
                    isEditingDescription: false,
                    // Store the original offer data for reference
                    originalOffer: offer
                }));
                
                // Update the offer cards state
                setOfferCards(formattedOffers.length > 0 ? formattedOffers : [
                    {
                        id: 1,
                        type: 'Instagram Photo',
                        quantity: '',
                        price: '',
                        description: '',
                        expectedViews: '',
                        isEditingDescription: false
                    },
                    {
                        id: 2,
                        type: 'TikTok Video',
                        quantity: '',
                        price: '',
                        description: '',
                        expectedViews: '',
                        isEditingDescription: false
                    }
                ]);
                
                setCreatorOffers(offersData);
            } catch (error) {
                console.error("Error loading creator offers:", error);
            } finally {
                setOffersLoading(false);
            }
        };
        
        fetchCreatorOffers();
    }, [auth.currentUser?.uid]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        // Special handling for username field
        if (name === 'username') {
            // Remove periods from the input value
            const sanitizedValue = value.replace(/\./g, '');
            
            // Update the form data with the sanitized value
            setFormData({
                ...formData,
                [name]: sanitizedValue
            });
        } else {
            // Normal handling for other fields
            setFormData({
                ...formData,
            [name]: value
            });
        }
    };

    const handleNestedInputChange = (category, field, value) => {
        setFormData(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [field]: value
            }
        }));
    };

    const handleArrayChange = (field, values) => {
        setFormData(prev => ({
            ...prev,
            [field]: values
        }));
    };

    const handleCheckboxChange = (field, value, isChecked) => {
        setFormData(prev => {
            const currentValues = prev[field] || [];
            if (isChecked) {
                return { ...prev, [field]: [...currentValues, value] };
            } else {
                return { ...prev, [field]: currentValues.filter(item => item !== value) };
            }
        });
    };

    const handleCollaborationChange = (index, field, value) => {
        setFormData(prev => {
            const updatedCollaborations = [...prev.collaborations];
            updatedCollaborations[index] = {
                ...updatedCollaborations[index],
                [field]: value
            };
            return {
                ...prev,
                collaborations: updatedCollaborations
            };
        });
    };

    const addCollaboration = () => {
        setFormData(prev => ({
            ...prev,
            collaborations: [
                ...prev.collaborations,
                { brandName: '', date: '', description: '', metrics: '' }
            ]
        }));
    };

    const removeCollaboration = (index) => {
        setFormData(prev => ({
            ...prev,
            collaborations: prev.collaborations.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async () => {
            setLoading(true);
        
        try {
            // Create a copy of the form data for submission
            const dataToSubmit = { ...formData };
            
            // Use the displayName value for the username field (removing any periods)
            if (dataToSubmit.displayName) {
                const sanitizedDisplayName = dataToSubmit.displayName.replace(/\./g, '');
                dataToSubmit.username = sanitizedDisplayName;
            }
            
            console.log("Submitting data to Firestore:", dataToSubmit); // Debug log
            
            const userId = auth.currentUser.uid;
            const userRef = doc(db, 'users', userId);
            
            // Calculate total followers
            const totalFollowers = Object.values(dataToSubmit.followers)
                .reduce((sum, count) => sum + parseInt(count || '0'), 0);

            // Prepare data for submission
            const userData = {
                ...dataToSubmit,
                // Ensure metrics are properly structured
                metrics: {
                    ...dataToSubmit.metrics,
                    contentPerformance: dataToSubmit.contentPerformance || dataToSubmit.metrics.contentPerformance,
                    engagementRate: dataToSubmit.engagementRate || dataToSubmit.metrics.engagementRate
                },
                // Ensure demographics are properly structured
                demographics: {
                    ...dataToSubmit.demographics,
                    genderDistribution: dataToSubmit.demographics.genderDistribution || ''
                },
                statistics: {
                    totalFollowers,
                    averageLikes: '',
                    averageViews: '',
                    engagementRate: dataToSubmit.engagementRate || dataToSubmit.metrics.engagementRate || ''
                },
                createdAt: dataToSubmit.createdAt || new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                uid: userId
            };

            await updateDoc(userRef, userData);

            toast({
                title: 'Profile Updated!',
                description: "Your creator profile has been set up successfully.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            navigate('/creator/profile');
        } catch (error) {
            console.error("Error updating profile:", error);
            toast({
                title: 'Error',
                description: 'Failed to update profile.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // Function to handle AI profile generation
    const generateProfileFromSocialMedia = async () => {
        if (!socialMediaUrl) {
            setValidationErrors({ socialMediaUrl: "Please enter a valid social media URL" });
            setAiError('Please enter a valid social media URL');
            return;
        }

        setAiLoading(true);
        setAiError('');
        setValidationErrors({});

        try {
            let platform;
            let username;
            let cleanUrl = socialMediaUrl.trim();

            // Check if input is just a username (no URL)
            if (!cleanUrl.includes('http') && !cleanUrl.includes('.com') && !cleanUrl.startsWith('@')) {
                setValidationErrors({ 
                    socialMediaUrl: "Please enter a complete social media URL. For example:\n" +
                    "Instagram: instagram.com/username\n" +
                    "TikTok: tiktok.com/@username\n" +
                    "YouTube: youtube.com/@username\n" +
                    "Twitter: twitter.com/username"
                });
                throw new Error("Please enter a complete social media URL");
            }

            // Clean the URL (remove trailing slashes, query params)
            cleanUrl = cleanUrl.split('?')[0].replace(/\/+$/, '');
            
            // Get platform from URL
            platform = getSocialMediaPlatform(cleanUrl);
            
            // Extract username based on platform
            switch (platform) {
                case 'instagram':
                    const instaMatch = cleanUrl.match(/(?:instagram\.com\/)([^/?]+)/);
                    username = instaMatch?.[1] || '';
                    if (!username) {
                        throw new Error('Please use format: instagram.com/username');
                    }
                    break;

                case 'tiktok':
                    const tiktokMatch = cleanUrl.match(/(?:tiktok\.com\/)?(@[^/?]+)/) || 
                                      cleanUrl.match(/^@([^/?]+)/);
                    username = tiktokMatch?.[1]?.replace('@', '') || '';
                    if (!username) {
                        throw new Error('Please use format: tiktok.com/@username');
                    }
                    break;

                case 'youtube':
                    const channelMatch = cleanUrl.match(/youtube\.com\/(?:channel|user)\/([^/?]+)/);
                    const handleMatch = cleanUrl.match(/youtube\.com\/@([^/?]+)/);
                    username = channelMatch?.[1] || handleMatch?.[1] || '';
                    if (!username) {
                        throw new Error('Please use format: youtube.com/@username');
                    }
                    break;

                case 'twitter':
                    const twitterMatch = cleanUrl.match(/(?:twitter\.com|x\.com)\/([^/?]+)/);
                    username = twitterMatch?.[1] || '';
                    if (!username) {
                        throw new Error('Please use format: twitter.com/username');
                    }
                    break;

                case 'website':
                    throw new Error(
                        "Please enter a complete social media URL:\n" +
                        "• Instagram: instagram.com/username\n" +
                        "• TikTok: tiktok.com/@username\n" +
                        "• YouTube: youtube.com/@username\n" +
                        "• Twitter: twitter.com/username"
                    );

                default:
                    throw new Error(`Unsupported platform: ${platform}. Please use Instagram, TikTok, YouTube, or Twitter.`);
            }

            // Additional validation
            if (username.includes('/') || username.length < 1) {
                throw new Error(`Invalid username format for ${platform}. Please enter the complete URL.`);
            }

            console.log(`Extracted username '${username}' from ${platform} URL`); // Debug log
            
            // Generate profile using AI
            const aiGeneratedData = await generateCreatorProfile(platform, username);
            
            // Update form data with AI-generated content
            setFormData(prev => ({
                ...prev,
                ...aiGeneratedData,
                socialLinks: {
                    ...prev.socialLinks,
                    [platform]: username
                },
                followers: {
                    ...prev.followers,
                    [platform]: aiGeneratedData.followers[platform]
                },
                metrics: {
                    ...prev.metrics,
                    ...aiGeneratedData.audienceInfo.metrics
                },
                demographics: {
                    ...prev.demographics,
                    ...aiGeneratedData.audienceInfo.demographics
                },
                interests: aiGeneratedData.audienceInfo.interests || [],
                engagementTopics: aiGeneratedData.audienceInfo.engagementTopics || []
            }));
            
            // Show success message
            toast({
                title: "Profile Generated",
                description: "We've analyzed your social media profile and pre-filled your information. Please review and make any necessary adjustments.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            
            // Move to the next step
            setStep(1);
        } catch (error) {
            console.error("Error generating profile:", error);
            setAiError(error.message || 'Failed to generate profile. Please try again or use manual entry.');
            setValidationErrors({ socialMediaUrl: error.message });
        } finally {
            setAiLoading(false);
        }
    };
    
    // Helper function to determine social media platform from URL
    const getSocialMediaPlatform = (url) => {
        if (url.includes('instagram')) return 'instagram';
        if (url.includes('tiktok')) return 'tiktok';
        if (url.includes('youtube')) return 'youtube';
        if (url.includes('twitter') || url.includes('x.com')) return 'twitter';
        if (url.includes('facebook')) return 'facebook';
        if (url.includes('linkedin')) return 'linkedin';
        return 'website';
    };

    // Update the handleGalleryPhotoUpload function
    const handleGalleryPhotoUpload = async (e) => {
        const files = Array.from(e.target.files);
        const maxFileSize = 5 * 1024 * 1024; // 5MB limit

        if (!isOnline) {
            setGalleryError('You are offline. Please check your internet connection.');
            return;
        }

        setGalleryUploading(true);
        setGalleryError(null);

        try {
            // Check if adding these files would exceed the 6 photo limit
            if (formData.galleryPhotos.length + files.length > 6) {
                throw new Error('Maximum 6 photos allowed. Please remove some photos first.');
            }

            // Check file sizes
            const oversizedFiles = files.filter(file => file.size > maxFileSize);
            if (oversizedFiles.length > 0) {
                throw new Error(`Some files exceed the 5MB limit. Please compress your images and try again.`);
            }

            // Get the current user's token
            const token = await auth.currentUser.getIdToken();

            // Upload files one by one
            const uploadedPhotos = [];
            
            for (const file of files) {
                try {
                    // Create a FormData object
                    const formData = new FormData();
                    formData.append('image', file);
                    
                    console.log('Uploading file:', file.name, 'Size:', file.size, 'Type:', file.type);
                    
                    // Use fetch for the upload
                    const response = await fetch(
                        `${config.api.baseURL}/upload/upload-gallery-photo`,
                        {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`
                                // Don't set Content-Type header - fetch will set it automatically with boundary for FormData
                        },
                        body: formData
                        }
                    );
                    
                    if (!response.ok) {
                        const errorData = await response.json().catch(() => ({}));
                        throw new Error(errorData.message || `Upload failed with status: ${response.status}`);
                    }
                    
                    const data = await response.json();
                    console.log('Upload response:', data);
                    
                    if (!data.success) {
                        throw new Error(data.message || 'Upload failed');
                    }
                    
                    uploadedPhotos.push({
                        public_id: data.public_id,
                        url: data.secure_url,
                        caption: ''
                    });
                } catch (error) {
                    console.error('Error uploading file:', file.name, error);
                    throw error;
                }
            }
            
            // Update Firestore with new gallery photos
            const userRef = doc(db, 'users', auth.currentUser.uid);
            const updatedPhotos = [...formData.galleryPhotos, ...uploadedPhotos];
            await updateDoc(userRef, {
                galleryPhotos: updatedPhotos
            });

            setFormData(prev => ({
                ...prev,
                galleryPhotos: updatedPhotos
            }));

            toast({
                title: 'Photos uploaded successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error uploading photos:', error);
            setGalleryError(error.message || 'There was an error uploading your photos. Please try again.');
            toast({
                title: 'Upload failed',
                description: error.message || 'There was an error uploading your photos. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setGalleryUploading(false);
        }
    };

    const handleGalleryPhotoDelete = (indexToDelete) => {
        setFormData(prev => ({
            ...prev,
            galleryPhotos: prev.galleryPhotos.filter((_, index) => index !== indexToDelete)
        }));
    };

    const handleGalleryCaptionChange = (index, caption) => {
        setFormData(prev => ({
            ...prev,
            galleryPhotos: prev.galleryPhotos.map((photo, i) => 
                i === index ? { ...photo, caption } : photo
            )
        }));
    };

    // Update the handleAddOfferCard function to create a new offer in Firestore
    const handleAddOfferCard = async () => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;
            
            // Create a new offer in Firestore
            const newOffer = {
                creatorId: userId,
                type: 'Instagram Photo',
                title: 'New Content Package',
                description: '',
                price: 0,
                expectedViews: '',
                deliveryTime: 7,
                requirements: '',
                platforms: ['Instagram'],
                numberOfPosts: '1',
                maxRevisions: '2',
                includes: [],
                status: 'active',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            };
            
            // Add the new offer to Firestore
            const offerRef = await addDoc(collection(db, 'creatorOffers'), newOffer);
            
            // Add the new offer to the state
            const newId = offerRef.id;
            setOfferCards([...offerCards, {
                id: newId,
                type: 'Instagram Photo',
                quantity: '1',
                price: '',
                description: '',
                expectedViews: '',
                isEditingDescription: false,
                originalOffer: { ...newOffer, id: newId }
            }]);
            
            // Update the creatorOffers state
            setCreatorOffers([...creatorOffers, { ...newOffer, id: newId }]);
            
            toast({
                title: 'New offer created',
                description: 'Your new content package has been created.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error creating new offer:", error);
            toast({
                title: 'Error',
                description: 'Failed to create new content package.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    // Update the handleRemoveOfferCard function to delete the offer from Firestore
    const handleRemoveOfferCard = async (id) => {
        try {
            // Find the offer in the state
            const offerToRemove = offerCards.find(card => card.id === id);
            
            if (offerToRemove?.originalOffer?.id) {
                // Delete the offer from Firestore
                await deleteDoc(doc(db, 'creatorOffers', offerToRemove.originalOffer.id));
                
                // Update the creatorOffers state
                setCreatorOffers(creatorOffers.filter(offer => offer.id !== offerToRemove.originalOffer.id));
            }
            
            // Update the offerCards state
            setOfferCards(offerCards.filter(card => card.id !== id));
            
            toast({
                title: 'Offer removed',
                description: 'Your content package has been removed.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error removing offer:", error);
            toast({
                title: 'Error',
                description: 'Failed to remove content package.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    // Update the handleOfferCardChange function to update the offer in Firestore
    const handleOfferCardChange = async (id, field, value) => {
        // Update the local state first for immediate feedback
        setOfferCards(offerCards.map(card => 
            card.id === id ? { ...card, [field]: value } : card
        ));
        
        // Find the offer in the state
        const offerToUpdate = offerCards.find(card => card.id === id);
        
        // If this is a real offer (has an originalOffer with an id), update it in Firestore
        if (offerToUpdate?.originalOffer?.id) {
            try {
                // Map the field to the corresponding field in the Firestore document
                let firestoreField = field;
                let firestoreValue = value;
                
                // Handle special mappings
                if (field === 'quantity') {
                    firestoreField = 'numberOfPosts';
                } else if (field === 'price') {
                    firestoreValue = Number(value) || 0;
                }
                
                // Update the offer in Firestore
                await updateDoc(doc(db, 'creatorOffers', offerToUpdate.originalOffer.id), {
                    [firestoreField]: firestoreValue,
                    updatedAt: new Date().toISOString()
                });
                
                // Update the creatorOffers state
                setCreatorOffers(creatorOffers.map(offer => 
                    offer.id === offerToUpdate.originalOffer.id 
                        ? { ...offer, [firestoreField]: firestoreValue, updatedAt: new Date().toISOString() } 
                        : offer
                ));
            } catch (error) {
                console.error("Error updating offer:", error);
            }
        }
    };

    // Update the toggleDescriptionEdit function to save the description to Firestore when done editing
    const toggleDescriptionEdit = async (id, isEditing) => {
        // Update the local state
        setOfferCards(offerCards.map(card => 
            card.id === id ? { ...card, isEditingDescription: isEditing } : card
        ));
        
        // If we're finishing editing, save to Firestore
        if (!isEditing) {
            const offerToUpdate = offerCards.find(card => card.id === id);
            
            if (offerToUpdate?.originalOffer?.id && offerToUpdate.description !== offerToUpdate.originalOffer.description) {
                try {
                    // Update the description in Firestore
                    await updateDoc(doc(db, 'creatorOffers', offerToUpdate.originalOffer.id), {
                        description: offerToUpdate.description,
                        updatedAt: new Date().toISOString()
                    });
                    
                    // Update the creatorOffers state
                    setCreatorOffers(creatorOffers.map(offer => 
                        offer.id === offerToUpdate.originalOffer.id 
                            ? { ...offer, description: offerToUpdate.description, updatedAt: new Date().toISOString() } 
                            : offer
                    ));
                } catch (error) {
                    console.error("Error updating offer description:", error);
                }
            }
        }
    };

    // Add this function to validate each step
    const validateStep = (currentStep) => {
        let errors = {};
        let isValid = true;
        
        switch(currentStep) {
            case 0: // Method Selection
                if (profileMethod === 'ai' && !socialMediaUrl) {
                    errors.socialMediaUrl = "Please enter a social media URL";
                    isValid = false;
                }
                break;
                
            case 1: // Display Name
                if (!formData.displayName || formData.displayName.trim() === '') {
                    errors.displayName = "Display name is required";
                    isValid = false;
                }
                break;
                
            case 2: // Location
                if (!formData.country || formData.country.trim() === '') {
                    errors.country = "Country is required";
                    isValid = false;
                }
                break;
                
            case 3: // Profile Picture
                // Profile picture is optional, but we could add validation if needed
                break;
                
            case 4: // Gallery Photos
                // Gallery photos are optional
                break;
                
            case 5: // New Portfolio Media step
                // Gallery photos are optional
                break;
                
            case 6: // Primary Category
                if (!formData.primaryCategory) {
                    errors.primaryCategory = "Please select a primary category";
                    isValid = false;
                }
                break;
                
            case 7: // Bio
                if (!formData.bio || formData.bio.trim() === '') {
                    errors.bio = "Bio is required";
                    isValid = false;
                }
                break;
                
            case 8: // Social Media Links
                // Check if at least one social media platform has a username
                const hasSocialMedia = Object.values(formData.socialLinks).some(link => link && link.trim() !== '');
                if (!hasSocialMedia) {
                    errors.socialLinks = "Please add at least one social media account";
                    isValid = false;
                }
                break;
                
            case 9: // Content Packages
                // Check if at least one offer card has a price
                const hasValidOffer = offerCards.some(card => 
                    card.price && parseFloat(card.price) > 0 && 
                    card.quantity && parseInt(card.quantity) > 0
                );
                
                if (!hasValidOffer) {
                    errors.offerCards = "Please complete at least one content package with quantity and price";
                    isValid = false;
                }
                break;
                
            default:
                break;
        }
        
        setValidationErrors(errors);
        return isValid;
    };

    // Modify the button click handler to validate before proceeding
    const handleNextStep = () => {
        if (step === 0 && profileMethod === 'ai') {
            // For AI method, we'll handle this separately
            generateProfileFromSocialMedia();
            return;
        }
        
        if (validateStep(step)) {
            if (step < totalSteps - 1) {
                setStep(step + 1);
                // Clear validation errors when moving to next step
                setValidationErrors({});
            } else {
                handleSubmit();
            }
        } else {
            // Show toast for validation errors
            toast({
                title: "Required Fields Missing",
                description: "Please fill out all required fields before continuing.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Update this function to include Firestore update
    const handlePortfolioMediaDelete = async (indexToDelete) => {
        try {
            // Update local state
            const updatedMedia = formData.portfolioMedia.filter((_, index) => index !== indexToDelete);
            
            // Update Firestore
            const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, {
                portfolioMedia: updatedMedia
            });

            // Update local state after successful Firestore update
            setFormData(prev => ({
                ...prev,
                portfolioMedia: updatedMedia
            }));

            toast({
                title: 'Media deleted',
                description: 'Portfolio item has been removed',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting media:', error);
            toast({
                title: 'Error',
                description: 'Failed to delete media',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Add this debounce function near your other utility functions
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    // Add this search function
    const searchCities = async (query) => {
        if (!query || query.length < 2) {
            setSearchResults([]);
            setSearchError('');
            return;
        }

        // Add this check
        if (!process.env.REACT_APP_GEOAPIFY_API_KEY) {
            console.error('API key is missing!');
            setSearchError('Configuration error: API key is missing');
            return;
        }

        setIsSearching(true);
        setSearchError('');

        const apiKey = process.env.REACT_APP_GEOAPIFY_API_KEY;
        console.log('Using API key:', apiKey); // Debug log

        try {
            const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(query)}&type=city&format=json&apiKey=${apiKey}`;
            console.log('Request URL:', url); // Debug log

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Failed to fetch cities');
            }

            const data = await response.json();
            console.log('API Response:', data); // Debug log
            
            if (data.results && Array.isArray(data.results)) {
                const formattedResults = data.results
                    .filter(result => result.city && result.country)
                    .map(result => ({
                        city: result.city,
                        country: result.country,
                        state: result.state,
                        formatted: result.formatted
                    }))
                    .slice(0, 5);

                setSearchResults(formattedResults);
            } else {
                setSearchResults([]);
            }
        } catch (error) {
            console.error('Error searching cities:', error);
            setSearchError('Unable to search cities at this time');
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    // Create debounced version of the search function
    const debouncedSearch = debounce(searchCities, 300);

    const renderStep = () => {
        switch(step) {
            case 0: // Method Selection
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Create Your Profile</Heading>
                        <Text color={textColor} fontSize="sm" className="step-description">Choose how you'd like to set up your profile</Text>
                        
                        <RadioGroup onChange={setProfileMethod} value={profileMethod} mb={4}>
                            <VStack spacing={4} align="stretch">
                                <Radio value="manual">
                                    <Text fontWeight="medium">Manual Setup</Text>
                                    <Text fontSize="sm" color={textColor}>Fill out each section of your profile manually</Text>
                                </Radio>
                                
                                <Radio value="ai">
                                    <Text fontWeight="medium">AI-Assisted Setup</Text>
                                    <Text fontSize="sm" color={textColor}>Let our AI analyze your social media profile and pre-fill your information</Text>
                                </Radio>
                            </VStack>
                        </RadioGroup>
                        
                        {profileMethod === 'ai' && (
                            <>
                                <FormControl isRequired isInvalid={!!validationErrors.socialMediaUrl}>
                                    <FormLabel fontWeight="medium" fontSize="sm">Social Media URL</FormLabel>
                                    <Input 
                                        value={socialMediaUrl}
                                        onChange={(e) => setSocialMediaUrl(e.target.value)}
                                        placeholder="Paste your Instagram, TikTok, YouTube, etc. profile URL"
                                        size="sm"
                                    />
                                    {validationErrors.socialMediaUrl && (
                                        <Text color="red.500" fontSize="xs" mt={1}>
                                            {validationErrors.socialMediaUrl}
                                        </Text>
                                    )}
                                    <Text fontSize="xs" color={textColor} mt={1}>
                                        We'll analyze your public profile to pre-fill your information
                                    </Text>
                                </FormControl>
                                
                                {aiError && (
                                    <Alert status="error" mt={2} size="sm" borderRadius="md">
                                        <AlertIcon />
                                        {aiError}
                                    </Alert>
                                )}
                                
                                <Button
                                    mt={4}
                                    colorScheme="blue"
                                    onClick={generateProfileFromSocialMedia}
                                    isLoading={aiLoading}
                                    loadingText="Analyzing Profile"
                                    size="sm"
                                    width="full"
                                >
                                    Generate Profile
                                </Button>
                                
                                <Divider my={4} />
                                
                                <Text fontSize="sm" color={textColor} textAlign="center">
                                    Or continue with manual setup
                                </Text>
                            </>
                        )}
                    </VStack>
                );
                
            case 1: // Basic Information (Display Name)
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Add Display Name</Heading>
                        <Text color={textColor} fontSize="sm" className="step-description">This is how you'll be known on the platform</Text>
                        
                        <FormControl isRequired isInvalid={!!validationErrors.displayName} className="form-field">
                            <Input 
                                name="displayName" 
                                value={formData.displayName} 
                                onChange={handleInputChange} 
                                placeholder="Your display name"
                                size="sm"
                                className="form-input"
                            />
                            {validationErrors.displayName && (
                                <Text color="red.500" fontSize="xs" mt={1}>
                                    {validationErrors.displayName}
                                </Text>
                            )}
                        </FormControl>
                    </VStack>
                );

            case 2: // Location step
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Where are you from?</Heading>
                        
                        <FormControl className="form-field">
                            <Box position="relative">
                                <Input
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSearchTerm(value);
                                        debouncedSearch(value);
                                    }}
                                    placeholder="Start typing your city name..."
                                    size="md"
                                    mb={2}
                                />
                                
                                {/* Loading indicator */}
                                {isSearching && (
                                    <Spinner
                                        size="sm"
                                        position="absolute"
                                        right="10px"
                                        top="10px"
                                    />
                                )}
                                
                                {/* Search Results Dropdown */}
                                {searchResults.length > 0 && (
                                    <Box
                                        position="absolute"
                                        top="100%"
                                        left={0}
                                        right={0}
                                        bg="white"
                                        boxShadow="md"
                                        borderRadius="md"
                                        maxH="200px"
                                        overflowY="auto"
                                        zIndex={1000}
                                        border="1px solid"
                                        borderColor="gray.200"
                                    >
                                        {searchResults.map((result, index) => (
                                            <Box
                                                key={index}
                                                p={2}
                                                cursor="pointer"
                                                _hover={{ bg: "gray.100" }}
                                                onClick={() => {
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        city: result.city,
                                                        country: result.country
                                                    }));
                                                    setSearchTerm(result.formatted);
                                                    setSearchResults([]);
                                                }}
                                            >
                                                {result.formatted}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Box>

                            {/* Error Message */}
                            {searchError && (
                                <Text color="red.500" fontSize="sm" mt={2}>
                                    {searchError}
                                </Text>
                            )}

                            {/* Selected Location Display */}
                            {formData.city && formData.country && (
                                <Text mt={2} fontSize="sm" color="gray.600">
                                    Selected: {formData.city}, {formData.country}
                                </Text>
                            )}
                            
                            <Text fontSize="xs" color={textColor} mt={1} className="field-hint">
                                This helps brands find creators in specific regions
                            </Text>
                        </FormControl>
                    </VStack>
                );

            case 3: // Profile Picture (simplified)
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Add a Profile Photo</Heading>
                        
                        <FormControl className="form-field">
                            <div className="avatar-upload-container">
                                {formData.profileImageUrl ? (
                                    <div className="avatar-preview">
                                        <Image 
                                            src={formData.profileImageUrl} 
                                            alt="Profile" 
                                            borderRadius="full" 
                                            boxSize="120px" 
                                            objectFit="cover"
                                        />
                                        <Button 
                                            size="xs" 
                                            colorScheme="gray" 
                                            variant="ghost" 
                                            onClick={() => setFormData({...formData, profileImageUrl: ''})}
                                            className="remove-avatar-btn"
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="avatar-upload">
                                        <Input 
                                            type="file" 
                                            accept="image/*" 
                                            onChange={(e) => {
                                                // In a real implementation, you would upload to storage
                                                // For now, we'll use a placeholder or FileReader for preview
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onload = (e) => {
                                                        setFormData({...formData, profileImageUrl: e.target.result});
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                            display="none"
                                            id="avatar-upload"
                                        />
                                        <Button 
                                            as="label" 
                                            htmlFor="avatar-upload" 
                                            colorScheme="gray" 
                                            variant="outline" 
                                            size="sm"
                                            className="upload-avatar-btn"
                                        >
                                            Upload Profile Picture
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <Text fontSize="xs" color={textColor} mt={4} textAlign="center" className="field-hint">
                                A clear photo helps brands recognize you and builds trust
                            </Text>
                        </FormControl>
                    </VStack>
                );

            case 4: // Gallery Photos (moved from case 3)
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Add Profile Gallery</Heading>
                        <Text color={textColor} fontSize="sm" className="step-description">
                            Upload photos to showcase your work (max 6 photos)
                        </Text>
                        
                        <FormControl className="form-field">
                            <div className="gallery-upload-container">
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handleGalleryPhotoUpload}
                                    disabled={galleryUploading || formData.galleryPhotos?.length >= 6}
                                    style={{ display: 'none' }}
                                    id="gallery-photo-input"
                                />
                                <label 
                                    htmlFor="gallery-photo-input" 
                                    style={{
                                        display: 'inline-block',
                                        padding: '0.75rem 1.5rem',
                                        backgroundColor: formData.galleryPhotos?.length >= 6 ? '#e2e8f0' : '#f0f0f0',
                                        border: '2px dashed #ccc',
                                        borderRadius: '8px',
                                        cursor: formData.galleryPhotos?.length >= 6 ? 'not-allowed' : 'pointer',
                                        textAlign: 'center',
                                        opacity: !isOnline || galleryUploading ? 0.6 : 1
                                    }}
                                >
                                    {galleryUploading ? 'Uploading...' : 
                                     !isOnline ? 'Offline - Please check your connection' :
                                     formData.galleryPhotos?.length >= 6 ? 'Maximum photos reached' : 
                                     'Upload Photos'}
                                </label>
                                
                                {galleryError && (
                                    <Alert status="error" mt={2} size="sm">
                                        <AlertIcon />
                                        {galleryError}
                                    </Alert>
                                )}
                                
                                <SimpleGrid columns={[1, 2, 3]} spacing={4} mt={4}>
                                    {formData.galleryPhotos?.map((photo, index) => (
                                        <Box 
                                            key={index} 
                                            position="relative" 
                                            borderRadius="md" 
                                            overflow="hidden"
                                            boxShadow="sm"
                                        >
                                            <Image 
                                                src={photo.url} 
                                                alt={`Gallery ${index + 1}`}
                                                objectFit="cover"
                                                height="200px"
                                                width="100%"
                                            />
                                            <Button
                                                position="absolute"
                                                top={2}
                                                right={2}
                                                size="sm"
                                                colorScheme="red"
                                                onClick={() => handleGalleryPhotoDelete(index)}
                                                disabled={galleryUploading}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </div>
                            <Text fontSize="xs" color={textColor} mt={4}>
                                Add up to 6 photos to showcase your best work. Each photo must be under 5MB.
                            </Text>
                        </FormControl>
                    </VStack>
                );

            case 5: // Portfolio Media step
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Add Portfolio Media</Heading>
                        <Text color={textColor} fontSize="sm" className="step-description">
                            Add your best performing content to showcase to brands
                        </Text>
                        
                        <FormControl className="form-field">
                            <div className="portfolio-upload-container">
                                <MediaUploader 
                                    onUploadSuccess={async (mediaInfo) => {
                                        try {
                                            // Create a new media item
                                            const newMediaItem = {
                                                id: Date.now().toString(),
                                                url: mediaInfo.secure_url || mediaInfo.url,
                                                public_id: mediaInfo.public_id,
                                                type: mediaInfo.resource_type || (mediaInfo.url?.includes('video') ? 'video' : 'image'),
                                                title: mediaInfo.title || '',
                                                description: mediaInfo.description || '',
                                                createdAt: new Date().toISOString()
                                            };

                                            // Create updated media array
                                            const updatedMedia = [...(formData.portfolioMedia || []), newMediaItem];

                                            // Update Firestore first
                                            const userRef = doc(db, 'users', auth.currentUser.uid);
                                            await updateDoc(userRef, {
                                                portfolioMedia: updatedMedia
                                            });

                                            // Update local state after successful Firestore update
                                            setFormData(prev => ({
                                                ...prev,
                                                portfolioMedia: updatedMedia
                                            }));

                                            toast({
                                                title: 'Media uploaded successfully',
                                                status: 'success',
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                        } catch (error) {
                                            console.error('Error saving media:', error);
                                            toast({
                                                title: 'Error',
                                                description: 'Failed to save media to your profile',
                                                status: 'error',
                                                duration: 5000,
                                                isClosable: true,
                                            });
                                        }
                                    }}
                                    buttonText="Upload Media Files"
                                    acceptTypes="image/*,video/*"
                                />
                                
                                {mediaError && (
                                    <Alert status="error" mt={2} size="sm">
                                        <AlertIcon />
                                        {mediaError}
                                    </Alert>
                                )}
                                
                                <SimpleGrid columns={[1, 2, 3]} spacing={4} mt={4}>
                                    {formData.portfolioMedia?.map((media, index) => (
                                        <Box 
                                            key={index} 
                                            position="relative" 
                                            borderRadius="md" 
                                            overflow="hidden"
                                            boxShadow="sm"
                                        >
                                            {media.type === 'video' ? (
                                                <video 
                                                    src={media.url} 
                                                    controls 
                                                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <Image 
                                                    src={media.url} 
                                                    alt={`Portfolio ${index + 1}`}
                                                    objectFit="cover"
                                                    height="200px"
                                                    width="100%"
                                                />
                                            )}
                                            <Button
                                                position="absolute"
                                                top={2}
                                                right={2}
                                                size="sm"
                                                colorScheme="red"
                                                onClick={() => handlePortfolioMediaDelete(index)}
                                                disabled={mediaUploading}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </div>
                            <Text fontSize="xs" color={textColor} mt={4}>
                                Upload your best performing content. Supported formats: images and videos up to 50MB.
                            </Text>
                        </FormControl>
                    </VStack>
                );

            case 6: // Primary Category (moved from case 7)
                return (
                    <VStack spacing={4} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Primary Category</Heading>
                        <Text color={textColor} fontSize="sm" className="step-description">Select your main content category</Text>
                        
                        <FormControl isRequired isInvalid={!!validationErrors.primaryCategory} className="form-field">
                            <Select 
                                name="primaryCategory" 
                                value={formData.primaryCategory} 
                                onChange={handleInputChange}
                                placeholder="Select your main category"
                                size="md"
                                color="black"
                                borderColor="gray.300"
                            >
                                <option value="crypto">Crypto</option>
                                <option value="investing">Investing</option>
                                <option value="budgeting">Budgeting</option>
                                <option value="banking">Banking</option>
                                <option value="retirement">Retirement</option>
                                <option value="fintech">Fintech</option>
                                <option value="real_estate">Real Estate</option>
                                <option value="credit">Credit</option>
                                <option value="financial_education">Financial Education</option>
                                <option value="student_finance">Student Finance</option>
                                <option value="business_finance">Business Finance</option>
                                <option value="sustainable_finance">Sustainable Finance</option>
                                <option value="innovation">Innovation</option>
                                <option value="security">Security</option>
                            </Select>
                            {validationErrors.primaryCategory && (
                                <Text color="red.500" fontSize="xs" mt={1}>
                                    {validationErrors.primaryCategory}
                                </Text>
                            )}
                            <Text fontSize="xs" color={textColor} mt={2}>
                                This helps brands find creators in their niche
                            </Text>
                        </FormControl>
                    </VStack>
                );

            case 7: // Bio (moved from case 5)
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Tell Us About Your Content</Heading>
                        
                        <FormControl isRequired isInvalid={!!validationErrors.bio} className="form-field">
                            <Textarea 
                                name="bio" 
                                value={formData.bio} 
                                onChange={handleInputChange} 
                                placeholder="Tell brands about yourself"
                                rows={3}
                                size="sm"
                                className="form-textarea"
                            />
                            {validationErrors.bio && (
                                <Text color="red.500" fontSize="xs" mt={1}>
                                    {validationErrors.bio}
                                </Text>
                            )}
                        </FormControl>
                    </VStack>
                );

            case 8: // Social Media Links (moved from case 6)
                return (
                    <VStack spacing={2} align="stretch" className="form-step">
                        <Heading size="md" color="black" mb={4} className="step-heading">Add your social channels</Heading>
                        
                        {validationErrors.socialLinks && (
                            <Alert status="error" mb={4} size="sm" borderRadius="md">
                                <AlertIcon />
                                {validationErrors.socialLinks}
                            </Alert>
                        )}
                        
                        <Flex direction="column" gap={3}>
                            {/* Instagram */}
                            <Flex gap={2} align="center">
                                <Text color="black" fontSize="md" fontWeight="medium" minW="100px">
                                    Instagram
                                </Text>
                                <FormControl flex="2">
                                    <Input 
                                        value={formData.socialLinks.instagram} 
                                        onChange={(e) => {
                                            const username = e.target.value.replace('@', '');
                                            handleNestedInputChange('socialLinks', 'instagram', username);
                                        }}
                                        placeholder="username (without @)"
                                        size="md"
                                        color="black"
                                        borderColor="gray.300"
                                    />
                                </FormControl>
                                
                                <FormControl flex="1">
                                    <Select 
                                        value={formData.followers.instagram || "0-1k"} 
                                        onChange={(e) => handleNestedInputChange('followers', 'instagram', e.target.value)} 
                                        size="md"
                                        color="black"
                                        borderColor="gray.300"
                                    >
                                        <option value="0-1k">0-1k</option>
                                        <option value="1k-10k">1k-10k</option>
                                        <option value="10k-50k">10k-50k</option>
                                        <option value="50k-100k">50k-100k</option>
                                        <option value="100k-500k">100k-500k</option>
                                        <option value="500k-1M">500k-1M</option>
                                        <option value="1M+">1M+</option>
                                    </Select>
                                </FormControl>
                            </Flex>

                            {/* TikTok */}
                            <Flex gap={2} align="center">
                                <Text color="black" fontSize="md" fontWeight="medium" minW="100px">
                                    TikTok
                                </Text>
                                <FormControl flex="2">
                                    <Input 
                                        value={formData.socialLinks.tiktok} 
                                        onChange={(e) => {
                                            const username = e.target.value.replace('@', '');
                                            handleNestedInputChange('socialLinks', 'tiktok', username);
                                        }}
                                        placeholder="username (without @)"
                                        size="md"
                                        color="black"
                                        borderColor="gray.300"
                                    />
                                </FormControl>
                                
                                <FormControl flex="1">
                                    <Select 
                                        value={formData.followers.tiktok || "0-1k"} 
                                        onChange={(e) => handleNestedInputChange('followers', 'tiktok', e.target.value)} 
                                        size="md"
                                        color="black"
                                        borderColor="gray.300"
                                    >
                                        <option value="0-1k">0-1k</option>
                                        <option value="1k-10k">1k-10k</option>
                                        <option value="10k-50k">10k-50k</option>
                                        <option value="50k-100k">50k-100k</option>
                                        <option value="100k-500k">100k-500k</option>
                                        <option value="500k-1M">500k-1M</option>
                                        <option value="1M+">1M+</option>
                                    </Select>
                                </FormControl>
                            </Flex>

                            {/* YouTube - Show only if added */}
                            {formData.socialLinks.youtube !== undefined && (
                                <Flex gap={2} align="center">
                                    <Text color="black" fontSize="md" fontWeight="medium" minW="100px">
                                        YouTube
                                    </Text>
                                    <FormControl flex="2">
                                        <Input 
                                            value={formData.socialLinks.youtube} 
                                            onChange={(e) => handleNestedInputChange('socialLinks', 'youtube', e.target.value)}
                                            placeholder="channel name"
                                            size="md"
                                            color="black"
                                            borderColor="gray.300"
                                        />
                                    </FormControl>
                                    
                                    <FormControl flex="1">
                                        <Select 
                                            value={formData.followers.youtube || "0-1k"} 
                                            onChange={(e) => handleNestedInputChange('followers', 'youtube', e.target.value)} 
                                            size="md"
                                            color="black"
                                            borderColor="gray.300"
                                        >
                                            <option value="0-1k">0-1k</option>
                                            <option value="1k-10k">1k-10k</option>
                                            <option value="10k-50k">10k-50k</option>
                                            <option value="50k-100k">50k-100k</option>
                                            <option value="100k-500k">100k-500k</option>
                                            <option value="500k-1M">500k-1M</option>
                                            <option value="1M+">1M+</option>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => {
                                            const { socialLinks, followers } = formData;
                                            delete socialLinks.youtube;
                                            delete followers.youtube;
                                            setFormData({ ...formData, socialLinks, followers });
                                        }}
                                    >
                                        ✕
                                    </Button>
                                </Flex>
                            )}

                            {/* Twitter - Show only if added */}
                            {formData.socialLinks.twitter !== undefined && (
                                <Flex gap={2} align="center">
                                    <Text color="black" fontSize="md" fontWeight="medium" minW="100px">
                                        Twitter
                                    </Text>
                                    <FormControl flex="2">
                                        <Input 
                                            value={formData.socialLinks.twitter} 
                                            onChange={(e) => {
                                                const username = e.target.value.replace('@', '');
                                                handleNestedInputChange('socialLinks', 'twitter', username);
                                            }}
                                            placeholder="username (without @)"
                                            size="md"
                                            color="black"
                                            borderColor="gray.300"
                                        />
                                    </FormControl>
                                    
                                    <FormControl flex="1">
                                        <Select 
                                            value={formData.followers.twitter || "0-1k"} 
                                            onChange={(e) => handleNestedInputChange('followers', 'twitter', e.target.value)} 
                                            size="md"
                                            color="black"
                                            borderColor="gray.300"
                                        >
                                            <option value="0-1k">0-1k</option>
                                            <option value="1k-10k">1k-10k</option>
                                            <option value="10k-50k">10k-50k</option>
                                            <option value="50k-100k">50k-100k</option>
                                            <option value="100k-500k">100k-500k</option>
                                            <option value="500k-1M">500k-1M</option>
                                            <option value="1M+">1M+</option>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => {
                                            const { socialLinks, followers } = formData;
                                            delete socialLinks.twitter;
                                            delete followers.twitter;
                                            setFormData({ ...formData, socialLinks, followers });
                                        }}
                                    >
                                        ✕
                                    </Button>
                                </Flex>
                            )}

                            {/* Additional Platform Buttons */}
                            <VStack spacing={2} mt={2}>
                                {formData.socialLinks.youtube === undefined && (
                                    <Button
                                        leftIcon={<span>▶</span>}
                                        variant="outline"
                                        size="md"
                                        onClick={() => {
                                            setFormData({
                                                ...formData,
                                                socialLinks: { ...formData.socialLinks, youtube: '' },
                                                followers: { ...formData.followers, youtube: '0-1k' }
                                            });
                                        }}
                                        width="100%"
                                        justifyContent="flex-start"
                                        color="black"
                                        borderColor="gray.300"
                                    >
                                        Add YouTube
                                    </Button>
                                )}
                                
                                {formData.socialLinks.twitter === undefined && (
                                    <Button
                                        leftIcon={<span>𝕏</span>}
                                        variant="outline"
                                        size="md"
                                        onClick={() => {
                                            setFormData({
                                                ...formData,
                                                socialLinks: { ...formData.socialLinks, twitter: '' },
                                                followers: { ...formData.followers, twitter: '0-1k' }
                                            });
                                        }}
                                        width="100%"
                                        justifyContent="flex-start"
                                        color="black"
                                        borderColor="gray.300"
                                    >
                                        Add Twitter
                                    </Button>
                                )}
                                
                                <Button
                                    leftIcon={<span>⧉</span>}
                                    variant="outline"
                                    size="md"
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            socialLinks: { ...formData.socialLinks, twitch: '' },
                                            followers: { ...formData.followers, twitch: '0-1k' }
                                        });
                                    }}
                                    width="100%"
                                    justifyContent="flex-start"
                                    color="black"
                                    borderColor="gray.300"
                                >
                                    Add Twitch
                                </Button>
                            </VStack>
                        </Flex>
                    </VStack>
                );

            case 9: // Content Packages/Offers
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Add Your Content Packages</Heading>
                        
                        {validationErrors.offerCards && (
                            <Alert status="error" mb={4} size="sm" borderRadius="md">
                                <AlertIcon />
                                {validationErrors.offerCards}
                            </Alert>
                        )}
                        
                        <Box>
                            <Text color={textColor} fontSize="sm" className="step-description" mb={1}>
                                Content packages are listed on your profile and can be purchased by brands.
                                <Button as="a" variant="link" colorScheme="blue" ml={1} size="sm">
                                    Use our rate calculator
                                </Button>
                            </Text>
                            
                            {/* Fee note with reduced gap */}
                            <Text fontSize="sm" fontWeight="medium" color={textColor} mt={1} pb={3} borderBottom="1px solid" borderColor="gray.200">
                                Note: Hypeo takes a 15% fee from each transaction.
                            </Text>
                        </Box>
                        
                        {offersLoading ? (
                            <Flex justify="center" align="center" py={10}>
                                <Spinner size="lg" color="blue.500" />
                                <Text ml={4} fontSize="md">Loading your content packages...</Text>
                            </Flex>
                        ) : (
                            <>
                                <VStack spacing={6} align="stretch">
                                    {offerCards.map(card => (
                                        <Box 
                                            key={card.id}
                                            borderWidth="2px" 
                                            borderRadius="md" 
                                            p={5} 
                                            borderColor="black"
                                            position="relative"
                                            boxShadow="sm"
                                            bg="white"
                                        >
                                            <FormControl mb={4}>
                                                <Select 
                                                    value={card.type}
                                                    onChange={(e) => handleOfferCardChange(card.id, 'type', e.target.value)}
                                                    icon={<span>▼</span>}
                                                    borderColor="gray.300"
                                                    _focus={{ borderColor: "black" }}
                                                    _hover={{ borderColor: "gray.400" }}
                                                >
                                                    {/* Instagram Options */}
                                                    <optgroup label="Instagram">
                                                        <option value="Instagram Photo">Instagram Photo</option>
                                                        <option value="Instagram Reel">Instagram Reel</option>
                                                        <option value="Instagram Story">Instagram Story</option>
                                                        <option value="Instagram Live">Instagram Live</option>
                                                    </optgroup>

                                                    {/* TikTok Options */}
                                                    <optgroup label="TikTok">
                                                        <option value="TikTok Video">TikTok Video</option>
                                                        <option value="TikTok Story">TikTok Story</option>
                                                        <option value="TikTok Live">TikTok Live</option>
                                                    </optgroup>

                                                    {/* YouTube Options */}
                                                    <optgroup label="YouTube">
                                                        <option value="YouTube Short">YouTube Short</option>
                                                        <option value="YouTube Video">YouTube Video</option>
                                                        <option value="YouTube Sponsor Integration">YouTube Sponsor Integration (30-60s)</option>
                                                        <option value="YouTube Dedicated Video">YouTube Dedicated Video</option>
                                                        <option value="YouTube Community Post">YouTube Community Post</option>
                                                        <option value="YouTube Live Mention">YouTube Live Mention</option>
                                                        <option value="YouTube End Screen">YouTube End Screen Promotion</option>
                                                        <option value="YouTube Description Link">YouTube Description Link</option>
                                                    </optgroup>

                                                    {/* General Content */}
                                                    <optgroup label="General Content">
                                                        <option value="UGC Video">UGC Video</option>
                                                        <option value="Video Promotion">Video Promotion</option>
                                                        <option value="Image Promotion">Image Promotion</option>
                                                        <option value="Live Promotion">Live Promotion</option>
                                                    </optgroup>
                                                </Select>
                                            </FormControl>
                                            
                                            <Flex gap={3} mb={3}>
                                                <FormControl>
                                                    <FormLabel fontSize="sm" color="gray.600">Quantity</FormLabel>
                                                    <Input 
                                                        placeholder="Quantity" 
                                                        type="number" 
                                                        min="1" 
                                                        value={card.quantity}
                                                        onChange={(e) => handleOfferCardChange(card.id, 'quantity', e.target.value)}
                                                        borderColor="gray.300"
                                                        _focus={{ borderColor: "black" }}
                                                        _hover={{ borderColor: "gray.400" }}
                                                    />
                                                </FormControl>
                                            </Flex>
                                            
                                            <FormControl mb={3}>
                                                <FormLabel fontSize="sm" color="gray.600">Price</FormLabel>
                                                <Input 
                                                    placeholder="Price (USD)" 
                                                    type="number" 
                                                    min="0" 
                                                    value={card.price}
                                                    onChange={(e) => handleOfferCardChange(card.id, 'price', e.target.value)}
                                                    borderColor="gray.300"
                                                    _focus={{ borderColor: "black" }}
                                                    _hover={{ borderColor: "gray.400" }}
                                                />
                                            </FormControl>
                                            
                                            <FormControl mb={3}>
                                                <FormLabel fontSize="sm" color="gray.600">Expected Views</FormLabel>
                                                <Input 
                                                    placeholder="e.g., 5k-10k views" 
                                                    value={card.expectedViews}
                                                    onChange={(e) => handleOfferCardChange(card.id, 'expectedViews', e.target.value)}
                                                    borderColor="gray.300"
                                                    _focus={{ borderColor: "black" }}
                                                    _hover={{ borderColor: "gray.400" }}
                                                />
                                                <Text fontSize="xs" color={textColor} mt={1}>
                                                    Provide an estimate of views this content typically receives
                                                </Text>
                                            </FormControl>
                                            
                                            <Flex justify="space-between" align="center" mt={4}>
                                                {card.description && !card.isEditingDescription ? (
                                                    <Flex align="center" width="100%">
                                                        <Text fontSize="sm" color="gray.600" mr={2}>{card.description}</Text>
                                                        <Button 
                                                            variant="link" 
                                                            size="sm" 
                                                            color="black"
                                                            onClick={() => toggleDescriptionEdit(card.id, true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Flex>
                                                ) : card.isEditingDescription ? (
                                                    <Flex width="100%" direction="column" mr={2}>
                                                        <Textarea 
                                                            placeholder="Enter description of your content package" 
                                                            value={card.description}
                                                            onChange={(e) => handleOfferCardChange(card.id, 'description', e.target.value)}
                                                            borderColor="gray.300"
                                                            _focus={{ borderColor: "black" }}
                                                            _hover={{ borderColor: "gray.400" }}
                                                            size="md"
                                                            rows={3}
                                                            resize="vertical"
                                                            mb={2}
                                                        />
                                                        <Button 
                                                            size="sm" 
                                                            colorScheme="blue"
                                                            alignSelf="flex-end"
                                                            onClick={() => toggleDescriptionEdit(card.id, false)}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Flex>
                                                ) : (
                                                    <Button 
                                                        variant="link" 
                                                        leftIcon={<span>+</span>} 
                                                        size="sm" 
                                                        color="black"
                                                        onClick={() => toggleDescriptionEdit(card.id, true)}
                                                    >
                                                        Add a Description
                                                    </Button>
                                                )}
                                                {offerCards.length > 1 && (
                                                    <Button 
                                                        size="sm" 
                                                        variant="ghost" 
                                                        colorScheme="gray"
                                                        onClick={() => handleRemoveOfferCard(card.id)}
                                                        ml="auto"
                                                        flexShrink={0}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </Flex>
                                        </Box>
                                    ))}
                                </VStack>
                                
                                <Button 
                                    leftIcon={<span>+</span>} 
                                    variant="outline" 
                                    size="md" 
                                    mt={4}
                                    borderColor="black"
                                    color="black"
                                    _hover={{
                                        bg: "gray.100"
                                    }}
                                    onClick={handleAddOfferCard}
                                >
                                    Add Another Package
                                </Button>
                            </>
                        )}
                    </VStack>
                );

            case 10: // Review step
                return (
                    <VStack spacing={6} align="stretch" className="form-step">
                        <Heading size="md" color={headingColor} className="step-heading">Review Your Profile</Heading>
                        <Text color={textColor} fontSize="sm" className="step-description">
                            Please review all the information you've provided before submitting your profile.
                        </Text>
                        
                        <Button 
                            colorScheme="blue"
                            onClick={handleSubmit}
                            isLoading={loading}
                            size="md"
                            width="full"
                        >
                            Submit Profile
                        </Button>
                    </VStack>
                );

            default:
                return null;
        }
    };

    return (
        <div className="creator-profile-container">
            <div className="creator-form-container">
                <div className="form-wrapper">
                    <div className="form-header">
                        {step > 0 && (
                            <>
                                <Text color={textColor} fontSize="sm">Step {step} of {totalSteps - 1}</Text>
                                <Progress 
                                    value={(step / (totalSteps - 1)) * 100} 
                                    size="xs" 
                                    colorScheme="blue" 
                                    className="progress-bar"
                                />
                            </>
                        )}
                    </div>

                    <div className="form-content">
                        {renderStep()}
                    </div>

                    <div className="form-footer">
                        <div className="button-group">
                            {step > 0 ? (
                                <Button 
                                    onClick={() => setStep(step - 1)}
                                    variant="outline"
                                    colorScheme="gray"
                                    size="sm"
                                    leftIcon={<span>←</span>}
                                    className="prev-button"
                                >
                                Previous
                            </Button>
                            ) : (
                                <div style={{ width: '1px' }}></div>
                        )}
                            
                            {(step > 0 || profileMethod === 'manual') && (
                        <Button
                                    colorScheme="gray"
                                    onClick={handleNextStep}
                                    isLoading={loading || aiLoading}
                                    size="sm"
                                    rightIcon={step < totalSteps - 1 ? <span>→</span> : null}
                                    className="next-button"
                        >
                                    {step === totalSteps - 1 ? 'Complete Profile' : 'Continue'}
                        </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatorProfileForm; 