import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, collection, query, where, limit, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useTracking } from '../../contexts/TrackingContext';
import { fetchSelectedCampaigns } from '../../api/api';
import TrackingStats from '../Tracking/TrackingStats';
import './CreatorCampaignDetails.css';
import {
  Center,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  VStack,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  Image,
  Flex,
  Badge,
  Icon,
  useToast,
  Heading,
  StatHelpText
} from '@chakra-ui/react';
import { FaCheck, FaChartLine } from 'react-icons/fa';

const formatFirestoreDate = (timestamp) => {
  if (!timestamp) return '';
  if (timestamp.toDate) {
    return timestamp.toDate().toLocaleDateString();
  }
  if (typeof timestamp === 'string') {
    return new Date(timestamp).toLocaleDateString();
  }
  return '';
};

const CreatorCampaignDetails = () => {
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [affiliateLink, setAffiliateLink] = useState('');
  
  const { campaignId } = useParams();
  const auth = getAuth();
  const { generateAffiliateLink } = useTracking();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchCampaignDetails = async () => {
        try {
            setLoading(true);
            console.log('Starting to fetch campaign details...');

            if (!auth.currentUser) {
                console.log('No current user found, skipping fetch');
                setLoading(false);
                return;
            }

            // Get all selected campaigns first
            const response = await fetchSelectedCampaigns();
            console.log('Selected campaigns response:', response);

            if (response?.success && Array.isArray(response.campaigns)) {
                // Find the specific campaign we want
                const selectedCampaign = response.campaigns.find(
                    campaign => campaign.id === campaignId
                );

                if (selectedCampaign) {
                    console.log('Found campaign:', selectedCampaign);
                    
                    // Get campaign metrics
                    const clicksQuery = query(
                        collection(db, 'clicks'),
                        where('aid', '==', auth.currentUser.uid),
                        where('campaignId', '==', campaignId)
                    );
                    
                    const conversionsQuery = query(
                        collection(db, 'conversions'),
                        where('affiliateId', '==', auth.currentUser.uid),
                        where('campaignId', '==', campaignId)
                    );

                    const [clicksSnapshot, conversionsSnapshot] = await Promise.all([
                        getDocs(clicksQuery),
                        getDocs(conversionsQuery)
                    ]);

                    const metrics = {
                        clicks: clicksSnapshot.size,
                        conversions: conversionsSnapshot.size,
                        revenue: 0,
                        commission: 0
                    };

                    conversionsSnapshot.forEach(doc => {
                        const conversion = doc.data();
                        metrics.revenue += conversion.amount || 0;
                        metrics.commission += (conversion.amount * (selectedCampaign.campaignDetails.payout / 100)) || 0;
                    });

                    setCampaign({
                        ...selectedCampaign,
                        metrics
                    });

                    // Generate affiliate link if needed
                    if (selectedCampaign.campaignDetails?.productUrl) {
                        const link = await generateAffiliateLink(
                            campaignId,
                            auth.currentUser.uid,
                            selectedCampaign.campaignDetails.productUrl
                        );
                        setAffiliateLink(link);
                    }

                } else {
                    console.log('Campaign not found in selected campaigns');
                    setError('Campaign not found');
                }
            } else {
                console.log('No campaigns found or invalid response format');
                setError('Failed to load campaign details');
            }

        } catch (err) {
            console.error('Error in fetchCampaignDetails:', err);
            setError('Failed to load campaign details');
        } finally {
            setLoading(false);
        }
    };

    if (campaignId && auth.currentUser) {
        fetchCampaignDetails();
    }
}, [campaignId, auth.currentUser, generateAffiliateLink]);

  if (loading) return (
    <Center py={8}>
      <Spinner size="xl" color="blue.500" />
    </Center>
  );

  if (error) return (
    <Alert status="error" borderRadius="lg">
      <AlertIcon />
      <AlertDescription>{error}</AlertDescription>
    </Alert>
  );

  return (
    <Container maxW="1200px" py={8}>
        <Card bg="white" shadow="sm" borderRadius="lg" overflow="hidden">
            <CardHeader>
                <VStack align="stretch" spacing={4}>
                    {/* Back Button and Campaign Title */}
                    <Flex justify="space-between" align="center">
                        <Button
                            colorScheme="gray"
                            variant="outline"
                            onClick={() => navigate(-1)}
                            size="sm"
                        >
                            Back to Dashboard
                        </Button>
                        <Badge colorScheme="green" p={2} fontSize="md">
                            {campaign?.campaignDetails?.status?.toUpperCase() || 'ACTIVE'}
                        </Badge>
                    </Flex>

                    {/* Campaign Title */}
                    <Box>
                        <Heading size="lg" color="gray.800">
                            {`${campaign?.campaignDetails?.campaignHeadline || 'Drive Sales'} for ${campaign?.campaignDetails?.brand || 'Brand'}`}
                        </Heading>
                        <Text color="gray.600" mt={1}>
                            {campaign?.campaignDetails?.brand || 'Brand'} • {campaign?.campaignDetails?.programName || 'Program'}
                        </Text>
                    </Box>

                    {/* Campaign Stats */}
                    <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
                        <Stat bg="gray.50" p={4} borderRadius="lg">
                            <StatLabel>Campaign Type</StatLabel>
                            <StatNumber fontSize="lg">{campaign?.campaignDetails?.campaignType || 'Brand Awareness'}</StatNumber>
                        </Stat>
                        <Stat bg="gray.50" p={4} borderRadius="lg">
                            <StatLabel>Commission Type</StatLabel>
                            <StatNumber fontSize="lg">{campaign?.campaignDetails?.commissionType || 'Fixed Amount'}</StatNumber>
                        </Stat>
                        <Stat bg="gray.50" p={4} borderRadius="lg">
                            <StatLabel>Start Date</StatLabel>
                            <StatNumber fontSize="lg">{formatFirestoreDate(campaign?.campaignDetails?.startDate)}</StatNumber>
                        </Stat>
                        <Stat bg="gray.50" p={4} borderRadius="lg">
                            <StatLabel>End Date</StatLabel>
                            <StatNumber fontSize="lg">{formatFirestoreDate(campaign?.campaignDetails?.endDate)}</StatNumber>
                        </Stat>
                    </SimpleGrid>

                    {/* Affiliate Link */}
                    <Box>
                        <Text fontWeight="medium" mb={2}>Your Affiliate Link</Text>
                        <InputGroup>
                            <Input
                                value={affiliateLink || campaign?.productUrl || 'http://localhost:4003/create-campaign'}
                                isReadOnly
                                bg="gray.50"
                                borderRadius="md"
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    onClick={() => {
                                        navigator.clipboard.writeText(affiliateLink);
                                        toast({
                                            title: "Link copied!",
                                            status: "success",
                                            duration: 2000,
                                            isClosable: true,
                                        });
                                    }}
                                >
                                    Copy
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Box>

                    {/* Campaign Analytics */}
                    <Box>
                        <Heading size="md" mb={4}>Campaign Analytics</Heading>
                        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Stat>
                                    <StatLabel color="gray.600">Total Clicks</StatLabel>
                                    <StatNumber color="blue.600" fontSize="2xl">
                                        {campaign?.metrics?.clicks || 0}
                                    </StatNumber>
                                </Stat>
                            </Box>

                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Stat>
                                    <StatLabel color="gray.600">Conversions</StatLabel>
                                    <StatNumber color="green.600" fontSize="2xl">
                                        {campaign?.metrics?.conversions || 0}
                                    </StatNumber>
                                    <StatHelpText>
                                        {((campaign?.metrics?.conversions / campaign?.metrics?.clicks) * 100 || 0).toFixed(1)}% Rate
                                    </StatHelpText>
                                </Stat>
                            </Box>

                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Stat>
                                    <StatLabel color="gray.600">Total Revenue</StatLabel>
                                    <StatNumber color="purple.600" fontSize="2xl">
                                        ${campaign?.metrics?.revenue?.toFixed(2) || '0.00'}
                                    </StatNumber>
                                </Stat>
                            </Box>

                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Stat>
                                    <StatLabel color="gray.600">Your Commission</StatLabel>
                                    <StatNumber color="green.600" fontSize="2xl">
                                        ${campaign?.metrics?.commission?.toFixed(2) || '0.00'}
                                    </StatNumber>
                                </Stat>
                            </Box>
                        </SimpleGrid>
                    </Box>
                </VStack>
            </CardHeader>

            <CardBody>
                <VStack align="stretch" spacing={6}>
                    {/* Product Details */}
                    <Box>
                        <Heading size="md" mb={4}>Product Information</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Text fontWeight="bold" mb={2}>Product Name</Text>
                                <Text>{campaign?.campaignDetails?.productName || 'Product Name'}</Text>
                                <Text fontWeight="bold" mt={4} mb={2}>Category</Text>
                                <Text>{campaign?.campaignDetails?.productCategory || 'Category'}</Text>
                                <Text fontWeight="bold" mt={4} mb={2}>Description</Text>
                                <Text>{campaign?.campaignDetails?.productDescription || 'Description'}</Text>
                            </Box>
                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Text fontWeight="bold" mb={2}>Payout per Sale</Text>
                                <Text>${campaign?.campaignDetails?.payout || '0'} per sale</Text>
                                <Text fontWeight="bold" mt={4} mb={2}>Special Offers</Text>
                                <Text>{campaign?.campaignDetails?.pricing?.specialOffers || 'None'}</Text>
                                <Text fontWeight="bold" mt={4} mb={2}>Target Audience</Text>
                                <Text>{campaign?.campaignDetails?.targetAudience?.interests || 'Not specified'}</Text>
                            </Box>
                        </SimpleGrid>
                    </Box>

                    {/* Content Requirements */}
                    <Box>
                        <Heading size="md" mb={4}>Content Requirements</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Text fontWeight="bold" mb={2}>Required Hashtags</Text>
                                <Text>{campaign?.campaignDetails?.contentRequirements?.requiredHashtags || 'None specified'}</Text>
                                <Text fontWeight="bold" mt={4} mb={2}>Required Mentions</Text>
                                <Text>{campaign?.campaignDetails?.contentRequirements?.requiredMentions || 'None specified'}</Text>
                            </Box>
                            <Box p={4} bg="gray.50" borderRadius="lg">
                                <Text fontWeight="bold" mb={2}>Preferred Platforms</Text>
                                <Text>{campaign?.campaignDetails?.creatorRequirements?.preferredPlatforms || 'Not specified'}</Text>
                                <Text fontWeight="bold" mt={4} mb={2}>Campaign Goal</Text>
                                <Text>{campaign?.campaignDetails?.campaignGoal || 'Not specified'}</Text>
                            </Box>
                        </SimpleGrid>
                    </Box>

                    {/* Creator Guidelines */}
                    <Box>
                        <Heading size="md" mb={4}>Creator Guidelines</Heading>
                        <Box p={4} bg="gray.50" borderRadius="lg">
                            <Text fontWeight="bold" mb={2}>Guidelines</Text>
                            <Text>{campaign?.creatorRequirements?.creatorGuidelines || 'sf'}</Text>
                            <Text fontWeight="bold" mt={4} mb={2}>Ideal Creator Profile</Text>
                            <Text>{campaign?.creatorRequirements?.idealCreators || 'dfdsfsf'}</Text>
                        </Box>
                    </Box>

                    {/* Brand Assets */}
                    {campaign?.brandImages?.length > 0 && (
                        <Box>
                            <Heading size="md" mb={4}>Brand Assets</Heading>
                            <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
                                {campaign.brandImages.map((url, index) => (
                                    <Box key={index} position="relative">
                                        <Image
                                            src={url}
                                            alt={`Brand asset ${index + 1}`}
                                            borderRadius="lg"
                                            objectFit="cover"
                                        />
                                        <Button
                                            position="absolute"
                                            bottom={2}
                                            right={2}
                                            size="sm"
                                            onClick={() => window.open(url, '_blank')}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        </Box>
                    )}
                </VStack>
            </CardBody>
        </Card>
    </Container>
  );
};

export default CreatorCampaignDetails;
