import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  SimpleGrid,
  Stack,
  IconButton,
  AspectRatio,
  Flex,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  Avatar,
  Badge,
  HStack,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import heroCreator from '../../assets/fin.jpg';
import hero1 from '../../assets/hero1.jpg';
import hero2 from '../../assets/hero2.jpg';
import hero3 from '../../assets/hero3.jpg';
import './SplashPage.css';
import { useSwipeable } from 'react-swipeable';
import { AddIcon } from '@chakra-ui/icons';
import { SearchIcon } from '@chakra-ui/icons';

const tiktokExamples = [
  {
    type: 'tiktok',
    videoId: '7459430479003913504',
    username: 'sarafinance',
    category: 'Finance',
    location: 'United States',
    followers: '220K',
    thumbnail: '/path/to/sara-thumbnail.jpg',
    title: '4 books that helped me make $100k/month'
  },
  {
    type: 'tiktok',
    videoId: '7459430479003913504',
    username: 'thequidsquid',
    category: 'Social Media',
    location: 'United States',
    followers: '98.2K',
    thumbnail: '/path/to/quid-thumbnail.jpg',
    title: '#HealthorWealth More Info Below'
  },
  {
    type: 'tiktok',
    videoId: '7456957826129300782',
    username: 'johnefinance',
    category: 'Finance',
    location: 'United States',
    followers: '386K',
    thumbnail: '/path/to/johne-thumbnail.jpg',
    title: 'HOW A 401K WORKS'
  },
  {
    type: 'tiktok',
    videoId: '7410081822287220000',
    username: 'financestrategist',
    category: 'Finance',
    location: 'United States',
    followers: '142K',
    thumbnail: '/path/to/strategist-thumbnail.jpg',
    title: '$2.50 PUMPKIN SPICE LATTE 🎃'
  }
];

const CreatorCard = ({ creator = {} }) => {
  const {
    image = '',
    name = '',
    rating = '0.0',
    location = '',
    platform = '',
    price = '0',
    description = '',
    badges = []
  } = creator;

  return (
    <Box
      borderRadius="xl"
      overflow="hidden"
      bg="white"
      boxShadow="md"
      mb={4}
    >
      <Image
        src={image}
        alt={name}
        objectFit="cover"
        height="200px"
        width="100%"
        fallbackSrc="/placeholder-image.jpg"
      />
      <Box p={4}>
        <Flex justify="space-between" align="center">
          <Box>
            <Text fontWeight="bold" fontSize="lg">{name}</Text>
            <Flex align="center" mt={1}>
              <Text color="yellow.400">★</Text>
              <Text>{rating}</Text>
            </Flex>
            <Text color="gray.600" fontSize="sm">{location}</Text>
          </Box>
        </Flex>
        
        <Flex justify="space-between" align="center" mt={2}>
          <Text color="gray.600">{platform}</Text>
          <Text fontWeight="bold">${price}</Text>
        </Flex>
        
        <Text mt={2} noOfLines={2}>{description}</Text>
      </Box>
    </Box>
  );
};

const StoryCard = ({ image, username, followers, text, category }) => (
  <Box
    position="relative"
    width={{ base: "280px", md: "320px" }}
    height={{ base: "500px", md: "600px" }}
    borderRadius="3xl"
    overflow="hidden"
    boxShadow="xl"
    bg="white"
    mx="auto"
  >
    <Image
      src={image}
      alt={username}
      objectFit="cover"
      w="100%"
      h="100%"
    />
    
    <Box p={4} position="absolute" bottom={0} left={0} right={0} bg="white" borderTopRadius="xl">
      <Text fontWeight="bold" fontSize="lg">{username}</Text>
      <Text color="gray.600" fontSize="sm">{category}</Text>
      <Flex align="center" mt={1} justify="space-between">
        <Flex align="center">
          <Box as="span" mr={2}>📍</Box>
          <Text fontSize="sm">United States</Text>
        </Flex>
        <Flex align="center">
          <Box as="span" mr={2}>👥</Box>
          <Text fontSize="sm">{followers}</Text>
        </Flex>
      </Flex>
    </Box>
  </Box>
);

const TikTokSection = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  
  const tiktokExamples = [
    {
      type: 'tiktok',
      videoId: '7459430479003913504',
      username: 'sarafinance'
    },
    {
      type: 'tiktok',
      videoId: '7428944852647267616',
      username: 'consultkingsley'
    },
    {
      type: 'tiktok',
      videoId: '7456957826129300782',
      username: 'johnefinance'
    }
  ];

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    script.defer = true;
    script.id = 'tiktok-script';

    script.onload = () => {
      if (window.TikTok) {
        window.TikTok.reload();
      }
      setIsLoading(false);
    };

    if (!document.getElementById('tiktok-script')) {
      document.head.appendChild(script);
    }

    return () => {
      const existingScript = document.getElementById('tiktok-script');
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <Box py={6}>
      <Container maxW="container.xl">
        <SimpleGrid 
          columns={{ base: 1, md: 3 }} 
          spacing={6}
          justifyContent="center"
        >
          {tiktokExamples.map((example, index) => (
            <Box 
              key={index}
              display={{ 
                base: index === 0 ? 'block' : 'none',
                md: 'block'
              }}
              overflow="hidden"
              opacity={isLoading ? 0 : 1}
              transition="opacity 0.3s"
            >
              <Box
                position="relative"
                height={{ base: "635px", md: "635px" }}
                width="100%"
                maxW="320px"
                mx="auto"
              >
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  borderRadius="2xl"
                  overflow="hidden"
                  boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
                >
                  <blockquote
                    className="tiktok-embed"
                    cite={`https://www.tiktok.com/@${example.username}/video/${example.videoId}`}
                    data-video-id={example.videoId}
                    data-autoplay="true"
                    data-playsinline="true"
                    style={{ 
                      maxWidth: '100%', 
                      minWidth: '100%',
                      height: '700px',
                      overflow: 'hidden'
                    }}
                  >
                    <section>
                      <a
                        target="_blank"
                        href={`https://www.tiktok.com/@${example.username}`}
                        rel="noreferrer"
                      >
                        @{example.username}
                      </a>
                    </section>
                  </blockquote>
                </Box>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const HowItWorksSection = () => {
  const navigate = useNavigate();
  
  const creators = [
    {
      name: "Finance Creator",
      rating: "4.9",
      location: "New York, US",
      image: "https://media.istockphoto.com/id/1213200051/photo/friendly-young-man-vlogging.jpg?s=612x612&w=0&k=20&c=47s6XzsHCKyKNt5mCTCjAqiLRtPoWI0nggQ6q9JrK8g=",
      badges: ["Top Creator"]
    }
  ];

  return (
    <Box className="how-it-works-section" py={{ base: 10, md: 20 }} bg="gray.50">
      <Container maxW="container.xl" px={{ base: 4, md: 6 }}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={12} alignItems="start">
          {/* Left Column - Text Content */}
          <Box maxW="7xl">
            <Text 
              color="pink.500" 
              fontSize="xl" 
              fontWeight="semibold" 
              mb={3}
            >
              Connect with Finance Creators
            </Text>

            <Heading
              as="h2"
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              fontWeight="bold"
              lineHeight="tight"
              mb={6}
              color="navy.900"
            >
              Boost sales by up to 14x
            </Heading>

            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="gray.600"
              mb={10}
              maxW="3xl"
            >
              Find and collaborate with finance creators who understand your industry. 
              Launch campaigns that resonate with your target audience and drive real results.
            </Text>

            <Stack spacing={4} mb={12}>
              <Flex align="center" gap={3}>
                <Box as="span" color="green.500">✓</Box>
                <Text fontSize="lg" color="gray.700">Access vetted finance creators</Text>
              </Flex>
              <Flex align="center" gap={3}>
                <Box as="span" color="green.500">✓</Box>
                <Text fontSize="lg" color="gray.700">Launch campaigns in minutes</Text>
              </Flex>
              <Flex align="center" gap={3}>
                <Box as="span" color="green.500">✓</Box>
                <Text fontSize="lg" color="gray.700">Track performance in real-time</Text>
              </Flex>
            </Stack>

            <Button
              size="lg"
              bg="pink.500"
              color="white"
              px={8}
              py={7}
              fontSize="lg"
              _hover={{ bg: 'pink.600' }}
              borderRadius="xl"
              leftIcon={<Box as="span" fontSize="24px">⚡</Box>}
              onClick={() => navigate('/signup')}
            >
              Try Hypeo
            </Button>
          </Box>

          {/* Right Column - Creator Cards */}
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>Instagram</Text>
            <Stack spacing={6}>
              {creators.map((creator, index) => (
                <Box
                  key={index}
                  borderRadius="xl"
                  overflow="hidden"
                  bg="white"
                  boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
                  mb={4}
                >
                  <Box position="relative">
                    <Image
                      src={creator.image}
                      alt={creator.name}
                      w="100%"
                      h={{ base: "200px", md: "300px" }}
                      objectFit="cover"
                    />
                    {creator.badges && (
                      <Flex 
                        position="absolute" 
                        top={3} 
                        left={3} 
                        gap={2}
                        flexWrap="wrap"
                      >
                        {creator.badges.map((badge, idx) => (
                          <Box
                            key={idx}
                            bg="blackAlpha.700"
                            color="white"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                            backdropFilter="blur(4px)"
                          >
                            {badge}
                          </Box>
                        ))}
                      </Flex>
                    )}
                  </Box>
                  
                  <Box p={{ base: 4, md: 6 }}>
                    <Flex 
                      justify="space-between" 
                      align="center" 
                      mb={2}
                      flexDirection={{ base: "column", sm: "row" }}
                      gap={{ base: 2, sm: 0 }}
                    >
                      <Box>
                        <Flex 
                          align="center" 
                          gap={2}
                          flexWrap="wrap"
                          justify={{ base: "center", sm: "flex-start" }}
                          w="full"
                        >
                          <Text fontWeight="bold">{creator.name}</Text>
                          <Flex align="center" gap={1}>
                            <Text color="yellow.400">★</Text>
                            <Text>{creator.rating}</Text>
                          </Flex>
                        </Flex>
                        <Text 
                          color="gray.600" 
                          fontSize="sm"
                          textAlign={{ base: "center", sm: "left" }}
                        >
                          {creator.location}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const AutomateSection = () => {
  const navigate = useNavigate();
  
  const creators = [
    {
      name: "Alex Thompson",
      rating: "4.9",
      location: "New York, US",
      description: "Personal Finance & Investment Strategy",
      price: "250"
    },
    {
      name: "Sarah Chen",
      rating: "5.0",
      location: "San Francisco, US",
      description: "Crypto & DeFi Expert",
      price: "200"
    },
    {
      name: "Marcus Williams",
      rating: "4.8",
      location: "London, UK",
      description: "Stock Market & Trading Tips",
      price: "300"
    }
  ];

  return (
    <Box py={{ base: 12, md: 24 }} bg="gray.50">
      <Container maxW="container.xl" px={{ base: 4, md: 6 }}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: 8, md: 16 }} alignItems="center">
          {/* Left Column - Text Content */}
          <Box maxW="7xl">
            <Text 
              color="pink.500" 
              fontSize="xl" 
              fontWeight="semibold" 
              mb={4}
              letterSpacing="tight"
            >
              Platform
            </Text>

            <Heading
              as="h2"
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              fontWeight="bold"
              lineHeight="1.1"
              mb={8}
              color="navy.900"
              letterSpacing="tight"
            >
              Find and Hire Influencers in Seconds
            </Heading>

            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="gray.600"
              mb={12}
              maxW="3xl"
              lineHeight="1.8"
            >
              Search thousands of vetted Instagram, TikTok, and YouTube influencers. 
              Safely purchase and communicate through our platform.
            </Text>

            <Stack spacing={6} mb={12}>
              <Flex align="center" gap={4}>
                <Box 
                  as="span" 
                  color="green.500" 
                  bg="green.50" 
                  p={2} 
                  borderRadius="full"
                >✓</Box>
                <Text fontSize="lg" color="gray.700" fontWeight="medium">Search Influencers</Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Box 
                  as="span" 
                  color="green.500" 
                  bg="green.50" 
                  p={2} 
                  borderRadius="full"
                >✓</Box>
                <Text fontSize="lg" color="gray.700" fontWeight="medium">Purchase & Chat Securely</Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Box 
                  as="span" 
                  color="green.500" 
                  bg="green.50" 
                  p={2} 
                  borderRadius="full"
                >✓</Box>
                <Text fontSize="lg" color="gray.700" fontWeight="medium">Receive Quality Content</Text>
              </Flex>
            </Stack>

            <Button
              size="lg"
              bg="pink.500"
              color="white"
              px={10}
              py={7}
              fontSize="lg"
              fontWeight="bold"
              _hover={{ bg: 'pink.600', transform: 'translateY(-2px)' }}
              _active={{ transform: 'translateY(0)' }}
              borderRadius="xl"
              leftIcon={<Box as="span" fontSize="24px">⚡</Box>}
              transition="all 0.2s"
              shadow="lg"
              onClick={() => navigate('/signup')}
            >
              Try Hypeo
            </Button>
          </Box>

          {/* Right Column - Marketplace UI */}
          <Box>
            <Box
              bg="white"
              borderRadius="2xl"
              p={{ base: 4, md: 8 }}
              boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
              border="1px"
              borderColor="gray.100"
              mb={8}
            >
              {/* Search and Filters */}
              <VStack spacing={4} mb={6}>
                <InputGroup size="lg">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.400" />
                  </InputLeftElement>
                  <Input 
                    placeholder="Search creators..." 
                    borderRadius="xl"
                    bg="gray.50"
                    _hover={{ bg: 'gray.100' }}
                    _focus={{ bg: 'white', borderColor: 'purple.500' }}
                  />
                </InputGroup>

                <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4} w="full">
                  <Select 
                    placeholder="Platform" 
                    bg="gray.50"
                    borderRadius="lg"
                    size="lg"
                  >
                    <option>Instagram</option>
                    <option>TikTok</option>
                    <option>YouTube</option>
                  </Select>
                  <Select 
                    placeholder="Category" 
                    bg="gray.50"
                    borderRadius="lg"
                    size="lg"
                  >
                    <option>Finance</option>
                    <option>Crypto</option>
                    <option>Investing</option>
                  </Select>
                </SimpleGrid>
              </VStack>

              {/* Creator Cards */}
              <VStack spacing={4} align="stretch">
                {creators.map((creator, index) => (
                  <Box
                    key={index}
                    bg="gray.50"
                    p={{ base: 3, md: 4 }}
                    borderRadius="xl"
                    display="flex"
                    flexDirection={{ base: "column", sm: "row" }}
                    gap={{ base: 3, md: 4 }}
                    _hover={{
                      bg: 'gray.100',
                      transform: 'translateY(-2px)'
                    }}
                    transition="all 0.2s"
                    cursor="pointer"
                    boxShadow="sm"
                  >
                    <Avatar 
                      size={{ base: "md", md: "lg" }}
                      src={`https://i.pravatar.cc/150?img=${index + 10}`}
                      borderRadius="lg"
                    />
                    <Box flex="1">
                      <Flex 
                        justify="space-between" 
                        align={{ base: "start", sm: "center" }}
                        direction={{ base: "column", sm: "row" }}
                        mb={{ base: 2, sm: 1 }}
                      >
                        <Text fontWeight="bold">{creator.name}</Text>
                        <Badge colorScheme="purple">Top Creator</Badge>
                      </Flex>
                      <Flex 
                        align="center" 
                        gap={2} 
                        mb={{ base: 2, sm: 1 }}
                        flexWrap="wrap"
                      >
                        <Text color="yellow.400">★</Text>
                        <Text fontSize="sm" color="gray.600">
                          {creator.rating} • {creator.location}
                        </Text>
                      </Flex>
                      <Text 
                        fontSize="sm" 
                        color="gray.600"
                        mb={{ base: 2, sm: 0 }}
                      >
                        {creator.description}
                      </Text>
                      <Text 
                        fontWeight="bold" 
                        color="purple.500"
                        display={{ base: "block", sm: "none" }}
                      >
                        ${creator.price}
                      </Text>
                    </Box>
                    <Box display={{ base: "none", sm: "block" }}>
                      <Text fontWeight="bold" color="purple.500">
                        ${creator.price}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </VStack>

              {/* View More Button */}
              <Button
                w="full"
                variant="outline"
                size="lg"
                mt={6}
                borderRadius="xl"
                borderColor="gray.200"
                _hover={{
                  bg: 'gray.50',
                  transform: 'translateY(-2px)'
                }}
                transition="all 0.2s"
              >
                View More Creators
              </Button>
            </Box>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const AnalyticsSection = () => {
  const navigate = useNavigate();
  
  return (
    <Box className="analytics-section" py={{ base: 16, md: 24 }}>
      <Container maxW="container.xl" px={{ base: 4, md: 6 }}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
          {/* Left Column - Text Content */}
          <Box maxW="7xl">
            <Text 
              color="pink.500" 
              fontSize="xl" 
              fontWeight="semibold" 
              mb={4}
              letterSpacing="tight"
            >
              Real-Time Analytics
            </Text>

            <Heading
              as="h2"
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              fontWeight="bold"
              lineHeight="1.1"
              mb={8}
              color="navy.900"
              letterSpacing="tight"
            >
              Track your social performance in one place
            </Heading>

            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="gray.600"
              mb={12}
              maxW="3xl"
              lineHeight="1.8"
            >
              Monitor Instagram, TikTok, and YouTube analytics from a single dashboard. Get automated daily updates on engagement, reach, and campaign performance without the spreadsheet hassle.
            </Text>

            <Stack spacing={6} mb={12}>
              <Flex align="center" gap={4}>
                <Box 
                  as="span" 
                  color="green.500" 
                  bg="green.50" 
                  p={2} 
                  borderRadius="full"
                >✓</Box>
                <Text fontSize="lg" color="gray.700" fontWeight="medium">One-click campaign tracking</Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Box 
                  as="span" 
                  color="green.500" 
                  bg="green.50" 
                  p={2} 
                  borderRadius="full"
                >✓</Box>
                <Text fontSize="lg" color="gray.700" fontWeight="medium">Daily performance updates</Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Box 
                  as="span" 
                  color="green.500" 
                  bg="green.50" 
                  p={2} 
                  borderRadius="full"
                >✓</Box>
                <Text fontSize="lg" color="gray.700" fontWeight="medium">Automated reporting tools</Text>
              </Flex>
            </Stack>

            <Button
              size="lg"
              bg="pink.500"
              color="white"
              px={10}
              py={7}
              fontSize="lg"
              fontWeight="bold"
              _hover={{ bg: 'pink.600', transform: 'translateY(-2px)' }}
              _active={{ transform: 'translateY(0)' }}
              borderRadius="xl"
              leftIcon={<Box as="span" fontSize="24px">📊</Box>}
              transition="all 0.2s"
              shadow="lg"
              onClick={() => navigate('/signup')}
            >
              Start Tracking
            </Button>
          </Box>

          {/* Right Column - Social Analytics Dashboard */}
          <Box>
            <Box
              borderRadius="2xl"
              p={{ base: 4, md: 8 }}
              boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
              border="1px"
              borderColor="gray.100"
              bg="white"
              mb={{ base: 6, md: 8 }}
              overflow="hidden"
            >
              {/* Platform Stats */}
              <SimpleGrid columns={{ base: 1, sm: 3 }} spacing={{ base: 3, md: 4 }} mb={{ base: 6, md: 8 }}>
                <Box bg="gray.50" p={{ base: 3, md: 4 }} borderRadius="xl">
                  <Text color="gray.600" fontSize="sm" mb={1}>Engagement Rate</Text>
                  <Flex align="center" justify="space-between">
                    <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">5.2%</Text>
                    <Text color="green.500" fontSize="sm">↑ 12.4%</Text>
                  </Flex>
                </Box>
                <Box bg="gray.50" p={{ base: 3, md: 4 }} borderRadius="xl">
                  <Text color="gray.600" fontSize="sm" mb={1}>Reach</Text>
                  <Flex align="center" justify="space-between">
                    <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">89K</Text>
                    <Text color="green.500" fontSize="sm">↑ 23.5%</Text>
                  </Flex>
                </Box>
                <Box bg="gray.50" p={{ base: 3, md: 4 }} borderRadius="xl">
                  <Text color="gray.600" fontSize="sm" mb={1}>Conversions</Text>
                  <Flex align="center" justify="space-between">
                    <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">1.8K</Text>
                    <Text color="green.500" fontSize="sm">↑ 18.2%</Text>
                  </Flex>
                </Box>
              </SimpleGrid>

              {/* Platform Performance */}
              <Box bg="gray.50" p={{ base: 3, md: 4 }} borderRadius="xl" mb={{ base: 6, md: 8 }}>
                <Text fontWeight="medium" mb={4}>Platform Performance</Text>
                <Stack spacing={3}>
                  {[
                    { platform: 'Instagram', percent: '45%', color: 'pink.500' },
                    { platform: 'TikTok', percent: '35%', color: 'purple.500' },
                    { platform: 'YouTube', percent: '20%', color: 'red.500' }
                  ].map((item) => (
                    <Box key={item.platform}>
                      <Flex justify="space-between" mb={2}>
                        <Text fontSize="sm">{item.platform}</Text>
                        <Text fontSize="sm" color="gray.600">{item.percent}</Text>
                      </Flex>
                      <Box bg="gray.200" h="2" borderRadius="full">
                        <Box bg={item.color} h="2" w={item.percent} borderRadius="full"/>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>

              {/* Recent Posts */}
              <Box>
                <Flex align="center" justify="space-between" mb={4}>
                  <Text fontWeight="medium">Recent Posts</Text>
                  <Select size="sm" w="120px" defaultValue="7d">
                    <option value="7d">Last 7 days</option>
                    <option value="30d">Last 30 days</option>
                  </Select>
                </Flex>
                <VStack spacing={{ base: 2, md: 4 }}>
                  {[
                    { platform: 'Instagram', type: 'Reel', likes: '2.4K', comments: '342', shares: '89' },
                    { platform: 'TikTok', type: 'Video', likes: '15.2K', comments: '856', shares: '2.3K' },
                    { platform: 'YouTube', type: 'Short', likes: '5.6K', comments: '445', shares: '234' }
                  ].map((post) => (
                    <Box
                      key={post.platform}
                      bg="gray.50"
                      p={{ base: 3, md: 4 }}
                      borderRadius="lg"
                      w="full"
                    >
                      <Flex justify="space-between" align="center" mb={{ base: 2, md: 3 }}>
                        <Text fontWeight="medium">{post.platform}</Text>
                        <Badge colorScheme={
                          post.platform === 'Instagram' ? 'pink' : 
                          post.platform === 'TikTok' ? 'purple' : 
                          'red'
                        }>
                          {post.type}
                        </Badge>
                      </Flex>
                      <Flex gap={{ base: 3, md: 4 }} flexWrap="wrap">
                        <Text fontSize="sm">❤️ {post.likes}</Text>
                        <Text fontSize="sm">💬 {post.comments}</Text>
                        <Text fontSize="sm">↗️ {post.shares}</Text>
                      </Flex>
                    </Box>
                  ))}
                </VStack>
              </Box>
            </Box>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const BenefitsSection = () => {
  const navigate = useNavigate();
  
  return (
    <Box 
      py={{ base: 16, md: 24 }} 
      bg="gray.50"
      mb={{ base: 24, md: 32 }}
    >
      <Container 
        maxW="container.xl"
        pb={8}
      >
        <SimpleGrid 
          columns={{ base: 1, lg: 2 }} 
          spacing={16}
          mb={8}
        >
          {/* For Creators Column */}
          <Box
            bg="white"
            borderRadius="2xl"
            boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
            overflow="hidden"
            mb={4}
          >
            <VStack align="start" spacing={8} p={8}>
              <Box>
                <Text 
                  color="pink.500" 
                  fontSize="xl" 
                  fontWeight="semibold"
                  mb={2}
                >
                  For Creators 🎯
                </Text>
                <Heading 
                  as="h3" 
                  fontSize={{ base: "2xl", md: "3xl" }}
                  mb={4}
                >
                  Monetize your influence
                </Heading>
              </Box>

              <VStack spacing={8} align="stretch" w="full">
                <HStack spacing={6} align="start">
                  <Box
                    p={4}
                    bg="pink.50"
                    borderRadius="xl"
                    color="pink.500"
                    fontSize="2xl"
                  >
                    💰
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" mb={2}>
                      Post & Earn
                    </Text>
                    <Text color="gray.600">
                      Create engaging content and earn revenue through your unique creator profile
                    </Text>
                  </Box>
                </HStack>

                <HStack spacing={6} align="start">
                  <Box
                    p={4}
                    bg="purple.50"
                    borderRadius="xl"
                    color="purple.500"
                    fontSize="2xl"
                  >
                    🎯
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" mb={2}>
                      Find Opportunities
                    </Text>
                    <Text color="gray.600">
                      Browse and apply to brand campaigns that match your niche and audience
                    </Text>
                  </Box>
                </HStack>

                <HStack spacing={6} align="start">
                  <Box
                    p={4}
                    bg="blue.50"
                    borderRadius="xl"
                    color="blue.500"
                    fontSize="2xl"
                  >
                    📊
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" mb={2}>
                      Track Success
                    </Text>
                    <Text color="gray.600">
                      Monitor your earnings and campaign performance in real-time
                    </Text>
                  </Box>
                </HStack>
              </VStack>

              <Button
                size="lg"
                bg="pink.500"
                color="white"
                px={8}
                w="full"
                _hover={{ bg: 'pink.600', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
                onClick={() => navigate('/signup')}
              >
                Start Creating
              </Button>
            </VStack>
          </Box>

          {/* For Brands Column */}
          <Box
            bg="white"
            borderRadius="2xl"
            boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
            overflow="hidden"
            mb={4}
          >
            <VStack align="start" spacing={8} p={8}>
              <Box>
                <Text 
                  color="purple.500" 
                  fontSize="xl" 
                  fontWeight="semibold"
                  mb={2}
                >
                  For Brands 
                </Text>
                <Heading 
                  as="h3" 
                  fontSize={{ base: "2xl", md: "3xl" }}
                  mb={4}
                >
                  Scale your creator program
                </Heading>
              </Box>

              <VStack spacing={8} align="stretch" w="full">
                <HStack spacing={6} align="start">
                  <Box
                    p={4}
                    bg="green.50"
                    borderRadius="xl"
                    color="green.500"
                    fontSize="2xl"
                  >
                    ⚡
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" mb={2}>
                      Post Offers
                    </Text>
                    <Text color="gray.600">
                      Create and publish campaign opportunities for creators in minutes
                    </Text>
                  </Box>
                </HStack>

                <HStack spacing={6} align="start">
                  <Box
                    p={4}
                    bg="orange.50"
                    borderRadius="xl"
                    color="orange.500"
                    fontSize="2xl"
                  >
                    🤝
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" mb={2}>
                      Find Creators
                    </Text>
                    <Text color="gray.600">
                      Connect with pre-vetted creators who align with your brand
                    </Text>
                  </Box>
                </HStack>

                <HStack spacing={6} align="start">
                  <Box
                    p={4}
                    bg="cyan.50"
                    borderRadius="xl"
                    color="cyan.500"
                    fontSize="2xl"
                  >
                    📈
                  </Box>
                  <Box>
                    <Text fontWeight="bold" fontSize="lg" mb={2}>
                      Drive Growth
                    </Text>
                    <Text color="gray.600">
                      Track ROI and optimize your creator partnerships in real-time
                    </Text>
                  </Box>
                </HStack>
              </VStack>

              <Button
                size="lg"
                bg="purple.500"
                color="white"
                px={8}
                w="full"
                _hover={{ bg: 'purple.600', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
                onClick={() => navigate('/signup')}
              >
                Start Growing
              </Button>
            </VStack>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const ValueProposition = () => {
  return (
    <Box py={{ base: 12, md: 16, lg: 24 }} bg="white" px={{ base: 4, md: 0 }}>
      <Container maxW="container.xl">
        <VStack spacing={{ base: 6, md: 8 }} align="center" textAlign="center">
          <Heading 
            fontSize={{ base: "2xl", md: "3xl" }}
            color="gray.900"
          >
            Drive Growth with Finance Creators
          </Heading>
          
          <Text 
            fontSize={{ base: "md", md: "xl" }}
            color="gray.600"
            maxW="2xl"
            px={{ base: 4, md: 0 }}
          >
            Connect with top finance creators who understand your industry and drive real results for your brand
          </Text>

          <SimpleGrid 
            columns={{ base: 1, md: 3 }} 
            spacing={{ base: 6, md: 8 }} 
            w="full"
          >
            <VStack>
              <Text fontWeight="bold" fontSize="lg">✓ Proven Results</Text>
              <Text color="gray.600">Work with creators who consistently drive conversions</Text>
            </VStack>
            <VStack>
              <Text fontWeight="bold" fontSize="lg">✓ Vetted Talent</Text>
              <Text color="gray.600">Access pre-screened finance creators</Text>
            </VStack>
            <VStack>
              <Text fontWeight="bold" fontSize="lg">✓ Easy Launch</Text>
              <Text color="gray.600">Start your campaign in minutes, not months</Text>
            </VStack>
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

const CategorySection = () => {
  const categories = [
    {
      title: "Investing",
      icon: "📈",
      image: "https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
    },
    {
      title: "Crypto",
      icon: "💰",
      image: "https://images.unsplash.com/photo-1621761191319-c6fb62004040?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1739&q=80"
    },
    {
      title: "Real Estate",
      icon: "🏠",
      image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1739&q=80"
    },
    {
      title: "Personal Finance",
      icon: "💳",
      image: "https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
    }
  ];

  return (
    <Box py={{ base: 16, md: 24 }}>
      <Container maxW="container.xl">
        <Heading 
          as="h2" 
          textAlign="center"
          fontSize={{ base: "3xl", md: "4xl" }}
          mb={12}
        >
          Categories
        </Heading>
        
        <SimpleGrid 
          columns={{ base: 1, sm: 2, lg: 4 }} 
          spacing={8}
        >
          {categories.map((category, index) => (
            <Box
              key={index}
              position="relative"
              height="280px"
              borderRadius="xl"
              overflow="hidden"
              cursor="pointer"
              transition="transform 0.2s"
              _hover={{
                transform: 'translateY(-4px)'
              }}
            >
              {/* Dark overlay */}
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="blackAlpha.600"
                zIndex={1}
              />
              
              {/* Background Image */}
              <Image
                src={category.image}
                alt={category.title}
                objectFit="cover"
                w="full"
                h="full"
              />
              
              {/* Content */}
              <VStack
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                p={6}
                align="start"
                spacing={2}
                zIndex={2}
              >
                <Text
                  fontSize="3xl"
                >
                  {category.icon}
                </Text>
                <Text
                  color="white"
                  fontSize="xl"
                  fontWeight="bold"
                >
                  {category.title}
                </Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const FeaturesSection = () => {
  const navigate = useNavigate();
  
  const features = [
    {
      icon: "👥",
      title: "Your Dream Creator Squad",
      description: "Stop scrolling endlessly for creators. We've built the A-team of financial influencers who actually understand fintech and drive results",
      color: "pink"
    },
    {
      icon: "📈",
      title: "Numbers That Make You Smile",
      description: "Watch your growth metrics soar with crystal-clear analytics. Track every conversion, CAC, and ROI like a fintech growth wizard",
      color: "purple"
    },
    {
      icon: "⚡",
      title: "Seamless Collaboration",
      description: "Launch campaigns in minutes, not months. Our streamlined workflow makes working with creators as easy as sending a tweet",
      color: "blue"
    }
  ];

  return (
    <Box py={{ base: 16, md: 24 }} bg="gray.50">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          {/* Header */}
          <Box textAlign="center" maxW="3xl" mx="auto">
            <Heading
              as="h2"
              fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
              mb={6}
              bgGradient="linear(to-r, pink.500, purple.500)"
              bgClip="text"
            >
              Unlock Next-Level Growth
            </Heading>
            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="gray.600"
            >
              Everything you need to scale your creator partnerships
            </Text>
          </Box>

          {/* Features Grid */}
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={{ base: 10, md: 8 }}
            w="full"
          >
            {features.map((feature, index) => (
              <Box
                key={index}
                bg="white"
                p={8}
                borderRadius="2xl"
                boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
                position="relative"
                overflow="hidden"
                _hover={{
                  transform: 'translateY(-4px)',
                  boxShadow: '2xl'
                }}
                transition="all 0.3s"
              >
                {/* Background Gradient */}
                <Box
                  position="absolute"
                  top="-20px"
                  right="-20px"
                  w="120px"
                  h="120px"
                  bg={`${feature.color}.50`}
                  borderRadius="full"
                  opacity="0.6"
                />

                {/* Icon */}
                <Box
                  mb={6}
                  fontSize="4xl"
                  bg={`${feature.color}.50`}
                  color={`${feature.color}.500`}
                  p={4}
                  borderRadius="xl"
                  display="inline-block"
                >
                  {feature.icon}
                </Box>

                {/* Content */}
                <Heading
                  as="h3"
                  fontSize={{ base: "xl", md: "2xl" }}
                  mb={4}
                  color={`${feature.color}.500`}
                >
                  {feature.title}
                </Heading>
                
                <Text
                  color="gray.600"
                  fontSize="lg"
                  lineHeight="tall"
                >
                  {feature.description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>

          {/* CTA Button */}
          <Button
            size="lg"
            bg="purple.500"
            color="white"
            px={8}
            py={6}
            fontSize="lg"
            fontWeight="bold"
            _hover={{
              bg: 'purple.600',
              transform: 'translateY(-2px)'
            }}
            transition="all 0.2s"
            onClick={() => navigate('/signup')}
          >
            Get Started Free
          </Button>
        </VStack>
      </Container>
    </Box>
  );
};

const SplashPage = () => {
  const navigate = useNavigate();
  
  // Filter to show only 2 videos on mobile
  const getDisplayedVideos = (isMobile) => {
    return isMobile ? tiktokExamples.slice(0, 2) : tiktokExamples;
  };

  const [currentStep, setCurrentStep] = React.useState(1);

  React.useEffect(() => {
    const loadTikTokScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.tiktok.com/embed.js';
      script.async = true;
      document.body.appendChild(script);
    };
    
    loadTikTokScript();
    
    return () => {
      const scripts = document.querySelectorAll('script[src="https://www.tiktok.com/embed.js"]');
      scripts.forEach(script => script.remove());
    };
  }, []);

  return (
    <Box className="splash-page">
      {/* Hero Section - Mobile Optimized */}
      <Box 
        className="hero-section" 
        pt={{ base: 12, md: 20 }}
        pb={{ base: 8, md: 10 }}
        px={{ base: 4, md: 0 }}
        textAlign="center"
      >
        <Container maxW="container.xl">
          <Box maxW="4xl" mx="auto">
            <Heading
              as="h1"
              fontSize={{ base: '3xl', md: '6xl', lg: '7xl' }}
              lineHeight={{ base: "1.3", md: "1.2" }}
              mb={{ base: 4, md: 6 }}
              color="gray.900"
            >
              Skyrocket Awareness With{' '}
              <Text as="span" color="purple.500" display="inline">
                Finance Creators
              </Text>
            </Heading>
            
            <Text 
              fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
              color="gray.700"
              mb={{ base: 6, md: 4 }}
              maxW="3xl"
              mx="auto"
              px={{ base: 4, md: 0 }}
            >
              Go viral by harnessing the creativity of{' '}
              <Text as="span" color="purple.500" display="inline">
                social influencers
              </Text>
            </Text>

            <Button 
              size={{ base: "md", md: "lg" }}
              bg="purple.500"
              color="white"
              px={{ base: 6, md: 8 }}
              py={{ base: 5, md: 6 }}
              fontSize={{ base: "md", md: "lg" }}
              _hover={{ bg: 'purple.600' }}
              onClick={() => navigate('/signup')}
              borderRadius="full"
              w={{ base: "full", md: "auto" }}
            >
              Let's Go
            </Button>
          </Box>
        </Container>
      </Box>

      {/* TikTok Section - Moved here right after hero */}
      <TikTokSection />

      {/* New How It Works section */}
      <HowItWorksSection />

      <AutomateSection />
      <AnalyticsSection />

      <FeaturesSection />

      <Box className="hype-creators-section">
        <Heading 
          as="h2" 
          className="hype-creators-title"
          fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
          textAlign="center"
          mb={12}
        >
          Every Creator Has A Place On HYPEO 🚀
        </Heading>
        <SimpleGrid className="hype-creators-grid" columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          <Box className="hype-creator-card">
            <Box className="hype-creator-image-wrapper">
              <Image 
                src="https://pbs.twimg.com/profile_images/1836846258251517959/9FPetwY0_400x400.jpg" 
                alt="Alex Popovic" 
                objectFit="cover" 
              />
            </Box>
            <Box className="hype-creator-info">
              <Heading as="h3" size="lg">Alex Budgets</Heading>
              <Text className="hype-follower-count">150K+ Followers</Text>
              <Text className="hype-creator-specialty">💰 Finance Expert</Text>
            </Box>
          </Box>

          <Box className="hype-creator-card">
            <Box className="hype-creator-image-wrapper">
              <Image 
                src="https://a.storyblok.com/f/191576/1024x1024/8f18838f03/sample-03.webp
"
                alt="Crypto Dara"
                objectFit="cover"
              />
            </Box>
            <Box className="hype-creator-info">
              <Heading as="h3" size="lg">Crypto Dara</Heading>
              <Text className="hype-follower-count">200K+ Followers</Text>
              <Text className="hype-creator-specialty">📊 Crypto Expert</Text>
            </Box>
          </Box>

          <Box className="hype-creator-card">
          <Box className="hype-creator-image-wrapper">
              <Image 
                src="https://media.istockphoto.com/id/1136449639/video/the-man-laughing-on-the-white-background-slow-motion.jpg?s=640x640&k=20&c=jCivJBgTODPt57y3ESNnasT4Dc8z2Semme4VmNK2EeM=
"
                alt="Crypto Dara"
                objectFit="cover"
              />
            </Box>
            <Box className="hype-creator-info">
              <Heading as="h3" size="lg">Anthony Talks</Heading>
              <Text className="hype-follower-count">120K+ Followers</Text>
              <Text className="hype-creator-specialty">💻 Finance Educator</Text>
            </Box>
          </Box>

        </SimpleGrid>
      </Box>

      <CategorySection />

      <BenefitsSection />

      {/* Story Cards Section */}
      
    </Box>
  );
};

export default SplashPage; 