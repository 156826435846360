import React, { useRef, useState } from 'react';
import { Button, Box, Text, useToast } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { auth } from '../firebase';

const MediaUploader = ({ onUploadSuccess, buttonText = "Add Media", acceptTypes = "image/*,video/*" }) => {
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const toast = useToast();

  const handleFileUpload = async (event) => {
    try {
      // Get the file from the input element
      const file = event.target.files[0];
      if (!file) {
        console.error('No file selected');
        toast({
          title: 'Error',
          description: 'No file selected',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      
      setIsUploading(true);
      console.log('Selected file:', file.name, 'Size:', file.size, 'Type:', file.type);
      
      // Create a FormData object
      const formData = new FormData();
      formData.append('image', file); // Use 'image' as the field name to match server expectations
      
      // Get the Firebase ID token
      const idToken = await auth.currentUser.getIdToken();
      
      // Use the portfolio-media endpoint
      console.log('Uploading file to /api/upload/portfolio-media');
      const response = await fetch('/api/upload/portfolio-media', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Upload failed:', response.status, response.statusText, errorText);
        throw new Error(`Upload failed: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Upload successful:', data);
      
      // Call the success callback with the uploaded media info
      if (data && data.secure_url) {
        const mediaInfo = {
          secure_url: data.secure_url,
          public_id: data.public_id,
          resource_type: data.resource_type || (file.type.startsWith('video/') ? 'video' : 'image'),
          title: file.name || 'Untitled',
          description: '',
          url: data.secure_url // Make sure this property is included
        };
        
        onUploadSuccess(mediaInfo);
        
        toast({
          title: 'Success',
          description: 'File uploaded successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      
      return data;
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: 'Upload failed',
        description: error.message || 'Unknown error',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      
      // Reset the file input on error too
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box mb={6}>
      <input
        type="file"
        id="media-upload"
        accept={acceptTypes}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        ref={fileInputRef}
      />
      <Button 
        leftIcon={<FaPlus />} 
        colorScheme="blue" 
        onClick={() => fileInputRef.current.click()}
        isLoading={isUploading}
        loadingText="Uploading..."
      >
        {buttonText}
      </Button>
      {isUploading && (
        <Text fontSize="sm" mt={2} color="gray.600">
          Uploading your file, please wait...
        </Text>
      )}
    </Box>
  );
};

export default MediaUploader; 