import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import MessageThread from '../Messages/MessageThread';
import { db } from '../../firebase';
import { 
    collection, 
    query, 
    where, 
    getDocs,
    updateDoc,
    serverTimestamp,
    doc,
    getDoc, 
    Timestamp
} from 'firebase/firestore';
import {
    Box,
    Flex,
    Text,
    VStack,
    HStack,
    Image,
    Badge,
    useBreakpointValue,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    useDisclosure,
    Spinner,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Heading,
    Button,
    Container,
    useColorModeValue
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FaUser, FaBuilding } from 'react-icons/fa';
import ProjectStatus from './ProjectStatus';
import './BrandWork.css';
import BrandWorkOrders from './BrandWorkOrders';
import CreatorWorkOrders from './CreatorWorkOrders';

const BrandWork = () => {
    const { currentUser } = useAuth();
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');

    useEffect(() => {
        const fetchUserRole = async () => {
            if (!currentUser) {
                setLoading(false);
                return;
            }

            try {
                // First check the users collection for a role field
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                
                if (userDoc.exists() && userDoc.data().role) {
                    setUserRole(userDoc.data().role);
                    setLoading(false);
                    return;
                }
                
                // If no role in users collection, check creators collection
                const creatorDoc = await getDoc(doc(db, 'creators', currentUser.uid));
                if (creatorDoc.exists()) {
                    setUserRole('creator');
                    setLoading(false);
                    return;
                }
                
                // If not a creator, check brands collection
                const brandDoc = await getDoc(doc(db, 'brands', currentUser.uid));
                if (brandDoc.exists()) {
                    setUserRole('brand');
                    setLoading(false);
                    return;
                }
                
                // If we get here, user doesn't have a role yet
                setUserRole(null);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user role:', error);
                setLoading(false);
            }
        };

        fetchUserRole();
    }, [currentUser]);

    if (loading) {
        return (
            <Box textAlign="center" py={10}>
                <Spinner size="xl" />
                <Text mt={4}>Loading your work dashboard...</Text>
            </Box>
        );
    }

    if (!userRole) {
        return (
            <Container maxW="container.xl" py={10}>
                <Box 
                    bg={cardBg} 
                    p={8} 
                    borderRadius="lg" 
                    boxShadow="md"
                    textAlign="center"
                >
                    <Heading as="h1" size="xl" mb={4}>
                        Complete Your Profile
                    </Heading>
                    <Text fontSize="lg" mb={6}>
                        Please complete your profile setup to access your work dashboard. 
                        You need to register as either a brand or a creator.
                    </Text>
                    <Flex justify="center" gap={4}>
                        <Button 
                            as={Link} 
                            to="/onboarding" 
                            colorScheme="blue" 
                            size="lg" 
                        >
                            Complete Profile
                        </Button>
                        <Button 
                            as={Link} 
                            to="/profile" 
                            variant="outline" 
                            colorScheme="blue" 
                            size="lg"
                        >
                            View Profile
                        </Button>
                    </Flex>
                </Box>
            </Container>
        );
    }

    return (
        <Box bg={bgColor} minH="calc(100vh - 80px)" py={8}>
            <Container maxW="container.xl">
                <Heading as="h1" size="xl" mb={6}>
                    {userRole === 'brand' ? 'Creator Orders' : 'My Work'}
                </Heading>
                
                <Tabs colorScheme="blue" variant="enclosed" isLazy>
                    <TabList mb={4}>
                        <Tab fontWeight="semibold">Active Orders</Tab>
                        <Tab fontWeight="semibold">Completed Orders</Tab>
                        {userRole === 'brand' && <Tab fontWeight="semibold">Create New Order</Tab>}
                    </TabList>
                    
                    <TabPanels>
                        <TabPanel p={0}>
                            {userRole === 'brand' ? (
                                <BrandWorkOrders status="active" />
                            ) : (
                                <CreatorWorkOrders status="active" />
                            )}
                        </TabPanel>
                        
                        <TabPanel p={0}>
                            {userRole === 'brand' ? (
                                <BrandWorkOrders status="completed" />
                            ) : (
                                <CreatorWorkOrders status="completed" />
                            )}
                        </TabPanel>
                        
                        {userRole === 'brand' && (
                            <TabPanel p={0}>
                                <Box 
                                    bg={cardBg} 
                                    p={6} 
                                    borderRadius="lg" 
                                    boxShadow="md"
                                    textAlign="center"
                                >
                                    <Heading as="h2" size="lg" mb={4}>
                                        Create a New Order
                                    </Heading>
                                    <Text mb={6}>
                                        Ready to work with a creator? Create a new order to get started.
                                    </Text>
                                    <Button 
                                        as={Link} 
                                        to="/orders/new" 
                                        colorScheme="blue" 
                                        size="lg"
                                    >
                                        Create Order
                                    </Button>
                                </Box>
                            </TabPanel>
                        )}
                    </TabPanels>
                </Tabs>
            </Container>
        </Box>
    );
};

export default BrandWork; 