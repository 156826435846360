import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Button,
  Text,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  IconButton,
  useDisclosure,
  SimpleGrid,
  Icon,
  Avatar,
  Badge,
  Divider
} from '@chakra-ui/react';
import { HamburgerIcon, AddIcon } from '@chakra-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, where, getDocs, getDoc, addDoc, serverTimestamp, doc, Timestamp, orderBy, updateDoc } from 'firebase/firestore';
import CampaignList from '../Campaigns/CampaignList';
import TrackingStats from '../Tracking/TrackingStats';
import './BrandDashboard.css';
import { auth } from '../../firebase';
import config from '../../config/config';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { EditIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';

const TRACKING_DOMAIN = process.env.NODE_ENV === 'production' 
  ? 'https://us-central1-hype-9b87b.cloudfunctions.net/api'
  : 'http://localhost:5001/api';

const formatDate = (timestamp) => {
  if (!timestamp) return 'N/A';
  
  // Check if it's a Firestore timestamp
  if (timestamp?.toDate) {
    return timestamp.toDate().toLocaleDateString();
  }
  
  // Check if it's a regular timestamp (number)
  if (typeof timestamp === 'number') {
    return new Date(timestamp).toLocaleDateString();
  }
  
  // If it's already a Date object
  if (timestamp instanceof Date) {
    return timestamp.toLocaleDateString();
  }
  
  // If it's a string, try to parse it
  if (typeof timestamp === 'string') {
    return new Date(timestamp).toLocaleDateString();
  }
  
  return 'Invalid date';
};

const BrandDashboard = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [activeTab, setActiveTab] = useState('metrics');
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('metrics');
  const [campaignMetrics, setCampaignMetrics] = useState({});
  const [orders, setOrders] = useState([]);
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metricsData, setMetricsData] = useState({
    totalRevenue: 0,
    totalConversions: 0,
    conversionRate: 0,
    activeCampaigns: 0,
    revenueChange: 0,
    conversionChange: 0,
    rateChange: 0
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    if (!loading && !currentUser) {
      navigate('/login');
    }
  }, [currentUser, loading, navigate]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      if (!currentUser?.uid) return;
      
      try {
        setIsLoading(true);
        const campaignsRef = collection(db, 'campaigns');
        const q = query(
          campaignsRef,
          where('brandId', '==', currentUser.uid),
          orderBy('createdAt', 'desc')
        );
        
        const snapshot = await getDocs(q);
        const campaignsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setCampaigns(campaignsData);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setError('Failed to load campaigns');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCampaigns();
  }, [currentUser]);

  useEffect(() => {
    const fetchCampaignMetrics = async () => {
      try {
        const campaignIds = campaigns.map(camp => camp.id);
        
        // Skip if no campaign IDs
        if (!campaignIds.length) {
          setCampaignMetrics({});
          return;
        }
        
        // Batch campaignIds into chunks of 10 (Firestore limit)
        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < campaignIds.length; i += batchSize) {
            batches.push(campaignIds.slice(i, i + batchSize));
        }
        
        let allClicks = [];
        let allConversions = [];
        
        // Process each batch
        for (const batchIds of batches) {
            const [clicksSnapshot, conversionsSnapshot] = await Promise.all([
                getDocs(query(
                    collection(db, 'clicks'),
                    where('campaignId', 'in', batchIds)
                )),
                getDocs(query(
                    collection(db, 'conversions'),
                    where('campaignId', 'in', batchIds)
                ))
            ]);
            
            allClicks = [...allClicks, ...clicksSnapshot.docs];
            allConversions = [...allConversions, ...conversionsSnapshot.docs];
        }
        
        // Process metrics as before
        const clicksCount = {};
        allClicks.forEach(doc => {
          const campaignId = doc.data().campaignId;
          clicksCount[campaignId] = (clicksCount[campaignId] || 0) + 1;
        });

        const metrics = {};
        campaignIds.forEach(id => {
          metrics[id] = {
            clicks: 0,
            conversions: 0,
            sales: 0,
            conversionRate: 0
          };
        });

        allConversions.forEach(doc => {
          const conversion = doc.data();
          const campaignId = conversion.campaignId;
          metrics[campaignId].conversions++;
          metrics[campaignId].sales += conversion.amount || 0;
        });

        Object.keys(metrics).forEach(campaignId => {
          metrics[campaignId].clicks = clicksCount[campaignId] || 0;
          metrics[campaignId].sales = metrics[campaignId].sales.toFixed(2);
          metrics[campaignId].conversionRate = metrics[campaignId].clicks > 0
            ? ((metrics[campaignId].conversions / metrics[campaignId].clicks) * 100).toFixed(1)
            : '0';
        });

        setCampaignMetrics(metrics);
      } catch (error) {
        setError(error.message);
      }
    };

    if (campaigns.length > 0) {
      fetchCampaignMetrics();
    } else {
      setCampaignMetrics({});
    }
  }, [campaigns]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!currentUser?.uid) return;
      
      try {
        setIsLoading(true);
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('brandId', '==', currentUser.uid),
          orderBy('createdAt', 'desc')
        );
        
        const snapshot = await getDocs(q);
        const ordersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to load orders');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [currentUser]);

  useEffect(() => {
    const fetchApplications = async () => {
      if (!currentUser?.uid) return;
      
      try {
        setIsLoading(true);
        
        const campaignsRef = collection(db, 'campaigns');
        const campaignsQuery = query(
          campaignsRef,
          where('brandId', '==', currentUser.uid)
        );
        const campaignsSnapshot = await getDocs(campaignsQuery);
        const campaignIds = campaignsSnapshot.docs.map(doc => doc.id);
        
        // If no campaigns found, return empty applications array
        if (campaignIds.length === 0) {
          setApplications([]);
          return;
        }
        
        // Process in batches of 10 (Firestore limit)
        const batchSize = 10;
        let allApplications = [];
        
        for (let i = 0; i < campaignIds.length; i += batchSize) {
          const batchIds = campaignIds.slice(i, i + batchSize);
          
          const applicationsRef = collection(db, 'applications');
          const q = query(
            applicationsRef,
            where('campaignId', 'in', batchIds)
          );
          
          const snapshot = await getDocs(q);
          allApplications = [...allApplications, ...snapshot.docs];
        }

        if (allApplications.length === 0) {
          setApplications([]);
          return;
        }

        const applicationsData = await Promise.all(
          allApplications.map(async (applicationDoc) => {
            const data = applicationDoc.data();

            try {
              let creatorData = null;
              if (data.userId) {
                const creatorRef = doc(db, 'users', data.userId);
                const creatorSnap = await getDoc(creatorRef);
                if (creatorSnap.exists()) {
                  creatorData = creatorSnap.data();
                }
              }

              const campaignRef = doc(db, 'campaigns', data.campaignId);
              const campaignSnap = await getDoc(campaignRef);
              const campaignData = campaignSnap.exists() ? campaignSnap.data() : null;
              
              return {
                id: applicationDoc.id,
                userId: data.userId || 'unknown',
                creatorName: creatorData?.name || 'Unknown Creator',
                creatorImage: creatorData?.profileImageUrl || '#',
                campaignName: campaignData?.programName || 'Unknown Campaign',
                status: data.status || 'Pending',
                appliedDate: data.createdAt ? new Date(data.createdAt.seconds * 1000) : null
              };
            } catch (error) {
              console.error('Error processing application:', error);
              return {
                id: applicationDoc.id,
                creatorName: 'Unknown Creator',
                creatorImage: '#',
                campaignName: 'Unknown Campaign',
                status: data.status || 'Pending',
                appliedDate: null
              };
            }
          })
        );
        
        setApplications(applicationsData);
        
      } catch (error) {
        console.error('Error fetching applications:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchApplications();
  }, [currentUser?.uid]);

  useEffect(() => {
    const calculateMetrics = async () => {
      try {
        // Check for empty campaigns array
        if (!campaigns || campaigns.length === 0) {
          setMetricsData({
            totalRevenue: 0,
            totalConversions: 0,
            conversionRate: 0,
            activeCampaigns: 0,
            revenueChange: 0,
            conversionChange: 0,
            rateChange: 0
          });
          return;
        }

        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
        const sixtyDaysAgo = new Date(currentDate.getTime() - (60 * 24 * 60 * 60 * 1000));

        const thirtyDaysAgoTimestamp = Timestamp.fromDate(thirtyDaysAgo);
        const sixtyDaysAgoTimestamp = Timestamp.fromDate(sixtyDaysAgo);

        const brandCampaignIds = campaigns.map(campaign => campaign.id);
        
        // Process in batches of 10 (Firestore limit)
        const batchSize = 10;
        let currentMetrics = {
          currentRevenue: 0,
          currentConversions: 0,
          currentClicks: 0,
          previousRevenue: 0,
          previousConversions: 0,
          previousClicks: 0
        };

        // Process in batches
        for (let i = 0; i < brandCampaignIds.length; i += batchSize) {
          const batchIds = brandCampaignIds.slice(i, i + batchSize);
          
          const [
            currentConvSnap,
            prevConvSnap,
            currentClicksSnap,
            prevClicksSnap
          ] = await Promise.all([
            getDocs(query(
              collection(db, 'conversions'),
              where('brandId', '==', currentUser.uid),
              where('campaignId', 'in', batchIds),
              where('timestamp', '>=', thirtyDaysAgoTimestamp)
            )),
            getDocs(query(
              collection(db, 'conversions'),
              where('brandId', '==', currentUser.uid),
              where('campaignId', 'in', batchIds),
              where('timestamp', '>=', sixtyDaysAgoTimestamp),
              where('timestamp', '<', thirtyDaysAgoTimestamp)
            )),
            getDocs(query(
              collection(db, 'clicks'),
              where('brandId', '==', currentUser.uid),
              where('campaignId', 'in', batchIds),
              where('timestamp', '>=', thirtyDaysAgoTimestamp)
            )),
            getDocs(query(
              collection(db, 'clicks'),
              where('brandId', '==', currentUser.uid),
              where('campaignId', 'in', batchIds),
              where('timestamp', '>=', sixtyDaysAgoTimestamp),
              where('timestamp', '<', thirtyDaysAgoTimestamp)
            ))
          ]);

          // Accumulate metrics from this batch
          currentMetrics.currentRevenue += currentConvSnap.docs.reduce((sum, doc) => {
            const data = doc.data();
            return sum + (typeof data.amount === 'number' ? data.amount : 0);
          }, 0);

          currentMetrics.currentConversions += currentConvSnap.size;
          currentMetrics.currentClicks += currentClicksSnap.size;
          
          currentMetrics.previousRevenue += prevConvSnap.docs.reduce((sum, doc) => {
            const data = doc.data();
            return sum + (typeof data.amount === 'number' ? data.amount : 0);
          }, 0);

          currentMetrics.previousConversions += prevConvSnap.size;
          currentMetrics.previousClicks += prevClicksSnap.size;
        }

        const currentRate = currentMetrics.currentClicks > 0 
          ? (currentMetrics.currentConversions / currentMetrics.currentClicks) * 100 
          : 0;
          
        const previousRate = currentMetrics.previousClicks > 0 
          ? (currentMetrics.previousConversions / currentMetrics.previousClicks) * 100 
          : 0;

        const revenueChange = currentMetrics.previousRevenue > 0 
          ? ((currentMetrics.currentRevenue - currentMetrics.previousRevenue) / currentMetrics.previousRevenue) * 100 
          : currentMetrics.currentRevenue > 0 ? 100 : 0;
        
        const conversionChange = currentMetrics.previousConversions > 0 
          ? ((currentMetrics.currentConversions - currentMetrics.previousConversions) / currentMetrics.previousConversions) * 100 
          : currentMetrics.currentConversions > 0 ? 100 : 0;
        
        const rateChange = previousRate > 0 
          ? ((currentRate - previousRate) / previousRate) * 100 
          : currentRate > 0 ? 100 : 0;

        const activeCampaignsCount = campaigns.filter(campaign => {
          const endDate = new Date(campaign.endDate);
          return endDate >= new Date();
        }).length;

        setMetricsData({
          totalRevenue: currentMetrics.currentRevenue,
          totalConversions: currentMetrics.currentConversions,
          conversionRate: currentRate,
          activeCampaigns: campaigns.length,
          revenueChange: revenueChange,
          conversionChange: conversionChange,
          rateChange: rateChange
        });

      } catch (error) {
        console.error('Error calculating metrics:', error);
        setError(error.message);
      }
    };

    if (currentUser && !loading && campaigns.length > 0) {
      calculateMetrics();
    }
  }, [currentUser, loading, campaigns]);

  const handleGetTags = (campaignId) => {
    setSelectedCampaignId(selectedCampaignId === campaignId ? null : campaignId);
  };

  const getHeaderScript = (campaignId) => {
    return `<!-- Hype Tracking Header Script -->
<script>
  (function() {
    const config = {
      brandId: '${currentUser.uid}',
      campaignId: '${campaignId}',
      trackingDomain: '${config.api.baseURL}'
    };

    // Link click tracking
    document.addEventListener('DOMContentLoaded', function() {
      var links = document.getElementsByTagName('a');
      for(var i = 0; i < links.length; i++) {
        var link = links[i];
        // Track all links
        link.href = createTrackingUrl(link.href);
        console.log('Original URL:', link.href);
        console.log('Tracking URL created:', link.href);
      }
    });

    function createTrackingUrl(originalUrl) {
      return \`\${config.trackingDomain}/tracking/redirect?\` + 
             \`aid=\${localStorage.getItem('hype_aid') || ''}&\` +
             \`cid=\${config.campaignId}&\` +
             \`url=\${encodeURIComponent(originalUrl)}\`;
    }
  })();
</script>`;
  };

  const generateTrackingCode = (username, campaignId) => {
    return `
<!-- Hype Conversion Tracking Script -->
<script>
    window.trackConversion = async function(orderId, amount) {
        try {
            const response = await fetch('${TRACKING_DOMAIN}/tracking/convert', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: '${username}',
                    campaignId: '${campaignId}',
                    orderId: orderId,
                    amount: parseFloat(amount)
                })
            });

            if (!response.ok) {
                throw new Error(\`HTTP error! status: \${response.status}\`);
            }
            
            const data = await response.json();
            console.log('Conversion tracked successfully:', data);
            return true;
        } catch (error) {
            console.error('Conversion tracking error:', error);
            return false;
        }
    }

    // Example implementation
    function purchaseProduct(productId, amount) {
        const orderId = 'ORDER-' + Math.random().toString(36).substr(2, 9);
        window.trackConversion(orderId, amount)
            .then(success => {
                if (success) {
                    console.log('Purchase tracked successfully!');
                } else {
                    console.error('Failed to track purchase');
                }
            });
    }
</script>`;
  };

  const createMessage = async (partnershipId) => {
    try {
        const messageRef = collection(db, 'messages'); // Assuming you have a 'messages' collection
        await addDoc(messageRef, {
            partnershipId: partnershipId,
            senderId: currentUser.uid,
            createdAt: serverTimestamp(),
            // Add any other fields you need for the message
        });

        // Navigate to the Brand Work messages section
        navigate('/work'); // Redirect to the messages section
    } catch (error) {
        console.error('Error creating message:', error);
        // Optionally handle the error without an alert
    }
  };

  const handleStatusToggle = async (campaign) => {
    try {
      const campaignRef = doc(db, 'campaigns', campaign.id);
      const newStatus = campaign.status === 'active' ? 'inactive' : 'active';
      await updateDoc(campaignRef, {
        status: newStatus,
        updatedAt: serverTimestamp()
      });
      
      // Update local state
      setCampaigns(campaigns.map(c => 
        c.id === campaign.id 
          ? { ...c, status: newStatus }
          : c
      ));
    } catch (error) {
      console.error('Error updating campaign status:', error);
      setError('Failed to update campaign status');
    }
  };

  const renderOrders = () => {
    if (isLoading) {
      return <Text>Loading orders...</Text>;
    }

    if (error) {
      return <Text color="red.500">{error}</Text>;
    }

    if (!orders.length) {
      return <Text>No orders found</Text>;
    }

    return (
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {orders.map((order) => (
          <Box
            key={order.id}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            bg="white"
            shadow="sm"
          >
            <VStack align="stretch" spacing={2}>
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">Order #{order.id.slice(-6)}</Text>
                <Badge 
                  colorScheme={order.status === 'completed' ? 'green' : 'orange'}
                >
                  {order.status || 'Pending'}
                </Badge>
              </Flex>
              
              <Text fontSize="sm">
                Date: {formatDate(order.createdAt)}
              </Text>
              
              <Text fontSize="sm">
                Amount: ${order.amount?.toFixed(2) || '0.00'}
              </Text>
              
              {order.customerName && (
                <Text fontSize="sm">
                  Customer: {order.customerName}
                </Text>
              )}
              
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => navigate(`/orders/${order.id}`)}
              >
                View Details
              </Button>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'metrics':
        return (
          <>
            <Text fontSize="2xl" fontWeight="bold" mb={6}>
              Performance Metrics
            </Text>
            <TrackingStats />
          </>
        );
      case 'campaigns':
        return (
          <>
            <Flex justify="space-between" align="center" mb={6}>
              <Text fontSize="2xl" fontWeight="bold">
                Your Campaigns
              </Text>
              <Button
                colorScheme="blue"
                leftIcon={<Icon as={AddIcon} />}
                onClick={() => navigate('/campaigns/create')}
              >
                Create Campaign
              </Button>
            </Flex>
            
            {isLoading ? (
              <Text>Loading campaigns...</Text>
            ) : error ? (
              <Text color="red.500">{error}</Text>
            ) : campaigns.length === 0 ? (
              <Text>No campaigns found</Text>
            ) : (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                {campaigns.map((campaign) => (
                  <Box
                    key={campaign.id}
                    p={5}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg="white"
                    shadow="sm"
                    transition="all 0.2s"
                    _hover={{ shadow: "md" }}
                  >
                    <VStack align="stretch" spacing={3}>
                      <Flex justify="space-between" align="start">
                        <VStack align="start" spacing={1}>
                          <Text fontWeight="bold" fontSize="lg" noOfLines={2}>
                            {campaign.title}
                          </Text>
                          <Badge 
                            colorScheme={campaign.status === 'active' ? 'green' : 'orange'}
                            fontSize="sm"
                          >
                            {campaign.status}
                          </Badge>
                        </VStack>
                      </Flex>

                      <Text fontSize="sm" color="gray.600" noOfLines={2}>
                        {campaign.description || 'No description provided'}
                      </Text>

                      <Divider />

                      <SimpleGrid columns={2} spacing={2}>
                        <Box>
                          <Text fontSize="xs" color="gray.500">Budget</Text>
                          <Text fontSize="sm" fontWeight="medium">
                            ${campaign.budget?.toFixed(2) || '0.00'}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="xs" color="gray.500">Created</Text>
                          <Text fontSize="sm">
                            {formatDate(campaign.createdAt)}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="xs" color="gray.500">Applications</Text>
                          <Text fontSize="sm">
                            {campaign.applicationCount || 0}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="xs" color="gray.500">Category</Text>
                          <Text fontSize="sm" noOfLines={1}>
                            {campaign.category || 'N/A'}
                          </Text>
                        </Box>
                      </SimpleGrid>

                      <HStack spacing={2} mt={2}>
                        <Button
                          size="sm"
                          flex={1}
                          colorScheme="blue"
                          variant="outline"
                          onClick={() => navigate(`/campaigns/${campaign.id}`)}
                        >
                          View Details
                        </Button>
                        <Button
                          size="sm"
                          flex={1}
                          colorScheme={campaign.status === 'active' ? 'red' : 'green'}
                          variant="outline"
                          onClick={() => handleStatusToggle(campaign)}
                        >
                          {campaign.status === 'active' ? 'Pause' : 'Activate'}
                        </Button>
                      </HStack>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </>
        );
      case 'orders':
        return (
          <>
            <Text fontSize="2xl" fontWeight="bold" mb={6}>
              Orders
            </Text>
            {renderOrders()}
          </>
        );
      default:
        return null;
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!currentUser) {
    navigate('/login');
    return null;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box minH="100vh" bg="gray.50" position="relative">
      <Box 
        maxW="1400px" 
        mx="auto" 
        pb={isMobile ? "80px" : 0}
        overflow="hidden"
      >
        <Flex>
          {/* Desktop Sidebar */}
          {!isMobile ? (
            <Box
              w="240px"
              bg="white"
              borderRight="1px"
              borderColor="gray.200"
              position="sticky"
              top="0"
              h="100vh"
              py={8}
              px={4}
            >
              {/* Brand/User Section */}
              <VStack mb={8} align="start" px={2}>
                <Text fontSize="lg" fontWeight="bold" color="gray.800">
                  Brand Dashboard
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {currentUser?.displayName}
                </Text>
              </VStack>

              {/* Navigation Items */}
              <VStack spacing={2} align="stretch">
                {[
                  { id: 'metrics', icon: '📊', label: 'Overview' },
                  { id: 'campaigns', icon: '🎯', label: 'Campaigns' },
                  { id: 'orders', icon: '🛍️', label: 'Orders' },
                  { id: 'partnerships', icon: '📝', label: 'Applications' }
                ].map((item) => (
                  <Button
                    key={item.id}
                    onClick={() => setActiveSection(item.id)}
                    variant="ghost"
                    justifyContent="flex-start"
                    py={4}
                    px={4}
                    borderRadius="lg"
                    bg={activeSection === item.id ? 'blue.50' : 'transparent'}
                    color={activeSection === item.id ? 'blue.600' : 'gray.600'}
                    fontWeight={activeSection === item.id ? "semibold" : "medium"}
                    _hover={{
                      bg: 'blue.50',
                      color: 'blue.600'
                    }}
                    leftIcon={
                      <Text fontSize="lg" mr={3}>
                        {item.icon}
                      </Text>
                    }
                  >
                    {item.label}
                  </Button>
                ))}
              </VStack>
            </Box>
          ) : null}

          {/* Main Content */}
          <Box 
            flex={1} 
            p={4}
            overflow="auto"
          >
            <Box
              bg="white"
              borderRadius="lg"
              boxShadow="sm"
              p={6}
            >
              {/* Performance Metrics Summary - Only show in metrics section */}
              {activeSection === 'metrics' && (
                <>
                  <Text 
                    fontSize="2xl" 
                    fontWeight="bold" 
                    mb={6}
                    color="gray.800"
                  >
                    Performance Metrics
                  </Text>
                  <SimpleGrid 
                    columns={{ base: 2, md: 4 }} 
                    spacing={4} 
                    mb={8}
                  >
                    <Box 
                      p={4} 
                      bg="blue.50" 
                      borderRadius="lg"
                      border="1px"
                      borderColor="blue.100"
                    >
                      <Text fontSize="sm" color="gray.600" mb={1}>
                        Revenue (30d)
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="blue.700">
                        ${metricsData.totalRevenue.toFixed(2)}
                      </Text>
                      <HStack spacing={1} mt={1}>
                        <Icon 
                          as={metricsData.revenueChange >= 0 ? ArrowUpIcon : ArrowDownIcon} 
                          color={metricsData.revenueChange >= 0 ? 'green.500' : 'red.500'}
                        />
                        <Text fontSize="sm" color={metricsData.revenueChange >= 0 ? 'green.500' : 'red.500'}>
                          {Math.abs(metricsData.revenueChange).toFixed(1)}%
                        </Text>
                      </HStack>
                    </Box>

                    <Box 
                      p={4} 
                      bg="green.50" 
                      borderRadius="lg"
                      border="1px"
                      borderColor="green.100"
                    >
                      <Text fontSize="sm" color="gray.600" mb={1}>
                        Conversions
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="green.700">
                        {metricsData.totalConversions}
                      </Text>
                      <HStack spacing={1} mt={1}>
                        <Icon 
                          as={metricsData.conversionChange >= 0 ? ArrowUpIcon : ArrowDownIcon}
                          color={metricsData.conversionChange >= 0 ? 'green.500' : 'red.500'}
                        />
                        <Text fontSize="sm" color={metricsData.conversionChange >= 0 ? 'green.500' : 'red.500'}>
                          {Math.abs(metricsData.conversionChange).toFixed(1)}%
                        </Text>
                      </HStack>
                    </Box>

                    <Box 
                      p={4} 
                      bg="purple.50" 
                      borderRadius="lg"
                      border="1px"
                      borderColor="purple.100"
                    >
                      <Text fontSize="sm" color="gray.600" mb={1}>
                        Conv. Rate
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="purple.700">
                        {metricsData.conversionRate.toFixed(1)}%
                      </Text>
                      <HStack spacing={1} mt={1}>
                        <Icon 
                          as={metricsData.rateChange >= 0 ? ArrowUpIcon : ArrowDownIcon}
                          color={metricsData.rateChange >= 0 ? 'green.500' : 'red.500'}
                        />
                        <Text fontSize="sm" color={metricsData.rateChange >= 0 ? 'green.500' : 'red.500'}>
                          {Math.abs(metricsData.rateChange).toFixed(1)}%
                        </Text>
                      </HStack>
                    </Box>

                    <Box 
                      p={4} 
                      bg="orange.50" 
                      borderRadius="lg"
                      border="1px"
                      borderColor="orange.100"
                    >
                      <Text fontSize="sm" color="gray.600" mb={1}>
                        Campaigns
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="orange.700">
                        {metricsData.activeCampaigns}
                      </Text>
                      <Text fontSize="sm" color="gray.500" mt={1}>
                        Active
                      </Text>
                    </Box>
                  </SimpleGrid>
                </>
              )}

              {/* Rest of the content */}
              <Box>
                {renderContent()}
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* Mobile Bottom Navigation */}
      {isMobile && (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg="white"
          boxShadow="0 -2px 10px rgba(0,0,0,0.05)"
          zIndex={1000}
          maxW="100%"
          mx="auto"
        >
          <SimpleGrid columns={4} spacing={0}>
            {[
              { id: 'metrics', icon: '📊', label: 'Overview' },
              { id: 'campaigns', icon: '🎯', label: 'Campaigns' },
              { id: 'orders', icon: '🛍️', label: 'Orders' },
              { id: 'partnerships', icon: '📝', label: 'Applications' }
            ].map((item) => (
              <Button
                key={item.id}
                onClick={() => setActiveSection(item.id)}
                variant="ghost"
                height="60px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={1}
                borderRadius={0}
                bg={activeSection === item.id ? 'blue.50' : 'transparent'}
                color={activeSection === item.id ? 'blue.600' : 'gray.600'}
                _hover={{
                  bg: 'blue.50'
                }}
                px={2}
                width="100%"
              >
                <Text fontSize="lg">{item.icon}</Text>
                <Text 
                  fontSize="xs" 
                  fontWeight={activeSection === item.id ? "semibold" : "medium"}
                  textAlign="center"
                  noOfLines={1}
                >
                  {item.label}
                </Text>
              </Button>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default BrandDashboard;
 