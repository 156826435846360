import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './CampaignPage.css';

const CampaignPage = () => {
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        // First try to fetch from Firestore directly
        const campaignDoc = await getDoc(doc(db, 'campaigns', id));
        
        if (campaignDoc.exists()) {
          console.log('Campaign found in Firestore:', campaignDoc.data());
          setCampaign({ id: campaignDoc.id, ...campaignDoc.data() });
          return;
        }

        // If not found in Firestore, try the API
        const response = await fetch(`http://localhost:5001/api/campaigns/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch campaign details');
        }
        const data = await response.json();
        setCampaign(data);
      } catch (err) {
        console.error('Error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignDetails();
  }, [id]);

  const handleApply = async () => {
    try {
        if (!auth.currentUser) {
            alert('Please login to apply');
            navigate('/login');
            return;
        }

        let token;
        try {
            token = await auth.currentUser.getIdToken(false);
        } catch (tokenError) {
            console.error('Token error:', tokenError);
            return;
        }

        const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';
        
        const requestDetails = {
            url: `${API_URL}/api/campaigns/${id}/apply`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: {
                campaignId: id,
                userId: auth.currentUser.uid
            }
        };

        const response = await fetch(requestDetails.url, {
            method: 'POST',
            headers: requestDetails.headers,
            body: JSON.stringify(requestDetails.body)
        });

        const responseData = await response.json();
        console.log('Application Response:', responseData);

        if (response.status === 401) {
            alert('Please login to apply');
            navigate('/login');
            return;
        }

        if (!response.ok) {
            throw new Error(responseData.message || 'Failed to apply');
        }

        alert('Successfully applied! You can now start promoting this campaign.');
        // Redirect to creator dashboard with campaigns tab
        navigate('/creator/dashboard?tab=campaigns');
        
    } catch (err) {
        console.error('Application error:', err);
        alert(`Failed to apply: ${err.message}`);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!campaign) return <div>Campaign not found</div>;

  return (
    <div className="campaign-page-container">
      <div className="campaign-page-header">
        <div className="campaign-page-header-content">
          <h1>{campaign.campaignHeadline}</h1>
          <span className="campaign-page-brand-name">by {campaign.brand} • Powered by Hypeo</span>
        </div>
        <div className="campaign-page-header-actions">
          {auth.currentUser ? (
            <button className="campaign-page-apply-btn" onClick={handleApply}>
              Apply to Promote
            </button>
          ) : (
            <button 
              className="campaign-page-apply-btn" 
              onClick={() => {
                navigate('/login', { state: { returnTo: `/campaigns/${id}` } });
              }}
            >
              Login to Apply
            </button>
          )}
          <button className="campaign-page-back-btn" onClick={() => navigate('/campaigns')}>
            Back to Campaigns
          </button>
        </div>
      </div>

      <div className="campaign-page-content">
        <div className="campaign-page-grid">
          {/* Left Column */}
          <div className="campaign-page-column-left">
            {/* Basic Campaign Info */}
            <div className="campaign-page-section">
              <h2 className="campaign-page-section-title">Campaign Overview</h2>
              <div className="campaign-page-card">
                {campaign.campaignType && (
                  <p><strong>Campaign Type:</strong> {campaign.campaignType}</p>
                )}
                {campaign.productCategory && (
                  <p><strong>Product Category:</strong> {campaign.productCategory}</p>
                )}
                {campaign.startDate && campaign.endDate && (
                  <p><strong>Duration:</strong> {new Date(campaign.startDate).toLocaleDateString()} - {new Date(campaign.endDate).toLocaleDateString()}</p>
                )}
              </div>
            </div>

            {/* Product Details */}
            <div className="campaign-page-section">
              <h2 className="campaign-page-section-title">Product Information</h2>
              <div className="campaign-page-card">
                {campaign.productName && (
                  <p><strong>Product:</strong> {campaign.productName}</p>
                )}
                {campaign.offerName && (
                  <p><strong>Offer to Promote:</strong> {campaign.offerName}</p>
                )}
                {campaign.productDescription && (
                  <p><strong>Product Description:</strong> {campaign.productDescription}</p>
                )}
                {campaign.uniqueSellingPoints && (
                  <p><strong>Unique Selling Points:</strong> {campaign.uniqueSellingPoints}</p>
                )}
                {campaign.pricing?.retailPrice && (
                  <p><strong>Retail Price:</strong> {campaign.pricing.retailPrice}</p>
                )}
                {campaign.pricing?.specialOffers && (
                  <p><strong>Special Offers:</strong> {campaign.pricing.specialOffers}</p>
                )}
                {campaign.productUrl && (
                  <p><strong>Product URL:</strong> <a href={campaign.productUrl} target="_blank" rel="noopener noreferrer">{campaign.productUrl}</a></p>
                )}
              </div>
            </div>

            {/* Ideal Creators */}
            <div className="campaign-page-section">
              <h2 className="campaign-page-section-title">Ideal Creators</h2>
              <div className="campaign-page-card">
                <div className="ideal-creators-content">
                  <div className="ideal-creators-item">
                    <strong>Ideal Creators</strong>
                    <p>{campaign.creatorRequirements?.idealCreators}</p>
                  </div>
                  <div className="ideal-creators-item">
                    <strong>Recommended Platforms</strong>
                    <div className="platform-icons">
                      {campaign.creatorRequirements?.preferredPlatforms?.split(',').filter(Boolean).map((platform) => (
                        <div key={platform} className="platform-icon">
                          {platform === 'Instagram' && <i className="fab fa-instagram"></i>}
                          {platform === 'TikTok' && <i className="fab fa-tiktok"></i>}
                          {platform === 'YouTube' && <i className="fab fa-youtube"></i>}
                          {platform === 'Twitter' && <i className="fab fa-twitter"></i>}
                          {platform === 'Facebook' && <i className="fab fa-facebook"></i>}
                          {platform === 'LinkedIn' && <i className="fab fa-linkedin"></i>}
                          <span>{platform}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="campaign-page-column-right">
            {/* Commission Details */}
            <div className="campaign-page-section">
              <h2 className="campaign-page-section-title">Commission Details</h2>
              <div className="campaign-page-card">
                <div className="commission-card">
                  <div className="commission-header">Payout per sale</div>
                  <div className="commission-value">
                    {campaign.commissionType === 'Percentage' ? (
                      <span className="commission-amount">{campaign.payout}%</span>
                    ) : (
                      <>
                        <span className="commission-currency">$</span>
                        <span className="commission-amount">{campaign.payout}</span>
                      </>
                    )}
                  </div>
                  <div className="commission-type">
                    {campaign.commissionType} commission
                  </div>
                </div>
              </div>
            </div>

            {/* Target Audience */}
            <div className="campaign-page-section">
              <h2 className="campaign-page-section-title">Target Audience</h2>
              <div className="campaign-page-card">
                {campaign.targetAudience?.ageRange && (
                  <p><strong>Age Range:</strong> {campaign.targetAudience.ageRange}</p>
                )}
                {campaign.targetAudience?.demographics && (
                  <p><strong>Demographics:</strong> {campaign.targetAudience.demographics}</p>
                )}
                {campaign.targetAudience?.interests && (
                  <p><strong>Interests:</strong> {campaign.targetAudience.interests}</p>
                )}
              </div>
            </div>

            {/* Brand Guidelines */}
            {campaign.brandGuidelines && (
              <div className="campaign-page-section">
                <h2 className="campaign-page-section-title">Brand Guidelines</h2>
                <div className="campaign-page-card">
                  <p>{campaign.brandGuidelines}</p>
                </div>
              </div>
            )}

            {/* Brand Assets Section */}
            <div className="campaign-page-section">
              <h2 className="campaign-page-section-title">Brand Assets</h2>
              <div className="campaign-page-card">
                {campaign.brandImages?.length > 0 && (
                  <div className="assets-section">
                    <h3>Brand Images</h3>
                    <div className="assets-grid">
                      {campaign.brandImages.map((url, index) => (
                        <div key={index} className="asset-item">
                          <img src={url} alt={`Brand image ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {campaign.socialPostExamples?.length > 0 && (
                  <div className="assets-section">
                    <h3>Social Post Examples</h3>
                    <div className="assets-grid">
                      {campaign.socialPostExamples.map((url, index) => (
                        <div key={index} className="asset-item">
                          <img src={url} alt={`Social post example ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {(!campaign.brandImages?.length && !campaign.socialPostExamples?.length) && (
                  <p className="no-assets">No brand assets uploaded yet.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPage;

<style>
  {`
    .campaign-page-card p {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 12px;
    }
    .campaign-page-card strong {
      font-size: 1.1rem;
    }
    .campaign-page-section-title {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
    .ideal-creators-item strong {
      font-size: 1.2rem;
      display: block;
      margin-bottom: 8px;
    }
    .ideal-creators-item p {
      font-size: 1.1rem;
      line-height: 1.6;
    }
    .commission-header {
      font-size: 1.2rem;
    }
    .commission-value {
      font-size: 2rem;
    }
    .commission-type {
      font-size: 1.1rem;
    }
    .platform-icons .platform-icon span {
      font-size: 1rem;
    }
  `}
</style>
