import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, googleProvider } from '../../firebase';
import { 
    createUserWithEmailAndPassword, 
    signInWithPopup,
    sendEmailVerification,
    setPersistence,
    browserSessionPersistence,
    signOut 
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import './styles/register.css';
import './Login.css'; // Import the Login CSS for Google button styling

const CreatorRegister = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [verificationSent, setVerificationSent] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            // Set persistence to none before creating user
            await setPersistence(auth, browserSessionPersistence);
            
            // Create auth user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Immediately sign out
            await signOut(auth);

            // Send verification email
            await sendEmailVerification(user);
            setVerificationSent(true);

            // Create user document in Firestore
            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: email,
                name: name,
                role: 'creator',
                emailVerified: false,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });

            navigate('/verify-email', { 
                state: { 
                    email: email,
                    message: 'Please check your email to verify your account before logging in.' 
                } 
            });

        } catch (error) {
            console.error('Registration failed:', error);
            setError('Registration failed: ' + error.message);
        }
    };

    const generateUsername = (email) => {
        // Get the part before @ and remove any special characters
        const baseUsername = email.split('@')[0]
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase();
        
        // Add a random number if you want to ensure uniqueness
        const randomNum = Math.floor(Math.random() * 1000);
        return `${baseUsername}${randomNum}`;
    };

    const handleGoogleSignup = async () => {
        try {
            // Set persistence to none before Google sign in
            await setPersistence(auth, browserSessionPersistence);
            
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Immediately sign out
            await signOut(auth);

            // Check if user already exists
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role !== 'creator') {
                    setError('This account is already registered as a ' + userData.role);
                    return;
                }
            }

            // For new Google sign-ups
            if (!user.emailVerified) {
                await sendEmailVerification(user);
                navigate('/verify-email', {
                    state: {
                        email: user.email,
                        message: 'Please check your email to verify your account before logging in.'
                    }
                });
                return;
            }

            const username = generateUsername(user.email);
            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: user.email,
                name: user.displayName || '',
                username: username,
                role: 'creator',
                emailVerified: user.emailVerified,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });

            navigate('/login', {
                state: {
                    message: 'Registration successful! Please log in to continue.'
                }
            });

        } catch (error) {
            console.error('Google signup failed:', error);
            setError('Google signup failed: ' + error.message);
        }
    };

    return (
        <div className="form-page">
            <div className="form-container">
                <h2>Register as Creator</h2>
                {error && <div className="error-message">{error}</div>}
                {verificationSent && (
                    <div className="success-message">
                        Verification email sent! Please check your inbox.
                    </div>
                )}
                
                <form onSubmit={handleRegister}>
                    <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Register</button>
                </form>

                <div className="login-divider">
                    <span>or</span>
                </div>

                <button 
                    type="button" 
                    onClick={handleGoogleSignup}
                    className="google-signin-button"
                >
                    <img 
                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
                        alt="Google" 
                        className="google-icon"
                    />
                    Sign up with Google
                </button>
            </div>
        </div>
    );
};

export default CreatorRegister;
