import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Badge,
  Spinner,
  Divider,
  Avatar,
  Button,
  useColorModeValue,
  VStack,
  HStack,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Alert,
  AlertIcon,
  Code,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  IconButton,
  useToast,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
  Link,
  FormHelperText,
  Spacer,
  Select
} from '@chakra-ui/react';
import { db } from '../../firebase';
import { doc, getDoc, collection, query, where, getDocs, orderBy, updateDoc, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { format } from 'date-fns';
import { AddIcon, DeleteIcon, ExternalLinkIcon, AttachmentIcon, SettingsIcon, EditIcon, CloseIcon } from "@chakra-ui/icons";
import { debounce } from "lodash";
import { auth } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { FaSmile, FaPaperPlane } from 'react-icons/fa';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

const ProjectStatus = () => {
  const { orderId } = useParams();
  const { currentUser } = useAuth();
  const [projectData, setProjectData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [creatorOffer, setCreatorOffer] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const headerBg = useColorModeValue('gray.50', 'gray.700');
  const selectBg = useColorModeValue('white', 'gray.800');
  const optionBg = useColorModeValue('white', 'gray.800');
  const optionColor = useColorModeValue('black', 'white');
  const messageBg = useColorModeValue('gray.50', 'gray.800');
  const inputBg = useColorModeValue('gray.100', 'gray.800');
  const inputFocusBg = useColorModeValue('gray.200', 'gray.700');
  const messageAreaBg = useColorModeValue('white', 'gray.700');
  
  const [brandWebsite, setBrandWebsite] = useState("");
  const [brandBrief, setBrandBrief] = useState("");
  const [promoMessages, setPromoMessages] = useState([""]);
  const [links, setLinks] = useState([""]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [savedData, setSavedData] = useState({
    brandWebsite: "",
    brandBrief: "",
    promoMessages: [""],
    links: [""]
  });
  const toast = useToast();
  
  const [postLink, setPostLink] = useState("");
  const [creatorNotes, setCreatorNotes] = useState("");
  const [isSubmittingDeliverables, setIsSubmittingDeliverables] = useState(false);
  const [deliverableLinks, setDeliverableLinks] = useState([""]);
  
  const [isCreator, setIsCreator] = useState(false);
  const [brandUserData, setBrandUserData] = useState(null);
  
  const [newMessage, setNewMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const messagesContainerRef = useRef(null);
  
  const [isLoading, setIsLoading] = useState(true);
  
  const [participants, setParticipants] = useState([]);
  
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  
  const [isEditingDeliverables, setIsEditingDeliverables] = useState(false);
  
  const debouncedSave = useRef(
    debounce(async (data) => {
      if (!orderId) {
        toast({
          title: "Error",
          description: "Cannot update: Order ID not found",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      
      setIsSubmitting(true);
      try {
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, {
          brandWebsite: data.brandWebsite,
          brandBrief: data.brandBrief,
          promoMessages: data.promoMessages.filter(msg => msg.trim() !== ""),
          links: data.links.filter(link => link.trim() !== ""),
          updatedAt: serverTimestamp()
        });
        
        setSavedData(data);
        
        toast({
          title: "Saved",
          description: "Brand brief updated automatically",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to save changes",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, 1500)
  ).current;
  
  useEffect(() => {
    if (orderData?.id) {
      const currentData = {
        brandWebsite,
        brandBrief,
        promoMessages: promoMessages.filter(msg => msg.trim() !== ""),
        links: links.filter(link => link.trim() !== "")
      };
      
      if (JSON.stringify(currentData) !== JSON.stringify(savedData)) {
        debouncedSave(currentData);
      }
    }
  }, [brandWebsite, brandBrief, promoMessages, links, orderData?.id, savedData]);
  
  const fetchProjectData = async () => {
    try {
      if (!orderId) {
        setIsLoading(false);
        return;
      }
      
      const orderDoc = await getDoc(doc(db, 'orders', orderId));
      
      if (orderDoc.exists()) {
        const data = orderDoc.data();
        setOrderData(data);
        setProjectData(data);

        const participantsList = [
          data.userId,
          data.items?.[0]?.creatorId
        ].filter(Boolean);
        setParticipants(participantsList);

        const offerId = data.items?.[0]?.offerId;
        if (offerId) {
          const creatorOfferDoc = await getDoc(doc(db, 'creatorOffers', offerId));
          if (creatorOfferDoc.exists()) {
            setCreatorOffer(creatorOfferDoc.data());
          }
        }

        if (data.userId) {
          const brandUserDoc = await getDoc(doc(db, 'users', data.userId));
          if (brandUserDoc.exists()) {
            setBrandUserData(brandUserDoc.data());
          }
        }
      } else {
        setError('Order not found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchProjectData();
    } else {
      setIsLoading(false);
    }
  }, [orderId]);
  
  useEffect(() => {
    const checkIfCreator = async () => {
      const user = auth.currentUser;
      if (!user) return;
      
      if (orderData?.items && orderData.items.length > 0) {
        const creatorId = orderData.items[0].creatorId;
        setIsCreator(user.uid === creatorId);
      }
    };
    
    checkIfCreator();
  }, [orderData]);
  
  // Helper function to format dates
  const formatDate = (dateValue) => {
    if (!dateValue) return 'N/A';
    
    let date;
    if (typeof dateValue === 'object' && dateValue.seconds) {
      // Firestore Timestamp
      date = new Date(dateValue.seconds * 1000);
    } else if (typeof dateValue === 'object' && dateValue.toDate) {
      // Firestore Timestamp with toDate method
      date = dateValue.toDate();
    } else {
      // Regular date string or object
      date = new Date(dateValue);
    }
    
    try {
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (err) {
      return 'Invalid date';
    }
  };
  
  // Helper function to get status color
  const getStatusColor = (status) => {
    if (!status) return 'gray';
    
    const normalizedStatus = status.toLowerCase();
    
    if (normalizedStatus.includes('complete') || normalizedStatus === 'completed') {
      return 'green';
    } else if (normalizedStatus.includes('cancel') || normalizedStatus === 'cancelled') {
      return 'red';
    } else if (normalizedStatus.includes('progress') || normalizedStatus === 'in progress') {
      return 'blue';
    } else if (normalizedStatus.includes('review')) {
      return 'purple';
    } else {
      return 'yellow';
    }
  };
  
  // Helper function to format status text
  const formatStatus = (status) => {
    if (!status) return 'Pending';
    
    // If status is already in a nice format, return it
    if (status.includes(' ')) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    }
    
    // Convert camelCase or snake_case to Title Case
    return status
      .replace(/([A-Z])/g, ' $1') // Insert a space before all capital letters
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); // Join back together
  };
  
  const handleAddPromoMessage = () => {
    setPromoMessages([...promoMessages, ""]);
  };

  const handleRemovePromoMessage = (index) => {
    const newMessages = [...promoMessages];
    newMessages.splice(index, 1);
    setPromoMessages(newMessages);
  };

  const handlePromoMessageChange = (index, value) => {
    const newMessages = [...promoMessages];
    newMessages[index] = value;
    setPromoMessages(newMessages);
  };

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const handleRemoveLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const handleLinkChange = (index, value) => {
    const newLinks = [...links];
    newLinks[index] = value;
    setLinks(newLinks);
  };
  
  // Update the manual save function
  const manualSave = async () => {
    if (!orderId) {
        toast({
            title: "Error",
            description: "Cannot update: Order ID not found",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
        return;
    }

    setIsSubmitting(true);
    try {
        const currentData = {
            brandWebsite,
            brandBrief,
            promoMessages: promoMessages.filter(msg => msg.trim() !== ""),
            links: links.filter(link => link.trim() !== ""),
        };
        
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, {
            ...currentData,
            updatedAt: serverTimestamp()
        });
        
        setSavedData(currentData);
        
        toast({
            title: "Success",
            description: "Brand brief saved successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    } catch (error) {
        toast({
            title: "Error",
            description: "Failed to save brand brief",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsSubmitting(false);
    }
  };
  
  // Add this useEffect to initialize savedData when orderData is loaded
  useEffect(() => {
    if (orderData) {
        setSavedData({
            brandWebsite: orderData.brandWebsite || "",
            brandBrief: orderData.brandBrief || "",
            promoMessages: orderData.promoMessages || [""],
            links: orderData.links || [""]
        });
        setBrandWebsite(orderData.brandWebsite || "");
        setBrandBrief(orderData.brandBrief || "");
        setPromoMessages(orderData.promoMessages || [""]);
        setLinks(orderData.links || [""]);
    }
  }, [orderData]);
  
  const handleAddDeliverableLink = () => {
    setDeliverableLinks([...deliverableLinks, ""]);
  };

  const handleRemoveDeliverableLink = (index) => {
    const newLinks = [...deliverableLinks];
    newLinks.splice(index, 1);
    setDeliverableLinks(newLinks);
  };

  const handleDeliverableLinkChange = (index, value) => {
    const newLinks = [...deliverableLinks];
    newLinks[index] = value;
    setDeliverableLinks(newLinks);
  };
  
  const submitDeliverables = async () => {
    if (!orderId) {
      toast({
        title: "Error",
        description: "Cannot update: Order ID not found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmittingDeliverables(true);
    try {
      const orderRef = doc(db, "orders", orderId);
      await updateDoc(orderRef, {
        postLink,
        creatorNotes,
        creatorDeliverables: {
          links: deliverableLinks.filter(link => link.trim() !== "")
        },
        submittedAt: isEditingDeliverables ? orderData.submittedAt : serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      
      toast({
        title: "Success",
        description: isEditingDeliverables ? "Deliverables updated successfully" : "Deliverables submitted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      if (isEditingDeliverables) {
        setIsEditingDeliverables(false);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit deliverables",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmittingDeliverables(false);
    }
  };
  
  // Add this helper function to get user data
  const getUserData = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
            return userDoc.data();
        }
        return null;
    } catch (error) {
        return null;
    }
  };

  // Update messages listener
  useEffect(() => {
    if (!orderId || !currentUser?.uid || !orderData) {
      return;
    }

    const messagesRef = collection(db, 'messages');
    const messagesQuery = query(
      messagesRef,
      where('orderId', '==', orderId),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(messagesQuery, {
      next: async (snapshot) => {
        const messagesWithNames = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const messageData = doc.data();
            
            // Determine if sender is brand or creator
            const senderIsBrand = messageData.senderId === orderData.userId;
            
            // If sender is brand, use SOFI as brandName
            if (senderIsBrand) {
              return {
                id: doc.id,
                ...messageData,
                senderName: "SOFI", // Hardcoded brand name
                senderType: 'brand'
              };
            }
            
            // If sender is creator, use creatorName from orderData
            const senderIsCreator = messageData.senderId === orderData.items?.[0]?.creatorId;
            if (senderIsCreator) {
              return {
                id: doc.id,
                ...messageData,
                senderName: orderData.items[0].creatorName || 'Creator',
                senderType: 'creator'
              };
            }

            // Fallback to user data if needed
            const userData = await getUserData(messageData.senderId);
            return {
              id: doc.id,
              ...messageData,
              senderName: userData?.name || userData?.firstName || 'Unknown User',
              senderType: 'unknown'
            };
          })
        );

        setMessages(messagesWithNames);
      },
      error: (error) => {
        console.error('Error in messages listener:', error);
      }
    });

    return () => unsubscribe();
  }, [orderId, currentUser?.uid, orderData]);
  
  // Update the scroll behavior useEffect
  useEffect(() => {
    if (messagesContainerRef.current) {
      const scrollContainer = messagesContainerRef.current.parentElement;
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  }, [messages]); // Scroll when messages update
  
  // Update the handleSendMessage function
  const handleSendMessage = async (e) => {
    if (e) e.preventDefault();
    
    if (!newMessage.trim() || !orderId || !currentUser?.uid) {
      return;
    }

    setIsSendingMessage(true);
    try {
      // First verify the order exists and get fresh data
      const orderRef = doc(db, 'orders', orderId);
      const orderSnap = await getDoc(orderRef);

      if (!orderSnap.exists()) {
        throw new Error('Order not found');
      }

      const orderData = orderSnap.data();
      
      // Verify user is either brand or creator
      const isBrand = currentUser.uid === orderData.userId;
      const isCreator = currentUser.uid === orderData.items?.[0]?.creatorId;

      if (!isBrand && !isCreator) {
        throw new Error('Unauthorized: Only brand or creator can send messages');
      }

      // Create message with exact structure needed for security rules
      const messageData = {
        text: newMessage.trim(),
        timestamp: serverTimestamp(),
        senderId: currentUser.uid,
        orderId: orderSnap.id
      };

      // Try to create the message
      const messagesRef = collection(db, 'messages');
      await addDoc(messagesRef, messageData);
      
      setNewMessage('');
    } catch (error) {
      toast({
        title: "Error sending message",
        description: `Failed to send message: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSendingMessage(false);
    }
  };
  
  // Add this near your other style constants
  const statusOptions = [
    { value: 'pending', label: 'Pending', color: 'yellow' },
    { value: 'in_progress', label: 'In Progress', color: 'blue' },
    { value: 'completed', label: 'Completed', color: 'green' },
    { value: 'declined', label: 'Declined', color: 'red' }
  ];
  
  // Update the handleStatusUpdate function
  const handleStatusUpdate = async (newStatus) => {
    if (!orderId) {
      toast({
        title: "Error",
        description: "Order ID not found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsUpdatingStatus(true);
    try {
      const orderRef = doc(db, "orders", orderId);
      await updateDoc(orderRef, {
        status: newStatus,
        updatedAt: serverTimestamp()
      });

      toast({
        title: "Success",
        description: `Status updated to ${newStatus}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update status",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUpdatingStatus(false);
    }
  };
  
  // Add these useEffects to initialize the form values when editing
  useEffect(() => {
    if (isEditingDeliverables && orderData) {
      setPostLink(orderData.postLink || '');
      setCreatorNotes(orderData.creatorNotes || '');
      setDeliverableLinks(
        orderData.creatorDeliverables?.links?.length > 0 
          ? orderData.creatorDeliverables.links 
          : ['']
      );
    }
  }, [isEditingDeliverables, orderData]);
  
  // Add this useEffect for real-time order updates (if not already present)
  useEffect(() => {
    if (!orderId) return;

    const orderRef = doc(db, 'orders', orderId);
    const unsubscribe = onSnapshot(orderRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setOrderData(data);
        setProjectData(data);
      }
    });

    return () => unsubscribe();
  }, [orderId]);
  
  if (isLoading) {
    return (
      <Container maxW="container.md" py={8}>
        <Flex justify="center" align="center" height="300px" direction="column">
          <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" mb={4} />
          <Text>Loading project data...</Text>
        </Flex>
      </Container>
    );
  }
  
  // Show debug information if there's an error or no project data
  if (error || !projectData) {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="error" borderRadius="md" mb={4}>
          <AlertIcon />
          {error || 'Project not found'}
        </Alert>
        
        <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md" borderWidth="1px" borderColor={borderColor} mb={4}>
          <Heading size="md" mb={4}>Debug Information</Heading>
          <Text fontWeight="bold" mb={2}>Offer ID: {orderId || 'None'}</Text>
          
          <Heading size="sm" mt={4} mb={2}>Fetch Attempts:</Heading>
          {[]}
          
          <Heading size="sm" mt={4} mb={2}>Errors:</Heading>
          {[]}
        </Box>
        
        <Flex justify="center" mt={6}>
          <Button colorScheme="blue" onClick={() => window.history.back()}>
            Back to Orders
          </Button>
        </Flex>
      </Container>
    );
  }
  
  return (
    <Container maxW="container.md" py={{ base: 4, md: 8 }} px={{ base: 2, md: 4 }}>
      <Box 
        bg={bgColor} 
        p={{ base: 3, md: 6 }} 
        borderRadius="lg" 
        boxShadow="md"
        borderWidth="1px"
        borderColor={borderColor}
      >
        {/* Header Section */}
        <Flex 
          direction={{ base: "column", md: "row" }}
          justify="space-between" 
          align={{ base: "start", md: "center" }}
          mb={6}
          bg={headerBg}
          p={{ base: 3, md: 4 }}
          borderRadius="lg"
          gap={{ base: 3, md: 0 }}
        >
          <Text 
            fontSize={{ base: "xl", md: "2xl" }} 
            fontWeight="bold"
            mb={{ base: 2, md: 0 }}
          >
            {creatorOffer?.type || 'Untitled Project'}
          </Text>

          {isCreator ? (
            <Box width={{ base: "full", md: "auto" }}>
              <FormControl>
                <Select
                  size={{ base: "md", md: "lg" }}
                  width={{ base: "full", md: "200px" }}
                  value={orderData?.status || 'pending'}
                  onChange={(e) => handleStatusUpdate(e.target.value)}
                  isDisabled={isUpdatingStatus}
                  bg={selectBg}
                  borderColor={getStatusColor(orderData?.status)}
                  _hover={{
                    borderColor: getStatusColor(orderData?.status),
                    opacity: 0.8
                  }}
                  icon={isUpdatingStatus ? <Spinner size="sm" /> : undefined}
                  cursor="pointer"
                >
                  {statusOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Badge 
              colorScheme={getStatusColor(orderData?.status)}
              fontSize={{ base: "sm", md: "md" }}
              px={3}
              py={1}
              width={{ base: "full", md: "auto" }}
              textAlign="center"
            >
              {formatStatus(orderData?.status)}
            </Badge>
          )}
        </Flex>

        {/* Status Alert */}
        {orderData?.status && (
          <Alert 
            status={
              orderData.status === 'completed' ? 'success' : 
              orderData.status === 'declined' ? 'error' :
              orderData.status === 'in_progress' ? 'info' : 
              'warning'
            } 
            mb={4} 
            borderRadius="lg"
            flexDirection={{ base: "column", md: "row" }}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <AlertIcon />
            <Box>
              <AlertTitle>
                {orderData.status === 'completed' ? 'Project Completed' :
                 orderData.status === 'declined' ? 'Project Declined' :
                 orderData.status === 'in_progress' ? 'Project In Progress' :
                 'Project Pending'}
              </AlertTitle>
              <AlertDescription>
                Status last updated on {formatDate(orderData.updatedAt)}
              </AlertDescription>
            </Box>
          </Alert>
        )}

        {/* Creator Info */}
        <Flex 
          align="center" 
          mb={4} 
          direction={{ base: "column", md: "row" }}
          gap={{ base: 3, md: 4 }}
        >
          <Avatar 
            size={{ base: "lg", md: "xl" }}
            name={orderData?.items?.[0]?.creatorName}
            src={orderData?.items?.[0]?.creatorImage}
            borderWidth="2px"
            borderColor="blue.500"
          />
          <Text 
            fontWeight="bold"
            fontSize={{ base: "xl", md: "2xl" }}
            textAlign={{ base: "center", md: "left" }}
          >
            {orderData?.items?.[0]?.creatorName}
          </Text>
        </Flex>

        {/* Stats */}
        <SimpleGrid 
          columns={{ base: 1, sm: 2, md: 3 }} 
          spacing={{ base: 3, md: 4 }} 
          mb={6}
        >
          <Stat>
            <StatLabel>Price</StatLabel>
            <StatNumber fontSize={{ base: "xl", md: "2xl" }}>
              ${orderData?.items?.[0]?.price || creatorOffer?.price || 0}
            </StatNumber>
          </Stat>
          
          <Stat>
            <StatLabel>Expected Views</StatLabel>
            <StatNumber fontSize={{ base: "xl", md: "2xl" }}>
              {orderData?.items?.[0]?.expectedViews || '10K'}
            </StatNumber>
          </Stat>
          
          <Stat>
            <StatLabel>Created</StatLabel>
            <StatNumber fontSize={{ base: "xl", md: "2xl" }}>
              {formatDate(orderData?.createdAt)}
            </StatNumber>
            <StatHelpText>
              {orderData?.updatedAt ? `Updated: ${formatDate(orderData.updatedAt)}` : ''}
            </StatHelpText>
          </Stat>
        </SimpleGrid>

        <Divider my={4} />

        {/* Tabs Section */}
        <Tabs 
          variant="enclosed" 
          colorScheme="blue" 
          size={{ base: "sm", md: "md" }}
          defaultIndex={0}
          sx={{
            '.chakra-tabs__tab': {
              _selected: {
                color: 'gray.800',
                bg: 'white',
                borderColor: 'gray.200',
                borderBottomColor: 'white',
                fontWeight: 'medium',
                opacity: 1,
              },
              _active: {
                bg: 'gray.50',
              },
              _hover: {
                bg: 'gray.50',
              },
              _disabled: {
                opacity: 1,
                cursor: 'pointer',
              },
              bg: 'gray.100',
              color: 'gray.700',
              py: { base: 3, md: 4 },
              px: { base: 4, md: 6 },
              fontSize: { base: 'md', md: 'md' },
              fontWeight: 'normal',
              transition: 'all 0.2s',
              borderRadius: '0',
              borderWidth: '1px',
              marginRight: '-1px',
              position: 'relative',
              opacity: 1,
              cursor: 'pointer',
            },
            '.chakra-tabs__tablist': {
              borderBottom: '1px solid',
              borderColor: 'gray.200',
              mb: '0',
            },
            '.chakra-tabs__tab-panel': {
              pt: 4,
            }
          }}
        >
          <TabList>
            <Tab isDisabled={false}>Brand Brief</Tab>
            <Tab isDisabled={false}>Creator Deliverables</Tab>
            <Tab isDisabled={false}>Messages</Tab>
          </TabList>

          <TabPanels>
            {/* Keep existing TabPanel content for Brand Brief */}
            <TabPanel p={3}>
                <VStack spacing={3} align="stretch">
                    {isCreator ? (
                        // Read-only view for creators
                        <>
                            <Box borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
                                <VStack spacing={4} align="stretch">
                                    {/* Brand Name */}
                                    <Box>
                                        <Text fontSize="sm" fontWeight="medium" color="gray.500">Brand Name</Text>
                                        <Text fontSize="md">{brandUserData?.brandName || 'Not provided'}</Text>
                                    </Box>

                                    {/* Brand Website */}
                                    <Box>
                                        <Text fontSize="sm" fontWeight="medium" color="gray.500">Brand Website</Text>
                                        {savedData.brandWebsite ? (
                                            <Link href={savedData.brandWebsite} color="blue.500" isExternal>
                                                {savedData.brandWebsite}
                                                <ExternalLinkIcon mx="2px" />
                                            </Link>
                                        ) : (
                                            <Text color="gray.600">Not provided</Text>
                                        )}
                                    </Box>

                                    {/* Brand Industry */}
                                    <Box>
                                        <Text fontSize="sm" fontWeight="medium" color="gray.500">Industry</Text>
                                        <Text>{savedData.industry || orderData?.industry || 'Not specified'}</Text>
                                    </Box>

                                    {/* Brand Description */}
                                    <Box>
                                        <Text fontSize="sm" fontWeight="medium" color="gray.500">Brand Description</Text>
                                        <Text whiteSpace="pre-wrap" color="gray.700">
                                            {savedData.brandBrief || 'No description provided'}
                                        </Text>
                                    </Box>

                                    {/* Target Audience */}
                                    <Box>
                                        <Text fontSize="sm" fontWeight="medium" color="gray.500">Target Audience</Text>
                                        <Text>{savedData.targetAudience || orderData?.targetAudience || 'Not specified'}</Text>
                                    </Box>

                                    {/* Promotional Messages */}
                                    {savedData.promoMessages?.length > 0 && savedData.promoMessages[0] !== "" && (
                                        <Box>
                                            <Text fontSize="sm" fontWeight="medium" color="gray.500">Key Messages</Text>
                                            <UnorderedList spacing={1}>
                                                {savedData.promoMessages.map((msg, index) => (
                                                    <ListItem key={index} color="gray.700">{msg}</ListItem>
                                                ))}
                                            </UnorderedList>
                                        </Box>
                                    )}

                                    {/* Important Links */}
                                    {savedData.links?.length > 0 && savedData.links[0] !== "" && (
                                        <Box>
                                            <Text fontSize="sm" fontWeight="medium" color="gray.500">Important Links</Text>
                                            <VStack align="stretch" spacing={1}>
                                                {savedData.links.map((link, index) => (
                                                    <Link key={index} href={link} color="blue.500" isExternal>
                                                        {link}
                                                        <ExternalLinkIcon mx="2px" />
                                                    </Link>
                                                ))}
                                            </VStack>
                                        </Box>
                                    )}

                                    {/* Campaign Goals */}
                                    {savedData.campaignGoals && (
                                        <Box>
                                            <Text fontSize="sm" fontWeight="medium" color="gray.500">Campaign Goals</Text>
                                            <Text color="gray.700">{savedData.campaignGoals}</Text>
                                        </Box>
                                    )}
                                </VStack>
                            </Box>

                            {/* Special Instructions or Notes */}
                            {savedData.specialInstructions && (
                                <Box mt={4}>
                                    <Text fontSize="sm" fontWeight="medium" color="gray.500">Special Instructions</Text>
                                    <Text whiteSpace="pre-wrap" color="gray.700">{savedData.specialInstructions}</Text>
                                </Box>
                            )}
                        </>
                    ) : (
                        // Editable form for brands (existing code)
                        <>
                            <FormControl>
                                <FormLabel fontSize="sm">Brand Website</FormLabel>
                                <Input 
                                    size="sm"
                                    value={brandWebsite} 
                                    onChange={(e) => setBrandWebsite(e.target.value)}
                                    placeholder="https://yourbrand.com"
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel fontSize="sm">Brand Description</FormLabel>
                                <Textarea
                                    size="sm"
                                    value={brandBrief}
                                    onChange={(e) => setBrandBrief(e.target.value)}
                                    placeholder="Describe your brand"
                                    rows={3}
                                />
                            </FormControl>

                            {/* Promo Messages */}
                            <FormControl>
                                <FormLabel fontSize="sm">Promotional Messages</FormLabel>
                                {promoMessages.map((message, index) => (
                                    <HStack key={index} mt={2}>
                                        <Input
                                            size="sm"
                                            value={message}
                                            onChange={(e) => handlePromoMessageChange(index, e.target.value)}
                                            placeholder="Enter promotional message or tagline"
                                        />
                                        <IconButton
                                            size="sm"
                                            icon={<DeleteIcon />}
                                            onClick={() => handleRemovePromoMessage(index)}
                                            aria-label="Remove message"
                                            isDisabled={promoMessages.length === 1}
                                        />
                                    </HStack>
                                ))}
                                <Button 
                                    leftIcon={<AddIcon />} 
                                    onClick={handleAddPromoMessage} 
                                    size="sm" 
                                    mt={2}
                                >
                                    Add Message
                                </Button>
                            </FormControl>

                            {/* Important Links */}
                            <FormControl>
                                <FormLabel fontSize="sm">Important Links</FormLabel>
                                {links.map((link, index) => (
                                    <HStack key={index} mt={2}>
                                        <Input
                                            size="sm"
                                            value={link}
                                            onChange={(e) => handleLinkChange(index, e.target.value)}
                                            placeholder="https://example.com/product"
                                        />
                                        <IconButton
                                            size="sm"
                                            icon={<DeleteIcon />}
                                            onClick={() => handleRemoveLink(index)}
                                            aria-label="Remove link"
                                            isDisabled={links.length === 1}
                                        />
                                    </HStack>
                                ))}
                                <Button 
                                    leftIcon={<AddIcon />} 
                                    onClick={handleAddLink} 
                                    size="sm" 
                                    mt={2}
                                >
                                    Add Link
                                </Button>
                            </FormControl>

                            <HStack mt={4} justify="space-between" align="center">
                                {isSubmitting ? (
                                    <Text color="blue.500" fontSize="sm">
                                        Saving changes...
                                    </Text>
                                ) : (
                                    <Text color="green.500" fontSize="sm">
                                        Changes auto-save as you type
                                    </Text>
                                )}
                                
                                <Button 
                                    colorScheme="blue" 
                                    onClick={manualSave} 
                                    isLoading={isSubmitting}
                                    size="sm"
                                >
                                    Save Brand Brief
                                </Button>
                            </HStack>
                        </>
                    )}
                </VStack>
            </TabPanel>
            
            {/* Keep existing TabPanel content for Creator Deliverables */}
            <TabPanel>
              <Box>
                <Flex justify="space-between" align="center" mb={4}>
                  <Heading size="sm">Creator Deliverables</Heading>
                  {isCreator && orderData?.submittedAt && (
                    <Button
                      size="sm"
                      leftIcon={isEditingDeliverables ? <CloseIcon /> : <EditIcon />}
                      onClick={() => setIsEditingDeliverables(!isEditingDeliverables)}
                      colorScheme={isEditingDeliverables ? "red" : "blue"}
                    >
                      {isEditingDeliverables ? "Cancel Edit" : "Edit Deliverables"}
                    </Button>
                  )}
                </Flex>
                
                {orderData?.submittedAt && !isEditingDeliverables ? (
                  <>
                    <Alert status="success" mb={4} borderRadius="md">
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Deliverables Submitted</AlertTitle>
                        <AlertDescription>
                          Submitted on {formatDate(orderData.submittedAt)}
                        </AlertDescription>
                      </Box>
                    </Alert>
                    
                    {orderData.postLink && (
                      <Box mb={4}>
                        <Heading size="xs" mb={1}>Main Post Link</Heading>
                        <Text as="a" href={orderData.postLink} color="blue.500" textDecoration="underline" target="_blank" rel="noopener noreferrer">
                          {orderData.postLink}
                        </Text>
                      </Box>
                    )}
                    
                    {orderData.creatorDeliverables?.links && orderData.creatorDeliverables.links.length > 0 && (
                      <Box mb={4}>
                        <Heading size="xs" mb={1}>Additional Links</Heading>
                        <UnorderedList>
                          {orderData.creatorDeliverables.links.map((link, index) => (
                            <ListItem key={index}>
                              <Link href={link} color="blue.500" isExternal>
                                {link}
                              </Link>
                            </ListItem>
                          ))}
                        </UnorderedList>
                      </Box>
                    )}
                    
                    {orderData.creatorNotes && (
                      <Box mb={4}>
                        <Heading size="xs" mb={1}>Creator Notes</Heading>
                        <Text whiteSpace="pre-wrap">{orderData.creatorNotes}</Text>
                      </Box>
                    )}
                  </>
                ) : isCreator && (
                  <VStack spacing={4} align="stretch">
                    <FormControl isRequired>
                      <FormLabel>Main Post Link</FormLabel>
                      <Input 
                        value={postLink} 
                        onChange={(e) => setPostLink(e.target.value)}
                        placeholder="https://instagram.com/p/your-post"
                      />
                      <FormHelperText>Link to your main promotional post</FormHelperText>
                    </FormControl>
                    
                    <FormControl>
                      <FormLabel>Additional Content Links</FormLabel>
                      {deliverableLinks.map((link, index) => (
                        <HStack key={index} mt={2}>
                          <Input
                            value={link}
                            onChange={(e) => handleDeliverableLinkChange(index, e.target.value)}
                            placeholder="https://example.com/additional-content"
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => handleRemoveDeliverableLink(index)}
                            aria-label="Remove link"
                            isDisabled={deliverableLinks.length === 1}
                          />
                        </HStack>
                      ))}
                      <Button 
                        leftIcon={<AddIcon />} 
                        onClick={handleAddDeliverableLink} 
                        size="sm" 
                        mt={2}
                      >
                        Add Link
                      </Button>
                      <FormHelperText>Add links to any additional content you created</FormHelperText>
                    </FormControl>
                    
                    <FormControl>
                      <FormLabel>Creator Notes</FormLabel>
                      <Textarea
                        value={creatorNotes}
                        onChange={(e) => setCreatorNotes(e.target.value)}
                        placeholder="Add any notes about the deliverables or instructions for the client"
                        rows={4}
                      />
                    </FormControl>
                    
                    <Button 
                      colorScheme="green" 
                      onClick={submitDeliverables} 
                      isLoading={isSubmittingDeliverables}
                      width="fit-content"
                      mt={2}
                    >
                      {isEditingDeliverables ? "Update Deliverables" : "Submit Deliverables"}
                    </Button>
                  </VStack>
                )}
              </Box>
            </TabPanel>

            {/* Messages Panel */}
            <TabPanel p={0}>
              <Box 
                height={{ base: "calc(100vh - 300px)", md: "600px" }} 
                display="flex" 
                flexDirection="column"
                position="relative"
                sx={{
                  touchAction: 'manipulation',
                  WebkitTapHighlightColor: 'transparent',
                  overflow: 'hidden',
                }}
              >
                {/* Messages Container */}
                <Box 
                  flex="1"
                  overflowY="scroll"
                  px={{ base: 3, md: 4 }}
                  pt={{ base: 3, md: 4 }}
                  pb={32}
                  mb={20}
                  id="messages-container"
                  sx={{
                    WebkitOverflowScrolling: 'touch',
                    scrollbarWidth: 'thin',
                    scrollBehavior: 'smooth',
                    touchAction: 'pan-y',
                    userSelect: 'text',
                    WebkitUserSelect: 'text',
                    '-webkit-overflow-scrolling': 'touch',
                    willChange: 'scroll-position',
                    pointerEvents: 'auto',
                    cursor: 'auto',
                  }}
                >
                  <VStack 
                    align="stretch" 
                    spacing={4} 
                    ref={messagesContainerRef}
                  >
                    {messages.map((message) => (
                      <Box 
                        key={message.id}
                        bg="gray.50"
                        p={3}
                        borderRadius="lg"
                        maxW="80%"
                        alignSelf="flex-start"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Text 
                          fontSize="sm" 
                          fontWeight="medium"
                          color="gray.700" 
                          mb={1}
                        >
                          {message.senderName}
                        </Text>
                        <Text color="gray.800">{message.text}</Text>
                        <Text 
                          fontSize="xs" 
                          color="gray.500" 
                          mt={1}
                        >
                          {message.timestamp ? new Date(message.timestamp.seconds * 1000).toLocaleTimeString([], { 
                            hour: '2-digit', 
                            minute: '2-digit' 
                          }) : ''}
                        </Text>
                      </Box>
                    ))}
                  </VStack>
                </Box>

                {/* Message Input */}
                <Box 
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  bg={bgColor}
                  borderTopWidth="1px"
                  p={{ base: 3, md: 4 }}
                  zIndex={2}
                >
                  <form onSubmit={handleSendMessage}>
                    <Box
                      borderWidth="1px"
                      borderColor="gray.200"
                      borderRadius="xl"
                      p={3}
                      bg="white"
                    >
                      <Input
                        placeholder="Send a message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        isDisabled={isSendingMessage}
                        border="none"
                        _focus={{ boxShadow: 'none' }}
                        fontSize={{ base: "sm", md: "md" }}
                        height={{ base: "36px", md: "40px" }}
                        color="gray.600"
                        onClick={(e) => e.stopPropagation()}
                      />
                      <HStack spacing={3} mt={2}>
                        <Text fontSize="sm" color="gray.500" cursor="pointer">T</Text>
                        <Icon as={AttachmentIcon} boxSize={3} color="gray.500" cursor="pointer" />
                        <Icon as={FaSmile} boxSize={3} color="gray.500" cursor="pointer" />
                        <Spacer />
                        <Icon 
                          as={FaPaperPlane} 
                          boxSize={3} 
                          color="blue.500"
                          cursor="pointer"
                          onClick={handleSendMessage}
                        />
                      </HStack>
                    </Box>
                  </form>
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Flex justify="center" mt={{ base: 4, md: 6 }}>
        <Button 
          colorScheme="blue" 
          onClick={() => window.history.back()}
          width={{ base: "full", md: "auto" }}
        >
          Back to Orders
        </Button>
      </Flex>
    </Container>
  );
};

export default ProjectStatus;