import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Desktop Footer */}
                <div className="footer-sections">
                    <div className="footer-section">
                        <h3>Platform</h3>
                        <ul className="footer-links">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/creators">Explore</Link></li>
                        </ul>
                    </div>

                    <div className="footer-section">
                        <h3>Discover</h3>
                        <ul className="footer-links">
                            <li><Link to="/creators">Creators</Link></li>
                            <li><Link to="/campaigns">Campaigns</Link></li>
                        </ul>
                    </div>

                    <div className="footer-section">
                        <h3>For Brands & Creators</h3>
                        <ul className="footer-links">
                            <li><Link to="/signup">Sign Up</Link></li>
                            <li><Link to="/login">Login</Link></li>
                        </ul>
                    </div>

                    <div className="footer-section">
                        <h3>Resources</h3>
                        <ul className="footer-links">
                            <li><Link to="/terms">Terms of Service</Link></li>
                            <li><Link to="/privacy">Privacy Policy</Link></li>
                        </ul>
                    </div>

                    <div className="footer-section">
                        <h3>Support</h3>
                        <div className="contact-info">
                            <p>Support Hours: Mon-Fri 9am-5pm EST</p>
                            <p>Email: support@hypedigital.io</p>
                            <div className="social-links">
                                <a href="https://twitter.com/hypedigital" target="_blank" rel="noopener noreferrer">Twitter</a>
                                <a href="https://linkedin.com/company/hypedigital" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile Footer */}
                <div className="mobile-footer">
                    <div className="social-links">
                        <a href="mailto:support@hypedigital.io">Support</a>
                        <a href="https://twitter.com/hypedigital" target="_blank" rel="noopener noreferrer">Twitter</a>
                        <a href="https://linkedin.com/company/hypedigital" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="trust-badges">
                        <span>🔒 Secure Payments</span>
                        <span>✓ Verified Partners</span>
                        <span>💯 Quality Guarantee</span>
                    </div>
                    <p className="copyright">© {currentYear} Hype Digital. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 