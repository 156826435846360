import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, googleProvider } from '../../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            
            // Update Firestore if email is verified
            if (user.emailVerified) {
                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, {
                    emailVerified: true,
                    updatedAt: new Date().toISOString()
                });
            }
            
            // Get user role from Firestore
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                // Navigate based on role
                if (userData.role === 'creator') {
                    navigate('/creator/profile');
                } else if (userData.role === 'brand') {
                    navigate('/brand/profile');
                } else {
                    navigate('/campaigns');
                }
            } else {
                setError('User profile not found');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed: ' + error.message);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            
            // Update Firestore if email is verified
            if (user.emailVerified) {
                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, {
                    emailVerified: true,
                    updatedAt: new Date().toISOString()
                });
            }
            
            // Get user role from Firestore
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                console.log('User data:', userData);
                
                // Navigate based on role
                if (userData.role === 'creator') {
                    navigate('/creator/profile');
                } else if (userData.role === 'brand') {
                    navigate('/brand/profile');
                } else {
                    navigate('/campaigns');
                }
            } else {
                setError('User profile not found. Please register first.');
                // Optionally sign out the user since they don't have a profile
                await auth.signOut();
            }
        } catch (error) {
            console.error('Google login failed:', error);
            setError('Google login failed: ' + error.message);
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
                <h2 className="login-title">Welcome Back</h2>
                
                {error && <div className="login-error">{error}</div>}
                
                <input
                    type="email"
                    className="login-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                
                <input
                    type="password"
                    className="login-input"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                
                <button type="submit" className="form-submit-button">
                    Log In
                </button>
                
                <div className="login-divider">
                    <span>or</span>
                </div>
                
                <button 
                    type="button" 
                    onClick={handleGoogleLogin}
                    className="google-signin-button"
                >
                    <img 
                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
                        alt="Google" 
                        className="google-icon"
                    />
                    Sign in with Google
                </button>
                
                <div className="login-links">
                    <a href="/forgot-password">Forgot Password?</a>
                    <a href="/signup">Create Account</a>
                </div>
            </form>
        </div>
    );
};

export default Login;
