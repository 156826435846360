import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/register.css';

const RegistrationSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/login');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="form-page">
      <div className="form-container success-container">
        <div className="success-content">
          <h2>Registration Successful! 🎉</h2>
          <p>Your brand account has been created successfully.</p>
          <p className="redirect-text">
            Redirecting to login in {countdown} seconds...
          </p>
          <button 
            onClick={() => navigate('/login')} 
            className="login-button"
          >
            Login Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccess; 