import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './TrackingStats.css';

const TrackingStats = ({ userId, userType, campaignId }) => {
    const [stats, setStats] = useState({
        clicks: 0,
        conversions: 0,
        salesRevenue: 0,
        commission: 0,
        conversionRate: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoading(true);

                // DEBUG: Check all clicks first
                const allClicks = await getDocs(collection(db, 'clicks'));
                console.log('ALL clicks in database:', allClicks.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                })));

                console.log('Attempting to query with:', {
                    username: userId,
                    cid: campaignId,
                    userType
                });

                // Get clicks using cid field
                const clicksQuery = query(
                    collection(db, 'clicks'),
                    where('username', '==', userId)
                    // Removed campaignId filter temporarily to see if we get any results
                );
                
                const clicksSnapshot = await getDocs(clicksQuery);
                console.log('Clicks found with just username:', {
                    total: clicksSnapshot.size,
                    clicks: clicksSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                });

                // Get conversions
                const conversionsQuery = query(
                    collection(db, 'conversions'),
                    where('username', '==', userId),
                    where('campaignId', '==', campaignId)
                );
                
                const conversionsSnapshot = await getDocs(conversionsQuery);
                
                let totalClicks = clicksSnapshot.size;
                let totalConversions = 0;
                let totalSalesRevenue = 0;

                // Process conversions
                conversionsSnapshot.forEach(doc => {
                    const conversion = doc.data();
                    if (conversion.amount) {
                        totalConversions++;
                        totalSalesRevenue += parseFloat(conversion.amount);
                    }
                });

                const conversionRate = totalClicks > 0 
                    ? ((totalConversions / totalClicks) * 100).toFixed(2)
                    : 0;

                setStats({
                    clicks: totalClicks,
                    conversions: totalConversions,
                    salesRevenue: totalSalesRevenue.toFixed(2),
                    commission: (totalSalesRevenue * 0.1).toFixed(2),
                    conversionRate: conversionRate
                });

            } catch (err) {
                console.error('Error fetching stats:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (userId && campaignId) {
            fetchStats();
        }
    }, [userId, campaignId, userType]);

    if (loading) return <div className="stats-loading">Loading stats...</div>;
    if (error) return <div className="stats-error">Error: {error}</div>;

    return (
        <div className="stats-container">
            <div className="stat-card">
                <h3>Clicks</h3>
                <p>{stats.clicks}</p>
            </div>
            <div className="stat-card">
                <h3>Conversions</h3>
                <p>{stats.conversions}</p>
            </div>
            <div className="stat-card">
                <h3>Sales Revenue</h3>
                <p>${stats.salesRevenue}</p>
            </div>
            <div className="stat-card">
                <h3>Commission</h3>
                <p>${stats.commission}</p>
            </div>
            <div className="stat-card">
                <h3>Conversion Rate</h3>
                <p>{stats.conversionRate}%</p>
            </div>
        </div>
    );
};

export default TrackingStats;