import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import './BrandProfileImage.css';

const BrandProfileImage = ({ currentImageUrl, onImageUpdate }) => {
    const [isUploading, setIsUploading] = useState(false);
    const auth = getAuth();

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('image', file);

            const token = await auth.currentUser.getIdToken(true);
            const response = await fetch('http://localhost:5001/api/upload/upload-brand-logo', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Upload failed with status: ${response.status}`);
            }

            const data = await response.json();
            onImageUpdate(data.url);
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="brand-profile-image">
            <div className="image-container">
                {currentImageUrl ? (
                    <img src={currentImageUrl} alt="Brand logo" />
                ) : (
                    <div className="placeholder">Logo</div>
                )}
            </div>
            <div className="upload-controls">
                <label className="upload-button" htmlFor="logo-upload">
                    {isUploading ? 'Uploading...' : 'Upload Logo'}
                </label>
                <input
                    id="logo-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    );
};

export default BrandProfileImage; 