import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

// Create the context
const CartContext = createContext();

// Custom hook to use the cart context
export const useCart = () => useContext(CartContext);

// Provider component
export const CartProvider = ({ children }) => {
  // Initialize state by checking for pending checkout first
  const [cartItems, setCartItems] = useState(() => {
    // Only load cart if user is logged in
    if (!auth.currentUser) {
      return [];
    }

    // First check for pending checkout items
    const pendingCart = localStorage.getItem('pendingCheckoutCart');
    if (pendingCart) {
      try {
        const parsedPendingCart = JSON.parse(pendingCart);
        // Clear pending cart after retrieving it
        localStorage.removeItem('pendingCheckoutCart');
        // Store it in regular cart
        localStorage.setItem('cart', JSON.stringify(parsedPendingCart));
        return parsedPendingCart;
      } catch (error) {
        console.error('Error parsing pending cart:', error);
      }
    }

    // If no pending cart, use regular cart
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      try {
        return JSON.parse(savedCart);
      } catch (error) {
        return [];
      }
    }
    return [];
  });

  // Calculate cart total
  const cartTotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  // Save cart items to localStorage whenever they change
  useEffect(() => {
    if (cartItems.length > 0) {
      localStorage.setItem('cart', JSON.stringify(cartItems));
    }
  }, [cartItems]);

  // Clear cart when user logs out
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setCartItems([]);
        localStorage.removeItem('cart');
        localStorage.removeItem('pendingCheckoutCart');
      }
    });

    return () => unsubscribe();
  }, []);

  const saveCartForCheckout = () => {
    if (cartItems.length > 0) {
      localStorage.setItem('pendingCheckoutCart', JSON.stringify(cartItems));
    }
  };

  const restoreCart = () => {
    const pendingCart = localStorage.getItem('pendingCheckoutCart');
    if (pendingCart) {
      try {
        const parsedCart = JSON.parse(pendingCart);
        setCartItems(parsedCart);
        localStorage.setItem('cart', JSON.stringify(parsedCart));
        localStorage.removeItem('pendingCheckoutCart');
      } catch (error) {
        console.error('Error restoring cart:', error);
      }
    }
  };

  // Add item to cart
  const addToCart = (offer, creatorInfo) => {
    setCartItems(prevItems => {
      // Check if item already exists in cart
      const existingItemIndex = prevItems.findIndex(item => item.offerId === offer.id);
      
      if (existingItemIndex >= 0) {
        // Increment quantity if item exists
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantity += 1;
        return updatedItems;
      } else {
        // Add new item to cart
        const newItem = {
          offerId: offer.id,
          creatorId: offer.creatorId || creatorInfo.id,
          title: offer.title,
          type: offer.contentType || offer.type || "Custom Content",
          price: offer.price,
          creatorName: creatorInfo.name,
          creatorImage: creatorInfo.profileImageUrl,
          platforms: offer.platforms || [],
          quantity: 1
        };
        return [...prevItems, newItem];
      }
    });
  };

  // Remove item from cart
  const removeFromCart = (offerId) => {
    setCartItems(prevItems => prevItems.filter(item => item.offerId !== offerId));
  };

  // Update quantity of an item in the cart
  const updateQuantity = (offerId, newQuantity) => {
    setCartItems(prevItems => 
      prevItems.map(item => 
        item.offerId === offerId 
          ? { ...item, quantity: Math.max(1, newQuantity) }
          : item
      )
    );
  };

  // Clear cart
  const clearCart = () => {
    setCartItems([]);
  };

  // Provide the cart context value
  const value = {
    cartItems,
    cartTotal,
    addToCart,
    removeFromCart,
    updateQuantity,
    clearCart,
    saveCartForCheckout,
    restoreCart
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
}; 