import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

const TrackingTester = () => {
  const [testResults, setTestResults] = useState({});
  const auth = getAuth();

  const createTestCampaign = async () => {
    try {
      const campaignData = {
        programName: "Test Campaign",
        brand: "Test Brand",
        productUrl: "https://www.amazon.com/test-product",
        payout: "10",
        commissionType: "Percentage",
        brandId: auth.currentUser.uid,
        createdAt: new Date().toISOString()
      };

      const docRef = await addDoc(collection(db, 'campaigns'), campaignData);
      setTestResults(prev => ({
        ...prev,
        campaignCreated: true,
        campaignId: docRef.id
      }));

      return docRef.id;
    } catch (error) {
      console.error('Error creating test campaign:', error);
    }
  };

  const testAffiliateLink = async (campaignId) => {
    try {
      // Generate affiliate link
      const affiliateLink = `http://localhost:5001/tracking/redirect?aid=${auth.currentUser.uid}&cid=${campaignId}&url=https://www.amazon.com/test-product`;

      // Simulate click
      const clickResponse = await fetch(affiliateLink);
      
      // Check if click was tracked
      const clicksQuery = query(
        collection(db, 'clicks'),
        where('campaignId', '==', campaignId)
      );
      const clicksSnapshot = await getDocs(clicksQuery);
      
      setTestResults(prev => ({
        ...prev,
        linkGenerated: true,
        linkClicked: clickResponse.ok,
        clickTracked: !clicksSnapshot.empty
      }));

      // Test conversion
      await testConversion(campaignId);
    } catch (error) {
      console.error('Error testing affiliate link:', error);
    }
  };

  const testConversion = async (campaignId) => {
    try {
      const conversionData = {
        orderId: `test-order-${Date.now()}`,
        amount: 100,
        affiliateId: auth.currentUser.uid,
        campaignId: campaignId,
        brandId: auth.currentUser.uid
      };

      const response = await fetch('http://localhost:5001/tracking/convert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(conversionData)
      });

      setTestResults(prev => ({
        ...prev,
        conversionTested: true,
        conversionSuccess: response.ok
      }));
    } catch (error) {
      console.error('Error testing conversion:', error);
    }
  };

  const runFullTest = async () => {
    const campaignId = await createTestCampaign();
    if (campaignId) {
      await testAffiliateLink(campaignId);
    }
  };

  return (
    <div className="tracking-tester">
      <h2>Tracking System Tester</h2>
      
      <button onClick={runFullTest}>Run Full Test</button>

      <div className="test-results">
        <h3>Test Results:</h3>
        <pre>{JSON.stringify(testResults, null, 2)}</pre>
      </div>
    </div>
  );
};

export default TrackingTester; 