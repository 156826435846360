import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import './Login.css';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [oobCode, setOobCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Get the reset code from URL parameters
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('oobCode');
        console.log('Reset code found:', code); // Debug log

        if (code) {
            setOobCode(code);
            // Verify the code is valid
            verifyPasswordResetCode(auth, code)
                .then(() => {
                    console.log('Code verified successfully'); // Debug log
                })
                .catch((error) => {
                    console.error('Code verification failed:', error); // Debug log
                    setError('Invalid or expired reset link. Please request a new one.');
                });
        } else {
            setError('No reset code found. Please request a new reset link.');
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (newPassword.length < 6) {
            setError('Password must be at least 6 characters');
            return;
        }

        try {
            await confirmPasswordReset(auth, oobCode, newPassword);
            console.log('Password reset successful'); // Debug log
            setIsSuccess(true);
        } catch (error) {
            console.error('Error resetting password:', error);
            setError(error.message);
        }
    };

    if (isSuccess) {
        return (
            <div className="login-container">
                <div className="login-form">
                    <h2 className="login-title">Password Reset Complete</h2>
                    
                    <div className="success-message">
                        <p>Password changed successfully!</p>
                        <p>You can now sign in with your new password.</p>
                    </div>
                    
                    <Link to="/login" className="form-submit-button">
                        Return to Login
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2 className="login-title">Reset Your Password</h2>
                
                {error && <div className="login-error">{error}</div>}
                
                <input
                    type="password"
                    className="login-input"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                
                <input
                    type="password"
                    className="login-input"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                
                <button type="submit" className="form-submit-button">
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default ResetPassword; 