import React, { useState } from 'react';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Reuse login styles

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Configure action code settings
            const actionCodeSettings = {
                url: window.location.origin + '/login', // Redirect to login after reset
                handleCodeInApp: false // Set this to false to use Firebase's default UI
            };

            await sendPasswordResetEmail(auth, email, actionCodeSettings);
            setMessage('Password reset email sent! Check your inbox.');
            
            // Let users check their email instead of auto-redirecting
            // setTimeout(() => {
            //     navigate('/login');
            // }, 3000);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2 className="login-title">Reset Password</h2>
                
                {error && <div className="login-error">{error}</div>}
                {message && <div className="login-success">{message}</div>}
                
                <p className="reset-instructions">
                    Enter your email address and we'll send you a link to reset your password.
                </p>
                
                <input
                    type="email"
                    className="login-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                
                <button type="submit" className="form-submit-button">
                    Send Reset Link
                </button>
                
                <div className="login-links">
                    <a href="/login">Back to Login</a>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword; 