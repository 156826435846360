import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { API_BASE_URL } from '../../api/api';
import './CampaignForm.css';

// Create an initial state object outside the component
const initialFormState = {
  programName: '',
  description: '',
  campaignHeadline: '',
  campaignGoal: '',
  payout: '',
  commissionType: 'Fixed Amount',
  startDate: '',
  endDate: '',
  promoCode: '',
  productUrl: '',
  campaignType: '',
  productCategory: '',
  brand: '',
  productName: '',
  productDescription: '',
  keyFeatures: '',
  uniqueSellingPoints: '',
  brandGuidelines: '',
  targetAudience: {
    interests: ''
  },
  pricing: {
    retailPrice: '',
    specialOffers: ''
  },
  contentRequirements: {
    requiredHashtags: '',
    requiredMentions: ''
  },
  platformRequirements: {
    allowedPlatforms: ''
  },
  creatorRequirements: {
    idealCreators: '',
    preferredPlatforms: '',
    creatorGuidelines: ''
  }
};

const CampaignForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [saveMessage, setSaveMessage] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 6;
  
  // Use the initial state object
  const [formData, setFormData] = useState(initialFormState);

  const [uploading, setUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({
    brand: [],
    social: []
  });

  const sections = [
    { id: 'basic', icon: '📝', title: 'Basic Information' },
    { id: 'campaign', icon: '🎯', title: 'Campaign Details' },
    { id: 'product', icon: '📦', title: 'Product Details' },
    { id: 'audience', icon: '👥', title: 'Audience' },
    { id: 'creators', icon: '🎭', title: 'Target Influencers' },
    { id: 'assets', icon: '🖼️', title: 'Brand Assets' }
  ];

  // Check authentication on mount
  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value || '' // Ensure empty string if value is null/undefined
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value || '' // Ensure empty string if value is null/undefined
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (!currentUser) {
        setSaveMessage('Please log in to create a campaign');
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        navigate('/login');
        return;
      }

      setSaveMessage('Creating campaign...');
      const token = await currentUser.getIdToken(true);

      const campaignData = {
        ...formData,
        brandImages: uploadedImages.brand,
        socialPostExamples: uploadedImages.social,
        status: 'active',
        createdAt: new Date().toISOString(),
        brandId: currentUser.uid
      };

      const response = await fetch(`${API_BASE_URL}/campaigns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(campaignData),
      });

      if (!response.ok) {
        throw new Error('Failed to create campaign');
      }

      const data = await response.json();
      const campaignId = data.id;

      setSaveMessage('Campaign created successfully!');
      navigate(`/brand/campaigns/${campaignId}`);
    } catch (error) {
      console.error('Error creating campaign:', error);
      setSaveMessage(`Error creating campaign: ${error.message}`);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleImageUpload = async (e, type) => {
    try {
      setUploading(true);
      const file = e.target.files[0];
      if (!file) return;

      const token = await currentUser.getIdToken();
      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch(`${API_BASE_URL}/upload/upload-campaign-image`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();

      if (!data.success) {
        throw new Error(data.message || 'Upload failed');
      }

      if (type === 'brand') {
        setUploadedImages(prev => ({
          ...prev,
          brand: [...prev.brand, data.url]
        }));
      } else {
        setUploadedImages(prev => ({
          ...prev,
          social: [...prev.social, data.url]
        }));
      }

    } catch (error) {
      console.error('Error uploading image:', error);
      setSaveMessage('Failed to upload image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Update the isStepValid function to make step 6 (Brand Assets) always valid
  const isStepValid = (step) => {
    switch (step) {
      case 1:
        return formData.campaignHeadline.trim() !== '' && 
               formData.brand.trim() !== '';
      
      case 2:
        return formData.campaignHeadline.trim() !== '' &&
               formData.campaignType !== '' && 
               formData.productCategory !== '' && 
               formData.startDate !== '' && 
               formData.endDate !== '';
      
      case 3:
        return formData.productUrl.trim() !== '' && 
               formData.productDescription.trim() !== '';
      
      case 4: // Social Mentions - Optional
        return true;
      
      case 5:
        return formData.creatorRequirements.idealCreators.trim() !== '' && 
               formData.creatorRequirements.preferredPlatforms !== '';
      
      case 6: // Brand Assets - Optional
        return true;
      
      default:
        return true;
    }
  };

  // Update the handleNext function
  const handleNext = () => {
    if (currentStep < totalSteps) {
      if (isStepValid(currentStep)) {
        setCurrentStep(currentStep + 1);
        setValidationError('');
      } else {
        setValidationError('Please fill out all required fields before proceeding.');
        // Scroll to top of the form
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  // Add state for validation error
  const [validationError, setValidationError] = useState('');

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="form-step">
            <div className="step-header">
              <span className="step-count">{currentStep}/{totalSteps}</span>
              <h2>Let's start with basic information</h2>
              <p>This is the foundation of your campaign. Make it count!</p>
            </div>
            
            <div className="form-fields">
              <div className="form-group">
                <label>Campaign Headline</label>
                <input
                  type="text"
                  name="campaignHeadline"
                  value={formData.campaignHeadline}
                  onChange={handleChange}
                  placeholder="Example: Drive New Users"
                  required
                />
                <span className="helper-text">This is what creators will see first</span>
              </div>

              <div className="form-group">
                <label>Brand Name</label>
                <input
                  type="text"
                  name="brand"
                  value={formData.brand}
                  onChange={handleChange}
                  placeholder="Enter your brand name"
                  required
                />
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="form-step">
            <div className="step-header">
              <span className="step-count">{currentStep}/{totalSteps}</span>
              <h2>Campaign Details</h2>
              <p>Tell us about your campaign goals</p>
            </div>
            
            <div className="form-fields grid-layout">
              {/* First Row - 2 columns */}
              <div className="form-row">
                <div className="form-group half-width">
                  <label>Commission Type</label>
                  <select
                    name="commissionType"
                    value={formData.commissionType}
                    onChange={handleChange}
                    required
                  >
                    <option value="Fixed Amount">Fixed Amount</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>

                <div className="form-group half-width">
                  <label>Payout Amount</label>
                  <input
                    type="number"
                    name="payout"
                    value={formData.payout}
                    onChange={handleChange}
                    placeholder={formData.commissionType === 'Percentage' ? 'Enter percentage' : 'Enter amount'}
                    required
                  />
                  <small className="helper-text">
                    {formData.commissionType === 'Percentage' ? '% of sale' : '$ per sale'}
                  </small>
                </div>
              </div>

              {/* Second Row - 2 columns */}
              <div className="form-row">
                <div className="form-group half-width">
                  <label>Campaign Type</label>
                  <select
                    name="campaignType"
                    value={formData.campaignType}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Campaign Type</option>
                    <option value="Product Release">Product Release</option>
                    <option value="Brand Awareness">Brand Awareness</option>
                    <option value="Product Education">Product Education</option>
                    <option value="Lead Generation">Lead Generation</option>
                    <option value="Sales Promotion">Sales Promotion</option>
                  </select>
                </div>

                <div className="form-group half-width">
                  <label>Product Category</label>
                  <select
                    name="productCategory"
                    value={formData.productCategory}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Category</option>
                    <option value="Crypto">Crypto</option>
                    <option value="Investing">Investing</option>
                    <option value="Budgeting">Budgeting</option>
                    <option value="Banking">Banking</option>
                    <option value="Retirement">Retirement</option>
                    <option value="Fintech">Fintech</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Credit">Credit</option>
                    <option value="Financial Education">Financial Education</option>
                    <option value="Student Finance">Student Finance</option>
                    <option value="Business Finance">Business Finance</option>
                    <option value="Sustainable Finance">Sustainable Finance</option>
                    <option value="Innovation">Innovation</option>
                    <option value="Security">Security</option>
                  </select>
                </div>
              </div>

              {/* Third Row - 2 columns */}
              <div className="form-row">
                <div className="form-group half-width">
                  <label>Start Date</label>
                  <input
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group half-width">
                  <label>End Date</label>
                  <input
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="form-step">
            <div className="step-header">
              <span className="step-count">{currentStep}/{totalSteps}</span>
              <h2>Product Details</h2>
              <p>Tell us about your product</p>
            </div>
            
            <div className="form-fields grid-layout">
              <div className="form-group">
                <label>Product URL</label>
                <input
                  type="url"
                  name="productUrl"
                  value={formData.productUrl}
                  onChange={handleChange}
                  placeholder="https://example.com/product"
                  pattern="https?://.*"
                  required
                />
              </div>

              <div className="form-group">
                <label>Product Description</label>
                <textarea
                  name="productDescription"
                  value={formData.productDescription}
                  onChange={handleChange}
                  placeholder="Describe your product and its key features"
                  rows="3"
                  required
                />
              </div>

              <div className="form-group">
                <label>Special Offers</label>
                <input
                  type="text"
                  name="pricing.specialOffers"
                  value={formData.pricing?.specialOffers || ''}
                  onChange={handleChange}
                  placeholder="Enter offers & discounts"
                />
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="form-step">
            <div className="step-header">
              <span className="step-count">{currentStep}/{totalSteps}</span>
              <h2>Audience</h2>
              <p>Tell us about your target audience</p>
            </div>
            
            <div className="form-fields grid-layout">
              <div className="form-group">
                <label>Target Audience</label>
                <textarea
                  name="targetAudience.interests"
                  value={formData.targetAudience.interests}
                  onChange={handleChange}
                  placeholder="List relevant interests of target audience"
                  rows="3"
                />
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div className="form-step">
            <div className="step-header">
              <span className="step-count">{currentStep}/{totalSteps}</span>
              <h2>Target Influencers</h2>
              <p>Tell us about the ideal creators for your campaign</p>
            </div>
            
            <div className="form-fields">
              <div className="form-group">
                <label>Ideal Creators for Our Brand</label>
                <textarea
                  name="creatorRequirements.idealCreators"
                  value={formData.creatorRequirements.idealCreators}
                  onChange={handleChange}
                  placeholder="Describe your ideal creator partners (style, values, audience type)"
                />
              </div>

              <div className="form-group">
                <label>Recommended Platforms</label>
                <div className="platform-checkboxes">
                  {['Instagram', 'TikTok', 'YouTube', 'Twitter', 'Facebook', 'LinkedIn'].map((platform) => (
                    <label key={platform} className="platform-checkbox">
                      <input
                        type="checkbox"
                        name={platform}
                        checked={formData.creatorRequirements.preferredPlatforms?.split(',').includes(platform) || false}
                        onChange={(e) => {
                          const currentPlatforms = formData.creatorRequirements.preferredPlatforms
                            ? formData.creatorRequirements.preferredPlatforms.split(',').filter(p => p.trim() !== '')
                            : [];
                          
                          let updatedPlatforms;
                          if (e.target.checked) {
                            updatedPlatforms = [...currentPlatforms, platform];
                          } else {
                            updatedPlatforms = currentPlatforms.filter(p => p !== platform);
                          }

                          setFormData({
                            ...formData,
                            creatorRequirements: {
                              ...formData.creatorRequirements,
                              preferredPlatforms: updatedPlatforms.join(',')
                            }
                          });
                        }}
                      />
                      <span className="platform-label">{platform}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 6:
        return (
          <div className="form-step">
            <div className="step-header">
              <span className="step-count">{currentStep}/{totalSteps}</span>
              <h2>Brand Assets</h2>
              <p>Upload your brand assets</p>
            </div>
            
            <div className="form-fields">
              <div className="form-group">
                <label>Brand Images</label>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'brand')}
                  disabled={uploading}
                />
                <div className="brand-image-preview">
                  {uploadedImages.brand.map((url, index) => (
                    <div key={index} className="uploaded-image-item">
                      <img src={url} alt={`Brand image ${index + 1}`} />
                      <button 
                        type="button" 
                        className="remove-image-btn"
                        onClick={() => {
                          setUploadedImages(prev => ({
                            ...prev,
                            brand: prev.brand.filter((_, i) => i !== index)
                          }));
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="campaign-form-container">
      {saveMessage && (
        <div className={`save-message ${saveMessage.includes('Error') ? 'error' : 'success'}`}>
          {saveMessage}
        </div>
      )}
      <div className="progress-bar">
        <div 
          className="progress-fill"
          style={{ width: `${(currentStep / totalSteps) * 100}%` }}
        />
      </div>

      <form onSubmit={handleSubmit} className="campaign-form">
        {validationError && (
          <div className="validation-error">
            {validationError}
          </div>
        )}
        
        {renderStepContent()}

        <div className="form-navigation">
          {currentStep > 1 && (
            <button 
              type="button"
              onClick={handleBack}
              className="back-button"
            >
              Back
            </button>
          )}

          {currentStep < totalSteps ? (
            <button
              type="button"
              onClick={handleNext}
              className="next-button"
            >
              Next: {sections[currentStep].title}
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSubmit}
              className="submit-button"
              disabled={uploading}
            >
              {uploading ? 'Uploading...' : 'Create Campaign'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CampaignForm;
