import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  Text,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  HStack,
  IconButton,
  Tooltip
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { generateCreatorProfile, validateSocialLinks, batchCreateUnclaimedProfiles } from '../../utils/aiProfileGenerator';

const CreateUnclaimedProfiles = () => {
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState([{
    socialLinks: {
      instagram: '',
      tiktok: '',
      youtube: '',
      twitter: '',
      facebook: '',
      twitch: ''
    }
  }]);
  const [bulkInput, setBulkInput] = useState('');
  const [results, setResults] = useState(null);
  const toast = useToast();

  const handleSocialLinkChange = (index, platform, value) => {
    const newCreators = [...creators];
    newCreators[index].socialLinks[platform] = value;
    setCreators(newCreators);
  };

  const addCreator = () => {
    setCreators([...creators, {
      socialLinks: {
        instagram: '',
        tiktok: '',
        youtube: '',
        twitter: '',
        facebook: '',
        twitch: ''
      }
    }]);
  };

  const removeCreator = (index) => {
    const newCreators = creators.filter((_, i) => i !== index);
    setCreators(newCreators);
  };

  const handleBulkInputChange = (e) => {
    setBulkInput(e.target.value);
  };

  const processBulkInput = () => {
    try {
      // Split by new lines and process each line
      const lines = bulkInput.split('\\n').filter(line => line.trim());
      const processedCreators = lines.map(line => {
        const links = {};
        // Check for Instagram
        const instaMatch = line.match(/instagram\.com\/([^/?\\s]+)/);
        if (instaMatch) links.instagram = `https://instagram.com/${instaMatch[1]}`;
        
        // Check for TikTok
        const tiktokMatch = line.match(/tiktok\.com\/@([^/?\\s]+)/);
        if (tiktokMatch) links.tiktok = `https://tiktok.com/@${tiktokMatch[1]}`;
        
        // Check for YouTube
        const youtubeMatch = line.match(/youtube\.com\/(channel|user)\/([^/?\\s]+)/);
        if (youtubeMatch) links.youtube = line.match(/youtube\.com\/.+/)[0];

        return { socialLinks: links };
      });

      setCreators(processedCreators);
      toast({
        title: 'Bulk input processed',
        description: `${processedCreators.length} creators extracted`,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error processing bulk input',
        description: error.message,
        status: 'error',
      });
    }
  };

  const generateProfiles = async () => {
    setLoading(true);
    try {
      const results = await batchCreateUnclaimedProfiles(creators);
      setResults(results);
      
      toast({
        title: 'Profiles generated',
        description: `Successfully created ${results.success.length} profiles`,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error generating profiles',
        description: error.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={8}>
      <VStack spacing={8} align="stretch">
        <Heading size="lg">Create Unclaimed Creator Profiles</Heading>

        {/* Bulk Input Section */}
        <Box>
          <Heading size="md" mb={4}>Bulk Input</Heading>
          <Text mb={2} fontSize="sm">
            Paste social media links (one creator per line)
          </Text>
          <Textarea
            value={bulkInput}
            onChange={handleBulkInputChange}
            placeholder="https://instagram.com/creator1&#10;https://tiktok.com/@creator2"
            mb={4}
            height="200px"
          />
          <Button onClick={processBulkInput} colorScheme="blue" mb={8}>
            Process Bulk Input
          </Button>
        </Box>

        {/* Individual Creator Inputs */}
        <Box>
          <Heading size="md" mb={4}>Manual Input</Heading>
          {creators.map((creator, index) => (
            <Box 
              key={index} 
              p={4} 
              borderWidth={1} 
              borderRadius="md" 
              mb={4}
              position="relative"
            >
              <IconButton
                icon={<DeleteIcon />}
                position="absolute"
                top={2}
                right={2}
                onClick={() => removeCreator(index)}
                size="sm"
                colorScheme="red"
                variant="ghost"
              />
              
              <VStack spacing={4}>
                {Object.keys(creator.socialLinks).map(platform => (
                  <FormControl key={platform}>
                    <FormLabel>{platform.charAt(0).toUpperCase() + platform.slice(1)}</FormLabel>
                    <Input
                      value={creator.socialLinks[platform]}
                      onChange={(e) => handleSocialLinkChange(index, platform, e.target.value)}
                      placeholder={`Enter ${platform} profile URL`}
                    />
                  </FormControl>
                ))}
              </VStack>
            </Box>
          ))}
          
          <Button 
            leftIcon={<AddIcon />} 
            onClick={addCreator}
            colorScheme="green"
            variant="outline"
          >
            Add Creator
          </Button>
        </Box>

        <Button
          colorScheme="blue"
          onClick={generateProfiles}
          isLoading={loading}
          loadingText="Generating Profiles..."
          size="lg"
        >
          Generate Profiles
        </Button>

        {/* Results Table */}
        {results && (
          <Box>
            <Heading size="md" mb={4}>Results</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Username</Th>
                  <Th>Status</Th>
                  <Th>Profile URL</Th>
                </Tr>
              </Thead>
              <Tbody>
                {results.success.map((profile, index) => (
                  <Tr key={`success-${index}`}>
                    <Td>{profile.username}</Td>
                    <Td><Badge colorScheme="green">Success</Badge></Td>
                    <Td>
                      <Button 
                        as="a" 
                        href={`/creator/${profile.username}`} 
                        size="sm" 
                        variant="link"
                      >
                        View Profile
                      </Button>
                    </Td>
                  </Tr>
                ))}
                {results.failed.map((profile, index) => (
                  <Tr key={`failed-${index}`}>
                    <Td>{profile.socialLinks?.instagram || 'Unknown'}</Td>
                    <Td>
                      <Tooltip label={profile.error}>
                        <Badge colorScheme="red">Failed</Badge>
                      </Tooltip>
                    </Td>
                    <Td>-</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default CreateUnclaimedProfiles; 