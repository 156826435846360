import React from 'react';
import { Navigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

const AdminRoute = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAdminStatus = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            
            if (!user) {
                setLoading(false);
                return;
            }

            try {
                const db = getFirestore();
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists() && userDoc.data().role === 'admin') {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error('Error checking admin status:', error);
            }
            
            setLoading(false);
        };

        checkAdminStatus();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAdmin) {
        console.log('Not admin, redirecting to home');
        return <Navigate to="/" />;
    }

    console.log('Is admin, showing admin content');
    return children;
};

export default AdminRoute; 